import { COMPANY } from '~/constants/Masterdata';

import { camelcaseKeysFromApi } from '~/services/kyClient';

import Article from '~/models/articles/Article';
import Concrete from '~/models/articles/Concrete';

export default class DeliveryNoteParser {
  static parseAssetMain(assetMain) {
    assetMain = camelcaseKeysFromApi(assetMain);

    if (
      [
        COMPANY.UNION_BETON,
        COMPANY.GANSER_BETON,
        COMPANY.FERTIGBETON_STIEB,
      ].includes(assetMain?.assetState?.body?.header?.issuer?.id) && // issuer
      [
        COMPANY.UNION_BETON,
        COMPANY.GANSER_BETON,
        COMPANY.FERTIGBETON_STIEB,
      ].includes(
        assetMain?.assetState?.body?.transaction?.delivery?.shipFrom
          ?.legalOrganization?.id,
      ) // supplier
    ) {
      DeliveryNoteParser.parseRohrdorfer(assetMain);
    }
  }

  static parseRohrdorfer(assetMain) {
    assetMain = camelcaseKeysFromApi(assetMain);

    // concrete always has an 8 digit article number
    if (
      assetMain?.assetState?.body?.transaction?.logisticsPackage?.[0]
        ?.lineItem?.[0]?.product?.sellerAssignedId?.length !== 8
    ) {
      return;
    }

    // replace EA with MTQ for concrete
    if (
      assetMain?.assetState?.body?.transaction?.logisticsPackage?.[0]
        ?.lineItem?.[0]?.delivery?.productUnit?.quantityType ===
      Article.UNIT.EA.STANDARDISED
    ) {
      assetMain.assetState.body.transaction.logisticsPackage[0].lineItem[0].delivery.productUnit.quantityType =
        Article.UNIT.MTQ.STANDARDISED;
    }

    if (
      !assetMain.assetState.body.transaction.logisticsPackage[0].lineItem[0]
        .product.name
    ) {
      return;
    }

    let [strengthClass, exposureClass, consistency, largestGrain] =
      assetMain.assetState.body.transaction.logisticsPackage[0].lineItem[0].product.name.split(
        ' ',
      );

    // only map concrete (e.g. Sandbeton is filtered out here)
    if (!Concrete.STRENGTH_CLASSES.includes(strengthClass)) {
      return;
    }

    // handle the case if the corrosion class is mapped to the largest grain
    if (!Concrete.LARGEST_GRAINS.includes(largestGrain)) {
      largestGrain = '';
    }

    assetMain.assetState.body.transaction.logisticsPackage[0].lineItem[0].product =
      {
        concrete: {
          consistency,
          exposureClass,
          largestGrain,
          strengthClass,
        },
        ...assetMain.assetState.body.transaction.logisticsPackage[0].lineItem[0]
          .product,
      };
  }
}
