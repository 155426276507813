import urlcat from 'urlcat';

import { type UUID } from '~/types/common';
import { type EntityType } from '~/types/entity';

/**
 * Paths for all endpoints of the VESTIGAS API used in the application.
 */
export const ENDPOINT = {
  ARTICLE: {
    CREATE: () => 'article_master/article',
    DELETE: (articleId: UUID) =>
      urlcat('article_master/article/:articleId', { articleId }),
    GET_ALL: () => 'article_master/article',
    UPDATE: (articleId: UUID) =>
      urlcat('article_master/article/:articleId', { articleId }),
  },
  CATEGORY: {
    CREATE: () => 'article_master/category',
    DELETE: (categoryId: UUID) =>
      urlcat('article_master/category/:categoryId', { categoryId }),
    DELETE_ICON: (categoryIconId: UUID) =>
      urlcat('article_master/category/:categoryIconId/icon', {
        categoryIconId,
      }),
    GET_ALL: () => 'article_master/category',
    GET_ICON: (categoryIconId: UUID) =>
      urlcat('article_master/category/:categoryIconId/icon', {
        categoryIconId,
      }),
    UPDATE: (categoryId: UUID) =>
      urlcat('article_master/category/:categoryId', { categoryId }),
    UPDATE_ICON: (categoryIconId: UUID) =>
      urlcat('article_master/category/:categoryIconId/icon', {
        categoryIconId,
      }),
  },
  COMPANY: {
    DELETE_LOGO: (companyId: UUID): string =>
      urlcat('company/:companyId/logo', { companyId }),
    GET: (companyId: UUID): string =>
      urlcat('company/:companyId', { companyId }),
    GET_ALL: () => 'company',
    GET_ALLOWED_BULK: (companyId: UUID) =>
      urlcat('company/bulk_allowed_companies', { company_id: companyId }), // endpoint uses query parameters in snake case
    GET_LOGO: (companyId: UUID): string =>
      urlcat('company/:companyId/logo', { companyId }),
    UPLOAD_LOGO: (companyId: UUID): string =>
      urlcat('company/:companyId/logo', { companyId }),
    UPSERT: (companyId: UUID): string =>
      urlcat('company/:companyId', { companyId }),
  },
  COST_CENTER: {
    CREATE: () => 'accounting_reference',
    GET: (costCenterId: UUID): string =>
      urlcat('accounting_reference/:costCenterId', { costCenterId }),
    GET_ALL: () => 'accounting_reference',
    GET_BULK: () => 'accounting_reference/query/bulk',
    UPDATE: (costCenterId: UUID): string =>
      urlcat('accounting_reference/:costCenterId', { costCenterId }),
  },
  CUSTOM_FIELD: {
    GET_ALL: () => 'custom_field/all',
    GET_BULK: () => 'custom_field/query/bulk',
  },
  CUSTOMER_SUBSCRIPTION: {
    GET_ALL: () => 'customer_subscription',
  },
  DELIVERY_NOTE: {
    GET: (deliveryNoteId: UUID): string =>
      urlcat('asset/delivery_note/:deliveryNoteId', { deliveryNoteId }),
    GET_ALL: () => 'asset/delivery_note/search',
    GET_ANALYTICS: () => 'analytics',
    GET_ANALYTICS_EXPORT: (requestId: UUID) =>
      urlcat('analytics/report/export/:requestId', { requestId }),
    GET_ASSET_EXPORT: (requestId: UUID) =>
      urlcat('asset/delivery_note/export/:requestId', { requestId }),
    GET_ATTACHMENT: (chainId: UUID, digest: string): string =>
      urlcat('asset/delivery_note/:chainId/attachment', { chainId, digest }),
    GET_CHAINS: (): string => 'asset/delivery_note/ids/chains',
    GET_FILTER_SUGGESTIONS: () => 'analytics/suggestions',
    GET_HANDWRITTEN_SIGNATURE: (chainId: UUID): string =>
      urlcat('asset/delivery_note/chain/:chainId/handwritten_signature', {
        chainId,
      }),
    GET_REPORT: () => 'analytics/report',
    GET_SEARCH_COUNT: () => 'asset/delivery_note/search/count',
    GET_SHARED_WITH_USERS: (deliveryNoteId: UUID) =>
      urlcat('asset/:deliveryNoteId/share', { deliveryNoteId }),
    GET_SYNC_GATEWAY_CHANGES: (since: number) =>
      urlcat('_changes', {
        feed: 'longpoll',
        include_docs: true,
        since,
      }), // endpoint uses query parameters in snake case
    REQUEST_ANALYTICS_EXPORT: () => 'analytics/report/export/request',
    REQUEST_ASSET_EXPORT: () => 'asset/delivery_note/export/request',
  },
  IGNORED_ARTICLES: {
    GET_ALL: () => 'invoice/ignored_article',
  },
  INVOICE: {
    GET_ALL: ({
      createdAfter,
      incoming,
      limit,
      offset,
      outgoing,
    }: {
      createdAfter?: string;
      incoming?: boolean;
      limit?: number;
      offset?: number;
      outgoing?: boolean;
    }) =>
      urlcat('asset/invoice', {
        created_after: createdAfter,
        incoming,
        limit,
        offset,
        outgoing,
      }), // endpoint uses query parameters in snake case
  },
  ORGANIZATIONAL_UNIT: {
    ADD_TO: (organizationalUnitId: UUID, entityType: EntityType): string =>
      urlcat('access_control/org_unit/:organizationalUnitId/:entityType', {
        entityType,
        organizationalUnitId,
      }),
    CREATE: () => 'access_control/org_unit',
    DELETE: (organizationalUnitId: UUID): string =>
      urlcat('access_control/org_unit/:organizationalUnitId', {
        organizationalUnitId,
      }),
    DELETE_FROM: (
      organizationalUnitId: UUID,
      entityType: EntityType,
      entityId: UUID,
    ): string =>
      urlcat(
        'access_control/org_unit/:organizationalUnitId/:entityType/:entityId',
        {
          entityId,
          entityType,
          organizationalUnitId,
        },
      ),
    GET: (organizationalUnitId: UUID): string =>
      urlcat('access_control/org_unit/:organizationalUnitId', {
        organizationalUnitId,
      }),
    GET_ALL: () => 'access_control/org_unit/test_all',
    UPDATE: (organizationalUnitId: UUID): string =>
      urlcat('access_control/org_unit/:organizationalUnitId', {
        organizationalUnitId,
      }),
  },
  PDF_TEMPLATES: {
    GET_ALL: () => 'pdf_template',
  },
  PERMISSION: {
    CREATE: (
      subjectType: Pick<EntityType, 'user' | 'user_group'>,
      subjectId: UUID,
      targetType: EntityType,
      targetId: UUID,
    ): string =>
      urlcat(
        'access_control/granted_permissions/:subjectType/:subjectId/:targetType/:targetId',
        {
          subjectId,
          subjectType,
          targetId,
          targetType,
        },
      ),
    DELETE: (permissionGrantId: UUID): string =>
      urlcat('access_control/granted_permissions/:permissionGrantId', {
        permissionGrantId,
      }),
    UPDATE: (permissionGrantId: UUID): string =>
      urlcat('access_control/granted_permissions/:permissionGrantId', {
        permissionGrantId,
      }),
  },
  SIGNATURE_FIELD: {
    GET_ALL: () => 'signature_field/all',
  },
  SITE: {
    CREATE: () => 'site',
    GET: (siteId: UUID): string => urlcat('site/:siteId', { siteId }),
    GET_ALL: () => 'site',
    GET_BULK: () => 'site/query/bulk',
    UPDATE: (siteId: UUID): string => urlcat('site/:siteId', { siteId }),
    UPDATE_COST_CENTERS: (siteId: UUID): string =>
      urlcat('site/:siteId/accounting_reference', { siteId }),
  },
  USER: {
    COMPANY_ACCOUNT: () => 'user/company_account',
    CREATE: (): string => 'admin/users',
    GET: (userId: UUID): string => urlcat('user/:userId', { userId }),
    GET_ALL: () => 'user',
    GET_BULK: () => 'user/query/bulk',
    GET_COMPANY: (userId: UUID): string =>
      urlcat('user/:userId/company', { userId }),
    GET_DATA: (includeCompanyInfo: boolean): string =>
      urlcat('user/data', { include_company_info: includeCompanyInfo }),
    // endpoint uses query parameter 'include_company_info'!
    GET_PROFILE_PICTURE: () => 'user/profile_picture',

    GET_PUSH_NOTIFICATION_SETTINGS: (userId: UUID): string =>
      urlcat('user/notification/settings', { user_id: userId }),
    // endpoint uses query parameter 'user_id'!
    GET_SETTINGS: (userId: UUID): string =>
      urlcat('admin/user/:userId/settings', { userId }),
    GET_SIGNATURE_ROLES: (userId: UUID): string =>
      urlcat('user/:userId/signature_roles', { userId }),
    UPDATE: (userId: UUID): string => urlcat('admin/users/:userId', { userId }),
    UPDATE_PUSH_NOTIFICATION_SETTINGS: (userId: UUID): string =>
      urlcat('user/notification/settings', { user_id: userId }),
    UPDATE_SETTINGS: (userId: UUID): string =>
      urlcat('admin/user/:userId/settings', { userId }),
    UPDATE_SIGNATURE_ROLES: (userId: UUID): string =>
      urlcat('user/:userId/signature_roles', { userId }), // endpoint uses query parameter 'user_id'!
  },
  USER_GROUP: {
    ADD_TO: (
      userGroupId: UUID,
      memberType: Pick<EntityType, 'user' | 'user_group'>,
    ): string =>
      urlcat('access_control/user_group/:userGroupId/:memberType', {
        memberType,
        userGroupId,
      }),
    CREATE: (): string => 'access_control/user_group',
    DELETE_FROM: (
      userGroupId: UUID,
      memberType: Pick<EntityType, 'user' | 'user_group'>,
      memberId: UUID,
    ): string =>
      urlcat('access_control/user_group/:userGroupId/:memberType/:memberId', {
        memberId,
        memberType,
        userGroupId,
      }),
    GET: (userGroupId: UUID): string =>
      urlcat('access_control/user_group/:userGroupId', { userGroupId }),
    GET_ALL: () => 'access_control/user_group/test_all',
    UPDATE: (userGroupId: UUID): string =>
      urlcat('access_control/user_group/:userGroupId', { userGroupId }),
  },
  VEHICLE: {
    CREATE: () => 'vehicle',
    GET: (vehicleId: UUID): string =>
      urlcat('vehicle/:vehicleId', { vehicleId }),
    GET_ALL: () => 'vehicle',
    UPDATE: (vehicleId: UUID): string =>
      urlcat('vehicle/:vehicleId', { vehicleId }),
  },
} as const;
