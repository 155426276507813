import clsx from 'clsx';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import {
  useQueryAttachment,
  useQueryHandwrittenSignature,
} from '~/data/deliveryNote';

import AttachmentModel from '~/models/deliveries/Attachment';

import { LightTooltipWide } from '~/utils/componentUtils';
import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

import { Spinner } from '~/components/Spinner';

import { AttachmentImage } from './AttachmentImage';
import { AttachmentJson } from './AttachmentJson';
import { AttachmentPdf } from './AttachmentPdf';

export const Attachment = ({ attachment }) => {
  const { chainId, contentType, digest, type } = attachment;

  const {
    data: attachmentData,
    isError: isErrorAttachment,
    isLoading: isLoadingAttachment,
  } = useQueryAttachment(
    { chainId, digest },
    {
      select: (data) => URL.createObjectURL(data),
    },
  );
  const {
    data: signatureData,
    isError: isErrorSignature,
    isLoading: isLoadingSignature,
  } = useQueryHandwrittenSignature(chainId, {
    enabled: Boolean(chainId) && type === 'signature',
    select: (data) => URL.createObjectURL(data),
  });

  const isLoading = isLoadingAttachment || isLoadingSignature;
  const isError = isErrorAttachment || isErrorSignature;

  const isImage =
    contentType === AttachmentModel.CONTENT_TYPE.JPEG ||
    contentType === AttachmentModel.CONTENT_TYPE.PNG ||
    contentType === AttachmentModel.CONTENT_TYPE.SVG;
  const isPdf = contentType === AttachmentModel.CONTENT_TYPE.PDF;
  const isJson = contentType === AttachmentModel.CONTENT_TYPE.JSON;

  const getTile = (content) => {
    return (
      <div
        className={clsx(
          'size-40 overflow-hidden rounded border border-gray-300',
          isImage ? 'bg-white' : 'bg-gray-300',
        )}
      >
        {content}
      </div>
    );
  };

  const getErrorTile = () => {
    return getTile(
      <div className="flex h-full w-full items-center justify-center text-white">
        <LightTooltipWide title="Anhang konnte nicht geladen werden.">
          <ErrorOutlineIcon className="text-white" fontSize="large" />
        </LightTooltipWide>
      </div>,
    );
  };

  if (isError) {
    return getErrorTile();
  }

  if (isLoading) {
    return getTile(<Spinner white />);
  }

  if (isImage) {
    return getTile(<AttachmentImage url={attachmentData || signatureData} />);
  }

  if (isPdf) {
    return getTile(<AttachmentPdf url={attachmentData} />);
  }

  if (isJson) {
    if (!UserUtils.isVestigasAccount()) {
      return null;
    }

    return getTile(<AttachmentJson url={attachmentData} />);
  }

  Log.error(
    null,
    new EnumValueNotFoundException(`Invalid attachment type: ${type}`),
  );

  return getErrorTile();
};
