import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';

import DeliveryNoteAction from '~/models/deliveries/DeliveryNoteAction';

import DatagridUtils from '~/utils/datagridUtils';
import ObjectUtils from '~/utils/objectUtils';

import { Attachment } from '~/components/attachments/Attachment';

export default function DeliveryNoteHistoryDetailPanel({ row, rowHeight }) {
  const columnProps = {
    renderCell: (params) => DatagridUtils.displayCellTooltip(params),
  };

  const getDeclinedTable = () => {
    if (!row.declinedArticles?.length) {
      return null;
    }

    const styledHeader = { headerClassName: 'bg-declined' };

    const columns = [
      {
        field: 'articleNumber',
        headerName: 'Artikel-Nr.',
        width: 100,
        ...columnProps,
        ...styledHeader,
      },
      {
        field: 'articleType',
        headerName: 'Artikel',
        width: 300,
        ...columnProps,
        ...styledHeader,
      },
      {
        field: 'partialAcceptStatusString',
        headerName: 'Grund',
        width: 300,
        ...columnProps,
        ...styledHeader,
      },
      {
        field: 'reason',
        headerName: 'Kommentar',
        width: 300,
        ...columnProps,
        ...styledHeader,
      },
      {
        field: '',
        flex: 1,
        headerName: '',
        ...columnProps,
        ...styledHeader,
      }, // empty column needed to fix bug with white tile in header (VGSD-1633)
    ];

    const rows = row.declinedArticles?.map((article, index) => {
      return {
        articleNumber: article.number,
        articleType: article.type ?? 'Artikel',
        id: index,
        partialAcceptStatusString:
          article.acceptArticle.partialAcceptStatusToString(),
        reason: article.acceptArticle.reason,
      };
    });

    return (
      <div className="flex flex-col gap-1">
        <span className="font-bold">Abgelehnte Artikel</span>
        <DataGridPro
          className="bg-white"
          columns={columns}
          rows={rows}
          rowHeight={rowHeight}
          getRowClassName={(params) => `bg-declined`}
          hideFooter
        />
      </div>
    );
  };

  const getApprovedTable = () => {
    if (!row.approvedArticles?.length) {
      return null;
    }

    const styledHeader = { headerClassName: 'bg-white' };

    const columns = [
      {
        field: 'articleNumber',
        headerName: 'Artikel-Nr.',
        width: 100,
        ...columnProps,
        ...styledHeader,
      },
      {
        field: 'articleType',
        headerName: 'Artikel',
        width: 300,
        ...columnProps,
        ...styledHeader,
      },
      {
        field: 'reason',
        headerName: 'Kommentar',
        width: 600,
        ...columnProps,
        ...styledHeader,
      },
      {
        field: '',
        flex: 1,
        headerName: '',
        ...columnProps,
        ...styledHeader,
      }, // empty column needed to fix bug with white tile in header (VGSD-1633)
    ];

    const rows = row.approvedArticles?.map((article, index) => {
      return {
        articleNumber: article.number,
        articleType: article.type ?? 'Artikel',
        id: index,
        reason: article.acceptArticle.reason,
      };
    });

    return (
      <div className="flex flex-col gap-1">
        <span className="font-bold">Angenommene Artikel</span>
        <DataGridPro
          className="bg-white"
          columns={columns}
          rows={rows}
          rowHeight={rowHeight}
          hideFooter
        />
      </div>
    );
  };

  const getChangesTable = () => {
    if (row.changes.length === 0) {
      return null;
    }

    const columns = [
      {
        field: 'name',
        headerName: 'Eigenschaft',
        width: 290,
        ...columnProps,
      },
      {
        field: 'oldValue',
        headerName: 'Alter Wert',
        width: 300,
        ...columnProps,
      },
      {
        field: 'newValue',
        headerName: 'Neuer Wert',
        width: 300,
        ...columnProps,
      },
    ];

    const rows = row.changes.map((change, index) => {
      const formatter = change.formatter ? change.formatter : (value) => value;

      return {
        id: index,
        name: change.name,
        newValue: ObjectUtils.isEmptyValue(formatter(change.newValue))
          ? '-'
          : formatter(change.newValue),
        oldValue: ObjectUtils.isEmptyValue(formatter(change.oldValue))
          ? '-'
          : formatter(change.oldValue),
      };
    });

    return (
      <div className="flex flex-col">
        <span className="font-bold">Änderungen</span>
        <DataGridPro
          className="bg-white"
          columns={columns}
          rows={rows}
          rowHeight={rowHeight}
          hideFooter
        />
      </div>
    );
  };

  const getSignerInformation = () => {
    if (!DeliveryNoteAction.isOnBehalfAction(row.action)) {
      return null;
    }

    return (
      <div>
        <div className="font-bold">Bildschirmunterschrift durch</div>
        <div>
          {row.signerTradeContactPersonName +
            ' (' +
            row.signerCompanyName +
            ')'}
        </div>
      </div>
    );
  };

  const getAttachments = () => {
    if (row.attachments.length === 0) {
      return null;
    }

    return (
      <div className="flex flex-col gap-1">
        <span className="font-bold">Anhänge</span>
        <div className="flex items-center gap-2">
          {row.attachments.map((attachment) => (
            <Attachment key={attachment.digest} attachment={attachment} />
          ))}
        </div>
      </div>
    );
  };

  if (
    !row.declinedArticles?.length &&
    row.changes.length === 0 &&
    row.attachments.length === 0
  ) {
    return (
      <div className="flex w-full flex-col gap-4 bg-gray-50 px-16 py-4 shadow-inner">
        {row.action}
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-8 bg-gray-50 px-16 py-4 shadow-inner">
      {getDeclinedTable()}
      {getApprovedTable()}
      {getChangesTable()}
      {getSignerInformation()}
      {getAttachments()}
    </div>
  );
}
