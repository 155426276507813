import clsx from 'clsx';
import { type PropsWithChildren } from 'react';

import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';

type P = PropsWithChildren<{
  isOpen: boolean;
  onOpen: (isOpen: boolean) => void;
  testId?: string;
}>;

export const MultiItemsManagerHeader = ({
  children,
  isOpen,
  onOpen,
  testId,
}: P) => (
  <button
    onMouseDown={() => {
      onOpen(!isOpen);
    }}
    onClick={(event) => {
      event.stopPropagation();
      event.preventDefault();
    }}
    className={clsx('flex w-full items-center gap-1.5 px-2 py-1 text-lg', {
      'border-b': isOpen,
    })}
    data-testid={testId}
  >
    {children}
    <div className="ml-auto size-10 p-2">
      <ChevronRightIcon
        className={clsx('-mt-1 transition-transform', {
          '-rotate-90': isOpen,
          'rotate-90': !isOpen,
        })}
      />
    </div>
  </button>
);
