import React, { useState, useEffect } from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import BilledItem from '~/models/billingState/BilledItem';
import { SettledIcon } from '~/assets/icons';
import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import InvoicesService from '~/services/invoices.service';
import InvoiceModel from '~/models/invoices/Invoice';
import { promiseHandler } from '~/utils/promiseHandler';
import { InvoiceLinks } from '~/utils/componentUtils';
import { type DeliveryNoteType } from './types';

type DeliveryNoteReferencedInvoicesProps = {
  deliveryNote: DeliveryNoteType;
};

const DeliveryNoteReferencedInvoices = ({
  deliveryNote,
}: DeliveryNoteReferencedInvoicesProps) => {
  const [referencedInvoices, setReferencedInvoices] = useState([]);

  const loadReferencedInvoices = async () => {
    const invoices = [];

    for (
      let index = 0;
      index < deliveryNote?.referencedInvoices?.length;
      index++
    ) {
      const [invoice, error] = await promiseHandler(
        InvoicesService.getInvoiceById(
          deliveryNote.referencedInvoices[index],
          InvoiceModel.DIRECTION.INCOMING,
        ),
      );

      if (error) {
        Log.error(
          'Failed to load invoice by id: ' +
            deliveryNote.referencedInvoices[index],
        );
        Log.productAnalyticsEvent(
          'Failed to load invoice',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
        continue;
      }

      invoices.push(invoice);
    }

    setReferencedInvoices(invoices);
  };

  useEffect(() => {
    loadReferencedInvoices();
  }, [deliveryNote?.referencedInvoices]);

  if (
    deliveryNote?.settledStatus === BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY
  ) {
    return (
      <div className="flex-s-c h-40px rounded-5px p-8px border-successBase text-successBase w-full">
        <SettledIcon className="mr-8px" />
        <span className="mr-8px">
          {BilledItem.SETTLED_STATUS.FULLY_SETTLED.DESCRIPTION}:
        </span>
        <span>
          <InvoiceLinks
            invoices={referencedInvoices}
            className="text-successBase bold"
          />
        </span>
      </div>
    );
  }

  if (
    deliveryNote?.settledStatus === BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY
  ) {
    return (
      <div className="flex-s-c h-40px rounded-5px p-8px border-warningBase text-warningBase w-full">
        <SettledIcon className="mr-8px" />
        <span className="mr-8px">
          {BilledItem.SETTLED_STATUS.PARTLY_SETTLED.DESCRIPTION}:
        </span>
        <span>
          <InvoiceLinks
            invoices={referencedInvoices}
            className="text-warningBase bold"
          />
        </span>
      </div>
    );
  }

  Log.error(
    null,
    new EnumValueNotFoundException(
      'Invalid settled status: ' + deliveryNote?.settledStatus,
    ),
  );
  Log.productAnalyticsEvent(
    'Failed to display referenced invoices',
    Log.FEATURE.DELIVERY_NOTE,
    Log.TYPE.ERROR,
  );

  return null;
};

export default withErrorBoundary(
  DeliveryNoteReferencedInvoices,
  'Verknüpfte Rechnungen konnten nicht geladen werden.',
);
