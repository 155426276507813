import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type DeliveryNoteReportSearchParams } from './useQueryDeliveryNoteReport';

type ExportReportResponse = {
  requestId: string;
};

/**
 * Sends a request to initiate report export.
 *
 * @param {DeliveryNoteReportSearchParams} searchParams - Parameters for filtering and configuring the report
 * @returns {Promise<ExportReportResponse>} The response containing request_id
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Analytics/operation/request_delivery_note_analytics_excel_analytics_report_export_request_post
 */
export const requestExportReport = async (
  searchParams: DeliveryNoteReportSearchParams,
): Promise<ExportReportResponse> => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.DELIVERY_NOTE.REQUEST_ANALYTICS_EXPORT(), {
        json: searchParams,
      })
      .json<ExportReportResponse>();

    return response;
  } catch (error) {
    console.error('Failed to request export report:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for requesting export of delivery note report.
 *
 * @returns {UseMutationResult<ExportReportResponse, Error, DeliveryNoteReportSearchParams>}
 */
export const useMutationRequestDeliveryNoteExcel = () => {
  return useMutation({
    mutationFn: async (searchParams: DeliveryNoteReportSearchParams) =>
      toast.promise(requestExportReport(searchParams), {
        error: 'Fehler beim Laden der Excel-Lieferung!',
        loading: 'Excel-Lieferung wird geladen...',
        success: 'Excel-Lieferung wurde erfolgreich abgeschlossen!',
      }),
  });
};
