import UniversalCommunication from '~/models/masterdata/UniversalCommunication';

import { withErrorBoundary } from '~/ui/atoms';

import { Tile } from './Tile';

export const SalesTile = withErrorBoundary(
  ({ className, style }: ComponentStyling) => {
    const { email, phone } = UniversalCommunication.getSalesContact();

    const rows = [
      {
        title: 'E-Mail',
        value: (
          <a href={`mailto:${email}`} target="_blank">
            {email}
          </a>
        ),
      },
      {
        title: 'Telefon',
        value: <a href={`tel:${phone}`}>{phone}</a>,
      },
    ];

    return (
      <Tile title={'VESTIGAS Vertrieb'} className={className} style={style}>
        <table className="border-separate border-spacing-y-2">
          <tbody>
            {rows.map(({ title, value }) => (
              <tr key={title} className="text-left align-top">
                <th className="pr-2 font-normal text-gray-400">{title}</th>
                <td className="w-full font-semibold">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="absolute bottom-0 w-full text-center font-semibold">
          Kontaktiere jederzeit gerne unseren Vertrieb bei Interesse an einer
          Vorstellung der VESTIGAS Vollversion!
        </div>
      </Tile>
    );
  },
  null,
);

SalesTile.displayName = 'SalesTile';
