import ms from 'ms';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { queryKeysUser } from './queryKeys';

import { type UUID } from '~/types/common';

export type UserCompanyAccount = {
  id: UUID;
};

/**
 * Fetches company data for a specific user from the API.
 * @param {UUID} userId - The ID of the user to fetch company data for
 * @returns {Promise<UserCompanyAccount |undefined>} The company data if successful, undefined otherwise
 * @see https://app.dev.vestigas.com/redoc#tag/User/operation/get_company_account_for_user_user_company_account_get
 */
export const fetchUserCompanyAccount = async (): Promise<
  UserCompanyAccount | undefined
> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.USER.COMPANY_ACCOUNT())
      .json<UserCompanyAccount>();

    return response;
  } catch (error) {
    Log.error('Error fetching user company data', error);
    throw error;
  }
};

/**
 * Generates the query options for the user company query.
 *
 * @param {Object} params - Parameters for the query
 * @param {UUID} params.userId - The ID of the user to fetch company data for
 * @param {Object} params.options - Additional options for the query
 * @returns {Object} The query options including queryKey, queryFn, and other settings.
 */
export const getUserCompanyAccountQueryOptions = ({
  options,
}: {
  userId: UUID;
  options?: Parameters<typeof useQuery>[0];
}) => {
  return {
    queryFn: async () => fetchUserCompanyAccount(),
    queryKey: queryKeysUser.getCompanyAccount(),
    staleTime: ms('10m'),
    ...options,
  };
};

/**
 * React Query based custom hook for getting the company data for a specific user.
 * @param {UUID} userId - The ID of the user to fetch company data for
 * @param {Object} options - Additional options for the useQuery hook
 * @returns {UseQueryResult<UserCompany['company'] | undefined>} The result of the useQuery hook
 */
export const useQueryUserCompanyAccount = (
  userId: UUID,
  options?: Parameters<typeof useQuery>[0],
): UseQueryResult<UserCompanyAccount | undefined> =>
  useQuery(
    getUserCompanyAccountQueryOptions({
      options,
      userId,
    }),
  );
