import { type PropsWithChildren, type ReactNode } from 'react';

import { cn } from '~/utils/tailwind';

import { withErrorBoundary } from '~/ui/atoms';

import { Spinner } from '~/components/Spinner';

type P = PropsWithChildren<{
  icon: ReactNode;
  isError: boolean;
  isLoading: boolean;
  rightAnnotation: string;
  title: string;
  width?: 1 | 2;
}> &
  ComponentStyling;

export const Tile = withErrorBoundary(
  ({
    children,
    className,
    icon,
    isError,
    isLoading,
    rightAnnotation,
    style,
    title,
    width = 1,
  }: P) => {
    const content = () => {
      if (isError) {
        return (
          <p className="w-full text-center text-gray-400">
            Daten konnten nicht geladen werden.
          </p>
        );
      }

      if (isLoading) {
        return <Spinner />;
      }

      return children;
    };

    return (
      <div
        className={cn([
          'flex h-80 flex-col gap-2 overflow-hidden rounded-md bg-white p-4 shadow-lg',
          {
            'col-span-1': width === 1,
            'col-span-2': width === 2,
            'col-span-3': width === 3,
          },
          className,
        ])}
        style={style}
      >
        <div className="flex h-8 w-full items-center justify-between gap-2 leading-8">
          <div className="flex flex-none items-center gap-2">
            {icon}
            <h3 className="text-xl font-semibold">{title}</h3>
          </div>
          <div className="ml-auto truncate text-gray-400">
            {rightAnnotation}
          </div>
        </div>
        <div className="relative flex flex-1 flex-col justify-center">
          {content()}
        </div>
      </div>
    );
  },
  null,
);

Tile.displayName = 'Tile';
