import React from 'react';
import { Grid } from '@mui/material';
import { withErrorBoundary } from '~/ui/atoms';
import { EditingHistorySafe as EditingHistory } from './EditingHistorySafe';
import ValueGroup from '~/models/deliveries/ValueGroup';
import Address from '~/models/masterdata/Address';
import { dateUtils } from '~/utils/dateUtils';
import { type BuyerAndSeller, type DeliveryNoteType } from './types';

type DeliveryNoteTraderProps = {
  deliveryNote: DeliveryNoteType;
  trader?: BuyerAndSeller;
};

const DeliveryNoteTrader = ({
  deliveryNote,
  trader,
}: DeliveryNoteTraderProps) => {
  const getEmailSubject = () => {
    return `Lieferung ${ValueGroup.getCurrentValue(deliveryNote.number)} vom ${dateUtils.getFormattedDate_safe(
      ValueGroup.getCurrentValue(deliveryNote.dlnDate),
      dateUtils.DATE_FORMAT.DD_MM_YYYY,
      dateUtils.DATE_FORMAT.YYYY_MM_DD__HH_mm_ss_SSSSSS,
    )}`;
  };

  const handlePhoneClick = () => {
    if (trader?.universalCommunication?.phone) {
      window.location = `tel: ${ValueGroup.getCurrentValue(trader.universalCommunication.phone)}`;
    }
  };

  const handleEmailClick = () => {
    if (trader?.universalCommunication?.email) {
      window.location = `mailto:${ValueGroup.getCurrentValue(
        trader.universalCommunication.email,
      )}?subject=${getEmailSubject()}`;
    }
  };

  return (
    <>
      <Grid container spacing="20px">
        <Grid item xs={12} lg={3}>
          <div>Händler</div>
          <div className="bold">
            <EditingHistory value={trader?.name} />
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div>Adresse</div>
          <div className="bold">
            <EditingHistory
              value={ValueGroup.applyFunction(
                trader?.address,
                Address.getConcatenatedAddress,
              )}
            />
          </div>
        </Grid>
      </Grid>
      {trader?.universalCommunication?.hasCommunicationInformation() && (
        <Grid container className="mt-20px">
          <Grid item xs={12} lg={3}>
            <div>Kontaktdaten</div>
            {trader?.universalCommunication?.hasPhone() && (
              <div className="bold cursor-pointer" onClick={handlePhoneClick}>
                <EditingHistory value={trader.universalCommunication.phone} />
              </div>
            )}
            {trader?.universalCommunication?.hasEmail() && (
              <div className="bold cursor-pointer" onClick={handleEmailClick}>
                <EditingHistory value={trader.universalCommunication.email} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withErrorBoundary(
  DeliveryNoteTrader,
  'Daten des Händlers konnten nicht geladen werden.',
);
