import { memo } from 'react';

import UniversalCommunication from '~/models/masterdata/UniversalCommunication';

export const PackageBasicRestrictionMessage = memo(() => {
  const { email, phone } = UniversalCommunication.getSalesContact();

  const handleEmailClick = () => {
    window.location.href = `mailto:${email}?subject=VESTIGAS-Vollversion`;
  };

  const handlePhoneClick = () => {
    window.location.href = `tel:${phone}`;
  };

  return (
    <span>
      Diese Funktion ist nicht im Paket Basic enthalten. Bei Interesse an einer
      Vorstellung der höheren Pakete, kontaktiere uns über{' '}
      <span className="bold cursor-pointer" onClick={handleEmailClick}>
        {email}
      </span>{' '}
      oder{' '}
      <span className="bold cursor-pointer" onClick={handlePhoneClick}>
        {phone}
      </span>
      .
    </span>
  );
});

PackageBasicRestrictionMessage.displayName = 'PackageBasicRestrictionMessage';
