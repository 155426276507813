import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import {
  useQueryDeliveryNote,
  useQueryDeliveryNoteChains,
  useGetPermittedUsersOfSites,
} from '~/data/deliveryNote';
import {
  fetchUser,
  fetchUserCompany,
  fetchUserCompanyAccount,
  queryKeysUser,
} from 'data/user';

import { setPageTitle } from '~/redux/menuSlice';

import Article from '~/models/articles/Article';
import BilledItem from '~/models/billingState/BilledItem';
import DeliveryNoteAction from '~/models/deliveries/DeliveryNoteAction';
import DeliveryNoteModel from '~/models/deliveries/DeliveryNote';
import ValueGroup from '~/models/deliveries/ValueGroup';

import BrowserUtils from '~/utils/browserUtils';

import DocumentLoadingPage from '~/components/DocumentLoadingPage';

import { ErrorBoundary, withErrorBoundary } from '~/ui/atoms';

import { DeliveryCategoryIcon } from '../DeliveryCategoryIcon';
import { DeliveryStatus } from '../DeliveryStatus';

import { DeliveryNoteButtons } from './DeliveryNoteButtons';
import { DeliveryNoteLogos } from './DeliveryNoteLogos';
import { DeliveryNoteMetaData } from './deliveryNoteMetaData/DeliveryNoteMetaData';
import DeliveryNoteArticleList from './DeliveryNoteArticleList';
import DeliveryNoteContractParties from './DeliveryNoteContractParties';
import DeliveryNoteHistory from './DeliveryNoteHistory';
import DeliveryNoteParty from './DeliveryNoteParty';
import DeliveryNoteReferencedInvoices from './DeliveryNoteReferencedInvoices';
import DeliveryNoteTrader from './DeliveryNoteTrader';

import { type DeliveryDirection, type DeliveryNoteType } from './types';

export const DeliveryNote = withErrorBoundary(() => {
  const { id } = useParams();
  const location = useLocation();

  const [deliveryNoteActions, setDeliveryNoteActions] = useState([]);
  const [isGridXsScreen, setIsGridXsScreen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [customData, setCustomData] = useState(null);

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const { data: deliveryNote, isLoading: isLoadingDeliveryNote } =
    useQueryDeliveryNote(id);

  const {
    data: deliveryNoteChainsData,
    isLoading: isLoadingDeliveryNoteChains,
  } = useQueryDeliveryNoteChains([id], {
    enabled: Boolean(id),
  });

  const permittedSiteIds =
    deliveryNote?.permittedToSites?.map(({ id }) => id) || [];

  const { data: permittedUsers, isLoading: isLoadingPermittedUsersOfSite } =
    useGetPermittedUsersOfSites(permittedSiteIds);

  useEffect(() => {
    updateIsGridXsScreen();
    window.addEventListener('resize', updateIsGridXsScreen);

    dispatch(setPageTitle('Lieferung'));
    document.title = 'VESTIGAS - Lieferung';

    return () => {
      window.removeEventListener('resize', updateIsGridXsScreen);
    };
  }, [dispatch]);

  useEffect(() => {
    if (deliveryNote && deliveryNoteChainsData?.length > 0) {
      loadDeliveryNoteHistory(deliveryNote);
    }
  }, [deliveryNote, deliveryNoteChainsData?.length]);

  const loadDeliveryNoteHistory = async (deliveryNote: DeliveryNoteType) => {
    const newDln = cloneDeep(deliveryNote);
    const attachments = [];

    const newDeliveryNoteActions = await Promise.all(
      deliveryNote.assetChain?.map(async (chainId, index) => {
        const chain = deliveryNoteChainsData.find(({ id }) => id === chainId);

        const newDeliveryNoteAction = new DeliveryNoteAction(chain, newDln);
        if (chain) {
          newDln.attachmentHandler.addAttachmentsFromChain(chain);

          const newAttachment = newDln.attachmentHandler.attachments;

          attachments.push(newAttachment);

          const fetchUserFunction = () =>
            queryClient.fetchQuery({
              queryFn: () => fetchUser(newDeliveryNoteAction.user.id),
              queryKey: queryKeysUser.get(newDeliveryNoteAction.user.id),
            });

          const fetchUserCompanyFunction = () =>
            queryClient.fetchQuery({
              queryFn: () => fetchUserCompany(newDeliveryNoteAction.user.id),
              queryKey: queryKeysUser.getCompany(newDeliveryNoteAction.user.id),
            });

          const fetchUserCompanyAccountFunction = () =>
            queryClient.fetchQuery({
              queryFn: () => fetchUserCompanyAccount(),
              queryKey: queryKeysUser.getCompanyAccount(),
            });

          await newDeliveryNoteAction.initUserWithReactQueryFn(
            fetchUserFunction,
            fetchUserCompanyFunction,
            fetchUserCompanyAccountFunction,
          );

          await newDeliveryNoteAction.initCompanyWithReactQueryFn(
            fetchUserCompanyFunction,
          );

          newDln.addChainToHistory(
            chain,
            newDeliveryNoteAction.company?.name,
            index === 0,
          );
        }

        return newDeliveryNoteAction;
      }) || [],
    );

    newDln.mergeHistory();

    setCustomData(newDln.customData);

    setAttachments(attachments[0]);

    setDeliveryNoteActions(newDeliveryNoteActions);
  };

  const updateIsGridXsScreen = () => {
    setIsGridXsScreen(BrowserUtils.isGridXsScreen());
  };

  const displayContractParties = () => {
    return (
      JSON.stringify(deliveryNote?.seller) !==
        JSON.stringify(deliveryNote?.supplier) ||
      JSON.stringify(deliveryNote?.buyer) !==
        JSON.stringify(deliveryNote?.recipient)
    );
  };

  const shouldDisplayArticleList = (deliveryDirection: DeliveryDirection) => {
    if (
      deliveryDirection === Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY &&
      deliveryNote?.returnedArticles?.length > 0
    ) {
      return true;
    }

    if (
      deliveryDirection === Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY &&
      deliveryNote?.deliveredArticles?.length > 0
    ) {
      return true;
    }

    // If no articles are delivered or returned, the article list should be displayed anyway.
    if (
      deliveryDirection === Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY &&
      deliveryNote?.deliveredArticles?.length === 0 &&
      deliveryNote?.returnedArticles?.length === 0
    ) {
      return true;
    }

    return false;
  };

  const getContentOnSmallScreen = () => {
    return (
      <Grid container spacing={4} justifyContent="space-between">
        <Grid item xs={6} lg={3}>
          <div className="h-full rounded-md bg-white p-4 shadow-lg">
            <DeliveryNoteMetaData
              deliveryNote={deliveryNote}
              permittedUsersLoading={isLoadingPermittedUsersOfSite}
              requestedUsersLoading={isLoadingPermittedUsersOfSite}
              sharedUsersLoading={isLoadingPermittedUsersOfSite}
              permittedUsers={permittedUsers}
              attachments={attachments}
              customData={customData}
            />
          </div>
        </Grid>
        <Grid item xs={6} lg={3}>
          <div className="flex w-full flex-col gap-2">
            <div className="rounded-md bg-white p-4 shadow-lg">
              <DeliveryNoteParty
                type={DeliveryNoteModel.PROCESS_ROLE.SUPPLIER.KEY}
                company={deliveryNote?.supplier}
                deliveryNote={deliveryNote}
              />
            </div>
            <div className="rounded-md bg-white p-4 shadow-lg">
              <DeliveryNoteParty
                type={DeliveryNoteModel.PROCESS_ROLE.CARRIER.KEY}
                company={deliveryNote?.carrier}
                deliveryNote={deliveryNote}
              />
            </div>
            <div className="rounded-md bg-white p-4 shadow-lg">
              <DeliveryNoteParty
                type={DeliveryNoteModel.PROCESS_ROLE.RECIPIENT.KEY}
                company={deliveryNote?.recipient}
                deliveryNote={deliveryNote}
                displayContractParties={displayContractParties()}
              />
            </div>
            {ValueGroup.getCurrentValue(deliveryNote?.trader?.name) ? (
              <div className="rounded-md bg-white p-4 shadow-lg">
                <DeliveryNoteTrader
                  trader={deliveryNote?.trader}
                  deliveryNote={deliveryNote}
                />
              </div>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} lg={9}>
          <div className="flex w-full flex-col gap-4">
            {displayContractParties() ? (
              <div className="rounded-md bg-white p-4 shadow-lg">
                <DeliveryNoteContractParties
                  seller={deliveryNote?.seller}
                  buyer={deliveryNote?.buyer}
                  buyerId={deliveryNote?.buyerId}
                  deliveryNote={deliveryNote}
                />
              </div>
            ) : null}
            {deliveryNote?.settledStatus &&
            deliveryNote?.settledStatus !==
              BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY ? (
              <div className="rounded-md bg-white shadow-lg">
                <DeliveryNoteReferencedInvoices deliveryNote={deliveryNote} />
              </div>
            ) : null}
            {shouldDisplayArticleList(
              Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY,
            ) && (
              <div className="rounded-md bg-white shadow-lg">
                <DeliveryNoteArticleList
                  deliveryNote={deliveryNote}
                  deliveryDirection={
                    Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY
                  }
                />
              </div>
            )}
            {shouldDisplayArticleList(
              Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY,
            ) && (
              <div className="rounded-md bg-white shadow-lg">
                <DeliveryNoteArticleList
                  deliveryNote={deliveryNote}
                  deliveryDirection={
                    Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY
                  }
                />
              </div>
            )}
            <div className="rounded-md bg-white shadow-lg">
              <DeliveryNoteHistory
                deliveryNoteActions={deliveryNoteActions}
                currentAcceptState={deliveryNote?.acceptState}
                loading={false}
                showChanges={location?.state?.showChanges}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    );
  };

  if (isLoadingDeliveryNote || isLoadingDeliveryNoteChains) {
    return (
      <DocumentLoadingPage
        loading={isLoadingDeliveryNote || isLoadingDeliveryNoteChains}
        documentType="Lieferung"
      />
    );
  }

  return (
    <div className="main-padding">
      <Grid
        container
        spacing={4}
        justifyContent="space-between"
        className="mb-4"
      >
        <Grid item xs={12} lg={3} className="flex items-end">
          <div className="h-12 w-full">
            <DeliveryStatus
              processState={deliveryNote?.processState}
              combinedState={deliveryNote?.combinedState}
              settledStatus={deliveryNote?.settledStatus}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={9}
          className="flex items-center justify-between gap-8"
        >
          <ErrorBoundary>
            <div className="flex h-12 min-w-24 items-center justify-center rounded-lg bg-white">
              <DeliveryCategoryIcon category={deliveryNote?.processCategory} />
            </div>
          </ErrorBoundary>
          <DeliveryNoteLogos
            supplier={deliveryNote?.supplier}
            carrier={deliveryNote?.carrier}
            recipient={deliveryNote?.recipient}
            acceptStateSupplier={deliveryNote?.acceptStateSupplier}
            acceptStateCarrier={deliveryNote?.acceptStateCarrier}
            acceptStateRecipient={deliveryNote?.acceptStateRecipient}
            acceptStateOnBehalfSupplier={
              deliveryNote?.acceptStateOnBehalfSupplier
            }
            acceptStateOnBehalfCarrier={
              deliveryNote?.acceptStateOnBehalfCarrier
            }
            acceptStateOnBehalfRecipient={
              deliveryNote?.acceptStateOnBehalfRecipient
            }
            processState={deliveryNote?.processState}
          />
          <DeliveryNoteButtons
            deliveryNote={deliveryNote}
            refreshDeliveryNote={() => {}}
          />
        </Grid>
      </Grid>
      {isGridXsScreen ? (
        getContentOnSmallScreen()
      ) : (
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} lg={3}>
            <div className="h-full rounded-md bg-white p-4 shadow-lg">
              <DeliveryNoteMetaData
                deliveryNote={deliveryNote}
                permittedUsersLoading={isLoadingPermittedUsersOfSite}
                requestedUsersLoading={isLoadingPermittedUsersOfSite}
                sharedUsersLoading={isLoadingPermittedUsersOfSite}
                permittedUsers={permittedUsers}
                attachments={attachments}
                customData={customData}
              />
            </div>
          </Grid>
          <Grid item xs={12} lg={9}>
            <div className="flex w-full flex-col gap-2">
              {deliveryNote?.settledStatus &&
              deliveryNote?.settledStatus !==
                BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY ? (
                <div className="rounded-md bg-white shadow-lg">
                  <DeliveryNoteReferencedInvoices deliveryNote={deliveryNote} />
                </div>
              ) : null}
              {shouldDisplayArticleList(
                Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY,
              ) && (
                <div className="rounded-md bg-white shadow-lg">
                  <DeliveryNoteArticleList
                    deliveryNote={deliveryNote}
                    deliveryDirection={
                      Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY
                    }
                  />
                </div>
              )}
              {shouldDisplayArticleList(
                Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY,
              ) && (
                <div className="rounded-md bg-white shadow-lg">
                  <DeliveryNoteArticleList
                    deliveryNote={deliveryNote}
                    deliveryDirection={
                      Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY
                    }
                  />
                </div>
              )}
              <div className="mt-2 rounded-md bg-white p-4 shadow-lg">
                <DeliveryNoteParty
                  type={DeliveryNoteModel.PROCESS_ROLE.SUPPLIER.KEY}
                  company={deliveryNote?.supplier}
                  deliveryNote={deliveryNote}
                />
              </div>
              <div className="rounded-md bg-white p-4 shadow-lg">
                <DeliveryNoteParty
                  type={DeliveryNoteModel.PROCESS_ROLE.CARRIER.KEY}
                  company={deliveryNote?.carrier}
                  deliveryNote={deliveryNote}
                />
              </div>
              <div className="rounded-md bg-white p-4 shadow-lg">
                <DeliveryNoteParty
                  type={DeliveryNoteModel.PROCESS_ROLE.RECIPIENT.KEY}
                  company={deliveryNote?.recipient}
                  deliveryNote={deliveryNote}
                  displayContractParties={displayContractParties()}
                />
              </div>
              {ValueGroup.getCurrentValue(deliveryNote?.trader?.name) ? (
                <div className="rounded-md bg-white p-4 shadow-lg">
                  <DeliveryNoteTrader
                    trader={deliveryNote?.trader}
                    deliveryNote={deliveryNote}
                  />
                </div>
              ) : null}
              {displayContractParties() ? (
                <div className="rounded-md bg-white p-4 shadow-lg">
                  <DeliveryNoteContractParties
                    seller={deliveryNote?.seller}
                    buyer={deliveryNote?.buyer}
                    buyerId={deliveryNote?.buyerId}
                  />
                </div>
              ) : null}
              <div className="mt-2 rounded-md bg-white shadow-lg">
                <DeliveryNoteHistory
                  deliveryNoteActions={deliveryNoteActions}
                  currentAcceptStateSupplier={deliveryNote?.acceptStateSupplier}
                  currentAcceptStateCarrier={deliveryNote?.acceptStateCarrier}
                  currentAcceptStateRecipient={
                    deliveryNote?.acceptStateRecipient
                  }
                  currentAcceptStateOnBehalfSupplier={
                    deliveryNote?.acceptStateOnBehalfSupplier
                  }
                  currentAcceptStateOnBehalfCarrier={
                    deliveryNote?.acceptStateOnBehalfCarrier
                  }
                  currentAcceptStateOnBehalfRecipient={
                    deliveryNote?.acceptStateOnBehalfRecipient
                  }
                  loading={isLoadingDeliveryNote || isLoadingDeliveryNoteChains}
                  showChanges={location?.state?.showChanges}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <div className="min-h-2rem" />
      {/* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding
        A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied.
        However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */}
    </div>
  );
}, 'Lieferung konnte nicht geladen werden.');
