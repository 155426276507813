import { useHandlePassedMasterData } from './useHandlePassedMasterData';
import { useLoadCustomFields } from './useLoadCustomFields';
import { useLoadIncomingInvoices } from './useLoadIncomingInvoices';
import { useLoadOutgoingInvoices } from './useLoadOutgoingInvoices';
import { useLoadUserData } from './useLoadUserData';

/**
 * Custom hook for loading all necessary data into the Redux state.
 *
 * This hook combines various data loading functions from other custom hooks
 * to provide a single, comprehensive data loading solution. It loads user data,
 * incoming and outgoing invoices, profile picture, custom fields, and handles
 * passed master data.
 *
 * @returns {Object} An object containing the loadData function.
 */
export const useLoadData = () => {
  const { loadUserData } = useLoadUserData();
  const { loadIncomingInvoices } = useLoadIncomingInvoices(); // TODO: useQueryInvoices
  const { loadOutgoingInvoices } = useLoadOutgoingInvoices(); // TODO: useQueryInvoices
  const { handlePassedMasterData } = useHandlePassedMasterData();
  const { loadCustomFields } = useLoadCustomFields(); // -> useQueryCustomFields

  /**
   * Loads all data that should be in Redux state.
   * This function calls all the individual data loading functions
   * to populate the Redux store with necessary data.
   */
  const loadData = () => {
    loadUserData();
    loadIncomingInvoices();
    loadOutgoingInvoices();
    handlePassedMasterData();
    loadCustomFields();
  };

  return {
    loadData,
  };
};
