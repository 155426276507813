import { memo, useState, useCallback } from 'react';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';
import { ComponentTestIds } from '~/constants/test-ids';

import { useQueryUsers } from '~/data/user';

import LocalStorageService from '~/services/localStorage.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../SettingsTable';

import { columns } from './constants';
import { selectUsers } from './selectUsers';

export const UsersTable = withErrorBoundary(
  memo(() => {
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: PAGINATION_PAGE_SIZE_DEFAULT,
    });
    const [sortModel, setSortModel] = useState([
      {
        field: 'email',
        sort: 'asc',
      },
    ]);

    const [filterActive, setFilterActive] = useState(true);

    const [searchString, setSearchString] = useState(undefined);

    const { data, isFetching, isPending, isPlaceholderData, refetch } =
      useQueryUsers(
        {
          limit: paginationModel.pageSize,
          offset: paginationModel.page * paginationModel.pageSize,
          orderBy: sortModel[0]?.field,
          searchString,
          sort: sortModel[0]?.sort,
          ...(filterActive !== '' && { filterActive }),
        },
        {
          select: selectUsers,
        },
      );

    const handleSetActiveFilter = useCallback((filterActive) => {
      setFilterActive(filterActive);
    }, []);

    const handlePaginationModelChange = useCallback((newModel) => {
      setPaginationModel(newModel);
    }, []);

    const handleSortModelChange = useCallback((newModel) => {
      setSortModel(newModel);
    }, []);

    const handleSearchStringChange = useCallback((newSearchString) => {
      // Show results from start when search string changes.
      setPaginationModel({
        ...paginationModel,
        page: 0,
      });
      setSearchString(newSearchString);
    }, []);

    const filterFunction = useCallback((string) => string, []); // We filter server side -> prevent the DataGrid from filtering.

    return (
      <SettingsTable
        key="UsersTable"
        columns={columns}
        entity="user"
        filterActive={filterActive}
        filterFunction={filterFunction}
        hasActiveStateFilter
        hasMultiPermissionGrantEditing
        isLoading={isPending || (isFetching && isPlaceholderData)}
        items={data?.users ?? []}
        localStorageKey={LocalStorageService.USERS_TABLE}
        multiPermissionGrantDefaultSubjectType={
          PermissionGrant.SUBJECT_TYPE.USER.KEY
        }
        multiPermissionGrantFixedPicker={PermissionGrant.TYPE.SUBJECT}
        onPaginationModelChange={handlePaginationModelChange}
        onSearchStringChange={handleSearchStringChange}
        onSortModelChange={handleSortModelChange}
        paginationModel={paginationModel}
        refreshData={refetch}
        rows={data?.rows ?? []}
        setActiveFilter={handleSetActiveFilter}
        sortBy="email"
        sortModel={sortModel}
        title="Benutzer"
        totalRowCount={data?.totalCount ?? 0}
        searchInputTestId={ComponentTestIds.SEARCH_INPUT.INPUT}
      />
    );
  }),
  'Nutzer konnten nicht geladen werden.',
);

UsersTable.displayName = 'UsersTable';
