import { validate as isUUID } from 'uuid';

/**
 * Checks if a filter name represents a custom field filter.
 * Custom fields can be identified by the first part of the property key, which is a UUID.
 * For example, "123e4567-e89b-12d3-a456-426614174000_customField" would be a valid custom field filter name.
 *
 * @param {string} filterName - The name of the filter to check
 * @returns {boolean} True if the filter name represents a custom field filter, false otherwise
 */
export const isCustomFieldFilter = (filterName: string) =>
  Boolean(filterName && isUUID(filterName?.split('_')?.[0]));

export type FilterContext = {
  component?: 'filterGroup' | 'filterModel' | 'query';
  isCustomField: boolean;
  page: 'dashboard' | 'delivery' | 'home';
};

/**
 * Returns an object with context information where a filter is applied by merging default values with provided partial context.
 *
 * @param {Partial<FilterContext>} filterContext - Partial filter context to merge with defaults
 * @returns {FilterContext} Complete filter context with all required fields
 */
export const getFilterContext = (
  filterContext: Partial<FilterContext>,
): FilterContext => ({
  component: null,
  isCustomField: false,
  page: null,
  ...filterContext,
});

type CustomFieldData = {
  id: string;
  level: 'global' | 'item';
  type: string;
};

type ReportFieldConfig = {
  customFieldId?: string;
  name: string;
};

/**
 * Returns a merged list of custom and standard fields for generating a delivery note report.
 * Custom fields are processed based on their level (`global` or `item`) and assigned a specific name.
 * Standard fields are added without modification.
 *
 * @param {CustomFieldData} customFields - An object containing a list of custom fields to be included in the report configuration
 * @returns {ReportFieldConfig[]} A list of report field configurations, including both custom and standard fields
 */
export const getDeliveryNoteReportConfig = (
  customFields: CustomFieldData[],
): ReportFieldConfig[] => {
  const customFieldsConfig = customFields.map(({ id, level }) => {
    const customFieldName =
      level === 'global'
        ? 'custom_field_global_level'
        : 'custom_field_item_level';

    return {
      customFieldId: id,
      name: customFieldName,
    };
  });

  const standardFieldsConfig = [
    { name: 'dln_date' },
    { name: 'dln_nr' },
    { name: 'supplier_assigned_site_name' },
    { name: 'loading_location' },
    { name: 'confirmed_accounting_reference_name' },
    { name: 'confirmed_accounting_reference_id' },
    { name: 'confirmed_site_name' },
    { name: 'confirmed_site_id' },
    { name: 'process_state' },
    { name: 'dln_accept_state' },
    { name: 'article_name' },
    { name: 'supplier_name' },
    { name: 'supplier_id' },
    { name: 'recipient_name' },
    { name: 'recipient_id' },
    { name: 'billed_state' },
  ];

  return [...customFieldsConfig, ...standardFieldsConfig];
};
