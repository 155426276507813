import { useQuery } from '@tanstack/react-query';

import CustomFieldService from '~/services/customField.service';

import { queryKeysCustomField } from './queryKeys';

// This query uses an deprecated endpoint for fetching ALL custom fields ever, which is not technically possible for large accounts.
export const useQueryCustomFieldsLegacy = (options) =>
  useQuery({
    queryFn: CustomFieldService.getAllCustomFields,
    queryKey: queryKeysCustomField.getAllLegacy(),
    ...options,
  });
