import { Grid } from '@mui/material';

import ValueGroup from '~/models/deliveries/ValueGroup';

import UnitUtils from '~/utils/unitUtils';

import { EditingHistorySafe as EditingHistory } from '~/components/deliveries/deliveryNote/EditingHistorySafe';

import { withErrorBoundary } from '~/ui/atoms';

export const ArticleWeighing = withErrorBoundary(({ weighing }) => {
  const grossWeightSet = () => {
    return (
      ValueGroup.getCurrentValue(weighing?.gross?.weight?.value) &&
      ValueGroup.getCurrentValue(weighing?.gross?.weight?.unit)
    );
  };

  const tareWeightSet = () => {
    return (
      ValueGroup.getCurrentValue(weighing?.tare?.weight?.value) &&
      ValueGroup.getCurrentValue(weighing?.tare?.weight?.unit)
    );
  };

  const weighingPersonSet = () => {
    return ValueGroup.getCurrentValue(weighing?.person?.name);
  };

  const formatWeighing = (weighing) => {
    const value = UnitUtils.roundAndFormatDe_safe(weighing.value);
    const unit = UnitUtils.getDescriptiveUnit(weighing.unit);

    return (
      (value === null ? '-' : value + ' ' + unit) +
      (weighing.scaleId
        ? '\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0' + weighing.scaleId
        : '')
    );
  };

  return (
    <Grid container spacing={4} justifyContent="space-between">
      {grossWeightSet() ? (
        <Grid item xs={12} lg={6}>
          <div>Bruttogewicht</div>
          <div className="font-bold">
            <EditingHistory
              value={ValueGroup.applyFunction(
                {
                  scaleId: weighing?.gross?.scaleId,
                  unit: weighing?.gross?.weight?.unit,
                  value: weighing?.gross?.weight?.value,
                },
                formatWeighing,
              )}
            />
          </div>
        </Grid>
      ) : null}
      {tareWeightSet() ? (
        <Grid item xs={12} lg={6}>
          <div>Taragewicht</div>
          <div className="font-bold">
            <EditingHistory
              value={ValueGroup.applyFunction(
                {
                  scaleId: weighing?.tare?.scaleId,
                  unit: weighing?.tare?.weight?.unit,
                  value: weighing?.tare?.weight?.value,
                },
                formatWeighing,
              )}
            />
          </div>
        </Grid>
      ) : null}
      {weighingPersonSet() ? (
        <Grid item xs={12} lg={6}>
          <div>Wäger</div>
          <div className="font-bold">
            <EditingHistory value={weighing?.person?.name} />
          </div>
        </Grid>
      ) : null}
    </Grid>
  );
}, 'Daten der Wägung konnten nicht geladen werden.');

ArticleWeighing.displayName = 'ArticleWeighing';
