import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { type GridRowSelectionModel } from '@mui/x-data-grid';

import { ROUTE } from '~/constants/Route';
import { DeliveriesTestIds } from '~/constants/test-ids';

import { useQueryCustomFields } from '~/data/customField';
import {
  getSearchBody,
  useQueryDeliveryNotes,
  type DeliveryNoteAssetsSearchParams,
  useMutationRequestDeliveryNoteAssetExport,
} from '~/data/deliveryNote';
import { useQueryUserData } from '~/data/user';

import { setDeliveryList_filterModel } from '~/redux/filtersSlice';
import { replaceBrowsableDeliveryNotes } from '~/redux/deliveryNotesSlice';

import ExportService from '~/services/export.service';
import DeliveriesService from '~/services/deliveries.service';
import LocalStorageService from '~/services/localStorage.service';
import ToastService from '~/services/toast.service';

import FilterNew from '~/models/filters/FilterNew';

import ArrayUtils from '~/utils/arrayUtils';
import BrowserUtils from '~/utils/browserUtils';
import { dateUtils } from '~/utils/dateUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import UserUtils from '~/utils/userUtils';

import ContextMenu from '~/components/menu/ContextMenu';
import { BasicTable } from '~/components/BasicTable';

import { withErrorBoundary } from '~/ui/atoms';

import { MapDirectDeliveryNoteForm } from '../../deliveryNoteActions/MapDirectDeliveryNoteForm';
import { RequestSignatureForm } from '../../deliveryNoteActions/RequestSignatureForm';
import { ShareDeliveryNoteForm } from '../../deliveryNoteActions/ShareDeliveryNoteForm';

import {
  formatDateInLocalTime,
  getColumns,
  selectDeliveryFilters,
} from './utils';

import { allExportedColumns, hiddenColumns } from './constants';
import { type FilterModel, type SortModel } from './types';
import { fetchDeliveryNoteAssetExport } from '~/data/deliveryNote/useQueryDeliveryNoteAssetExport';

// This is utter shit: we have to map the names back and forth between frontend names and backend names to ensure that the sorting works.
const sortModelMap = {
  supplier: 'supplierName',
  toSiteSupplier: 'supplierAssignedSiteName',
};
const reverseSortModelMap = {
  supplierAssignedSiteName: 'toSiteSupplier',
  supplierName: 'supplier',
};
const reverseMapSortModel = (sortModel: SortModel) =>
  sortModel.map((model) => ({
    ...model,
    field: reverseSortModelMap[model.field] ?? model.field,
  }));

export const DeliveryList = withErrorBoundary(() => {
  const history = useHistory();

  const dispatch = useDispatch();

  const deliveryFilters = useSelector(selectDeliveryFilters);

  const { data: customFields, isLoading: isLoadingCustomFields } =
    useQueryCustomFields();

  const { data: currentUser } = useQueryUserData(true);

  const companyAccountInfo = currentUser?.companyAccountInfo ?? {};
  const featureFlags =
    currentUser?.companyAccountInfo?.data?.featureFlags ?? {};
  const userType = currentUser?.userSettings?.userType ?? '';

  const isSchickerAccount = UserUtils.isSchickerAccount(companyAccountInfo);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: 'modifiedOn',
      sort: 'desc',
    },
  ]);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [excelData, setExcelData] = useState([]);

  const [mapDirectDeliveryNoteFormOpen, setMapDirectDeliveryNoteFormOpen] =
    useState(false);
  const [
    mapDirectDeliveryNoteToSiteSupplierNames,
    setMapDirectDeliveryNoteToSiteSupplierNames,
  ] = useState([]);
  const [mapDirectDeliveryNoteIssuerIds, setMapDirectDeliveryNoteIssuerIds] =
    useState([]);
  const [
    mapDirectDeliveryNoteDefaultSelectedCostCenterId,
    setMapDirectDeliveryNoteDefaultSelectedCostCenterId,
  ] = useState(null);
  const [
    mapDirectDeliveryNoteDefaultSelectedSiteId,
    setMapDirectDeliveryNoteDefaultSelectedSiteId,
  ] = useState(null);

  const [
    requestDeliveryNoteSignatureFormOpen,
    setRequestDeliveryNoteSignatureFormOpen,
  ] = useState(false);
  const [
    requestDeliveryNoteSignatureFormOpenDeliveryNoteId,
    setRequestDeliveryNoteSignatureFormOpenDeliveryNoteId,
  ] = useState(null);

  const [shareDeliveryNoteFormOpen, setShareDeliveryNoteFormOpen] =
    useState(false);
  const [
    shareDeliveryNoteFormOpenDeliveryNoteId,
    setShareDeliveryNoteFormOpenDeliveryNoteId,
  ] = useState(null);

  const [contextMenu, setContextMenu] = useState(null);

  const {
    dateRange,
    filterModel,
    query,
    selectedAcceptState,
    selectedArticle,
    selectedArticleNumber,
    selectedCostCenter, // the selected cost centers from the deliveries filters
    selectedCostCenters, // the globally selected cost centers
    selectedCustomFields,
    selectedFromSite,
    // selectedNumber, // the DLN number;not supported in API search body yet
    selectedPermittedCostCenters,
    selectedPermittedToSites,
    selectedProcessState,
    selectedRecipient,
    selectedSettledStatus,
    selectedSites, // the globally selected sites
    selectedSupplier,
    selectedToSiteRecipient,
    selectedToSiteSupplier,
  } = deliveryFilters;

  useEffect(() => {
    setPaginationModel((previousPaginationModel) => ({
      ...previousPaginationModel,
      page: 0, // reset pagination to first page if any of the filters change.
    }));
  }, [
    dateRange,
    filterModel,
    query,
    selectedAcceptState,
    selectedArticle,
    selectedArticleNumber,
    selectedCostCenter,
    selectedCostCenters,
    selectedCustomFields,
    selectedFromSite,
    selectedPermittedCostCenters,
    selectedPermittedToSites,
    selectedProcessState,
    selectedRecipient,
    selectedSettledStatus,
    selectedSites,
    selectedSupplier,
    selectedToSiteRecipient,
    selectedToSiteSupplier,
  ]);

  const deliveryNotesQueryBody = getSearchBody({
    definedCustomFields: customFields,
    filterGroups: {
      acceptStates: selectedAcceptState,
      article: selectedArticle,
      articleNumber: selectedArticleNumber,
      dateRange,
      filterModel,
      fromSite: [...selectedFromSite, ...selectedSites],
      permittedCostCenters: selectedPermittedCostCenters,
      permittedToSites: selectedPermittedToSites,
      processStates: selectedProcessState,
      query,
      recipients: selectedRecipient,
      selectedCostCenters: [...selectedCostCenter, ...selectedCostCenters],
      selectedCustomFields,
      settledStatus: selectedSettledStatus,
      suppliers: selectedSupplier,
      toSiteRecipient: selectedToSiteRecipient,
      toSiteSupplier: selectedToSiteSupplier,
    },
  });

  const {
    data: deliveryNotes,
    isError,
    isLoading: isLoadingDeliveryNotes,
    isFetching: isFetchingDeliveryNotes,
  } = useQueryDeliveryNotes({
    ...deliveryNotesQueryBody,
    limit: paginationModel.pageSize,
    offset: paginationModel.page * paginationModel.pageSize,
    orderBy: sortModel[0]?.field,
    sortOrder: sortModel[0]?.sort,
  });

  const { mutateAsync: requestDeliveryNoteAssetExport } =
    useMutationRequestDeliveryNoteAssetExport();

  const csvOptions = useMemo(() => {
    if (isSchickerAccount) {
      return {
        delimiter: ';',
        includeHeaders: false,
      };
    }

    return {};
  }, [companyAccountInfo]);

  const onPdfExport = (downloadOption: string) => {
    ExportService.exportDeliveryNotes(rowSelectionModel, downloadOption);
  };

  const exportConfig = useMemo(
    () =>
      customFields?.length > 0
        ? allExportedColumns.map((name) => ({ name }))
        : [],
    [allExportedColumns, customFields],
  );

  const handleExcelExportFromBackend = async () => {
    if (rowSelectionModel.length === 0) {
      ToastService.info(
        [ToastService.MESSAGE.DLN_DOWNLOAD_NONE_SELECTED],
        ToastService.ID.DLN_DOWNLOAD_NONE_SELECTED,
      );
      return;
    }

    const body: DeliveryNoteAssetsSearchParams = {
      assetIds: rowSelectionModel,
      exportConfig,
      exportOrder: [],
      filterConfig: null,
      format: 'excel',
    };
    const { requestId } = await requestDeliveryNoteAssetExport(body);

    const data = await fetchDeliveryNoteAssetExport(requestId);

    ExportService.exportExcelFromBlob(data);
  };

  const handleCSVExportFromBackend = async () => {
    if (rowSelectionModel.length === 0) {
      ToastService.info(
        [ToastService.MESSAGE.DLN_DOWNLOAD_NONE_SELECTED],
        ToastService.ID.DLN_DOWNLOAD_NONE_SELECTED,
      );
      return;
    }

    const body: DeliveryNoteAssetsSearchParams = {
      assetIds: rowSelectionModel,
      exportConfig,
      exportOrder: [],
      filterConfig: null,
      format: 'csv',
    };
    const { requestId } = await requestDeliveryNoteAssetExport(body);

    const data = await fetchDeliveryNoteAssetExport(requestId);

    ExportService.exportCSVFromBlob(data);
  };

  const onRowSelectionModelChange = (event: GridRowSelectionModel) => {
    Log.info(
      'Change selection value of selected delivery notes',
      {
        from: rowSelectionModel,
        to: event,
      },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select delivery note',
      Log.FEATURE.DELIVERY_LIST,
    );

    setRowSelectionModel(event);
  };

  const handleFilterModelChange = (event: FilterModel) => {
    Log.info(
      'Change filter value of filter model',
      {
        from: filterModel,
        to: event,
      },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter', Log.FEATURE.DELIVERY_LIST);

    dispatch(setDeliveryList_filterModel(event));
  };

  const onOpenDeliveryNote = (id: string) => {
    Log.productAnalyticsEvent('Open delivery note', Log.FEATURE.DELIVERY_LIST);

    dispatch(replaceBrowsableDeliveryNotes(rowSelectionModel));

    history.push(`${ROUTE.DELIVERY_NOTE.ROUTE}/${id}`);
  };

  const onOpenDeliveryNoteInNewTab = () => {
    Log.productAnalyticsEvent(
      'Open delivery note in new tab',
      Log.FEATURE.DELIVERY_LIST,
    );

    dispatch(replaceBrowsableDeliveryNotes(rowSelectionModel));

    BrowserUtils.openNewTab(`${ROUTE.DELIVERY_NOTE.ROUTE}/${contextMenu.id}`);
    onCloseContextMenu();
  };

  const onOpenDeliveryDataQuality = (id: string) => {
    Log.productAnalyticsEvent(
      'Open delivery note data quality',
      Log.FEATURE.SUPPLIER_OVERVIEW,
    );
    history.push(`${ROUTE.DELIVERY_NOTE_DATA_QUALITY.ROUTE}/${id}`);
  };

  const onOpenContextMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();

    if (contextMenu) {
      // Repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu. Other native context menus might behave different.
      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context men
      setContextMenu(null);
      return;
    }

    Log.productAnalyticsEvent('Open context menu', Log.FEATURE.MENU);
    setContextMenu({
      id: event.currentTarget.dataset.id,
      mouseX: Number(event.clientX) + 2,
      mouseY: Number(event.clientY) - 6,
    });
  };

  const onCloseContextMenu = () => {
    Log.productAnalyticsEvent('Close context menu', Log.FEATURE.MENU);
    setContextMenu(null);
  };

  const onSortModelChange = (sortModel: SortModel) => {
    const mappedSortModel = sortModel.map((sortField) => ({
      ...sortField,
      field: sortModelMap[sortField.field] ?? sortField.field,
    }));

    Log.productAnalyticsEvent('Sort', Log.FEATURE.DELIVERY_LIST, undefined, {
      mappedSortModel,
    });

    setSortModel(mappedSortModel);
  };

  const onRequestDeliveryNoteSignature = () => {
    if (rowSelectionModel.length === 0) {
      ToastService.warning([
        'Bitte wähle mindestens eine Lieferung aus, die von einem anderen Benutzer signiert werden soll.',
      ]);

      Log.productAnalyticsEvent(
        'No delivery note selected to request signature',
        Log.FEATURE.NOTIFICATIONS,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    Log.productAnalyticsEvent(
      'Open request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );

    setRequestDeliveryNoteSignatureFormOpen(true);
  };

  const onShareDeliveryNote = () => {
    if (rowSelectionModel.length === 0) {
      ToastService.warning([
        'Bitte wähle mindestens eine Lieferung aus, die mit einem anderen Benutzer geteilt werden soll.',
      ]);

      Log.productAnalyticsEvent(
        'No delivery note selected to share',
        Log.FEATURE.SHARE_DELIVERY_NOTE,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    // Prevent sending too many requests to the backend to get the users that the delivery note is already shared with.
    if (rowSelectionModel.length > 5) {
      ToastService.warning([
        'Bitte wähle maximal 5 Lieferungen aus, die mit einem anderen Benutzer geteilt werden sollen.',
      ]);

      Log.productAnalyticsEvent(
        'More than 5 delivery notes selected to share',
        Log.FEATURE.SHARE_DELIVERY_NOTE,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    Log.productAnalyticsEvent(
      'Open share delivery note form',
      Log.FEATURE.SHARE_DELIVERY_NOTE,
    );

    setShareDeliveryNoteFormOpen(true);
  };

  const onMapDirectDeliveryNote = async () => {
    if (rowSelectionModel.length === 0) {
      ToastService.warning([
        'Bitte wähle mindestens eine Lieferung aus, die einem Standort und einer Kostenstelle zugewiesen werden soll.',
      ]);

      Log.productAnalyticsEvent(
        'No delivery note selected to map',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    // Prevent sending too many requests to the backend to update the direct mapping.
    if (rowSelectionModel.length > 10) {
      ToastService.warning([
        'Bitte wähle maximal 10 Lieferungen aus, die einem Standort und einer Kostenstelle zugewiesen werden sollen.',
      ]);

      Log.productAnalyticsEvent(
        'More than 10 delivery notes selected to map',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.FAILED_VALIDATION,
      );

      return;
    }

    Log.productAnalyticsEvent(
      'Open map direct delivery note form',
      Log.FEATURE.SITE_MAPPING,
    );

    const mapDirectDeliveryNoteIssuerIds = [];
    const mapDirectDeliveryNoteToSiteSupplierNames = [];
    const mapDirectDeliveryNotePermittedToSiteIds = [];
    const mapDirectDeliveryNotePermittedCostCenterIds = [];

    for (const element of rowSelectionModel) {
      const [deliveryNote, error] = await promiseHandler(
        DeliveriesService.getDeliveryNoteById(element),
      );

      if (error) {
        Log.error('Failed to load delivery note', error);
        Log.productAnalyticsEvent(
          'Failed to load delivery note',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
        continue;
      }

      mapDirectDeliveryNoteIssuerIds.push(deliveryNote.issuer.id);
      mapDirectDeliveryNoteToSiteSupplierNames.push(
        deliveryNote.toSiteSupplier.name,
      );
      mapDirectDeliveryNotePermittedToSiteIds.push(
        ...deliveryNote.permittedToSites.map(({ id }) => id),
      );
      mapDirectDeliveryNotePermittedCostCenterIds.push(
        ...deliveryNote.permittedCostCenters.map(({ id }) => id),
      );
    }

    const filteredMapDirectDeliveryNoteToSiteSupplierNames =
      mapDirectDeliveryNoteToSiteSupplierNames.filter(Boolean);

    if (filteredMapDirectDeliveryNoteToSiteSupplierNames.length === 0) {
      ToastService.warning([
        'Die ausgewählten Zeilen enthalten keine Werte in der Spalte Lieferort (Lieferantenbezeichnung). Bitte wählen Sie gültige Zeilen aus.',
      ]);

      return;
    }

    setMapDirectDeliveryNoteDefaultSelectedCostCenterId(
      ArrayUtils.getMostFrequentValue(
        mapDirectDeliveryNotePermittedCostCenterIds,
      ),
    );

    setMapDirectDeliveryNoteDefaultSelectedSiteId(
      ArrayUtils.getMostFrequentValue(mapDirectDeliveryNotePermittedToSiteIds),
    );

    setMapDirectDeliveryNoteFormOpen(true);

    setMapDirectDeliveryNoteIssuerIds(mapDirectDeliveryNoteIssuerIds);

    setMapDirectDeliveryNoteToSiteSupplierNames(
      filteredMapDirectDeliveryNoteToSiteSupplierNames,
    );
  };

  const getTableColumns = () => {
    return getColumns({
      companyAccountInfo,
      customFields: customFields ?? [],
      featureFlags,
      onRequestSingleSignatures:
        setRequestDeliveryNoteSignatureFormOpenDeliveryNoteId,
      onShareSingleDeliveryNote: setShareDeliveryNoteFormOpenDeliveryNoteId,
    });
  };

  const getDefaultHiddenColumns = () => {
    switch (userType) {
      case 'supplier': {
        hiddenColumns.push('supplier');
        break;
      }

      case 'carrier': {
        hiddenColumns.push('carrier');
        break;
      }

      case 'buyer': {
        hiddenColumns.push('recipient');
        break;
      }

      default: {
        break;
      }
    }

    return hiddenColumns;
  };

  useEffect(() => {
    if (!deliveryNotes?.rows?.length > 0) {
      return;
    }

    setExcelData(
      deliveryNotes.rows
        .filter(({ id }) => rowSelectionModel.includes(id))
        .map((row) => ({
          ...row,
          createdOn: formatDateInLocalTime(row.createdOn),
          dlnDate: formatDateInLocalTime(
            row.dlnDate,
            isSchickerAccount
              ? dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss
              : dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ),
          modifiedOn: formatDateInLocalTime(row.modifiedOn),
          permittedCostCenters: row.permittedCostCenterNames.join(', '),
          permittedToSites: row.permittedToSiteNames.join(', '),
        })),
    );
  }, [rowSelectionModel, deliveryNotes?.rows]);

  if (isSchickerAccount) {
    csvOptions.delimiter = ';';
    csvOptions.includeHeaders = false;
  }

  const paginationProps = {
    onPaginationModelChange: setPaginationModel,
    pagination: true,
    paginationMeta: {
      totalRowCount: deliveryNotes?.totalCount ?? 0,
    },
    paginationMode: 'server',
    paginationModel,
    rowCount: deliveryNotes?.totalCount ?? 0,
  };

  const reverseMappedSortModel = reverseMapSortModel(sortModel);
  return (
    <div className="mt-4 h-[600px] flex-1 rounded-md bg-white shadow-lg">
      <BasicTable
        columns={getTableColumns()}
        csvOptions={csvOptions}
        defaultHiddenColumns={getDefaultHiddenColumns()}
        disableSelectAllRowsButton={true}
        disableSelectAllRowsButtonMessage="Im Archiv können nicht alle Lieferungen ausgewählt werden."
        excelColumns={getTableColumns()}
        excelData={excelData}
        filterModel={deliveryFilters.filterModel}
        isFetching={isFetchingDeliveryNotes}
        isLoading={isLoadingDeliveryNotes || isLoadingCustomFields}
        localStorageKey={LocalStorageService.DELIVERY_LIST}
        logicOperators={[FilterNew.BOOLEAN_OPERATOR.AND]}
        multiplePdfDownload={rowSelectionModel.length > 1}
        onFilterModelChange={handleFilterModelChange}
        onMapDirectDeliveryNote={onMapDirectDeliveryNote}
        onPdfExport={onPdfExport}
        onRequestDeliveryNoteSignature={onRequestDeliveryNoteSignature}
        onRowClick={(rowData) => {
          onOpenDeliveryNote(rowData.row.id);
        }}
        onRowRightClick={onOpenContextMenu}
        onRowSelectionModelChange={onRowSelectionModelChange}
        onShareDeliveryNote={onShareDeliveryNote}
        onSortModelChange={onSortModelChange}
        pinnedColumnsCookieId={LocalStorageService.DELIVERY_LIST_PINNED_COLUMNS}
        productAnalyticsFeature={Log.FEATURE.DELIVERY_LIST}
        rows={deliveryNotes?.rows ?? []}
        rowSelectionModel={rowSelectionModel}
        onExcelExportFromBackend={handleExcelExportFromBackend}
        onCSVExportFromBackend={handleCSVExportFromBackend}
        selectAllRowsCount={deliveryNotes?.totalCount ?? 0}
        sortingMode="server"
        sortModel={reverseMappedSortModel}
        testId={DeliveriesTestIds.DELIVERIES_TABLE}
        {...paginationProps}
        checkboxSelection
        noExportButton
      />
      <ContextMenu
        open={false} // TODO: how is this thing actually opened?!
        contextMenu={contextMenu}
        onClose={onCloseContextMenu}
        onOpen={() => {
          onOpenDeliveryNote(contextMenu.id);
        }}
        onOpenInNewTab={onOpenDeliveryNoteInNewTab}
        customMenuItems={[
          {
            callback() {
              onOpenDeliveryDataQuality(contextMenu.id);
            },
            key: 'openDeliveryDataQuality',
            name: 'In Datenansicht öffnen',
          },
        ]}
      />
      {/* When requesting signatures for / sharing multiple delivery notes via BasicTable GridToolbar. */}
      {requestDeliveryNoteSignatureFormOpen ? (
        <RequestSignatureForm
          permittedUserIds={[]}
          deliveryNoteIds={rowSelectionModel}
          open={requestDeliveryNoteSignatureFormOpen}
          closeForm={() => setRequestDeliveryNoteSignatureFormOpen(false)}
        />
      ) : null}
      {shareDeliveryNoteFormOpen ? (
        <ShareDeliveryNoteForm
          deliveryNoteIds={rowSelectionModel}
          open={shareDeliveryNoteFormOpen}
          closeForm={() => setShareDeliveryNoteFormOpen(false)}
        />
      ) : null}
      {shareDeliveryNoteFormOpenDeliveryNoteId ? (
        <ShareDeliveryNoteForm
          deliveryNoteIds={[shareDeliveryNoteFormOpenDeliveryNoteId]}
          open={shareDeliveryNoteFormOpenDeliveryNoteId}
          closeForm={() => setShareDeliveryNoteFormOpenDeliveryNoteId(null)}
        />
      ) : null}
      <MapDirectDeliveryNoteForm
        issuerIds={mapDirectDeliveryNoteIssuerIds}
        toSiteSupplierNames={mapDirectDeliveryNoteToSiteSupplierNames}
        deliveryNoteIds={rowSelectionModel}
        defaultSelectedSiteId={mapDirectDeliveryNoteDefaultSelectedSiteId}
        defaultSelectedCostCenterId={
          mapDirectDeliveryNoteDefaultSelectedCostCenterId
        }
        open={mapDirectDeliveryNoteFormOpen}
        closeForm={() => setMapDirectDeliveryNoteFormOpen(false)}
        formSuccess={() => setRowSelectionModel([])}
      />
      {/* When requesting signatures for / sharing a single delivery note via the button in the single rows. */}
      {/* The forms are not stored in the DeliveryListActions component because otherwise there would be a form for each data grid row */}
      {requestDeliveryNoteSignatureFormOpenDeliveryNoteId ? (
        <RequestSignatureForm
          permittedUserIds={[]}
          deliveryNoteIds={
            requestDeliveryNoteSignatureFormOpenDeliveryNoteId
              ? [requestDeliveryNoteSignatureFormOpenDeliveryNoteId]
              : []
          }
          open={requestDeliveryNoteSignatureFormOpenDeliveryNoteId}
          closeForm={() =>
            setRequestDeliveryNoteSignatureFormOpenDeliveryNoteId(null)
          }
        />
      ) : null}
      {/* When requesting signatures for / sharing a single delivery note via the button in the single rows. */}
    </div>
  );
}, 'Lieferungen konnten nicht geladen werden.');

DeliveryList.displayName = 'DeliveryList';
