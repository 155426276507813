import moment from 'moment'; // TODO: replace

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import BackendFilter from '~/models/filters/BackendFilter';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import FilterNew from '~/models/filters/FilterNew';
import MuiDataGridFilter from '~/models/filters/MuiDataGridFilter';

import { dateUtils } from '~/utils/dateUtils';

import { type FilterClause, type FilterGroup } from './types';

/**
 * Creates filter clauses for a date range query.
 * Converts the date range into backend filter format with proper ISO formatting.
 *
 * @param dateRange - Tuple containing start and end dates
 * @returns Array of filter clauses for the date range
 */
const createDateRangeFilters = (dateRange: [Date, Date]): FilterClause[] => {
  if (!dateRange) {
    return [];
  }

  return [
    {
      comp: BackendFilter.OPERATOR.GREATER_THAN_OR_EQUALS,
      name: 'dln_date',
      value: dateUtils.getFormattedDate(
        moment(dateRange[0]),
        dateUtils.DATE_FORMAT.YYYY_MM_DD_ISO,
      ),
    },
    {
      comp: BackendFilter.OPERATOR.LESS_THAN_OR_EQUALS,
      name: 'dln_date',
      value: dateUtils.getFormattedDate(
        moment(dateRange[1]).add(1, 'days'),
        dateUtils.DATE_FORMAT.YYYY_MM_DD_ISO,
      ),
    },
  ];
};

const createProcessStateFilter = (
  processStates: string[],
): FilterClause | undefined => {
  if (!processStates || processStates.length === 0) {
    return null;
  }

  const processStateValues = processStates.flatMap((processStateString) => {
    const processState =
      DeliveryNote.getProcessStateKeyByString(processStateString);

    return processState === DeliveryNote.PROCESS_STATE.DELIVERED.API_KEY
      ? [processState, DeliveryNote.PROCESS_STATE.SETTLED.API_KEY]
      : [processState];
  });

  return {
    comp: BackendFilter.OPERATOR.IS_ANY_OF,
    name: BackendFilter.FILTER.PROCESS_STATE,
    value: processStateValues,
  };
};

const createSimpleArrayFilter = (
  values: string[],
  name: string,
  operator = BackendFilter.OPERATOR.IS_ANY_OF,
): FilterClause | undefined => {
  if (!values || values.length === 0) {
    return null;
  }

  return {
    comp: operator,
    name,
    value: values,
  };
};

const createQueryFilter = (query: string): FilterClause | undefined => {
  if (!query) {
    return null;
  }

  return {
    comp: BackendFilter.OPERATOR.CONTAINS,
    name: BackendFilter.FILTER.QUERY,
    value: query,
  };
};

const createAcceptStatesFilter = (
  acceptStates: string[],
): FilterClause | undefined => {
  if (!acceptStates || acceptStates.length === 0) {
    return null;
  }

  const acceptStateValues = acceptStates.map((state) =>
    AcceptStateCalculator.getBackendAcceptStateKey(state),
  );

  return {
    comp: BackendFilter.OPERATOR.IS_ANY_OF,
    name: BackendFilter.FILTER.DLN_ACCEPT_STATE,
    value: acceptStateValues,
  };
};

const createSettledStatusFilter = (
  settledStatus: string[],
): FilterClause | undefined => {
  if (!settledStatus || settledStatus.length === 0) {
    return null;
  }

  const settledStatusValues = settledStatus.map((state) =>
    state.toLowerCase() === 'ja' ? 'fully_billed' : 'unbilled',
  );

  return {
    comp: BackendFilter.OPERATOR.IS_ANY_OF,
    name: BackendFilter.FILTER.DLN_SETTLED_STATUS,
    value: settledStatusValues,
  };
};

const createPermittedSitesAndCostCentersFilterGroup = (
  permittedSites: string[],
  permittedCostCenters: string[],
) => {
  const filterClauses = [];

  if (permittedSites.length > 0) {
    filterClauses.push({
      comp: BackendFilter.OPERATOR.IS_ANY_OF,
      name: BackendFilter.FILTER.CONFIRMED_SITE_ID,
      value: permittedSites,
    });
  }

  if (permittedCostCenters.length > 0) {
    filterClauses.push({
      comp: BackendFilter.OPERATOR.IS_ANY_OF,
      name: BackendFilter.FILTER.CONFIRMED_ACCOUNTING_REFERENCE_ID,
      value: permittedCostCenters,
    });
  }

  return {
    filterAggr: FilterNew.BOOLEAN_OPERATOR.OR,
    filterClauses,
  };
};

const createCustomFieldFilters = (
  selectedCustomFields,
  definedCustomFields,
) => {
  if (
    !Array.isArray(selectedCustomFields) ||
    selectedCustomFields.length === 0 ||
    !Array.isArray(definedCustomFields) ||
    definedCustomFields.length === 0
  ) {
    return [];
  }

  const customFieldFilters = selectedCustomFields.map((customField) => {
    const definedCustomField = definedCustomFields.find(
      ({ key }) => key === customField.key,
    );

    if (!definedCustomField) {
      return null;
    }

    const hasNoValues =
      customField.filterValue.map((value) => value.trim()).filter(Boolean)
        .length === 0;
    if (hasNoValues) {
      return null;
    }

    return {
      comp: BackendFilter.OPERATOR.IS_ANY_OF,
      customFieldId: definedCustomField.id,
      name:
        definedCustomField.level === 'global'
          ? 'custom_field_global_level'
          : 'custom_field_item_level',
      value: customField.filterValue,
    };
  });

  return customFieldFilters.filter(Boolean);
};

const createMuiDataGridFilterGroup = (
  filterModel: any,
): FilterGroup | undefined => {
  if (!filterModel?.items?.length) {
    return null;
  }

  const filterClauses = filterModel.items
    .map((model) => {
      const {
        filterProps: { fieldBackend, operatorBackend },
        value,
      } = MuiDataGridFilter.getParsedMuiDataGridFilter(model);

      return value
        ? {
            caseSensitive: false,
            comp: operatorBackend,
            name: fieldBackend,
            value,
          }
        : null;
    })
    .filter(Boolean);

  return {
    filterAggr: filterModel.booleanOperator ?? FilterNew.BOOLEAN_OPERATOR.AND,
    filterClauses,
  };
};

/**
 * Returns the search body for the delivery notes.
 *
 * Takes filter groups and an optional filter aggregator as parameters and returns a DlnFilterConfig.
 * @see  https://app.dev.vestigas.com/redoc#tag/Analytics/operation/compute_dln_analytics_suggestions_analytics_suggestions_post for an example what the DlnFilterConfig looks like as used in an API endpoint.
 *
 * @param {object} options.filterGroups - The filter groups object.
 * @param {string} options.filterAggregator - The filter aggregator - defaults to AND
 * @return {object} The search body.
 */
export const getSearchBody = ({
  filterGroups: {
    acceptStates = [],
    article = [],
    articleNumber = [],
    dateRange,
    filterModel,
    fromSite = [],
    permittedCostCenters = [],
    permittedToSites = [],
    processStates = [],
    query = '',
    recipients = [],
    selectedCostCenters = [],
    selectedCustomFields = [],
    selectedSites = [],
    settledStatus = [],
    suppliers = [],
    toSiteRecipient = [],
    toSiteSupplier = [],
  },
  filterAggregator = FilterNew.BOOLEAN_OPERATOR.AND,
  definedCustomFields,
}) => {
  const mappedFilterGroups: FilterGroup[] = [];

  // Handle permitted sites and cost centers.
  if (
    (selectedSites && selectedSites.length > 0) ||
    (selectedCostCenters && selectedCostCenters.length > 0)
  ) {
    mappedFilterGroups.push(
      createPermittedSitesAndCostCentersFilterGroup(
        selectedSites,
        selectedCostCenters,
      ),
    );
  }

  // Create generic filters group.
  const genericFilters: FilterClause[] = [
    ...createDateRangeFilters(dateRange),
    createProcessStateFilter(processStates),
    createAcceptStatesFilter(acceptStates),
    createSettledStatusFilter(settledStatus),
    createSimpleArrayFilter(
      toSiteRecipient,
      BackendFilter.FILTER.CONFIRMED_SITE_NAME,
    ),
    createSimpleArrayFilter(
      toSiteSupplier,
      BackendFilter.FILTER.SUPPLIER_ASSIGNED_SITE_NAME,
    ),
    createSimpleArrayFilter(article, BackendFilter.FILTER.ARTICLE_NAME),
    createSimpleArrayFilter(articleNumber, BackendFilter.FILTER.ARTICLE_NUMBER),
    createSimpleArrayFilter(suppliers, BackendFilter.FILTER.SUPPLIER_NAME),
    createSimpleArrayFilter(recipients, BackendFilter.FILTER.RECIPIENT_NAME),
    createSimpleArrayFilter(
      permittedToSites,
      BackendFilter.FILTER.CONFIRMED_SITE_ID,
    ),
    createSimpleArrayFilter(
      permittedCostCenters,
      BackendFilter.FILTER.CONFIRMED_ACCOUNTING_REFERENCE_ID,
    ),
    createSimpleArrayFilter(fromSite, BackendFilter.FILTER.LOADING_LOCATION),
    createQueryFilter(query),
    ...createCustomFieldFilters(selectedCustomFields, definedCustomFields),
  ].filter(Boolean);

  if (genericFilters.length > 0) {
    mappedFilterGroups.push({
      filterAggr: FilterNew.BOOLEAN_OPERATOR.AND,
      filterClauses: genericFilters,
    });
  }

  // Handle MUI DataGrid filters.
  const muiFilterGroup = createMuiDataGridFilterGroup(filterModel);
  if (muiFilterGroup) {
    mappedFilterGroups.push(muiFilterGroup);
  }

  return {
    filterConfig: {
      filterAggr: filterAggregator,
      filterGroups: mappedFilterGroups,
    },
  };
};

export const getUnassignedDeliveryNotesSearchBody = (
  dateRange: [Date, Date],
) => {
  return {
    filterConfig: {
      filterAggr: 'and',
      filterGroups: [
        {
          filterAggr: 'and',
          filterClauses: [
            ...createDateRangeFilters(dateRange),
            {
              comp: 'eq',
              name: 'recipient_name', // is toSiteRecipient empty?
              value: '',
            },
            {
              comp: 'eq',
              name: 'authorized_site_id', // is permittedToSites empty?
              value: '',
            },
            {
              comp: 'eq',
              name: 'authorized_accounting_reference_id', // is permittedCostCenters empty?
              value: '',
            },
          ],
        },
      ],
    },
  };
};
