import ArrayUtils from '~/utils/arrayUtils';
import { DeliveryNoteLinks } from '~/utils/componentUtils';
import { pluralizeString, pluralizeWord } from '~/utils/pluralize';

import { withErrorBoundary } from '~/ui/atoms';

import InvoiceCheck from '../InvoiceCheck';

import { InvoiceCheckCategory } from './InvoiceCheckCategory';

export const DlnCheck = withErrorBoundary(({ category }) => {
  const getErrorInvoiceChecks = () => {
    const deliveryNoteExistsCheckResults = category.errorChecks.filter(
      ({ name }) => name === 'DeliveryNoteExists',
    );
    const deliveryNoteNotAlreadySettledCheckResults =
      category.errorChecks.filter(
        ({ name }) => name === 'DeliveryNoteNotAlreadySettled',
      );
    const deliveryNoteItemAlreadyBilledCheckResults =
      category.errorChecks.filter(
        ({ name }) => name === 'DeliveryNoteItemAlreadyBilled',
      );
    const deliveryNoteReferenceExistsCheckResults = category.errorChecks.filter(
      ({ name }) => name === 'DeliveryNoteReferenceExists',
    );

    const deliveryNoteExistsInvoiceCheck = (
      <InvoiceCheck
        expandedDefault
        checkResults={deliveryNoteExistsCheckResults}
        checkResultFormatter={(checkResult) =>
          'Lieferung ' + checkResult.getDeliveryNoteNumbers().join(', ')
        }
      >
        <div className="w-full">
          Die {pluralizeWord(deliveryNoteExistsCheckResults.length, 'folgende')}{' '}
          {pluralizeString(
            deliveryNoteExistsCheckResults.length,
            'Lieferung liegt',
            deliveryNoteExistsCheckResults.length > 1,
          )}{' '}
          nicht von diesem Lieferanten vor.
        </div>
      </InvoiceCheck>
    );

    const deliveryNoteNotAlreadySettledInvoiceCheck = (
      <InvoiceCheck
        expandedDefault
        checkResults={deliveryNoteNotAlreadySettledCheckResults}
        checkResultFormatter={(checkResult) => (
          <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
        )}
      >
        <div className="w-full">
          Die{' '}
          {pluralizeWord(
            deliveryNoteNotAlreadySettledCheckResults.length,
            'folgende',
          )}{' '}
          {pluralizeString(
            deliveryNoteNotAlreadySettledCheckResults.length,
            'Lieferung wurde',
            deliveryNoteNotAlreadySettledCheckResults.length > 1,
          )}{' '}
          bereits mit einer anderen Rechnung dieses Lieferanten abgerechnet.
        </div>
      </InvoiceCheck>
    );

    const deliveryNoteItemAlreadyBilledInvoiceCheck = (
      <InvoiceCheck>
        <div className="w-full">
          {pluralizeWord(
            deliveryNoteItemAlreadyBilledCheckResults.length,
            'Der',
          )}{' '}
          Artikel{' '}
          {ArrayUtils.joinComponents(
            deliveryNoteItemAlreadyBilledCheckResults.map(
              (checkResult, index) => (
                <span key={index} className="text-error500">
                  "{checkResult.articleName ?? ''}"
                </span>
              ),
            ),
          )}{' '}
          {pluralizeWord(
            deliveryNoteItemAlreadyBilledCheckResults.length,
            'wurde',
          )}{' '}
          bereits mit einer anderen Rechnung dieses Lieferanten abgerechnet.
        </div>
      </InvoiceCheck>
    );

    const deliveryNoteReferenceExistsInvoiceCheck = (
      <InvoiceCheck>
        <div className="w-full">
          {pluralizeWord(deliveryNoteReferenceExistsCheckResults.length, 'Der')}{' '}
          Artikel{' '}
          {ArrayUtils.joinComponents(
            deliveryNoteReferenceExistsCheckResults.map(
              (checkResult, index) => (
                <span key={index} className="text-error500">
                  "{checkResult.articleName ?? ''}"
                </span>
              ),
            ),
          )}{' '}
          {pluralizeWord(
            deliveryNoteReferenceExistsCheckResults.length,
            'enthält',
          )}{' '}
          keine Referenz auf eine Lieferung.
        </div>
      </InvoiceCheck>
    );

    return (
      <>
        {deliveryNoteExistsCheckResults.length > 0
          ? deliveryNoteExistsInvoiceCheck
          : null}
        {deliveryNoteNotAlreadySettledCheckResults.length > 0
          ? deliveryNoteNotAlreadySettledInvoiceCheck
          : null}
        {deliveryNoteItemAlreadyBilledCheckResults.length > 0
          ? deliveryNoteItemAlreadyBilledInvoiceCheck
          : null}
        {deliveryNoteReferenceExistsCheckResults.length > 0
          ? deliveryNoteReferenceExistsInvoiceCheck
          : null}
      </>
    );
  };

  const getSuccessInvoiceChecks = () => {
    const deliveryNoteExistsCheckResults = category.successChecks.filter(
      (checkResult) => checkResult.name === 'DeliveryNoteExists',
    );
    const deliveryNoteNotAlreadySettledCheckResults =
      category.successChecks.filter(
        (checkResult) => checkResult.name === 'DeliveryNoteNotAlreadySettled',
      );
    const deliveryNoteItemAlreadyBilledCheckResults =
      category.successChecks.filter(
        (checkResult) => checkResult.name === 'DeliveryNoteItemAlreadyBilled',
      );
    const deliveryNoteReferenceExistsCheckResults =
      category.successChecks.filter(
        (checkResult) => checkResult.name === 'DeliveryNoteReferenceExists',
      );

    const deliveryNoteExistsInvoiceCheck = (
      <InvoiceCheck
        expandedDefault
        checkResults={deliveryNoteExistsCheckResults}
        checkResultFormatter={(checkResult) => (
          <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
        )}
      >
        <div className="w-full">
          Die {pluralizeWord(deliveryNoteExistsCheckResults.length, 'folgende')}{' '}
          {pluralizeString(
            deliveryNoteExistsCheckResults.length,
            'Lieferung liegt',
            deliveryNoteExistsCheckResults.length > 1,
          )}{' '}
          von diesem Lieferanten vor.
        </div>
      </InvoiceCheck>
    );

    const deliveryNoteNotAlreadySettledInvoiceCheck = (
      <InvoiceCheck
        expandedDefault
        checkResults={deliveryNoteNotAlreadySettledCheckResults}
        checkResultFormatter={(checkResult) => (
          <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
        )}
      >
        <div className="w-full">
          Die{' '}
          {pluralizeWord(
            deliveryNoteNotAlreadySettledCheckResults.length,
            'folgende',
          )}{' '}
          {pluralizeString(
            deliveryNoteNotAlreadySettledCheckResults.length,
            'Lieferung wurde',
            deliveryNoteNotAlreadySettledCheckResults.length > 1,
          )}{' '}
          noch nicht mit einer anderen Rechnung dieses Lieferanten abgerechnet.
        </div>
      </InvoiceCheck>
    );

    const deliveryNoteItemAlreadyBilledInvoiceCheck = (
      <InvoiceCheck>
        <div className="w-full">
          {pluralizeWord(
            deliveryNoteItemAlreadyBilledCheckResults.length,
            'Der',
          )}{' '}
          Artikel{' '}
          {ArrayUtils.joinComponents(
            deliveryNoteItemAlreadyBilledCheckResults.map(
              (checkResult) => '"' + (checkResult.articleName ?? '') + '"',
            ),
          )}{' '}
          {pluralizeWord(
            deliveryNoteItemAlreadyBilledCheckResults.length,
            'wurde',
          )}{' '}
          noch nicht mit einer anderen Rechnung dieses Lieferanten abgerechnet.
        </div>
      </InvoiceCheck>
    );

    const deliveryNoteReferenceExistsInvoiceCheck = (
      <InvoiceCheck
        expandedDefault
        checkResults={deliveryNoteReferenceExistsCheckResults}
      >
        <div className="w-full">
          {pluralizeWord(deliveryNoteReferenceExistsCheckResults.length, 'Der')}{' '}
          Artikel{' '}
          {ArrayUtils.joinComponents(
            deliveryNoteReferenceExistsCheckResults.map(
              (checkResult) => '"' + (checkResult.articleName ?? '') + '"',
            ),
          )}{' '}
          {pluralizeWord(
            deliveryNoteReferenceExistsCheckResults.length,
            'enthält',
          )}{' '}
          eine Referenz auf eine Lieferung.
        </div>
      </InvoiceCheck>
    );

    return (
      <>
        {deliveryNoteExistsCheckResults.length > 0
          ? deliveryNoteExistsInvoiceCheck
          : null}
        {deliveryNoteNotAlreadySettledCheckResults.length > 0
          ? deliveryNoteNotAlreadySettledInvoiceCheck
          : null}
        {deliveryNoteItemAlreadyBilledCheckResults.length > 0
          ? deliveryNoteItemAlreadyBilledInvoiceCheck
          : null}
        {deliveryNoteReferenceExistsCheckResults.length > 0
          ? deliveryNoteReferenceExistsInvoiceCheck
          : null}
      </>
    );
  };

  return (
    <InvoiceCheckCategory
      category={category}
      errorInvoiceChecks={getErrorInvoiceChecks()}
      successInvoiceChecks={getSuccessInvoiceChecks()}
    />
  );
}, 'Prüfschritt "Lieferscheinprüfung" konnte nicht geladen werden.');

DlnCheck.displayName = 'DlnCheck';
