import { DeliveryNoteLinks } from '~/utils/componentUtils';

import { withErrorBoundary } from '~/ui/atoms';

import InvoiceCheck from '../InvoiceCheck';

import { InvoiceCheckCategory } from './InvoiceCheckCategory';

export const AmountCheck = withErrorBoundary(({ category }) => {
  const errorInvoiceChecks = (
    <>
      {category.errorChecks.map((checkResult, index) => (
        <InvoiceCheck key={index}>
          <div className="w-full">
            <div>
              Der Artikel{' '}
              <span className="text-error500">
                "{checkResult.articleName ?? ''}"
              </span>{' '}
              enthält eine andere Menge oder Einheit als in der{' '}
              <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />{' '}
              ausgewiesen.
            </div>
            <div className="flex w-full items-end">
              <div className="text-error500 w-1/2">
                Rechnung: {checkResult.invoiceValue ?? '-'}
              </div>
              <div className="w-1/2">
                Lieferung: {checkResult.expectedValue ?? '-'}
              </div>
            </div>
          </div>
        </InvoiceCheck>
      ))}
    </>
  );

  const successInvoiceChecks = (
    <>
      {category.successChecks.map((checkResult, index) => (
        <InvoiceCheck key={index}>
          <div className="w-full">
            Der Artikel "{checkResult.articleName ?? ''}" enthält die gleiche
            Menge und Einheit wie in der{' '}
            <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />{' '}
            ausgewiesen.
          </div>
        </InvoiceCheck>
      ))}
    </>
  );

  return (
    <InvoiceCheckCategory
      category={category}
      errorInvoiceChecks={errorInvoiceChecks}
      successInvoiceChecks={successInvoiceChecks}
    />
  );
}, 'Prüfschritt "Mengenabgleich" konnte nicht geladen werden.');

AmountCheck.displayName = 'AmountCheck';
