import { type PropsWithChildren, useState } from 'react';

import FeatureService from '~/services/feature.service';

import { LightTooltip } from '~/utils/componentUtils';

import { ModuleInvoiceRestrictionMessage } from './MF_ModuleInvoiceRestrictionMessage';

type P = PropsWithChildren & ComponentStyling;

export const ModuleInvoiceRestrictionTooltip = ({
  children,
  className,
  style,
}: P) => {
  const [isShown, setIsShown] = useState(false);

  if (!children) {
    return null;
  }

  if (!FeatureService.moduleInvoiceRestriction()) {
    return children;
  }

  return (
    <LightTooltip
      title={<ModuleInvoiceRestrictionMessage />}
      enterDelay={1500}
      enterNextDelay={1500}
      open={isShown}
      onOpen={setIsShown(true)}
      onClose={setIsShown(false)}
    >
      <span className={className} onClick={setIsShown(true)} style={style}>
        {children}
      </span>
    </LightTooltip>
  );
};
