import { useQueries } from '@tanstack/react-query';

import DeliveryNote from '~/models/deliveries/DeliveryNote';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysDeliveryNote } from './queryKeys';
import { fetchDeliveryNote } from './useQueryDeliveryNote';

type QueryResult = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data?: UUID[];
};

const combinePermittedUsers = (results: QueryResult[]): QueryResult => {
  const isLoading = results.some(({ isLoading }) => isLoading);
  const isSuccess = results.every(({ isSuccess }) => isSuccess);
  const isError = results.some(({ isError }) => isError);

  const permittedUserIds = ArrayUtils.removeDuplicates(
    results
      .filter(({ isSuccess }) => isSuccess)
      .flatMap(({ data }) => data || []),
  );

  return {
    data: isSuccess ? permittedUserIds : [],
    isError,
    isLoading,
    isSuccess,
  };
};

export const useQueryPermittedUserIds = (deliveryNoteIds: UUID[] = []) =>
  useQueries({
    combine: combinePermittedUsers,
    queries: deliveryNoteIds.map((deliveryNoteId) => ({
      onError(error: unknown) {
        Log.error(
          `Failed to load permitted users for delivery note id: ${deliveryNoteId}`,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to load permitted users',
          Log.FEATURE.NOTIFICATIONS,
          Log.TYPE.ERROR,
        );
      },
      async queryFn() {
        const deliveryNote = await fetchDeliveryNote(deliveryNoteId);

        if (deliveryNote) {
          const deliveryNoteClassInstance = new DeliveryNote(deliveryNote);

          await deliveryNoteClassInstance.initPermittedUsers(); // TODO: this function is ridiculously expensive and makes huge amounts of requests using the old service functions (so they are not cached).

          return deliveryNoteClassInstance.permittedUsers.map(({ id }) => id);
        }

        return [];
      },
      queryKey: queryKeysDeliveryNote.getPermittedUserIds(deliveryNoteId),
    })),
  });
