import { DataObject as DataObjectIcon } from '@mui/icons-material';

import ExportService from '~/services/export.service';

import { dateUtils } from '~/utils/dateUtils';
import Log from '~/utils/Log';

export const AttachmentJson = ({ url }) => {
  const handleClick = () => {
    Log.productAnalyticsEvent(
      'Download json attachment',
      Log.FEATURE.OTHER_FEATURE,
    );

    ExportService.downloadUrlWithCustomName(
      url,
      'VESTIGAS_' +
        dateUtils.getFormattedDate_safe(
          new Date(),
          dateUtils.DATE_FORMAT.YYYYMMDD_HHMMSS,
        ) +
        '.json',
    );
  };

  return (
    <div
      className="flex h-full w-full cursor-pointer items-center justify-center"
      onClick={handleClick}
    >
      <DataObjectIcon className="text-white" fontSize="large" />
    </div>
  );
};
