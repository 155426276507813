export const columns = [
  {
    cellClassName: 'licensePlate-cell',
    field: 'licensePlate',
    headerClassName: 'licensePlate-header',
    headerName: 'Kennzeichen',
    resizableText: true,
    sortable: true,
    width: 250,
  },
  {
    cellClassName: 'companyName-cell',
    field: 'companyName',
    headerClassName: 'companyName-header',
    headerName: 'Firma',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    cellClassName: 'isActive-cell',
    field: 'isActive',
    headerClassName: 'isActive-header',
    headerName: 'Aktiv',
    resizableText: true,
    sortable: false,
    width: 100,
  },
  {
    cellClassName: 'id-cell',
    field: 'id',
    headerClassName: 'id-header',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
] as const;
