import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';

import StyledAccordion from '~/components/baseComponents/accordion/StyledAccordion';

import { withErrorBoundary } from '~/ui/atoms';

import InvoiceCheckIcon from '../InvoiceCheckIcon';
import { InvoiceCheckSummaryText } from '../invoiceCheckSummary/InvoiceCheckSummaryText';

export const InvoiceCheckCategory = withErrorBoundary(
  (props) => (
    <>
      <div className="flex gap-4">
        <InvoiceCheckIcon
          iconKey={props.category.key}
          status={props.category.status}
          className={
            'icon-extra-large ' +
            InvoiceCheckResult.getStatusColorClass(props.category.status)
          }
        />
        <div>
          <h2 className="text-2xl font-medium">{props.category.name}</h2>
          <div>
            <InvoiceCheckSummaryText category={props.category} withTooltip />
          </div>
        </div>
      </div>
      {props.category.status === InvoiceCheckResult.STATUS.ERROR ? (
        <div className="mt-4 flex flex-col gap-2">
          {props.errorInvoiceChecks}
        </div>
      ) : null}
      {props.category.status === InvoiceCheckResult.STATUS.WARNING ? (
        <div className="mt-4 flex flex-col gap-2">
          {props.errorInvoiceChecks}
        </div>
      ) : null}
      {props.category.status === InvoiceCheckResult.STATUS.DELAYED_SUCCESS ? (
        <div className="mt-4 flex flex-col gap-2">
          {props.delayedSuccessInvoiceChecks}
        </div>
      ) : null}
      {props.category.status ===
        InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE &&
      props.category.errorChecks.length > 0 ? (
        <div className="mt-4 flex flex-col gap-2">
          {props.errorInvoiceChecks}
        </div>
      ) : null}
      {props.category.successChecks.length > 0 ? (
        <StyledAccordion
          title="Korrekte Schritte"
          productAnalyticsFeature="invoice_check_correct_checks"
        >
          <div className="mt-4 flex flex-col gap-2">
            {props.successInvoiceChecks}
          </div>
        </StyledAccordion>
      ) : null}
    </>
  ),
  'Prüfschritt konnte nicht geladen werden',
);

InvoiceCheckCategory.displayName = 'InvoiceCheckCategory';
