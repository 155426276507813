import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { queryKeysDeliveryNote } from './queryKeys';

/**
 * Fetches the completed asset for the provided request ID.
 *
 * @param {string} requestId - The ID of the export request.
 * @returns {Promise<Blob>} The exported Excel file as a Blob.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Delivery-Note/operation/request_delivery_note_export_asset_delivery_note_export_request_post
 */
export const fetchDeliveryNoteAssetExport = async (
  requestId: string,
): Promise<Blob> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.DELIVERY_NOTE.GET_ASSET_EXPORT(requestId))
      .blob();

    return response;
  } catch (error) {
    console.error('Failed to fetch exported asset:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query custom hook for fetching the exported report using request ID.
 *
 * @param {string} requestId - The ID of the export request.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<Blob>} The result of the useQuery hook containing the exported report.
 */
export const useQueryDeliveryNoteAssetExport = (
  requestId: string,
  options?: Omit<UseQueryOptions<Blob, Error>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery({
    enabled: Boolean(requestId),
    queryFn: async () => fetchDeliveryNoteAssetExport(requestId),
    queryKey: queryKeysDeliveryNote.getExportExcel(requestId),
    ...options,
  });
};
