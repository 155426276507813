import { dateUtils, fromUTC } from '~/utils/dateUtils';

const formatDateInLocalTime = (date) =>
  dateUtils.getFormattedDate(
    fromUTC(date),
    dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
  );

export const columns = [
  {
    cellClassName: 'name-cell',
    field: 'name',
    headerClassName: 'name-header',
    headerName: 'Kostenstelle',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    cellClassName: 'isActive-cell',
    field: 'isActive',
    headerClassName: 'isActive-header',
    headerName: 'Aktiv',
    resizableText: true,
    sortable: true,
    width: 100,
  },
  {
    cellClassName: 'id-cell',
    field: 'id',
    headerClassName: 'id-header',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    cellClassName: 'start-cell',
    field: 'start',
    headerClassName: 'start-header',
    headerName: 'Startdatum',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    cellClassName: 'end-cell',
    field: 'end',
    headerClassName: 'end-header',
    headerName: 'Enddatum',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'createdOn',
    headerName: 'Erstellt am',
    resizableText: true,
    sortable: true,
    valueGetter: formatDateInLocalTime,
    width: 300,
  },
  {
    field: 'modifiedOn',
    headerName: 'Geändert am',
    resizableText: true,
    sortable: true,
    valueGetter: formatDateInLocalTime,
    width: 300,
  },
];

export const columnVisibility = {
  createdOn: false,
  modifiedOn: false,
};
