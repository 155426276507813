import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';

import { LOADING_STATE } from '~/constants/LoadingState';

import CustomFieldService from '~/services/customField.service';
import DeliveriesService from '~/services/deliveries.service';
import LocalStorageService from '~/services/localStorage.service';
import ToastService from '~/services/toast.service';

import DeliveryNoteDataQualityModel from '~/models/deliveries/DeliveryNoteDataQuality';

import { setPageTitle } from '~/redux/menuSlice';

import { LightTooltip } from '~/utils/componentUtils';
import FunctionUtils from '~/utils/functionUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';

import { Spinner } from '~/components/Spinner';

import { withErrorBoundary } from '~/ui/atoms';

import DocumentLoadingPage from '../../DocumentLoadingPage';

import { DeliveryNoteDataQualityCategory } from './DeliveryNoteDataQualityCategory';

const HIDE_DESCRIPTION_COOKIE = 'hide_description';

export const DeliveryNoteDataQuality = withErrorBoundary(({ match }) => {
  const dispatch = useDispatch();

  const [dataQualityCategories, setDataQualityCategories] = useState([]);
  const [hideDescription, setHideDescription] = useState(
    LocalStorageService.getBooleanFromLocalStorage(HIDE_DESCRIPTION_COOKIE),
  );
  const [isUploading, setIsUploading] = useState(false);

  // TODO: replace this with react query
  const [loading, setLoading] = useState(LOADING_STATE.LOADING);
  const deliveryNotes = useSelector((state) => state.deliveryNotes);
  const loadDocument = useCallback(async () => {
    // Before throwing an error, try to load the delivery note 5 times
    const [dln, error] = await promiseHandler(
      FunctionUtils.repeatAsyncFunction(
        () => DeliveriesService.getDeliveryNoteById(match.params.id),
        5,
        1000,
      ),
    );

    if (error) {
      throw error;
    }

    dispatch(setPageTitle('Lieferung ' + (dln.number ?? '')));
    document.title = 'VESTIGAS - Lieferung ' + (dln.number ?? '');

    const customFieldIds = dln.getCustomFieldIds();
    const [, error2] = await promiseHandler(
      CustomFieldService.loadCustomFieldsBulk(customFieldIds),
    );

    if (error2) {
      throw error2;
    }

    const [, error3] = await promiseHandler(dln.asyncInit());

    if (error3) {
      throw error3;
    }

    const [backendDln, error4] = await promiseHandler(
      DeliveriesService.getDeliveryNote(match.params.id),
    );

    if (error4) {
      Log.error('Failed to load delivery note. id: ' + match.params.id, error4);
      ToastService.httpError(
        [
          'Datenqualität konnte nicht geladen werden wegen eines internen Fehlers.',
        ],
        error4.response,
      );
      Log.productAnalyticsEvent(
        'Failed to load delivery note',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      return;
    }

    const [validationResults, error5] = await promiseHandler(
      DeliveriesService.validateDeliveryNoteVestigasFormat(
        backendDln.asset_state.body,
      ),
    );

    if (error5) {
      Log.error(
        'Failed to validate delivery note. id: ' + match.params.id,
        error5,
      );
      ToastService.httpError(
        [
          'Lieferung konnte nicht validiert werden wegen eines internen Fehlers.',
        ],
        error5.response,
      );
      Log.productAnalyticsEvent(
        'Failed to validate delivery note',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      return;
    }

    const deliveryNoteDataQuality = new DeliveryNoteDataQualityModel(
      dln,
      validationResults,
    );

    const categories = deliveryNoteDataQuality.getDataQualityCategories();

    setDataQualityCategories(categories);
    setLoading(LOADING_STATE.SUCCEEDED);
  }, [match.params.id, setPageTitle]);

  const loadDocument_safe = useCallback(() => {
    loadDocument().catch((error) => {
      Log.error('Failed to load delivery note', error);
      Log.productAnalyticsEvent(
        'Failed to load delivery note',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );

      setLoading(LOADING_STATE.FAILED);
    });
  }, [loadDocument]);

  useEffect(() => {
    loadDocument_safe();
    setPageTitle('Lieferung');
    document.title = 'VESTIGAS - Lieferung';
  }, [loadDocument_safe, setPageTitle]);

  useEffect(() => {
    const dlnFromStore = deliveryNotes.deliveryNotes.find(
      (dln) => dln.id === match.params.id,
    );

    // Watch for changes in deliveryNotes or match.params.id
    const handleUpdate = () => {
      loadDocument_safe();
    };

    handleUpdate();
  }, [deliveryNotes, match.params.id, loadDocument_safe]);

  const createDeliveryNote = async (body) => {
    setIsUploading(true);

    await promiseHandler(
      DeliveriesService.createDeliveryNoteVestigasFormat_enhanced(body),
    );

    setIsUploading(false);
  };

  const closeDescription = () => {
    Log.productAnalyticsEvent(
      'Close description of delivery note data quality',
      Log.FEATURE.DELIVERY_NOTE,
    );
    setHideDescription(true);
    LocalStorageService.setLocalStorage(HIDE_DESCRIPTION_COOKIE, true);
  };

  const getArticleComponents = () => {
    const articleComponents = [];

    const dataQualityArticles = dataQualityCategories.filter(
      (item) =>
        item.NAME === DeliveryNoteDataQualityModel.CATEGORY.ARTICLE.NAME,
    );

    for (const [index, dataQualityArticle] of dataQualityArticles.entries()) {
      articleComponents.push(
        <div
          className={
            'w-full rounded-md bg-white p-2 shadow-lg ' +
            (index > 0 ? 'mt-4' : '')
          }
        >
          <DeliveryNoteDataQualityCategory
            category={DeliveryNoteDataQualityModel.CATEGORY.ARTICLE.NAME}
            dataQualityPairs={dataQualityArticle.dataQualityPairs}
          />
        </div>,
      );
    }

    return articleComponents;
  };

  if (loading !== LOADING_STATE.SUCCEEDED) {
    return <DocumentLoadingPage loading={loading} documentType="Lieferung" />;
  }

  return (
    <div className="main-padding whitespace-pre">
      <div className="mb-4 flex flex-row-reverse items-center justify-between gap-4">
        <LightTooltip title="Neue Lieferung im JSON Format hochladen.">
          <label htmlFor="input-json-upload">
            <input
              type="file"
              className="hidden"
              accept=".json"
              id="input-json-upload"
              onChange={(event) =>
                DeliveriesService.uploadJson(
                  event.target.files[0],
                  createDeliveryNote,
                )
              }
            />
            <Button
              className="primary-button"
              startIcon={isUploading ? null : <AddIcon />}
              component="span"
              disabled={isUploading}
            >
              {isUploading ? <Spinner title="Hochladen..." /> : 'Hochladen'}
            </Button>
          </label>
        </LightTooltip>
        {hideDescription ? null : (
          <div className="flex items-center justify-between gap-4 rounded-md bg-white p-2 shadow-lg">
            <div>
              <span className="font-bold">Willkommen in der Datenansicht!</span>
              <br />
              In der Datenansicht können alle Informationen einer Lieferung
              sowie deren Datenqualität eingesehen werden.
              <br />
              Dies ist vor allem hilfreich, um nachvollziehen zu können, ob
              Lieferungen die gewünschte Datenqualität aufweisen.
            </div>
            <IconButton onClick={closeDescription} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
      <Grid container spacing={4} justifyContent="space-between">
        <Grid item xs={12} lg={4}>
          <div className="w-full rounded-md bg-white p-2 shadow-lg">
            <DeliveryNoteDataQualityCategory
              category={DeliveryNoteDataQualityModel.CATEGORY.META_DATA.NAME}
              dataQualityPairs={
                dataQualityCategories.find(
                  (category) =>
                    category.NAME ===
                    DeliveryNoteDataQualityModel.CATEGORY.META_DATA.NAME,
                ).dataQualityPairs
              }
            />
          </div>
          <div className="mt-4 w-full rounded-md bg-white p-2 shadow-lg">
            <DeliveryNoteDataQualityCategory
              category={DeliveryNoteDataQualityModel.CATEGORY.ISSUER.NAME}
              dataQualityPairs={
                dataQualityCategories.find(
                  (category) =>
                    category.NAME ===
                    DeliveryNoteDataQualityModel.CATEGORY.ISSUER.NAME,
                ).dataQualityPairs
              }
            />
          </div>
          <div className="mt-4 w-full rounded-md bg-white p-2 shadow-lg">
            <DeliveryNoteDataQualityCategory
              category={DeliveryNoteDataQualityModel.CATEGORY.SUPPLIER.NAME}
              dataQualityPairs={
                dataQualityCategories.find(
                  (category) =>
                    category.NAME ===
                    DeliveryNoteDataQualityModel.CATEGORY.SUPPLIER.NAME,
                ).dataQualityPairs
              }
            />
          </div>
          <div className="mt-4 w-full rounded-md bg-white p-2 shadow-lg">
            <DeliveryNoteDataQualityCategory
              category={DeliveryNoteDataQualityModel.CATEGORY.CARRIER.NAME}
              dataQualityPairs={
                dataQualityCategories.find(
                  (category) =>
                    category.NAME ===
                    DeliveryNoteDataQualityModel.CATEGORY.CARRIER.NAME,
                ).dataQualityPairs
              }
            />
          </div>
          <div className="mt-4 w-full rounded-md bg-white p-2 shadow-lg">
            <DeliveryNoteDataQualityCategory
              category={DeliveryNoteDataQualityModel.CATEGORY.RECIPIENT.NAME}
              dataQualityPairs={
                dataQualityCategories.find(
                  (category) =>
                    category.NAME ===
                    DeliveryNoteDataQualityModel.CATEGORY.RECIPIENT.NAME,
                ).dataQualityPairs
              }
            />
          </div>
          <div className="mt-4 w-full rounded-md bg-white p-2 shadow-lg">
            <DeliveryNoteDataQualityCategory
              category={DeliveryNoteDataQualityModel.CATEGORY.SELLER.NAME}
              dataQualityPairs={
                dataQualityCategories.find(
                  (category) =>
                    category.NAME ===
                    DeliveryNoteDataQualityModel.CATEGORY.SELLER.NAME,
                ).dataQualityPairs
              }
            />
          </div>
          <div className="mt-4 w-full rounded-md bg-white p-2 shadow-lg">
            <DeliveryNoteDataQualityCategory
              category={DeliveryNoteDataQualityModel.CATEGORY.BUYER.NAME}
              dataQualityPairs={
                dataQualityCategories.find(
                  (category) =>
                    category.NAME ===
                    DeliveryNoteDataQualityModel.CATEGORY.BUYER.NAME,
                ).dataQualityPairs
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <div className="w-full rounded-md bg-white p-2 shadow-lg">
            <DeliveryNoteDataQualityCategory
              category={DeliveryNoteDataQualityModel.CATEGORY.EXECUTION.NAME}
              dataQualityPairs={
                dataQualityCategories.find(
                  (category) =>
                    category.NAME ===
                    DeliveryNoteDataQualityModel.CATEGORY.EXECUTION.NAME,
                ).dataQualityPairs
              }
            />
          </div>
          <div className="mt-4 w-full rounded-md bg-white p-2 shadow-lg">
            <DeliveryNoteDataQualityCategory
              category={
                DeliveryNoteDataQualityModel.CATEGORY.FREIGHT_FORWARDER.NAME
              }
              dataQualityPairs={
                dataQualityCategories.find(
                  (category) =>
                    category.NAME ===
                    DeliveryNoteDataQualityModel.CATEGORY.FREIGHT_FORWARDER
                      .NAME,
                ).dataQualityPairs
              }
            />
          </div>
          <div className="mt-4 w-full rounded-md bg-white p-2 shadow-lg">
            <DeliveryNoteDataQualityCategory
              category={DeliveryNoteDataQualityModel.CATEGORY.FROM_SITE.NAME}
              dataQualityPairs={
                dataQualityCategories.find(
                  (category) =>
                    category.NAME ===
                    DeliveryNoteDataQualityModel.CATEGORY.FROM_SITE.NAME,
                ).dataQualityPairs
              }
            />
          </div>
          <div className="mt-4 w-full rounded-md bg-white p-2 shadow-lg">
            <DeliveryNoteDataQualityCategory
              category={
                DeliveryNoteDataQualityModel.CATEGORY.TO_SITE_SUPPLIER.NAME
              }
              dataQualityPairs={
                dataQualityCategories.find(
                  (category) =>
                    category.NAME ===
                    DeliveryNoteDataQualityModel.CATEGORY.TO_SITE_SUPPLIER.NAME,
                ).dataQualityPairs
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          {getArticleComponents()}
        </Grid>
      </Grid>
      <div
        className="min-h-2rem"
        /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
        /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
        /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
      />
    </div>
  );
}, 'Lieferung konnte nicht geladen werden.');

DeliveryNoteDataQuality.displayName = 'DeliveryNoteDataQuality';
