import ArrayUtils from '~/utils/arrayUtils';

import { withErrorBoundary } from '~/ui/atoms';

import { AmountApprovedCheck } from './AmountApprovedCheck';
import { AmountCheck } from './AmountCheck';
import { ArticleExistsCheck } from './ArticleExistsCheck';
import { DlnCheck } from './DlnCheck';
import { FormalCheck } from './FormalCheck';
import { PriceCheck } from './PriceCheck';
import { SignatureCheck } from './SignatureCheck';

export const InvoiceCheckCategories = withErrorBoundary(
  ({ invoice, activeInvoiceCheckCategories }) => {
    const getCategoryCheckClassName = (category) => {
      let className = 'bg-white rounded-md shadow-lg w-full ';

      className += activeInvoiceCheckCategories.includes(category.name)
        ? 'fade-in-1500ms p-4 mb-4'
        : 'fade-out-0ms max-h-0';

      return className;
    };

    const categoryComponentPairs = [
      {
        category: invoice.checkCategory.formalCheck,
        categoryName: invoice.checkCategory.formalCheck.name,
        component: <FormalCheck category={invoice.checkCategory.formalCheck} />,
      },
      {
        category: invoice.checkCategory.dlnCheck,
        categoryName: invoice.checkCategory.dlnCheck.name,
        component: <DlnCheck category={invoice.checkCategory.dlnCheck} />,
      },
      {
        category: invoice.checkCategory.signatureCheck,
        categoryName: invoice.checkCategory.signatureCheck.name,
        component: (
          <SignatureCheck category={invoice.checkCategory.signatureCheck} />
        ),
      },
      {
        category: invoice.checkCategory.articleExistsCheck,
        categoryName: invoice.checkCategory.articleExistsCheck.name,
        component: (
          <ArticleExistsCheck
            category={invoice.checkCategory.articleExistsCheck}
          />
        ),
      },
      {
        category: invoice.checkCategory.amountCheck,
        categoryName: invoice.checkCategory.amountCheck.name,
        component: <AmountCheck category={invoice.checkCategory.amountCheck} />,
      },
      {
        category: invoice.checkCategory.amountApprovedCheck,
        categoryName: invoice.checkCategory.amountApprovedCheck.name,
        component: (
          <AmountApprovedCheck
            category={invoice.checkCategory.amountApprovedCheck}
          />
        ),
      },
      {
        category: invoice.checkCategory.priceCheck,
        categoryName: invoice.checkCategory.priceCheck.name,
        component: <PriceCheck category={invoice.checkCategory.priceCheck} />,
      },
    ];

    // Sort by user click order
    const sortedCategoryComponentPairs = ArrayUtils.sortByKeyValues(
      categoryComponentPairs,
      activeInvoiceCheckCategories,
      'categoryName',
    );

    // Add inactive categories for fade animation
    for (const categoryComponentPair of categoryComponentPairs) {
      if (
        !activeInvoiceCheckCategories.includes(
          categoryComponentPair.category.name,
        )
      ) {
        sortedCategoryComponentPairs.push(categoryComponentPair);
      }
    }

    return (
      <div>
        {sortedCategoryComponentPairs.map((categoryComponentPair) => (
          <div
            className={getCategoryCheckClassName(
              categoryComponentPair.category,
            )}
            key={categoryComponentPair.category.name}
          >
            {categoryComponentPair.component}
          </div>
        ))}
      </div>
    );
  },
  null,
);

InvoiceCheckCategories.displayName = 'InvoiceCheckCategories';
