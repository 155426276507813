import UnitUtils from '~/utils/unitUtils';
import Company from '../masterdata/Company';
import Concrete from './Concrete';
import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import { camelcaseKeysFromApi } from '~/services/kyClient';
import ValueGroup from '../deliveries/ValueGroup';
import AcceptStateCalculator from '../acceptState/AcceptStateCalculator';
import AcceptArticle from '../acceptState/AcceptArticle';
import CustomData from '../customData/CustomData';
import SignatureRoles from '../masterdata/SignatureRoles';
import BilledItem from '../billingState/BilledItem';
import ShippingMark from '../deliveries/ShippingMark';

export default class Article {
  constructor(
    lineItem,
    logisticsPackage,
    documentLogPackagePosition,
    documentLineItemPosition,
    acceptItems,
    billedItems,
  ) {
    lineItem = camelcaseKeysFromApi(lineItem);
    logisticsPackage = camelcaseKeysFromApi(logisticsPackage);
    acceptItems = camelcaseKeysFromApi(acceptItems);
    billedItems = camelcaseKeysFromApi(billedItems);

    this.logisticsPackage = logisticsPackage ?? 0;
    this.position = lineItem?.id ?? 0;

    const product = lineItem?.product || {};
    this.number = product?.seller_assigned_id ?? product?.sellerAssignedId ?? 0;
    this.ean = product?.ean ?? '';
    this.type = product?.name ?? '';
    this.description = product?.description ?? '';
    this.comment = product?.comment ?? '';
    this.constructionPlan =
      product?.construction_plan ?? product?.constructionPlan ?? null;
    this.constructionComponent =
      product?.construction_component ?? product?.constructionComponent ?? null;

    const settlement = lineItem?.settlement || {};
    const payableAccount =
      settlement?.payable_account ?? settlement?.payableAccount ?? {};
    this.costCenter = payableAccount?.name ?? null;

    const delivery = lineItem?.delivery || {};
    this.shippingMarks = (
      delivery?.shipping_marks ??
      delivery?.shippingMarks ??
      []
    ).map((shipping_mark) => new ShippingMark(shipping_mark));

    const manufacturer = product?.manufacturer || {};
    this.manufacturer = new Company(
      manufacturer?.legal_organization ?? manufacturer?.legalOrganization,
    );

    this.customData = new CustomData(
      lineItem?.additional_party_data ?? lineItem?.additionalPartyData,
    );

    this.documentLogPackagePosition = documentLogPackagePosition ?? 0;
    this.documentLineItemPosition = documentLineItemPosition ?? 0;

    this.quantity = Article.getEmptyValue(); // Equals product_unit
    this.weight = Article.getEmptyValue(); // Equals net_weight
    this.amount = Article.getEmptyValue(); // Amount that is displayed per default in the UI.

    this.weighingInformation = this.calculateWeighingInformation(lineItem);

    if (!lineItem) {
      return;
    }

    this.loadNetWeight(lineItem);
    this.loadProductUnit(lineItem);
    this.loadConcrete(lineItem);

    this.amount = this.getAmountFromArticle(this.quantity, this.weight);
    this.billedItem = this.getBilledItem(billedItems);

    this.acceptArticleSupplier = AcceptArticle.getAcceptArticleOfParty(
      acceptItems,
      SignatureRoles.SIGNATURE_ROLE.SUPPLIER.KEY,
      this.documentLogPackagePosition,
      this.documentLineItemPosition,
    );
    this.acceptArticleCarrier = AcceptArticle.getAcceptArticleOfParty(
      acceptItems,
      SignatureRoles.SIGNATURE_ROLE.CARRIER.KEY,
      this.documentLogPackagePosition,
      this.documentLineItemPosition,
    );
    this.acceptArticleRecipient = AcceptArticle.getAcceptArticleOfParty(
      acceptItems,
      SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY,
      this.documentLogPackagePosition,
      this.documentLineItemPosition,
    );
    this.acceptArticleOnBehalfSupplier = AcceptArticle.getAcceptArticleOfParty(
      acceptItems,
      SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_SUPPLIER.KEY,
      this.documentLogPackagePosition,
      this.documentLineItemPosition,
    );
    this.acceptArticleOnBehalfCarrier = AcceptArticle.getAcceptArticleOfParty(
      acceptItems,
      SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_CARRIER.KEY,
      this.documentLogPackagePosition,
      this.documentLineItemPosition,
    );
    this.acceptArticleOnBehalfRecipient = AcceptArticle.getAcceptArticleOfParty(
      acceptItems,
      SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_RECIPIENT.KEY,
      this.documentLogPackagePosition,
      this.documentLineItemPosition,
    );
    this.acceptState =
      AcceptStateCalculator.calculateOverallAcceptStateFromParties(
        this.acceptArticleSupplier.acceptState,
        this.acceptArticleCarrier.acceptState,
        this.acceptArticleRecipient.acceptState,
        this.acceptArticleOnBehalfSupplier.acceptState,
        this.acceptArticleOnBehalfCarrier.acceptState,
        this.acceptArticleOnBehalfRecipient.acceptState,
      );

    this.acceptArticleSupplier.setUnit(this.amount.unit);
    this.acceptArticleCarrier.setUnit(this.amount.unit);
    this.acceptArticleRecipient.setUnit(this.amount.unit);
    this.acceptArticleOnBehalfSupplier.setUnit(this.amount.unit);
    this.acceptArticleOnBehalfCarrier.setUnit(this.amount.unit);
    this.acceptArticleOnBehalfRecipient.setUnit(this.amount.unit);
  }

  loadNetWeight(lineItem) {
    if (
      !(
        lineItem.delivery?.net_weight?.measure ||
        lineItem.delivery?.netWeight?.measure
      )
    ) {
      return;
    }

    this.weight = {
      unit:
        lineItem.delivery?.net_weight?.measure ??
        lineItem.delivery?.netWeight?.measure,
      value: UnitUtils.parseToNumber(
        lineItem.delivery?.net_weight?.weight ??
          lineItem.delivery?.netWeight?.weight,
      ),
    };
  }

  loadProductUnit(lineItem) {
    if (
      !(
        lineItem.delivery?.product_unit?.quantity_type ||
        lineItem.delivery?.productUnit?.quantityType
      )
    ) {
      return;
    }

    this.quantity = {
      unit:
        lineItem.delivery?.product_unit?.quantity_type ??
        lineItem.delivery?.productUnit?.quantityType,
      value: UnitUtils.parseToNumber(
        lineItem.delivery?.product_unit?.quantity ??
          lineItem.delivery?.productUnit?.quantity,
      ),
    };
  }

  loadConcrete(lineItem) {
    if (
      !lineItem.product?.hasOwnProperty('concrete') ||
      lineItem.product.concrete === null
    ) {
      return;
    }

    this.delivery = {
      actual: {
        unit:
          lineItem.delivery?.actual_delivery?.measure ??
          lineItem.delivery?.actualDelivery?.measure,
        value: UnitUtils.parseToNumber(
          lineItem.delivery?.actual_delivery?.value ??
            lineItem.delivery?.actualDelivery?.value,
        ),
      },
      remaining: {
        unit:
          lineItem.delivery?.remaining_delivery?.measure ??
          lineItem.delivery?.remainingDelivery?.measure,
        value: UnitUtils.parseToNumber(
          lineItem.delivery?.remaining_delivery?.value ??
            lineItem.delivery?.remainingDelivery?.value,
        ),
      },
      requested: {
        unit:
          lineItem.delivery?.requested_delivery?.measure ??
          lineItem.delivery?.requestedDelivery?.measure,
        value: UnitUtils.parseToNumber(
          lineItem.delivery?.requested_delivery?.value ??
            lineItem.delivery?.requested_delivery?.value,
        ),
      },
    };

    this.concrete = new Concrete(this.number, lineItem.product.concrete);
  }

  calculateWeighingInformation(lineItem) {
    return {
      gross: {
        scaleId:
          lineItem?.document_line?.note?.content?.gross_weight?.id ??
          lineItem?.documentLine?.note?.content?.grossWeight?.id,
        weight: {
          unit:
            lineItem?.document_line?.note?.content?.gross_weight?.measure ??
            lineItem?.documentLine?.note?.content?.grossWeight?.measure,
          value: UnitUtils.parseToNumber(
            lineItem?.document_line?.note?.content?.gross_weight?.weight ??
              lineItem?.documentLine?.note?.content?.grossWeight?.weight,
          ),
        },
      },
      person: {
        name:
          lineItem?.document_line?.note?.content?.weighing_person
            ?.person_name ??
          lineItem?.documentLine?.note?.content?.weighingPerson?.person_name,
      },
      tare: {
        scaleId:
          lineItem?.document_line?.note?.content?.tare_weight?.id ??
          lineItem?.documentLine?.note?.content?.tareWeight?.id,
        weight: {
          unit:
            lineItem?.document_line?.note?.content?.tare_weight?.measure ??
            lineItem?.documentLine?.note?.content?.tareWeight?.measure,
          value: UnitUtils.parseToNumber(
            lineItem?.document_line?.note?.content?.tare_weight?.weight ??
              lineItem?.documentLine?.note?.content?.tareWeight?.weight,
          ),
        },
      },
    };
  }

  getAmountFromArticle(quantity, weight) {
    if (UnitUtils.isValidNumber(quantity.value) && quantity.unit) {
      return {
        unit: quantity.unit,
        value: quantity.value,
      };
    }

    if (UnitUtils.isValidNumber(weight.value) && weight.unit) {
      return {
        unit: weight.unit,
        value: weight.value,
      };
    }

    return Article.getEmptyValue();
  }

  getBilledItem(billedItems) {
    const partyBilledItems = billedItems?.seller ?? billedItems?.trader ?? [];

    const partyBilledItem = partyBilledItems.find(
      (partyBilledItem) =>
        JSON.stringify(partyBilledItem.path) ===
        JSON.stringify([
          'transaction',
          'logisticsPackage',
          this.documentLogPackagePosition,
          'lineItem',
          this.documentLineItemPosition,
        ]),
    );

    return new BilledItem(partyBilledItem);
  }

  static getEmptyValue() {
    return {
      unit: null,
      value: null,
    };
  }

  hasBeenEdited() {
    return ValueGroup.hasBeenEdited(
      this,
      Article.CHANGES_BOOLEAN_EXCLUDED_PATHS,
      null,
    );
  }

  // Return the string that should be displayed for the partial accept status in the dln article list.
  // This contains the bug that when recipient has declined, supplier and carrier are ignored.
  // However, the reason for the bug is already the UI because there is only one "Abgelehnt" column in the dln article list.
  // Hence, already there multiple parties declining isn't handled. Thus, the design of the dln article list must be adjusted to fix this bug.
  getPartialAcceptStatusString() {
    if (
      this.acceptArticleRecipient.acceptState ===
      AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      return this.acceptArticleRecipient.partialAcceptStatusToString();
    }

    if (
      this.acceptArticleSupplier.acceptState ===
      AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      return this.acceptArticleSupplier.partialAcceptStatusToString();
    }

    if (
      this.acceptArticleCarrier.acceptState ===
      AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      return this.acceptArticleCarrier.partialAcceptStatusToString();
    }

    if (
      this.acceptArticleOnBehalfRecipient.acceptState ===
      AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      return this.acceptArticleOnBehalfRecipient.partialAcceptStatusToString();
    }

    if (
      this.acceptArticleOnBehalfSupplier.acceptState ===
      AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      return this.acceptArticleOnBehalfSupplier.partialAcceptStatusToString();
    }

    if (
      this.acceptArticleOnBehalfCarrier.acceptState ===
      AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      return this.acceptArticleOnBehalfCarrier.partialAcceptStatusToString();
    }

    return null;
  }

  // Display amount if it is given
  displayAmount() {
    return (
      UnitUtils.isValidNumber(ValueGroup.getCurrentValue(this.amount.value)) &&
      ValueGroup.getCurrentValue(this.amount.unit)
    );
  }

  // Display weight if it is given and doesn't equal amount
  displayWeight() {
    return (
      UnitUtils.isValidNumber(ValueGroup.getCurrentValue(this.weight.value)) &&
      ValueGroup.getCurrentValue(this.weight.unit) &&
      ValueGroup.getCurrentValue(this.amount.value) !==
        ValueGroup.getCurrentValue(this.weight.value) &&
      ValueGroup.getCurrentValue(this.amount.unit) !==
        ValueGroup.getCurrentValue(this.weight.unit)
    );
  }

  isConcrete() {
    return this.hasOwnProperty('concrete');
  }

  static getMaterialName(key) {
    const articleConst = Object.keys(Article.MATERIAL).find(
      (x) => key === Article.MATERIAL[x].STANDARDISED,
    );

    if (!articleConst) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid material: ' + key),
      );
      return key;
    }

    return Article.MATERIAL[articleConst].DESCRIPTIVE;
  }

  getBackendFormat(
    costCenter,
    sellerOrderReferences,
    buyerOrderReferences,
    constructionComponents,
    constructionPlans,
  ) {
    return {
      agreement: {
        buyer_order: buyerOrderReferences,
        seller_order: sellerOrderReferences,
      },
      delivery: {
        net_weight: this.weight.value
          ? {
              measure: this.weight.unit,
              weight: this.weight.value.replace(',', '.'),
            }
          : null,
        product_unit: this.quantity.value
          ? {
              quantity: this.quantity.value.replace(',', '.'),
              quantity_type: this.quantity.unit,
            }
          : null,
      },
      document_line: {
        note: {
          content: {
            gross_weight: this.weighingInformation.gross.weight.value
              ? {
                  measure: this.weight.unit,
                  weight: this.weighingInformation.gross.weight.value.replace(
                    ',',
                    '.',
                  ),
                }
              : null,
            tare_weight: this.weighingInformation.tare.weight.value
              ? {
                  measure: this.weight.unit,
                  weight: this.weighingInformation.tare.weight.value.replace(
                    ',',
                    '.',
                  ),
                }
              : null,
          },
        },
      },
      id: this.position,
      product: {
        construction_component: constructionComponents,

        // Writing the constructionPlans into the construction_plan variable doesn't work properly if a delivery note has multiple articles with different construction plans.
        // In this case, every article has all the construction plans assigned to. This is similar to construction components and buyer/seller order references.
        construction_plan: constructionPlans,

        ean: this.ean,

        manufacturer: this.manufacturer.getBackendFormat(),

        name: this.type,
        seller_assigned_id: this.number,
      },
      settlement: costCenter
        ? { payable_account: { name: costCenter, type_code: 'AWE' } }
        : null,
    };
  }

  static MATERIAL = {
    AC: {
      DESCRIPTIVE: 'Zusatzmittel Erhärtungs-/Erstarrungsbeschleuniger',
      STANDARDISED: 'AC',
    },
    AD: {
      DESCRIPTIVE: 'Zusatzmittel Luftentzieher',
      STANDARDISED: 'AD',
    },
    CE: {
      DESCRIPTIVE: 'Zement',
      STANDARDISED: 'CE',
    },
    CP: {
      DESCRIPTIVE: 'Zusatzmittel Stabilisierer',
      STANDARDISED: 'CP',
    },
    DEFAULT: {
      // as fallback if no other material fits
      DESCRIPTIVE: 'Inhaltsstoff',
    },
    DP: {
      DESCRIPTIVE: 'Zusatzmittel Dichtungsmittel',
      STANDARDISED: 'DP',
    },
    GF: {
      DESCRIPTIVE: 'Zusatzmittel Luftporenbildner',
      STANDARDISED: 'GF',
    },
    OA: {
      DESCRIPTIVE: 'Zusatzstoff',
      STANDARDISED: 'OA',
    },
    OM: {
      DESCRIPTIVE: 'Andere Zusatzmittel',
      STANDARDISED: 'OM',
    },
    PZ: {
      DESCRIPTIVE: 'Zusatzmittel Puzzolanzement',
      STANDARDISED: 'PZ',
    },
    RT: {
      DESCRIPTIVE: 'Zusatzmittel Verzögerer',
      STANDARDISED: 'RT',
    },
    SP: {
      DESCRIPTIVE: 'Zusatzmittel Fließmittel',
      STANDARDISED: 'SP',
    },
    WR: {
      DESCRIPTIVE: 'Zusatzmittel Betonverflüssiger',
      STANDARDISED: 'WR',
    },
    WT: {
      DESCRIPTIVE: 'Wasser',
      STANDARDISED: 'WT',
    },
  };
  static UNIT = {
    AB: {
      ABBREVIATED: 'Großpack',
      DESCRIPTIVE: 'Großpack',
      STANDARDISED: 'AB',
      TYPE: 'amount',
    },

    BE: {
      ABBREVIATED: 'Bündel',
      DESCRIPTIVE: 'Bündel',
      STANDARDISED: 'BE',
      TYPE: 'amount',
    },

    BG: {
      ABBREVIATED: 'Beutel',
      DESCRIPTIVE: 'Beutel',
      STANDARDISED: 'BG',
      TYPE: 'amount',
    },

    BJ: {
      ABBREVIATED: 'Eim',
      DESCRIPTIVE: 'Eimer',
      STANDARDISED: 'BJ',
      TYPE: 'amount',
    },

    C62: {
      ABBREVIATED: 'Einheiten',
      DESCRIPTIVE: 'Einheiten',
      STANDARDISED: 'C62',
      TYPE: 'amount',
    },

    CA: {
      ABBREVIATED: 'Dosen',
      DESCRIPTIVE: 'Dosen',
      STANDARDISED: 'CA',
      TYPE: 'amount',
    },

    CI: {
      ABBREVIATED: 'Kanister',
      DESCRIPTIVE: 'Kanister',
      STANDARDISED: 'CI',
      TYPE: 'amount',
    },

    CMQ: {
      ABBREVIATED: 'cm³',
      DESCRIPTIVE: 'Kubikzentimeter',
      FACTOR: 0.001,
      STANDARDISED: 'CMQ',
      TYPE: 'volume',
    },

    CMT: {
      ABBREVIATED: 'cm',
      DESCRIPTIVE: 'Zentimeter',
      STANDARDISED: 'CMT',
      TYPE: 'amount',
    },

    CQ: {
      ABBREVIATED: 'Kartusche',
      DESCRIPTIVE: 'Kartusche',
      STANDARDISED: 'CQ',
      TYPE: 'amount',
    },

    CT: {
      ABBREVIATED: 'Kartone',
      DESCRIPTIVE: 'Kartone',
      STANDARDISED: 'CT',
      TYPE: 'amount',
    },

    DAY: {
      ABBREVIATED: 'Tage',
      DESCRIPTIVE: 'Tage',
      FACTOR: 1440,
      STANDARDISED: 'DAY',
      TYPE: 'time',
    },

    DMQ: {
      ABBREVIATED: 'dm³',
      DESCRIPTIVE: 'Kubikdezimeter',
      FACTOR: 1,
      STANDARDISED: 'DMQ',
      TYPE: 'volume',
    },

    E48: {
      ABBREVIATED: 'Service',
      DESCRIPTIVE: 'Service',
      STANDARDISED: 'E48',
      TYPE: 'amount',
    },

    // amount units
    EA: {
      ABBREVIATED: 'Stk',
      DESCRIPTIVE: 'Stück',
      STANDARDISED: 'EA',
      TYPE: 'amount',
    },

    EUR: {
      ABBREVIATED: '€',
      DESCRIPTIVE: 'Euro',
      STANDARDISED: 'EUR',
      TYPE: 'currency',
    },

    GRM: {
      ABBREVIATED: 'g',
      DESCRIPTIVE: 'Gramm',
      FACTOR: 1 / 1000,
      STANDARDISED: 'GRM',
      TYPE: 'weight',
    },

    HUR: {
      ABBREVIATED: 'Std',
      DESCRIPTIVE: 'Stunden',
      FACTOR: 60,
      STANDARDISED: 'HUR',
      TYPE: 'time',
    },

    KGM: {
      ABBREVIATED: 'kg',
      DESCRIPTIVE: 'Kilogramm',
      FACTOR: 1,
      STANDARDISED: 'KGM',
      TYPE: 'weight',
    },

    // other units
    KMQ: {
      ABBREVIATED: 'kg/m³',
      DESCRIPTIVE: 'Kilogramm pro Kubikmeter',
      STANDARDISED: 'KMQ',
      TYPE: 'weight-per-volume',
    },

    KMT: {
      ABBREVIATED: 'km',
      DESCRIPTIVE: 'Kilometer',
      STANDARDISED: 'KMT',
      TYPE: 'amount',
    },

    KT: {
      ABBREVIATED: 'Kit',
      DESCRIPTIVE: 'Kit',
      STANDARDISED: 'KT',
      TYPE: 'amount',
    },

    LR: {
      ABBREVIATED: 'Lage',
      DESCRIPTIVE: 'Lage',
      STANDARDISED: 'LR',
      TYPE: 'amount',
    },

    // volume units
    LTR: {
      ABBREVIATED: 'Ltr',
      DESCRIPTIVE: 'Liter',
      FACTOR: 1,
      STANDARDISED: 'LTR',
      TYPE: 'volume',
    },

    // time units
    MIN: {
      ABBREVIATED: 'Min',
      DESCRIPTIVE: 'Minuten',
      FACTOR: 1,
      STANDARDISED: 'MIN',
      TYPE: 'time',
    },

    MMQ: {
      ABBREVIATED: 'mm³',
      DESCRIPTIVE: 'Kubikmillimeter',
      FACTOR: 0.000_001,
      STANDARDISED: 'MMQ',
      TYPE: 'volume',
    },

    MMT: {
      ABBREVIATED: 'mm',
      DESCRIPTIVE: 'Millimeter',
      STANDARDISED: 'MMT',
      TYPE: 'amount',
    },

    MON: {
      ABBREVIATED: 'Monate',
      DESCRIPTIVE: 'Monate',
      FACTOR: 43_200,
      STANDARDISED: 'MON',
      TYPE: 'time', // Based on thirty days
    },

    MTK: {
      ABBREVIATED: 'm²',
      DESCRIPTIVE: 'Quadratmeter',
      STANDARDISED: 'MTK',
      TYPE: 'amount',
    },

    MTQ: {
      ABBREVIATED: 'm³',
      DESCRIPTIVE: 'Kubikmeter',
      FACTOR: 1000,
      STANDARDISED: 'MTQ',
      TYPE: 'volume',
    },

    MTR: {
      ABBREVIATED: 'm',
      DESCRIPTIVE: 'Meter',
      STANDARDISED: 'MTR',
      TYPE: 'amount',
    },

    PA: {
      ABBREVIATED: 'Pkt',
      DESCRIPTIVE: 'Pakete',
      STANDARDISED: 'PA',
      TYPE: 'amount',
    },

    PF: {
      ABBREVIATED: 'Pal',
      DESCRIPTIVE: 'Paletten',
      STANDARDISED: 'PF',
      TYPE: 'amount',
    },

    PK: {
      ABBREVIATED: 'Pack',
      DESCRIPTIVE: 'Packungen',
      STANDARDISED: 'PK',
      TYPE: 'amount',
    },

    PR: {
      ABBREVIATED: 'Paar',
      DESCRIPTIVE: 'Paar',
      STANDARDISED: 'PR',
      TYPE: 'amount',
    },

    RO: {
      ABBREVIATED: 'Rollen',
      DESCRIPTIVE: 'Rollen',
      STANDARDISED: 'RO',
      TYPE: 'amount',
    },

    SA: {
      ABBREVIATED: 'Säcke',
      DESCRIPTIVE: 'Säcke',
      STANDARDISED: 'SA',
      TYPE: 'amount',
    },

    SET: {
      ABBREVIATED: 'Satz',
      DESCRIPTIVE: 'Satz',
      STANDARDISED: 'SET',
      TYPE: 'amount',
    },

    // weight units
    TNE: {
      ABBREVIATED: 't',
      DESCRIPTIVE: 'Tonnen',
      FACTOR: 1000,
      STANDARDISED: 'TNE',
      TYPE: 'weight',
    },
    TU: {
      ABBREVIATED: 'Tuben',
      DESCRIPTIVE: 'Tuben',
      STANDARDISED: 'TU',
      TYPE: 'amount',
    },
  };
  static DELIVERY_DIRECTION = {
    DELIVERED_ARTICLES: {
      KEY: 'delivered_articles',
      STRING: 'Auslieferung',
    },
    RETURNED_ARTICLES: {
      KEY: 'returned_articles',
      STRING: 'Retoure',
    },
  };
  // exclude some paths when evaluating whether an article has been edited
  static CHANGES_BOOLEAN_EXCLUDED_PATHS = [
    ['number'], // number is already shown in article list and thus doesn't need extra highlighting by edited icon
    ['type'], // same as for number
    ['costCenter'], // cost center is usual to be updated, so it's excluded from evaluating whether there were "real" changes to the article
    ['amount'], // same as for number
    ['weight'], // same as for number
  ];
}
