import * as React from 'react';
import { DateRange as DateRangeIcon } from '@mui/icons-material';
import { FormControl, MenuItem, Select } from '@mui/material';

import { dateUtils } from '~/utils/dateUtils';

import { DateRangePicker } from './DateRangePicker';

const INDIVIDUAL = 'individual';
export const PREDEFINED_DATE_RANGE_OPTIONS = [
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.DAY.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.DAY.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.YESTERDAY.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.YESTERDAY.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.WEEK.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.WEEK.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.LAST_WEEK.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.LAST_WEEK.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.MONTH.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.MONTH.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.LAST_MONTH.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.LAST_MONTH.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.YEAR.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.YEAR.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._7DAYS.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._7DAYS.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._30DAYS.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._30DAYS.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._365DAYS.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._365DAYS.STRING,
  },
  {
    id: INDIVIDUAL,
    name: null,
  },
];

const DateRangeSelect = ({
  dateRange,
  disabled,
  individualDateRange,
  onDateRangeChange,
  onPredefinedDateRangeChange,
  predefinedDateRange,
  testId,
}) => {
  const [open, setOpen] = React.useState(false);

  const [localDateRange, setLocalDateRange] = React.useState(null);

  React.useEffect(() => {
    setLocalDateRange(dateRange);
  }, [dateRange]);

  const handlePredefinedDateRangeChange = (entityName) => {
    const entityId = PREDEFINED_DATE_RANGE_OPTIONS.find(
      (option) => option.id === entityName,
    )?.id;

    onPredefinedDateRangeChange(entityId);
  };

  const handleIndividualDateRangeChange = (date) => {
    onDateRangeChange(date);
  };

  const renderValue = (id) => {
    return id === INDIVIDUAL ? (
      <div>
        {dateUtils.getFormattedDate(
          dateRange[0],
          dateUtils.DATE_FORMAT.DD_MM_YY,
        ) +
          ' - ' +
          dateUtils.getFormattedDate(
            dateRange[1],
            dateUtils.DATE_FORMAT.DD_MM_YY,
          )}
      </div>
    ) : (
      <div>
        {PREDEFINED_DATE_RANGE_OPTIONS.find((option) => option.id === id)?.name}
      </div>
    );
  };

  return (
    <FormControl>
      <div className="flex items-center gap-4">
        <Select
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          className="w-56 bg-white"
          size="small"
          labelId="date-range-label"
          data-testid={testId}
          id="date-range-select"
          value={individualDateRange ? INDIVIDUAL : predefinedDateRange}
          renderValue={renderValue}
          key="date-range-select-0"
          onChange={(event) => {
            handlePredefinedDateRangeChange(event.target.value);
          }}
          disabled={disabled}
          startAdornment={
            disabled ? null : (
              <DateRangeIcon
                className="mr-2 cursor-pointer text-gray-500"
                onClick={() => setOpen(true)}
                disabled={disabled}
              />
            )
          }
        >
          {PREDEFINED_DATE_RANGE_OPTIONS.map((entity) => {
            if (entity.id === INDIVIDUAL) {
              return (
                <div className="px-2 pt-3" key={entity.id}>
                  <DateRangePicker
                    onDateRangeChange={handleIndividualDateRangeChange}
                    dateRange={localDateRange ?? dateRange}
                    disabled={disabled}
                  />
                </div>
              );
            }

            return (
              <MenuItem
                value={entity.id}
                key={entity.id}
                className="ml-1 flex items-center justify-between border-b border-gray-900 p-2"
              >
                {entity.name}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </FormControl>
  );
};

export default DateRangeSelect;
