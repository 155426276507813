import { useState } from 'react';

import Log from '~/utils/Log';

import BasicModal from '~/components/BasicModal';

export const AttachmentImage = ({ url }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    Log.productAnalyticsEvent(
      'Open image attachment',
      Log.FEATURE.OTHER_FEATURE,
    );
    setOpen(true);
  };

  const handleClose = () => {
    Log.productAnalyticsEvent(
      'Close image attachment',
      Log.FEATURE.OTHER_FEATURE,
    );
    setOpen(false);
  };

  return (
    <button onMouseDown={handleClick} className="h-full w-full">
      <img className="fit h-full w-full" src={url} key={url} />
      <BasicModal title="Anhang" open={open} closeModal={handleClose}>
        <img className="max-h-70vh max-w-70vw" src={url} />
      </BasicModal>
    </button>
  );
};
