import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';

import { useQueryUserData } from '~/data/user';

import FunctionUtils from '~/utils/functionUtils';
import Log from '~/utils/Log';

import { PasswordTextField } from '~/components/baseComponents/inputs/PasswordTextField';
import { withErrorBoundary } from '~/ui/atoms';
import { SelectCompany } from '~/ui/molecules/SelectServerDriven';

export const AccountInformation = withErrorBoundary(({ setUser, user }) => {
  const {
    data: currentUser,
    isError: isErrorUserData,
    isLoading: isLoadingUserData,
    isSuccess: isSuccessUserData,
  } = useQueryUserData(true);

  const companyInfo = currentUser?.companyInfo ?? {};

  const resetDefaultValues = useCallback(() => {
    const newUser = cloneDeep(user);

    newUser.companyId ||= companyInfo.id;

    setUser(newUser);
  }, [companyInfo.id, user]);

  useEffect(() => {
    resetDefaultValues();
  }, [isSuccessUserData]);

  const handleInputChange = (event) => {
    const newUser = cloneDeep(user);

    switch (event.target.name) {
      case 'email': {
        newUser.email = event.target.value;

        Log.info(
          'Change form value of email',
          {
            from: user.email,
            to: newUser.email,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_create_wizard_change_email',
          Log.productAnalyticsEvent,
          ['Change email in create user wizard', Log.FEATURE.WIZARD],
        );

        break;
      }

      case 'password': {
        newUser.password = event.target.value;

        Log.info(
          'Change form value of password',
          {
            from: user.password,
            to: newUser.password,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_create_wizard_change_password',
          Log.productAnalyticsEvent,
          ['Change password in create user wizard', Log.FEATURE.WIZARD],
        );

        break;
      }

      case 'first_name': {
        newUser.firstName = event.target.value;

        Log.info(
          'Change form value of first name',
          {
            from: user.firstName,
            to: newUser.firstName,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_create_wizard_change_first_name',
          Log.productAnalyticsEvent,
          ['Change first name in create user wizard', Log.FEATURE.WIZARD],
        );

        break;
      }

      case 'last_name': {
        newUser.lastName = event.target.value;

        Log.info(
          'Change form value of last name',
          {
            from: user.lastName,
            to: newUser.lastName,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_create_wizard_change_last_name',
          Log.productAnalyticsEvent,
          ['Change last name in create user wizard', Log.FEATURE.WIZARD],
        );

        break;
      }

      case 'position': {
        newUser.position = event.target.value;

        Log.info(
          'Change form value of position',
          {
            from: user.position,
            to: newUser.position,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_create_wizard_change_position',
          Log.productAnalyticsEvent,
          ['Change position in create user wizard', Log.FEATURE.WIZARD],
        );

        break;
      }

      default: {
        break;
      }
    }

    setUser(newUser);
  };

  const handleChangeCompany = (value) => {
    const newUser = cloneDeep(user);

    newUser.companyId = value;

    Log.info(
      'Change form value of company',
      {
        from: user.companyId,
        to: newUser.companyId,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change company in create user wizard',
      Log.FEATURE.WIZARD,
    );

    setUser(newUser);
  };

  return (
    <Grid container direction="row" spacing={3} space={4}>
      <Grid item lg={12} xs={12}>
        <h3 className="main-text mt-0">Benutzer-Account Informationen</h3>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={6}>
            <TextField
              autoComplete="off"
              fullWidth
              id="email-input"
              label="E-Mail"
              name="email"
              onChange={handleInputChange}
              required
              type="text"
              value={user.email}
            />
          </Grid>
          <Grid item lg={4} xs={6}>
            <PasswordTextField
              fullWidth
              onChange={handleInputChange}
              required
              value={user.password}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={6}>
            <TextField
              autoComplete="off"
              fullWidth
              id="first_name-input"
              label="Vorname"
              name="first_name"
              onChange={handleInputChange}
              required
              type="text"
              value={user.firstName}
            />
          </Grid>
          <Grid item lg={4} xs={6}>
            <TextField
              autoComplete="off"
              fullWidth
              id="last_name-input"
              label="Nachname"
              name="last_name"
              onChange={handleInputChange}
              required
              type="text"
              value={user.lastName}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <h3 className="mt-20px main-text">
          Welcher Firma soll der Benutzer zugeordnet sein?
        </h3>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={6}>
            <SelectCompany
              onChange={handleChangeCompany}
              value={user.companyId}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <h3 className="mt-20px main-text">
          Welche Position hat der Benutzer in der Firma?
        </h3>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={6}>
            <TextField
              fullWidth
              id="position-input"
              label="Position"
              name="position"
              onChange={handleInputChange}
              type="text"
              value={user.position}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}, 'Daten konnten nicht geladen werden.');
