import React, { useState } from 'react';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import Log from '~/utils/Log';

export const PasswordTextField = ({ id, label, name, testId, ...props }) => {
  const [hidePassword, setHidePassword] = useState(true);

  const handlePasswordVisibilityToggle = () => {
    const eventName = hidePassword ? 'Display password' : 'Hide password';

    Log.productAnalyticsEvent(eventName, Log.FEATURE.USER);

    setHidePassword((prevState) => !prevState);
  };

  return (
    <TextField
      {...props}
      id={id ?? 'password-input'}
      name={name ?? 'password'}
      label={label ?? 'Passwort'}
      type={hidePassword ? 'password' : 'text'}
      inputProps={{
        'data-testid': testId,
        minLength: 8,
      }}
      autoComplete="new-password"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div className="mr-2">
              <IconButton
                onClick={handlePasswordVisibilityToggle}
                edge="end"
                size="large"
              >
                {hidePassword ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
};
