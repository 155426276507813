import { cn } from '~/utils/tailwind';

import LinkButton from '~/components/LinkButton';
import { Spinner } from '~/components/Spinner';

import { withErrorBoundary } from '~/ui/atoms';

import { Tile } from './Tile';

type P = {
  buttonCallback: () => void;
  buttonLink: string;
  buttonTitle: string;
  firstNumber: string | number;
  firstNumberTitle: string;
  icon: string;
  isLoading: boolean;
  secondNumber: string | number;
  secondNumberTitle: string;
  subheader: string;
  title: string;
} & ComponentStyling;

const NumberCard = ({
  className,
  isLoading,
  label,
  style,
  value,
  valueClassName,
}: Pick<
  P,
  'className' | 'isLoading' | 'label' | 'style' | 'value' | 'valueClassName'
>) => {
  return (
    <div
      className={cn(
        'flex h-32 w-36 flex-1 flex-col items-center justify-center rounded-md p-4 text-center font-medium',
        className,
      )}
      style={style}
    >
      <div
        className={cn(
          'flex h-6 flex-1 items-center justify-center text-5xl leading-6',
          valueClassName,
          {
            // Reduce font size for long numbers
            'text-3xl': value.toString().length > 6,
            'text-4xl': value.toString().length > 4,
          },
        )}
      >
        {isLoading ? <Spinner /> : value}
      </div>
      <div className="flex-shrink-0 font-normal leading-6 text-gray-500">
        {label}
      </div>
    </div>
  );
};

export const NumberTile = withErrorBoundary(
  ({
    buttonCallback,
    buttonLink,
    buttonTitle,
    className,
    firstNumber,
    firstNumberTitle,
    icon,
    isLoading,
    secondNumber,
    secondNumberTitle,
    style,
    subheader,
    title,
  }: P) => (
    <Tile
      title={title}
      icon={icon}
      buttonTitle={buttonTitle}
      buttonLink={buttonLink}
      className={className}
      style={style}
    >
      <div className="flex h-full flex-col items-center justify-between">
        <div className="h-0 whitespace-nowrap text-gray-400">{subheader}</div>
        <div className="flex w-full items-center justify-center gap-4">
          <NumberCard
            value={firstNumber}
            label={firstNumberTitle}
            isLoading={isLoading}
            className="bg-secondary1"
          />
          <NumberCard
            value={secondNumber}
            label={secondNumberTitle}
            isLoading={isLoading}
            className={secondNumber > 0 ? 'bg-errorFFD0D1' : 'bg-gray-100'}
            valueClassName={secondNumber > 0 ? 'text-gray-600' : ''}
          />
        </div>
        <LinkButton
          title={buttonTitle}
          link={buttonLink}
          callback={buttonCallback}
          className="h-10 w-full"
        />
      </div>
    </Tile>
  ),
  null,
);

NumberTile.displayName = 'NumberTile';
