import React from 'react';
import { Grid } from '@mui/material';

import Invoice from '~/models/invoices/Invoice';

import { dateUtils } from '~/utils/dateUtils';

import { withErrorBoundary } from '~/ui/atoms';

import { InvoiceMetaDataItem } from './InvoiceMetaDataItem';

function InvoiceMetaData({ invoice, toSite }) {
  const categoryValues = [];

  if (invoice?.direction === Invoice.DIRECTION.INCOMING) {
    categoryValues.push({
      category: Invoice.PROPERTY.SELLER.STRING,
      lg: 6,
      value: `${invoice?.seller?.name}\n${invoice?.seller?.address?.getConcatenatedAddress()}`,
      xs: 4,
    });
  }

  if (invoice?.direction === Invoice.DIRECTION.OUTGOING) {
    categoryValues.push({
      category: Invoice.PROPERTY.BUYER.STRING,
      lg: 6,
      value: `${invoice?.buyer?.name}\n${invoice?.buyer?.address?.getConcatenatedAddress()}`,
      xs: 4,
    });
  }

  if (invoice?.direction === Invoice.DIRECTION.NA) {
    categoryValues.push(
      {
        category: Invoice.PROPERTY.SELLER.STRING,
        lg: 3,
        value: `${invoice?.seller?.name}\n${invoice?.seller?.address?.getConcatenatedAddress()}`,
        xs: 2,
      },
      {
        category: Invoice.PROPERTY.BUYER.STRING,
        lg: 3,
        value: `${invoice?.buyer?.name}\n${invoice?.buyer?.address?.getConcatenatedAddress()}`,
        xs: 2,
      },
    );
  }

  categoryValues.push(
    {
      category: 'Lieferort',
      lg: 3,
      value: toSite ?? '-',
      xs: 3,
    },
    {
      category: 'Bestellnummer',
      lg: 2,
      value: '-',
      xs: 3,
    },
    {
      category: 'Datum',
      lg: 1,
      value:
        dateUtils.getFormattedDate_safe(
          invoice?.date,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ) ?? '-',
      xs: 2,
    },
  );

  return (
    <Grid container spacing={2}>
      {categoryValues.map((dataSet, index) => (
        <Grid key={index} item xs={dataSet.xs} lg={dataSet.lg}>
          <InvoiceMetaDataItem
            key={index}
            category={dataSet.category}
            value={dataSet.value}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default withErrorBoundary(
  InvoiceMetaData,
  'Rechnungsdaten konten nicht geladen werden.',
);
