import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { keepPreviousData } from '@tanstack/react-query';

import { useQueryCustomFields } from '~/data/customField';
import {
  fetchExportedReport,
  useMutationRequestDeliveryNoteExcel,
  useQueryDeliveryNoteReport,
} from '~/data/deliveryNote';

import {
  setDashboard_selectableColumns,
  setDashboard_selectedColumns,
} from '~/redux/filtersSlice';

import ExportService from '~/services/export.service';
import FeatureService from '~/services/feature.service';
import LocalStorageService from '~/services/localStorage.service';

import ArrayUtils from '~/utils/arrayUtils';
import { dateUtils, parseDate } from '~/utils/dateUtils';
import { getDeliveryNoteReportConfig } from '~/utils/filters';
import Log from '~/utils/Log';
import { precision } from '~/utils/number';
import UnitUtils from '~/utils/unitUtils';

import { BasicTable } from '~/components/BasicTable';
import ClientPortalMessage from '~/components/salesPackages/clientPortal/clientPortalMessage';
import { PackageBasicRestrictionMessage } from '~/components/salesPackages/packageBasicRestriction/MF_PackageBasicRestrictionMessage';

import { withErrorBoundary } from '~/ui/atoms';

import { DashboardReportChips } from '../DashboardReportChips';

import { SELECTABLE_COLUMNS } from './constants';
import { selectDashboardReportData } from './selectDashboardReportData';

const DEFAULT_DELIVERY_NOTE_REPORT_ITEMS = 100;
const DEFAULT_PAGE_SIZE = 100;

const selectSelectableColumns = (state) =>
  state.filters.dashboard_selectableColumns;
const selectSelectedColumns = (state) =>
  state.filters.dashboard_selectedColumns;
const selectSelectedUnit = (state) => state.filters.dashboard_selectedUnit;

type P = {
  searchBody: any;
};

export const DashboardReport = memo(
  withErrorBoundary(({ searchBody }: P) => {
    const dispatch = useDispatch();

    const selectableColumns = useSelector(selectSelectableColumns);
    const selectedColumns = useSelector(selectSelectedColumns);
    const selectedUnit = useSelector(selectSelectedUnit);

    const { mutateAsync: requestExport } =
      useMutationRequestDeliveryNoteExcel();

    const { data: customFields, isLoading: isLoadingCustomFields } =
      useQueryCustomFields();

    const reportConfig = getDeliveryNoteReportConfig(customFields ?? []);

    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
    });

    const { data: reportData, isLoading: isLoadingReportData } =
      useQueryDeliveryNoteReport(
        {
          ...searchBody,
          limit: paginationModel.pageSize,
          offset: paginationModel.page * paginationModel.pageSize,
          reportConfig,
          unitType: selectedUnit,
        },
        {
          enabled: Boolean(selectedUnit) && !isLoadingCustomFields,
          placeholderData: keepPreviousData,
          select: selectDashboardReportData,
        },
      );

    useEffect(() => {
      if (!customFields) {
        return;
      }

      const columns = cloneDeep(SELECTABLE_COLUMNS);

      for (const customField of customFields) {
        columns.push({
          disableReorder: true,
          field: customField.key,
          flex: 6,
          headerName: customField.displayName,
          resizableText: true,
          sortable: true,
        });
      }

      dispatch(setDashboard_selectableColumns(columns));
    }, [JSON.stringify(customFields)]);

    useEffect(() => {
      // Show results from start when search parameters change
      setPaginationModel((previousModel) => ({
        ...previousModel,
        page: 0,
      }));
    }, [JSON.stringify(searchBody)]);

    const handleExcelExportFromBackend = async () => {
      const searchParams = {
        ...searchBody,
        limit: DEFAULT_DELIVERY_NOTE_REPORT_ITEMS,
        offset: 0,
        reportConfig,
        unitType: selectedUnit,
      };

      const response = await requestExport(searchParams);

      const data = await fetchExportedReport(response.requestId);

      ExportService.exportExcelFromBlob(data);
    };

    const handleClick = useCallback(
      (clickedField) => {
        Log.productAnalyticsEvent('(De)select column', Log.FEATURE.REPORT);

        let newSelectedFields = [...selectedColumns];

        if (selectedColumns.includes(clickedField)) {
          newSelectedFields = ArrayUtils.remove(
            newSelectedFields,
            clickedField,
          );
        } else {
          newSelectedFields.push(clickedField);
        }

        dispatch(setDashboard_selectedColumns(newSelectedFields));
      },
      [selectedColumns, dispatch],
    );

    const handleReorderColumns = useCallback(
      (sourceIndex, destinationIndex) => {
        Log.productAnalyticsEvent('Reorder columns', Log.FEATURE.REPORT);

        let newSelectableColumns = cloneDeep(selectableColumns);
        newSelectableColumns = ArrayUtils.moveItem(
          newSelectableColumns,
          sourceIndex,
          destinationIndex,
        );
        dispatch(setDashboard_selectableColumns(newSelectableColumns));

        const sortedColumns = newSelectableColumns.map(
          (selectableColumn) => selectableColumn.headerName,
        );
        dispatch(
          setDashboard_selectedColumns(
            sortedColumns.filter((column) => selectedColumns.includes(column)),
          ),
        );
      },
      [selectableColumns, selectedColumns, dispatch],
    );

    const getSelectedColumns = useCallback(() => {
      const columns = cloneDeep(
        selectedColumns
          .map((selectedColumn) =>
            selectableColumns.find(
              ({ headerName }) => headerName === selectedColumn,
            ),
          )
          .filter(Boolean)
          .filter(({ field }) => field),
      );

      for (const selectedColumn of columns) {
        if (selectedColumn.field === 'dlnDate') {
          selectedColumn.valueGetter = parseDate;
          selectedColumn.renderCell = ({ value }) =>
            dateUtils.getFormattedDate_safe(
              value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY,
              dateUtils.DATE_FORMAT.YYYY_MM_DD,
            );
        }
      }

      return columns;
    }, [JSON.stringify(selectedColumns), JSON.stringify(selectableColumns)]);

    const getColumns = useCallback(() => {
      let maxPrecision = 0;

      if (reportData) {
        for (const row of reportData.data) {
          const p = precision(row.value);

          if (p > maxPrecision && p <= 2) {
            maxPrecision = p;
          }
        }
      }

      const selectedColumns = getSelectedColumns();

      selectedColumns.push(
        {
          disableReorder: true,
          field: 'amount',
          flex: 2,
          headerName: 'Menge',
          renderCell: ({ value }) =>
            UnitUtils.formatDeWithPrecision_safe(
              value,
              maxPrecision,
              maxPrecision,
            ),
          resizableText: true,
          sortable: true,
          type: 'number',
        },
        {
          disableReorder: true,
          field: 'unitType',
          flex: 1,
          headerName: 'Einheit',
          renderCell: ({ value }) => UnitUtils.getAbbreviatedUnit(value),
          resizableText: true,
          sortable: true,
        },
      );

      return selectedColumns;
    }, [reportData?.data, getSelectedColumns]);

    const handlePaginationModelChange = useCallback(
      (newModel) => {
        // Only update if it's a user-initiated change (not an internal DataGrid validation)
        if (
          newModel.page !== paginationModel.page ||
          newModel.pageSize !== paginationModel.pageSize
        ) {
          setPaginationModel(newModel);
        }
      },
      [paginationModel],
    );

    const noColumnIsSelected = useCallback(() => {
      const availableColumns = selectableColumns.map(
        ({ headerName }) => headerName,
      );

      return (
        ArrayUtils.getOverlappingValues(selectedColumns, availableColumns)
          .length === 0
      );
    }, [selectableColumns, selectedColumns]);

    if (FeatureService.clientPortal()) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <ClientPortalMessage />
        </div>
      );
    }

    if (FeatureService.packageBasicRestriction()) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <PackageBasicRestrictionMessage />
        </div>
      );
    }

    return (
      <div className="flex flex-1 flex-col">
        <div className="py-2">
          <DashboardReportChips
            selectedColumns={selectedColumns}
            selectableColumns={selectableColumns}
            onClick={handleClick}
            onReorderColumns={handleReorderColumns}
          />
        </div>
        {noColumnIsSelected() ? (
          <div className="pb-2">
            * Bitte wähle mindestens eine Spalte (Status, LFS-Datum, ...) aus,
            um die Berichte-Funktion zu verwenden.
          </div>
        ) : null}
        <div className="h-[600px]">
          <BasicTable
            rows={reportData?.data ?? []}
            columns={getColumns()}
            disableColumnReorder
            excelData={reportData?.data ?? []}
            excelColumns={getColumns()}
            onExcelExportFromBackend={handleExcelExportFromBackend}
            multiplePdfDownload={reportData?.data?.length > 1}
            loading={isLoadingReportData}
            localStorageKey={LocalStorageService.DASHBOARD_REPORT}
            productAnalyticsFeature={Log.FEATURE.REPORT}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            paginationMode="server"
            rowCount={reportData?.totalCount ?? 0}
            paginationMeta={{
              totalRowCount: reportData?.totalCount ?? 0,
            }}
            pagination
            disableRowSelectionOnClick
            noExportButton
          />
        </div>
      </div>
    );
  }, 'Berichts-Daten konnten nicht geladen werden.'),
);

DashboardReport.displayName = 'DashboardReport';
