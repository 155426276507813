import clsx from 'clsx';
import { type ReactNode, useEffect, useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';

import { ComponentTestIds } from '~/constants/test-ids';

import { ListItem } from './ListItem';

type P = {
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  itemHeight: number;
  items: any[];
  renderItem: (item: any) => ReactNode;
  totalCount: number;
} & ComponentStyling;

export const VirtualizedListServerDriven = ({
  className = 'h-96',
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  itemHeight = 48,
  items,
  renderItem,
  style,
  totalCount = 0,
}: P) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: totalCount,
    estimateSize: () => itemHeight,
    getScrollElement: () => parentRef.current,
    overscan: 5,
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= items.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    items.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems(),
  ]);

  return (
    <div
      ref={parentRef}
      className={clsx('overflow-y-auto', className)}
      style={style}
    >
      <ul
        className="relative snap-y list-none divide-y"
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        {rowVirtualizer.getVirtualItems().map(({ index, size, start }) => {
          const isLoadingRow = index > items.length - 1;

          return (
            <li
              key={index}
              className="absolute left-0 top-0 w-full border-solid border-gray-200 last:!border-b"
              style={{
                height: `${size}px`,
                transform: `translateY(${start}px)`,
              }}
              data-testid={
                ComponentTestIds.MULTI_SELECT.LIST_SERVER_DRIVEN_ITEM_ASSIGNABLE
              }
            >
              {isLoadingRow ? (
                <ListItem>
                  {hasNextPage
                    ? 'Lade Daten...'
                    : 'Alle verfügbaren Daten geladen'}
                </ListItem>
              ) : (
                renderItem(items[index])
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
