import DatagridUtils from '~/utils/datagridUtils';

const createFilters = (isEmptyFilterGroup, filterValues) => {
  const keys = [
    'selectedAcceptState',
    'selectedArticle',
    'selectedArticleNumber',
    'selectedCostCenter',
    'selectedCustomFields',
    'selectedFromSite',
    'selectedNumber',
    'selectedPermittedCostCenters',
    'selectedPermittedToSites',
    'selectedProcessState',
    'selectedRecipient',
    'selectedSettledStatus',
    'selectedSupplier',
    'selectedToSiteRecipient',
    'selectedToSiteSupplier',
  ];

  return keys.reduce((filters, key) => {
    filters[key] = isEmptyFilterGroup ? [] : filterValues[key];

    return filters;
  }, {});
};

/**
 * Returns a filter group object based on the provided parameters.
 *
 * @param {string} filterGroupId - The ID of the filter group.
 * @param {string} filterGroupName - The name of the filter group.
 * @param {Array} filterGroups - The list of filter groups.
 * @param {Array} [filterRows=[]] - The list of filter rows.
 * @param {Object} filterValues - The values of the filters.
 * @param {Object} calculatedFilterModel - The calculated filter model.
 * @param {boolean} isEmptyFilterGroup - Indicates if the filter group is empty.
 * @return {Object} The filter group object.
 */
export const getFilterGroupObject = (
  filterGroupId,
  filterGroupName,
  filterGroups,
  filterRows = [],
  filterValues,
  calculatedFilterModel,
  isEmptyFilterGroup,
) => {
  const filterGroup = filterGroups.find(({ id }) => id === filterGroupId);

  let newFilterRows = [...filterRows];

  /*
   * If no filter rows, but a filter group have been provided,
   * take the filter rows from the filter group.
   */
  if (newFilterRows.length === 0 && filterGroup) {
    newFilterRows = [...filterGroup.filterRows];
  }

  const filterGroupObject = {
    filterModel: isEmptyFilterGroup
      ? DatagridUtils.EMPTY_FILTER_MODEL
      : calculatedFilterModel,
    filterRows: newFilterRows,
    filters: createFilters(isEmptyFilterGroup, filterValues),
    id: filterGroupId,
    name: filterGroupName ?? filterGroup?.name, // filterGroups are not yet set when initializing DeliveryOverview.
  };

  return filterGroupObject;
};
