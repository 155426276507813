import {
  useState,
  useEffect,
  useCallback,
  memo,
  forwardRef,
  type ReactNode,
} from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useDebounce } from '@uidotdev/usehooks';

import { EndAdornmentClear } from './EndAdornmentClear';

import Log from '~/utils/Log';
import { cn } from '~/utils/tailwind';

type P = {
  autoFocus?: boolean;
  debounceTime?: number;
  disabled?: boolean;
  onChange: (value: string) => void;
  onFocus: () => void;
  placeholder: string;
  productAnalyticsFeature: string;
  startAdornment?: ReactNode;
  testId?: string;
  value: string;
} & ComponentStyling;

export const FreeTextSearch = memo(
  forwardRef<HTMLInputElement, P>(
    (
      {
        autoFocus,
        className,
        debounceTime = 600,
        disabled,
        onChange,
        onFocus,
        placeholder = 'Suchen ...',
        productAnalyticsFeature,
        startAdornment = (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        style,
        testId,
        value = '',
      }: P,
      forwardedRef,
    ) => {
      const [searchTerm, setSearchTerm] = useState(value);
      const debouncedSearchTerm = useDebounce(searchTerm, debounceTime);

      useEffect(() => {
        setSearchTerm(value);
      }, [value]);

      useEffect(() => {
        if (debouncedSearchTerm !== value) {
          Log.productAnalyticsEvent(
            'Update free text search',
            productAnalyticsFeature,
          );
          onChange(debouncedSearchTerm);
        }
      }, [debouncedSearchTerm, onChange, productAnalyticsFeature, value]);

      const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value;
          Log.info(
            'Change value of free text search',
            {
              from: searchTerm,
              to: newValue,
            },
            Log.BREADCRUMB.FILTER_CHANGE.KEY,
          );
          setSearchTerm(newValue);
        },
        [searchTerm],
      );

      const handleClear = useCallback(() => {
        Log.info(
          'Change value of free text search',
          {
            from: searchTerm,
            to: '',
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Remove free text search',
          productAnalyticsFeature,
        );
        setSearchTerm('');
      }, [searchTerm, productAnalyticsFeature]);

      const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Escape') {
            handleClear();
            event.stopPropagation();
          }
        },
        [handleClear],
      );

      const handleFocus = useCallback(() => {
        onFocus?.();
      }, [onFocus]);

      return (
        <TextField
          inputRef={forwardedRef}
          placeholder={placeholder}
          variant="outlined"
          value={searchTerm}
          onClick={handleFocus}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          size="small"
          className={cn('w-full bg-white pl-0', className)}
          disabled={disabled}
          autoFocus={autoFocus}
          inputProps={{
            'data-testid': testId,
          }}
          InputProps={{
            endAdornment: searchTerm ? (
              <EndAdornmentClear onClick={handleClear} />
            ) : null,
            startAdornment,
          }}
          style={style}
        />
      );
    },
  ),
);
