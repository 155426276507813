import { ReturnedGoodsIcon } from '~/assets/icons';

import Article from '~/models/articles/Article';
import ShippingMark from '~/models/deliveries/ShippingMark';
import ValueGroup from '~/models/deliveries/ValueGroup';

import ArrayUtils from '~/utils/arrayUtils';
import UnitUtils from '~/utils/unitUtils';

import { EditingHistorySafe as EditingHistory } from '~/components/deliveries/deliveryNote/EditingHistorySafe';
import { DeliveryNoteMetaDataItem } from '~/components/deliveries/deliveryNote/deliveryNoteMetaData/DeliveryNoteMetaDataItem';

import { withErrorBoundary } from '~/ui/atoms';

export const ArticleMetaData = withErrorBoundary(
  ({ article, deliveryDirection, deliveryNote }) => {
    const categoryValues = [];

    const formatValueUnitPair = (pair) => {
      return (
        UnitUtils.formatValueUnitPair_safe(
          pair.value,
          pair.unit,
          UnitUtils.getDescriptiveUnit,
        ) ?? '-'
      );
    };

    if (
      deliveryDirection === Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY
    ) {
      categoryValues.push({
        category: 'Richtung',
        value: (
          <div className="flex items-center">
            Retoure
            <ReturnedGoodsIcon className="ml-2 size-5" />
          </div>
        ),
      });
    }

    if (deliveryNote?.hasMultipleLogisticsPackages()) {
      categoryValues.push({
        category: 'Paket',
        value: <EditingHistory value={article?.logisticsPackage} />,
      });
    }

    categoryValues.push(
      {
        category: 'Position',
        value: <EditingHistory value={article?.position} />,
      },
      {
        category: 'Artikelnummer',
        value: <EditingHistory value={article?.number} />,
      },
    );

    if (
      article?.isConcrete() &&
      ValueGroup.getCurrentValue(article?.concrete?.concreteId)
    ) {
      categoryValues.push({
        category: 'Sortennummer',
        value: <EditingHistory value={article?.concrete?.concreteId} />,
      });
    }

    categoryValues.push({
      category: 'Artikel',
      value: <EditingHistory value={article?.type} />,
    });

    if (ValueGroup.getCurrentValue(article?.ean)) {
      categoryValues.push({
        category: 'EAN',
        value: <EditingHistory value={article?.ean} />,
      });
    }

    if (article?.displayAmount()) {
      categoryValues.push({
        category: 'Menge',
        value: (
          <EditingHistory
            value={ValueGroup.applyFunction(
              {
                unit: article?.amount?.unit,
                value: article?.amount?.value,
              },
              formatValueUnitPair,
            )}
          />
        ),
      });
    }

    if (article?.displayWeight()) {
      categoryValues.push({
        category: 'Gewicht',
        value: (
          <EditingHistory
            value={ValueGroup.applyFunction(
              {
                unit: article?.weight?.unit,
                value: article?.weight?.value,
              },
              formatValueUnitPair,
            )}
          />
        ),
      });
    }

    categoryValues.push({
      category: 'Kostenstelle',
      value: <EditingHistory value={article?.costCenter} />,
    });

    // Group shipping marks by code and sort values alphabetically
    const uniqueShippingMarkCodes = ArrayUtils.removeDuplicates(
      ValueGroup.getCurrentValue(article?.shippingMarks)?.map((shippingMark) =>
        ValueGroup.getCurrentValue(shippingMark.code),
      ) ?? [],
    );

    for (const shippingMarkCode of uniqueShippingMarkCodes) {
      let categoryHeader = ShippingMark.getShippingMarkString(shippingMarkCode);

      categoryHeader ||= ShippingMark.FALLBACK_STRING;

      const values = [];

      ValueGroup.getCurrentValue(article?.shippingMarks)?.forEach(
        (shippingMark) => {
          if (
            ValueGroup.getCurrentValue(shippingMark.code) !== shippingMarkCode
          ) {
            return;
          }

          values.push(ValueGroup.getCurrentValue(shippingMark.value));
        },
      );

      values.sort((a, b) => a.localeCompare(b));

      categoryValues.push({
        category: categoryHeader,
        value: (
          <EditingHistory
            value={ValueGroup.applyFunction(values, () => values.join(', '))}
          />
        ),
      });
    }

    if (ValueGroup.getCurrentValue(article?.constructionPlan)) {
      categoryValues.push({
        category: 'Plan',
        value: <EditingHistory value={article?.constructionPlan} />,
      });
    }

    if (ValueGroup.getCurrentValue(article?.constructionComponent)) {
      categoryValues.push({
        category: 'Bauteil',
        value: <EditingHistory value={article?.constructionComponent} />,
      });
    }

    return (
      <div className="flex flex-col gap-4">
        {categoryValues.map((dataSet, index) => (
          <DeliveryNoteMetaDataItem
            key={index}
            category={dataSet.category}
            value={dataSet.value}
          />
        ))}
      </div>
    );
  },
  'Artikeldaten konnten nicht geladen werden.',
);

ArticleMetaData.displayName = 'ArticleMetaData';
