import cloneDeep from 'lodash/cloneDeep';

import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';

/**
 * Updates the name of a filter group in the given filterGroups array.
 *
 * @param {string} filterGroupId - The ID of the filter group to update.
 * @param {string} filterGroupName - The new name for the filter group.
 * @param {array} filterGroups - The array of filter groups to update.
 * @param {function} setDelivery_filterGroups - Callback function to set the updated filter groups.
 * @return {void}
 */
export const updateFilterGroupName = async (
  dispatch,
  filterGroupId,
  filterGroupName,
  filterGroups,
  setDelivery_filterGroups,
) => {
  const newFilterGroups = cloneDeep(filterGroups);

  for (const filterGroup of newFilterGroups) {
    if (filterGroup.id === filterGroupId) {
      filterGroup.name = filterGroupName;
    }
  }

  const [, error] = await promiseHandler(
    UserService.updateDeliveryFilterGroups(newFilterGroups),
  );

  if (error) {
    Log.error('Failed to update filter group name.', error);
    ToastService.error(['Filter konnte nicht umbenannt werden.']);
    throw error; // Re-throw error to be caught by calling function
  }

  dispatch(setDelivery_filterGroups(newFilterGroups));
};
