import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { Bar } from 'react-chartjs-2';
import { useMemo } from 'react';

import DashboardService from '~/services/dashboard.service';
import FeatureService from '~/services/feature.service';

import { getOptimallyDistinctColor } from '~/utils/color';
import { endOfDay, startOfDay } from '~/utils/dateUtils';
import { cn } from '~/utils/tailwind';
import UnitUtils from '~/utils/unitUtils';

import ClientPortalMessage from '~/components/salesPackages/clientPortal/clientPortalMessage';
import { PackageBasicRestrictionMessage } from '~/components/salesPackages/packageBasicRestriction/MF_PackageBasicRestrictionMessage';
import { Spinner } from '~/components/Spinner';

import { withErrorBoundary } from '~/ui/atoms';

type AnalyticsData = {
  article: string;
  dates: string[];
  total: number;
  values: Array<number | undefined>;
};

type P = {
  data: AnalyticsData;
  isLoading: boolean;
  selectedDateRange: Date[];
  selectedUnit: string;
} & ComponentStyling;

export const TimeSeriesChart = withErrorBoundary(
  ({
    className,
    data,
    isLoading,
    selectedDateRange,
    selectedUnit,
    style,
  }: P) => {
    const aggregateBy =
      DashboardService.getAggregationFromDateRange(selectedDateRange);

    const getOptions = () => {
      return {
        maintainAspectRatio: false,
        maxBarThickness: 40,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label(context) {
                if (!(context.parsed.y > 0)) {
                  return;
                }

                const label = context.dataset.label;
                const formattedValue =
                  UnitUtils.roundAndFormatDe_safe(context.parsed.y) ?? '';
                return [label, formattedValue].join(': ');
              },
              title: formatTitle,
            },
            itemSort(contextA, contextB) {
              return contextB.parsed.y - contextA.parsed.y;
            },
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            stacked: true,
            ticks: {
              callback: formatXAxisLabel,
            },
            time: {
              unit: aggregateBy,
              unitStepSize: 1,
            },
            type: 'time',
          },
          yAxisID: {
            grid: {
              drawBorder: false,
            },
            ticks: {
              callback(value) {
                return (
                  UnitUtils.formatDe_safe(value) +
                  ' ' +
                  UnitUtils.getAbbreviatedUnitString(selectedUnit)
                );
              },
            },
          },
        },
      };
    };

    const getTimeFrameForDateRange = () => {
      const newOptions = getOptions();

      newOptions.scales.x.time.unit = aggregateBy;
      newOptions.scales.x.min = startOfDay(selectedDateRange[0]);
      newOptions.scales.x.max = endOfDay(selectedDateRange[1]);

      return newOptions;
    };

    const formatTitle = (title) => {
      const aggregation = Object.keys(DashboardService.DATA_AGGREGATION).find(
        (x) =>
          DashboardService.DATA_AGGREGATION[x].AGGREGATE_BY === aggregateBy,
      );
      if (aggregation) {
        return DashboardService.DATA_AGGREGATION[aggregation].TOOLTIP_TITLE(
          title[0].label,
        );
      }
    };

    const formatXAxisLabel = (label) => {
      const formatXAxisCallback = DashboardService.getXAxisFormatter(
        aggregateBy,
        DashboardService.DATA_AGGREGATION,
      );

      return formatXAxisCallback(label);
    };

    const chartData = useMemo(() => {
      if (!data) {
        return [];
      }

      const datasets = DashboardService.createBarChartDatasetsFromAnalyticsData(
        data,
        selectedDateRange,
      );

      const totalColors = datasets.length;

      for (const [index, dataset] of datasets.entries()) {
        dataset.backgroundColor = getOptimallyDistinctColor(index, totalColors);
      }

      return datasets;
    }, [data, selectedDateRange]);

    const clientPortalOverlay = FeatureService.clientPortal() ? (
      <div className="absolute flex h-full w-full items-center justify-center backdrop-blur-md">
        <div className="rounded-md bg-white p-4 shadow-lg">
          <ClientPortalMessage />
        </div>
      </div>
    ) : null;

    const packageBasicRestrictionOverlay =
      FeatureService.packageBasicRestriction() ? (
        <div className="absolute flex h-full w-full items-center justify-center backdrop-blur-md">
          <div className="rounded-md bg-white p-4 shadow-lg">
            <PackageBasicRestrictionMessage />
          </div>
        </div>
      ) : null;

    return (
      <div
        className={cn('relative h-[40vh] min-h-96', className)}
        style={style}
      >
        {isLoading && <Spinner className="absolute inset-0" />}
        {clientPortalOverlay}
        {packageBasicRestrictionOverlay}
        <Bar
          data={{
            datasets: chartData,
          }}
          options={getTimeFrameForDateRange()}
        />
      </div>
    );
  },
  'Zeitlicher Verlauf konnte nicht geladen werden.',
);

TimeSeriesChart.displayName = 'TimeSeriesChart';
