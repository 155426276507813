import React from 'react';
import { Grid } from '@mui/material';
import { withErrorBoundary } from '~/ui/atoms';
import { EditingHistorySafe as EditingHistory } from './EditingHistorySafe';
import ValueGroup from '~/models/deliveries/ValueGroup';
import Address from '~/models/masterdata/Address';
import DeliveryNoteModel from '~/models/deliveries/DeliveryNote';
import { dateUtils } from '~/utils/dateUtils';
import {
  type BuyerAndSeller,
  type DeliveryNoteType,
  type ValueWithHistory,
} from './types';

type DeliveryNoteContractPartiesProps = {
  deliveryNote: DeliveryNoteType;
  seller: BuyerAndSeller;
  buyer: BuyerAndSeller;
  buyerId: ValueWithHistory;
};

const DeliveryNoteContractParties = ({
  deliveryNote,
  seller,
  buyer,
  buyerId,
}: DeliveryNoteContractPartiesProps) => {
  const getEmailSubject = () =>
    `Lieferung ${ValueGroup.getCurrentValue(deliveryNote.number)} vom ${dateUtils.getFormattedDate_safe(
      ValueGroup.getCurrentValue(deliveryNote.dlnDate),
      dateUtils.DATE_FORMAT.DD_MM_YYYY,
      dateUtils.DATE_FORMAT.YYYY_MM_DD__HH_mm_ss_SSSSSS,
    )}`;

  const handlePhoneClick = (phone) => {
    if (phone) {
      window.location = `tel:${ValueGroup.getCurrentValue(phone)}`;
    }
  };

  const handleEmailClick = (email) => {
    if (email) {
      window.location = `mailto:${ValueGroup.getCurrentValue(email)}?subject=${getEmailSubject()}`;
    }
  };

  return (
    <>
      <Grid container spacing="20px" justifyContent="space-between">
        <Grid item xs={12} lg={6}>
          <div>{DeliveryNoteModel.CONTRACT_ROLE.SELLER.STRING}</div>
          <div className="bold">
            <EditingHistory value={seller?.name} />
          </div>
          <div className="bold">
            <EditingHistory
              value={ValueGroup.applyFunction(
                seller?.address,
                Address.getConcatenatedAddress,
              )}
            />
          </div>
        </Grid>
        <Grid item xs={8} lg={4}>
          <div>{DeliveryNoteModel.CONTRACT_ROLE.BUYER.STRING}</div>
          <div className="bold">
            <EditingHistory value={buyer?.name} />
          </div>
          <div className="bold">
            <EditingHistory
              value={ValueGroup.applyFunction(
                buyer?.address,
                Address.getConcatenatedAddress,
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4} lg={2}>
          <div>Kunden-Nr.</div>
          <div className="bold">
            <EditingHistory value={buyerId} />
          </div>
        </Grid>
      </Grid>
      {(seller?.universalCommunication?.hasCommunicationInformation() ||
        buyer?.universalCommunication?.hasCommunicationInformation()) && (
        <Grid container spacing="20px" className="mt-20px">
          <Grid item xs={12} lg={6}>
            <div>Kontaktdaten</div>
            {seller?.universalCommunication?.hasPhone() && (
              <div
                className="bold cursor-pointer"
                onClick={() => {
                  handlePhoneClick(seller.universalCommunication.phone);
                }}
              >
                <EditingHistory value={seller.universalCommunication.phone} />
              </div>
            )}
            {seller?.universalCommunication?.hasEmail() && (
              <div
                className="bold cursor-pointer"
                onClick={() => {
                  handleEmailClick(seller.universalCommunication.email);
                }}
              >
                <EditingHistory value={seller.universalCommunication.email} />
              </div>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <div>Kontaktdaten</div>
            {buyer?.universalCommunication?.hasPhone() && (
              <div
                className="bold cursor-pointer"
                onClick={() => {
                  handlePhoneClick(buyer.universalCommunication.phone);
                }}
              >
                <EditingHistory value={buyer.universalCommunication.phone} />
              </div>
            )}
            {buyer?.universalCommunication?.hasEmail() && (
              <div
                className="bold cursor-pointer"
                onClick={() => {
                  handleEmailClick(buyer.universalCommunication.email);
                }}
              >
                <EditingHistory value={buyer.universalCommunication.email} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withErrorBoundary(
  DeliveryNoteContractParties,
  'Daten der Lieferpartei konnten nicht geladen werden.',
);
