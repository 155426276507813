import { TAB } from '~/constants/Tab';

import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

/**
 * Handles the selection of a filter group.
 *
 * @param {string} filterGroupId - The ID of the selected filter group.
 * @param {Array} filterGroups - The array of filter groups.
 * @param {string} selectedFilterGroup - The ID of the currently selected filter group.
 * @param {function} setDelivery_selectedFilterGroup - The function to set the selected filter group.
 * @param {object} userFeatureFlags - Object containing user feature flags.
 * @param {function} handleChangeFilterGroup - The function to handle changing the filter group.
 */
export const handleSelectFilterGroup = (
  dispatch,
  filterGroupId,
  filterGroups,
  selectedFilterGroup,
  setDelivery_selectedFilterGroup,
  userFeatureFlags,
  handleChangeFilterGroup,
  tabOffset,
) => {
  if (filterGroupId === selectedFilterGroup) {
    return;
  }

  dispatch(setDelivery_selectedFilterGroup(filterGroupId));
  handleChangeFilterGroup(filterGroups.find(({ id }) => id === filterGroupId));

  const selectedTab = filterGroups.findIndex(({ id }) => id === filterGroupId);

  if (selectedTab === TAB.DELIVERY.LIST.INDEX) {
    Log.productAnalyticsEvent(
      'Open delivery list filter group',
      Log.FEATURE.FILTER_GROUPS,
    );
  }

  if (
    selectedTab === TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES.INDEX &&
    (userFeatureFlags.accessPermittedSites ||
      UserUtils.isPermittedSiteAllowedUser())
  ) {
    Log.productAnalyticsEvent(
      'Open unassigned deliveries filter group',
      Log.FEATURE.SITE_MAPPING,
    );
  }

  if (selectedTab >= tabOffset) {
    Log.productAnalyticsEvent(
      'Open custom delivery filter group',
      Log.FEATURE.FILTER_GROUPS,
    );
  }
};
