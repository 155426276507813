import cloneDeep from 'lodash/cloneDeep';
import qs from 'qs';

import Config from '~/Config';

import { LOADING_STATE } from '~/constants/LoadingState';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import {
  replaceDeliveryNoteTemplates,
  setDeliveryNoteTemplatesLoading,
  updateDeliveryNotes,
} from '~/redux/deliveryNotesSlice';
import store from '~/redux/store';

import Company from '~/models/masterdata/Company';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import DeliveryNoteValidationResult from '~/models/deliveries/DeliveryNoteValidationResult';

import axios from '~/utils/api-client';
import ArrayUtils from '~/utils/arrayUtils';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import FileReaderUtils from '~/utils/fileReaderUtils';
import Log from '~/utils/Log';
import ObjectUtils from '~/utils/objectUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import PromiseUtils from '~/utils/promiseUtils';

import CacheService from './cache.service';
import CostCenterService from './costCenter.service';
import CustomFieldService from './customField.service';
import SiteService from './site.service';
import ToastService from './toast.service';
import UserService from './user.service';

const apiUrl = Config.apiUrl;

class DeliveriesService {
  constructor() {
    this.DELIVERY_NOTES_PAGE_SIZE = 1000;

    this.dlnCursor = null;
    this.dlnErrorCount = 0;
    // this.loadedDlnPages = 0;
    // To not overwrite the dlns when the user changes the sites or cost centers and thus the dlns have to be reloaded.
    this.dlnRequestId = 0;

    this.cachedChainsByDlns = [];

    // This variable is needed as a workaround to fix a certain bug.
    // Bug: When passing a dln directly via history.push to the delivery note form, an error was thrown.
    // Workaround bug fix: Write the dln to this variable and pass a flag (passDeliveryNote) to the delivery note form.
    // In the form, read the dln from this variable (this.deliveryNoteForCreationForm).
    this.deliveryNoteForCreationForm = null;

    this.DLN_PAGINATION_SIZE = 1000;
  }

  // search for dln in store. if not found, load from backend
  async getDeliveryNoteById(dlnId, ignoreCache) {
    if (!ignoreCache) {
      const deliveryNote = store
        .getState()
        .deliveryNotes?.deliveryNotes?.find(({ id }) => id === dlnId);
      if (deliveryNote) {
        return ES6ClassFactory.convertToES6Class(
          [deliveryNote],
          new DeliveryNote(),
        )[0];
      }
    }

    const [response, error] = await promiseHandler(
      this.getDeliveryNote(dlnId, ignoreCache),
    );

    if (error) {
      throw error;
    }

    const deliveryNote = new DeliveryNote(response);

    if (ignoreCache) {
      store.dispatch(updateDeliveryNotes([deliveryNote]));
    }

    return deliveryNote;
  }

  async getDeliveryNote(dlnId, ignoreCache) {
    const url = apiUrl + '/asset/delivery_note/' + dlnId;

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url)
      .then(({ data, status }) => {
        if (status !== 200) {
          Log.warn(
            'GET /delivery_note did not return 200',
            { status_code: status },
            Log.BREADCRUMB.HTTP_NOT_200.KEY,
          );
        }

        CacheService.setCached(url, data);

        return data;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  }

  async getDeliveryNotesByIds(dlnIds, ignoreCache) {
    const deliveryNotes = [];

    if (!ignoreCache) {
      const deliveryNotesFromStore = store
        .getState()
        .deliveryNotes?.deliveryNotes?.filter((dln) => dlnIds.includes(dln.id));
      deliveryNotes.push(
        ...ES6ClassFactory.convertToES6Class(
          deliveryNotesFromStore,
          new DeliveryNote(),
        ),
      );
    }

    const missingDlnIds = ArrayUtils.subtract(
      dlnIds,
      deliveryNotes.map(({ id }) => id),
    );

    if (missingDlnIds.length > 0) {
      const [response, error] = await promiseHandler(
        this.getDeliveryNotes(missingDlnIds),
      );

      if (error) {
        throw error;
      }

      deliveryNotes.push(
        ...response.assets.map((dln) => new DeliveryNote(dln)),
      );
    }

    if (ignoreCache) {
      store.dispatch(updateDeliveryNotes(deliveryNotes));
    }

    return deliveryNotes;
  }

  async getDeliveryNotes(ids) {
    return axios
      .get(apiUrl + '/asset/delivery_note/ids', {
        params: { ids },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat' }),
      })
      .then((response) => {
        if (response.status !== 200) {
          Log.warn(
            'GET /delivery_note/ids did not return 200',
            { status_code: response.status },
            Log.BREADCRUMB.HTTP_NOT_200.KEY,
          );
        }

        return response.data;
      });
  }

  async getDeliveryNoteChain(chainId) {
    const cachedChain = this.cachedChainsByDlns.find(
      (chain) => chain._id === chainId,
    );
    if (cachedChain) {
      return cloneDeep(cachedChain);
    }

    const url = apiUrl + '/asset/delivery_note/chain/' + chainId;

    const [cachedValue, error] = CacheService.getCached(url);
    if (cachedValue) {
      return cachedValue;
    }

    if (error) {
      throw error;
    }

    return axios
      .get(url)
      .then(({ response }) => {
        if (response?.status !== 200) {
          Log.warn('GET /delivery_note/chain did not return 200', {
            status: response?.status,
          });
        }

        CacheService.setCached(url, response?.data);
        return response?.data;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  }

  async getDeliveryNoteChainsByDlnIds(dlnIds) {
    const cachedResponse = [];
    const uncachedDlnIds = [];

    for (const dlnId of dlnIds) {
      const cached = this.cachedChainsByDlns.filter(
        ({ asset_id }) => asset_id === dlnId,
      );

      if (cached.length > 0) {
        cachedResponse.push(...cached);
      } else {
        uncachedDlnIds.push(dlnId);
      }
    }

    if (uncachedDlnIds.length === 0) {
      return cachedResponse;
    }

    const query = uncachedDlnIds.map((id) => 'ids=' + id).join('&');

    return axios
      .get(apiUrl + '/asset/delivery_note/ids/chains?' + query)
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn('GET /delivery_note/ids/chains did not return 200', {
            status: response?.status,
          });
        }

        this.cachedChainsByDlns.push(...response?.data?.assets);

        return cloneDeep([...cachedResponse, ...response?.data?.assets]);
      });
  }

  async createDeliveryNote(body) {
    return axios.post(apiUrl + '/asset/manual', body);
  }

  async createDeliveryNoteVestigasFormat(body) {
    return axios.post(apiUrl + '/asset/vestigas_dln', body);
  }

  async createDeliveryNoteVestigasFormat_enhanced(body) {
    Log.info(
      'Upload delivery note as JSON',
      body,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );

    const [response, error] = await promiseHandler(
      this.createDeliveryNoteVestigasFormat(body),
    );

    if (error) {
      Log.productAnalyticsEvent(
        'Failed to upload invalid JSON',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      Log.error('Failed to upload JSON.', error);
      ToastService.httpError(
        ['Lieferung konnte nicht hochgeladen werden.'],
        error.response,
        null,
        true,
      );
      throw error;
    }

    ToastService.success(['Lieferung erstellt.']);
  }

  uploadJson = async (file, createDeliveryNoteCallback) => {
    Log.productAnalyticsEvent('Upload JSON', Log.FEATURE.CREATE_DELIVERY_NOTE);

    const [string, error] = await promiseHandler(
      FileReaderUtils.readFileAsTextAsync(file),
    );

    if (error) {
      Log.error('Error reading JSON file.', error);
      Log.productAnalyticsEvent(
        'Failed to upload JSON',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      ToastService.error(['Lieferung konnte nicht hochgeladen werden.']);
    }

    try {
      createDeliveryNoteCallback(JSON.parse(string));
    } catch (error) {
      Log.error('Error reading JSON file.', error);
      Log.productAnalyticsEvent(
        'Failed to upload JSON',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      ToastService.error(['Lieferung konnte nicht hochgeladen werden.']);
    }

    const fileInput = document.querySelector('#input-json-upload');
    if (fileInput) {
      fileInput.value = '';
    } else {
      console.error('fileInput with ID input-json-upload not found.');
    }
  };

  async validateDeliveryNoteVestigasFormat(body) {
    return axios.post(apiUrl + '/asset/validate', body).then((response) => {
      return response.data.detail.map(
        (item) => new DeliveryNoteValidationResult(item),
      );
    });
  }

  async getAllTemplates() {
    return axios
      .get(apiUrl + '/dln_template')
      .then(({ data }) => data.delivery_notes);
  }

  async createTemplate(name, company_id, body) {
    return axios.post(apiUrl + '/dln_template', body, {
      params: { company_id, name },
    });
  }

  async updateTemplate(name, template_id, body) {
    return axios.put(apiUrl + '/dln_template', body, {
      params: { name, template_id },
    });
  }

  async deleteTemplate(template_id) {
    return axios.delete(apiUrl + '/dln_template/delete', {
      params: { template_id },
    });
  }

  async getAllArticleTemplates(company_id) {
    return axios
      .get(apiUrl + '/article_master/all', { params: { company_id } })
      .then(({ data }) => data.articles);
  }

  async updateArticleTemplate(company_id, body) {
    return axios.put(apiUrl + '/article_master', body, {
      params: { company_id },
    });
  }

  async getAllowedCompaniesForDeliveryNoteCreation(company_type) {
    return axios
      .get(apiUrl + '/company/allowed_companies', { params: { company_type } })
      .then(({ data }) => {
        const initCompany = (company) => new Company(company);

        return data.companies.map(initCompany);
      });
  }

  async getBulkAllowedCompaniesForDeliveryNoteCreation() {
    return axios
      .get(apiUrl + '/company/bulk_allowed_companies')
      .then((response) => {
        if (!response.data.allowed_companies) {
          return {
            buyers: [],
            carriers: [],
            recipients: [],
            sellers: [],
            suppliers: [],
          };
        }

        const { buyer, carrier, recipient, seller, supplier } =
          response.data.allowed_companies;
        const initCompany = (company) => new Company(company);

        return {
          buyers: buyer?.companies?.map(initCompany) ?? [],
          carriers: carrier?.companies?.map(initCompany) ?? [],
          recipients: recipient?.companies?.map(initCompany) ?? [],
          sellers: seller?.companies?.map(initCompany) ?? [],
          suppliers: supplier?.companies?.map(initCompany) ?? [],
        };
      });
  }

  // get the correct CSS class for the corresponding status
  switchClassName(parameter) {
    const status = Object.keys(DeliveryNote.PROCESS_STATE).find(
      (x) => DeliveryNote.PROCESS_STATE[x].STRING === parameter,
    );

    if (!status) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid process state: ' + parameter),
      );
      return DeliveryNote.PROCESS_STATE.DEFAULT.CLASS;
    }

    return DeliveryNote.PROCESS_STATE[status].CLASS;
  }

  /**
   * Initializes delivery notes based on the provided assetMains.
   *
   * @param {Array} assetMains - The array of assetMains to initialize delivery notes from.
   * @returns {Array} An array of initialized delivery notes.
   */
  async initDlns(assetMains) {
    const deliveryNotes = [];

    for (const assetMain of assetMains) {
      try {
        deliveryNotes.push(new DeliveryNote(assetMain));
      } catch (error) {
        Log.error(
          'Failed to initialize delivery note. id: ' + assetMain?._id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to initialize delivery note',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
      }
    }

    let siteIds = [];
    let costCenterIds = [];
    let userIds = [];
    let customFieldIds = [];

    for (const deliveryNote of deliveryNotes) {
      siteIds.push(deliveryNote.toSiteRecipient.id);
      siteIds.push(...deliveryNote.permittedToSites.map((site) => site.id));
      costCenterIds.push(
        ...deliveryNote.permittedCostCenters.map((costCenter) => costCenter.id),
      );
      userIds.push(
        ...ObjectUtils.entries(deliveryNote.processStateChangeUser).map(
          (entry) => entry.value.id,
        ),
      );
      customFieldIds.push(...deliveryNote.getCustomFieldIds());
    }

    // Need to filter out 10003 manually because in dev - polier-dev there is a corrupt dln with this site id.
    siteIds = ArrayUtils.removeDuplicates(siteIds).filter(
      (siteId) => siteId && siteId !== '10003',
    );
    costCenterIds = ArrayUtils.removeDuplicates(costCenterIds).filter(Boolean);
    userIds = ArrayUtils.removeDuplicates(userIds).filter(Boolean);
    customFieldIds =
      ArrayUtils.removeDuplicates(customFieldIds).filter(Boolean);

    const sitesBulkPromise =
      siteIds.length && SiteService.loadSitesBulk(siteIds);
    const costCentersBulkPromise =
      costCenterIds.length &&
      CostCenterService.loadCostCentersBulk(costCenterIds);
    const usersBulkPromise =
      userIds.length && UserService.loadUsersBulk(userIds);
    const customFieldsBulkPromise =
      customFieldIds.length &&
      CustomFieldService.loadCustomFieldsBulk(customFieldIds);

    const [, error] = await promiseHandler(
      PromiseUtils.allResolved(
        [
          sitesBulkPromise,
          costCentersBulkPromise,
          usersBulkPromise,
          customFieldsBulkPromise,
        ].filter(Boolean), // Remove requests without payload
      ),
    );

    if (error) {
      Log.error(
        'Failed to load sites, cost centers, users or custom fields for delivery notes.',
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load sites, cost centers, users or custom fields for delivery notes',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      throw error;
    }

    for (const [index, deliveryNote] of deliveryNotes.entries()) {
      try {
        const [, error_] = await promiseHandler(
          deliveryNote.asyncInitWithBulkEntities(),
        );

        if (error_) {
          Log.error(
            'Failed to initialize async data of delivery note. id: ' +
              assetMains[index]?._id,
            error_,
          );
          Log.productAnalyticsEvent(
            'Failed to initialize enhanced delivery note data',
            Log.FEATURE.DELIVERY_NOTE,
            Log.TYPE.ERROR,
          );
        }

        deliveryNote.initUserActions();
      } catch (error) {
        Log.error(
          'Failed to initialize delivery note. id: ' + assetMains[index]?._id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to initialize delivery note',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
      }
    }

    return deliveryNotes;
  }

  getDeliveryNoteForCreationForm() {
    return this.deliveryNoteForCreationForm;
  }

  setDeliveryNoteForCreationForm(deliveryNote) {
    this.deliveryNoteForCreationForm = cloneDeep(deliveryNote);
  }

  refreshTemplates = async () => {
    store.dispatch(setDeliveryNoteTemplatesLoading(LOADING_STATE.LOADING));

    const [templates, error] = await promiseHandler(this.getAllTemplates());

    if (error) {
      store.dispatch(setDeliveryNoteTemplatesLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load delivery note templates.', error);
      Log.productAnalyticsEvent(
        'Failed to load templates',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceDeliveryNoteTemplates(templates));
  };

  async shareDeliveryNote(deliveryNoteId, share_user_id) {
    return axios.post(apiUrl + '/asset/' + deliveryNoteId + '/share', null, {
      params: { share_user_id },
    });
  }

  async mapDirectDeliveryNotes(
    deliveryNoteId,
    siteIds,
    costCenterIds,
    replaceSites,
    replaceCostCenters,
  ) {
    return axios.put(apiUrl + '/asset/' + deliveryNoteId + '/direct_mapping', {
      acc_refs: costCenterIds,
      replace_after_signing: false,
      replace_cc_fields: replaceCostCenters,
      replace_pr_fields: replaceSites,
      sites: siteIds, // Keep sites and cost centers for already signed dlns.
    });
  }

  async removeDeliveryNoteMapping(deliveryNoteId) {
    return axios.put(apiUrl + '/asset/' + deliveryNoteId + '/direct_mapping', {
      acc_refs: [],
      replace_after_signing: false,
      replace_cc_fields: true,
      replace_pr_fields: true,
      sites: [], // Keep sites and cost centers for already signed dlns.
    });
  }

  getUnassignedDeliveryRows(deliveryRows) {
    return deliveryRows.filter(
      ({ permittedCostCenters, permittedToSites, toSiteRecipient }) =>
        !toSiteRecipient && !permittedToSites && !permittedCostCenters,
    );
  }
}

export default new DeliveriesService();
