import React from 'react';

import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';

import { Select } from '~/components/baseComponents/inputs/select/Select';
import DataSubscriptionFilterClauseModel from '~/models/dataSubscriptions/DataSubscriptionFilterClause';
import cloneDeep from 'lodash/cloneDeep';
import DataSubscription from '~/models/dataSubscriptions/DataSubscription';

class DataSubscriptionFilterClause extends React.Component {
  handleChangeFilterKeyword = (event) => {
    const newFilterClause = cloneDeep(this.props.filterClause);

    newFilterClause.filterKeyword = event.target.value;

    this.props.onChange(newFilterClause);
  };
  handleChangeValue = (event) => {
    const newFilterClause = cloneDeep(this.props.filterClause);

    newFilterClause.value = event.target.value;

    this.props.onChange(newFilterClause);
  };
  handleChangeFilterUnion = (event) => {
    this.props.onChangeFilterUnion(event.target.value);
  };
  getFilterKeywordOptions = () => {
    return Object.values(DataSubscriptionFilterClauseModel.FILTER_KEYWORD).map(
      (value) => {
        return {
          id: value.KEY,
          name: value.STRING,
        };
      },
    );
  };
  getFilterUnionOptions = () => {
    return Object.values(DataSubscription.FILTER_UNION).map((value) => {
      return {
        id: value.KEY,
        name: value.STRING,
      };
    });
  };

  render() {
    return (
      <div className="flex-sb-c gap-16px">
        <Select
          value={this.props.filterClause.filterKeyword}
          fullWidth
          onChange={this.handleChangeFilterKeyword}
          size="small"
          options={this.getFilterKeywordOptions()}
        />
        ist
        <TextField
          id="value-input"
          name="value"
          placeholder={
            'Bitte ' +
            DataSubscriptionFilterClauseModel.getFilterKeywordString(
              this.props.filterClause.filterKeyword,
            ) +
            ' eingeben'
          }
          type="text"
          fullWidth
          value={this.props.filterClause.value}
          onChange={this.handleChangeValue}
          autoComplete="off"
        />
        <div className="w-220px">
          {!this.props.isLastFilterClause && (
            <Select
              value={this.props.filterUnion}
              fullWidth
              onChange={this.handleChangeFilterUnion}
              size="small"
              options={this.getFilterUnionOptions()}
            />
          )}
        </div>
        <IconButton size="small" onClick={this.props.onDelete}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    );
  }
}

export default DataSubscriptionFilterClause;
