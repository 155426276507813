import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  TextField,
} from '@mui/material';

import { connect } from 'react-redux';
import CustomerSubscription from '~/models/masterdata/CustomerSubscription';
import { promiseHandler } from '~/utils/promiseHandler';
import CustomerSubscriptionService from '~/services/customerSubscription.service';
import Log from '~/utils/Log';
import BasicForm from '~/components/BasicForm';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';
import cloneDeep from 'lodash/cloneDeep';
import ToastService from '~/services/toast.service';
import { LightTooltipWide } from '~/utils/componentUtils';
import FunctionUtils from '~/utils/functionUtils';
import { PasswordTextField } from '~/components/baseComponents/inputs/PasswordTextField';

const mapDispatchToProps = () => ({});

class CustomerSubscriptionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerSubscription: new CustomerSubscription(),
      submittingForm: false,
    };
  }

  componentDidMount() {
    this.resetForm();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.resetForm();
    }
  }

  resetForm() {
    this.setState({
      customerSubscription:
        this.props.customerSubscription ?? new CustomerSubscription(),
    });
  }

  formSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      submittingForm: true,
    });

    const body = this.getBody();

    Log.info(
      'Submit customer subscription form',
      body,
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.SUBSCRIPTION);

    if (this.renderForCreate()) {
      const [response, error] = await promiseHandler(
        CustomerSubscriptionService.createCustomerSubscription(body),
      );

      if (error) {
        ToastService.httpError(
          [ToastService.MESSAGE.CUSTOMER_SUBSCRIPTION_CREATION_FAILED],
          error.response,
        );
        Log.error('Failed to create customer subscription', error);
        Log.productAnalyticsEvent(
          'Failed to create',
          Log.FEATURE.SUBSCRIPTION,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    } else {
      const [response, error] = await promiseHandler(
        CustomerSubscriptionService.updateCustomerSubscription(
          this.state.customerSubscription?.id,
          body,
        ),
      );

      if (error) {
        ToastService.httpError(
          [ToastService.MESSAGE.CUSTOMER_SUBSCRIPTION_UPDATE_FAILED],
          error.response,
        );
        Log.error(
          'Failed to update customer subscription. id: ' +
            this.state.customerSubscription?.id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.SUBSCRIPTION,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    }

    this.setState({
      submittingForm: true,
    });

    this.props.closeForm();
    CustomerSubscriptionService.refreshCustomerSubscriptions();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.SUBSCRIPTION);
    this.props.closeForm();
    this.resetForm();
  };
  handleInputChange = (event) => {
    const newCustomerSubscription = cloneDeep(this.state.customerSubscription);

    switch (event.target.name) {
      case 'name': {
        newCustomerSubscription.name = event.target.value;
        Log.info(
          'Change form value of name',
          {
            from: this.state.customerSubscription.name,
            to: newCustomerSubscription.name,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'subscription_change_name',
          Log.productAnalyticsEvent,
          ['Change name', Log.FEATURE.SUBSCRIPTION],
        );
        break;
      }

      case 'key_auth_header': {
        newCustomerSubscription.keyAuthHeader = event.target.value;
        Log.info(
          'Change form value of keyAuthHeader',
          {
            from: this.state.customerSubscription.keyAuthHeader,
            to: newCustomerSubscription.keyAuthHeader,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'subscription_change_key_auth_header',
          Log.productAnalyticsEvent,
          ['Change keyAuthHeader', Log.FEATURE.SUBSCRIPTION],
        );
        break;
      }

      case 'key_auth_value': {
        newCustomerSubscription.keyAuthValue = event.target.value;
        Log.info(
          'Change form value of keyAuthValue',
          {
            from: this.state.customerSubscription.keyAuthValue,
            to: newCustomerSubscription.keyAuthValue,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'subscription_change_key_auth_value',
          Log.productAnalyticsEvent,
          ['Change keyAuthValue', Log.FEATURE.SUBSCRIPTION],
        );
        break;
      }

      case 'url': {
        newCustomerSubscription.callbackUrl = event.target.value;
        Log.info(
          'Change form value of callbackUrl',
          {
            from: this.state.customerSubscription.callbackUrl,
            to: newCustomerSubscription.callbackUrl,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'subscription_change_callback_url',
          Log.productAnalyticsEvent,
          ['Change callback url', Log.FEATURE.SUBSCRIPTION],
        );
        break;
      }

      case 'port': {
        newCustomerSubscription.callbackPort =
          event.target.value < 0 ? 0 : event.target.value;
        Log.info(
          'Change form value of callbackPort',
          {
            from: this.state.customerSubscription.callbackPort,
            to: newCustomerSubscription.callbackPort,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'subscription_change_callback_port',
          Log.productAnalyticsEvent,
          ['Change callback port', Log.FEATURE.SUBSCRIPTION],
        );
        break;
      }
    }

    this.setState({
      customerSubscription: newCustomerSubscription,
    });
  };
  handleCheckboxChange = (event) => {
    const newCustomerSubscription = cloneDeep(this.state.customerSubscription);

    switch (event.target.name) {
      case 'is_active': {
        newCustomerSubscription.isActive = event.target.checked;
        Log.info(
          'Change form value of active',
          {
            from: this.state.customerSubscription.isActive,
            to: newCustomerSubscription.isActive,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change active checkbox',
          Log.FEATURE.SUBSCRIPTION,
        );
        break;
      }

      case 'include_dln': {
        newCustomerSubscription.includeDln = event.target.checked;
        Log.info(
          'Change form value of includeDln',
          {
            from: this.state.customerSubscription.includeDln,
            to: newCustomerSubscription.includeDln,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change includeDln checkbox',
          Log.FEATURE.SUBSCRIPTION,
        );
        break;
      }
    }

    this.setState({
      customerSubscription: newCustomerSubscription,
    });
  };
  handleEventsChange = (events) => {
    const newCustomerSubscription = cloneDeep(this.state.customerSubscription);

    newCustomerSubscription.events = events.map((event) => event.id);

    Log.info(
      'Change form value of events',
      {
        from: this.state.customerSubscription.events,
        to: newCustomerSubscription.events,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change events', Log.FEATURE.SUBSCRIPTION);

    this.setState({
      customerSubscription: newCustomerSubscription,
    });
  };
  renderForCreate = () => {
    return this.props.type === 'create';
  };

  getEvents() {
    return Object.keys(CustomerSubscription.EVENT).map((key) => {
      return {
        id: CustomerSubscription.EVENT[key]?.KEY,
        name: CustomerSubscription.EVENT[key]?.STRING,
      };
    });
  }

  testCustomerSubscription = () => {
    Log.productAnalyticsEvent('Test connection', Log.FEATURE.SUBSCRIPTION);

    CustomerSubscriptionService.testCustomerSubscription(this.getBody())
      .then((response) => {
        ToastService.success([
          'Verbindung konnte erfolgreich aufgebaut werden.',
        ]);
      })
      .catch((error) => {
        ToastService.warning(['Verbindung konnte nicht aufgebaut werden.']);
        Log.productAnalyticsEvent(
          'Failed to connect',
          Log.FEATURE.SUBSCRIPTION,
          Log.TYPE.ERROR,
        );
      });
  };

  getBody() {
    return {
      auth_type: this.state.customerSubscription?.authType,
      callback_port:
        this.state.customerSubscription?.callbackPort === ''
          ? null
          : this.state.customerSubscription?.callbackPort,
      callback_url: this.state.customerSubscription?.callbackUrl,
      include_dln_data: this.state.customerSubscription?.includeDln,
      is_active: this.state.customerSubscription?.isActive,
      key_auth_header: this.state.customerSubscription?.keyAuthHeader,
      key_auth_value: this.state.customerSubscription?.keyAuthValue,
      name: this.state.customerSubscription?.name,
      subscription_events: this.state.customerSubscription?.events,
      subscription_type: this.state.customerSubscription?.type,
    };
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        title={
          'Lieferungen-Update ' +
          (this.renderForCreate()
            ? 'Erstellen'
            : this.props.customerSubscription.name)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        id={this.props.customerSubscription?.id}
      >
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text mt-0">Lieferungen-Update</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="name-input"
                  name="name"
                  label="Name"
                  type="text"
                  required
                  fullWidth
                  value={this.state.customerSubscription?.name}
                  onChange={this.handleInputChange}
                  autoFocus
                  autoComplete="off"
                />
              </Grid>
              {this.renderForCreate() ? null : (
                <Grid item xs={6} lg={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.customerSubscription?.isActive}
                        onChange={this.handleCheckboxChange}
                        name="is_active"
                      />
                    }
                    label="Aktiv"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Verbindung</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="key_auth_header-input"
                  name="key_auth_header"
                  label="Authorization Header"
                  type="text"
                  required
                  fullWidth
                  value={this.state.customerSubscription.keyAuthHeader}
                  onChange={this.handleInputChange}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <PasswordTextField
                  id="key_auth_value-input"
                  name="key_auth_value"
                  label="Authorization Passwort"
                  fullWidth
                  required={this.renderForCreate()}
                  value={this.state.customerSubscription.keyAuthValue}
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="url-input"
                  name="url"
                  label="URL"
                  type="text"
                  required
                  fullWidth
                  value={this.state.customerSubscription.callbackUrl}
                  onChange={this.handleInputChange}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="port-input"
                  name="port"
                  label="Port"
                  type="number"
                  fullWidth
                  value={this.state.customerSubscription.callbackPort}
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              className="mt-20px"
              onClick={this.testCustomerSubscription}
            >
              Verbindung testen
            </Button>
          </Grid>
          <Grid item xs={12} lg={8}>
            <h3 className="mt-20px main-text">Benachrichtigungen</h3>
            <GenericMultiPicker
              textfieldLabel="Wenn eine Lieferung in den Status wechselt"
              pickedItems={this.state.customerSubscription?.events?.map(
                (event) => {
                  return {
                    id: event,
                    name: CustomerSubscription.getEventString(event),
                  };
                },
              )}
              allItems={this.getEvents()}
              onSaveSelection={this.handleEventsChange}
              fieldName="name"
            />
            <LightTooltipWide title="Wenn diese Option ausgewählt ist, erhältst du zu einer Benachrichtigung alle Lieferdaten (u.a. Lieferant, Lieferort, Artikel, etc.).">
              <FormControlLabel
                className="pt-20px"
                label="Gesamte Lieferdaten"
                control={
                  <Checkbox
                    checked={this.state.customerSubscription?.includeDln}
                    onChange={this.handleCheckboxChange}
                    name="include_dln"
                  />
                }
              />
            </LightTooltipWide>
          </Grid>
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(null, mapDispatchToProps())(CustomerSubscriptionForm);
