export const selectDashboardReportData = (data) => ({
  ...data,
  data: data.data.map(
    (
      {
        amount,
        articleName,
        billedState,
        confirmedAccountingReferenceName,
        confirmedSiteName,
        dlnAcceptState,
        dlnDate,
        dlnNr,
        processState,
        recipientName,
        supplierAssignedSiteName,
        supplierName,
        unitType,
      },
      index,
    ) => ({
      acceptState: dlnAcceptState,
      amount,
      articleName,
      billedState,
      confirmedAccountingReferenceName,
      dlnDate,
      dlnNr,
      id: index,
      processState,
      recipientName,
      supplierName,
      toSiteRecipientName: confirmedSiteName,
      toSiteSupplierName: supplierAssignedSiteName,
      unitType,
    }),
  ),
});
