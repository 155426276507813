import React from 'react';
import { AddBoxOutlined as AddBoxOutlinedIcon } from '@mui/icons-material';
import { Grid } from '@mui/material';

import Article from '~/models/articles/Article';
import ValueGroup from '~/models/deliveries/ValueGroup';

import { LightTooltipWide } from '~/utils/componentUtils';
import UnitUtils from '~/utils/unitUtils';

import { EditingHistorySafe as EditingHistory } from '~/components/deliveries/deliveryNote/EditingHistorySafe';

import { withErrorBoundary } from '~/ui/atoms';

const formatValueUnitPair = (pair) =>
  UnitUtils.formatValueUnitPair_safe(
    pair.value,
    pair.unit,
    UnitUtils.getAbbreviatedUnit,
  ) ?? '-';

export const ArticleMaterial = withErrorBoundary(
  ({ initialComponent, material }) => {
    return (
      <Grid container spacing={4} justifyContent="space-between">
        <Grid item xs={12} lg={6}>
          <div className="flex">
            {ValueGroup.getCurrentValue(initialComponent) ? null : (
              <LightTooltipWide title="Nachträglich zum Betongemisch hinzugefügter Inhaltsstoff">
                <AddBoxOutlinedIcon fontSize="small" className="mr-1" />
              </LightTooltipWide>
            )}
            <div>
              {Article.getMaterialName(
                ValueGroup.getCurrentValue(material?.type),
              )}
            </div>
          </div>
          <div className="font-bold">
            <EditingHistory value={material?.name} />
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div>Menge</div>
          <div className="font-bold">
            <EditingHistory
              value={ValueGroup.applyFunction(
                {
                  unit: material?.quantity?.unit,
                  value: material?.quantity?.value,
                },
                formatValueUnitPair,
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div>
            {ValueGroup.getCurrentValue(initialComponent) ? (
              <>
                <div>Angefragte Menge</div>
                <div className="font-bold">
                  <EditingHistory
                    value={ValueGroup.applyFunction(
                      {
                        unit: material?.volumeRequested?.unit,
                        value: material?.volumeRequested?.value,
                      },
                      formatValueUnitPair,
                    )}
                  />
                </div>
              </>
            ) : (
              <>
                <div>Verbleibende Menge</div>
                <div className="font-bold">
                  <EditingHistory
                    value={ValueGroup.applyFunction(
                      {
                        unit: material?.remainingConcrete?.unit,
                        value: material?.remainingConcrete?.value,
                      },
                      formatValueUnitPair,
                    )}
                  />
                </div>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div>Hersteller</div>
          <div className="font-bold">
            <EditingHistory value={material?.manufacturer?.name} />
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div>Kommentar</div>
          <div className="font-bold">
            <EditingHistory value={material?.comment} />
          </div>
        </Grid>
        {ValueGroup.getCurrentValue(initialComponent) ? null : (
          <>
            <Grid item xs={12} lg={6}>
              <div>Grund</div>
              <div className="font-bold">
                <EditingHistory value={material?.reason} />
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div>Ergebnis</div>
              <div className="font-bold">
                <EditingHistory value={material?.result} />
              </div>
            </Grid>
          </>
        )}
      </Grid>
    );
  },
  'Inhaltsstoff konnte nicht geladen werden.',
);

ArticleMaterial.displayName = 'ArticleMaterial';
