export default class Attachment {
  constructor(blob, chainId, type) {
    this.digest = blob?.digest ?? '';
    this.contentType = blob?.contentType ?? blob?.content_type ?? '';
    this.chainId = chainId ?? '';
    this.type = type ?? '';
  }

  isDeliveryPicture() {
    return this.type === Attachment.TYPE.DELIVERY_PICTURE;
  }

  static TYPE = {
    DELIVERY: 'delivery', // for accept articles
    DELIVERY_PICTURE: 'delivery_picture', // for "kein berechtigter vor Ort" feature
    SIGNATURE: 'signature', //
  };
  static CONTENT_TYPE = {
    JPEG: 'image/jpeg',
    JSON: 'application/json',
    PDF: 'application/octet-stream',
    PNG: 'image/png',
    SVG: 'image/svg+xml',
  };
}
