import { FormHelperText } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';

import BasicForm from '~/components/BasicForm';
import { Spinner } from '~/components/Spinner';

import { MultiSelectUsers } from '~/ui/molecules/SelectServerDriven';

import { type UUID } from '~/types/common';

import { DeliveryNoteActionMetaData } from '../DeliveryNoteActionMetaData';

import { useShareDeliveryNoteForm } from './useShareDeliveryNoteForm';

type P = {
  closeForm: () => void;
  deliveryNoteIds: UUID[];
  open: boolean;
};

const ShareDeliveryNoteFormComponent = ({
  closeForm,
  deliveryNoteIds,
  open,
}: P) => {
  const {
    handleCancel,
    handleChange,
    handleSubmit,
    isAuthorized,
    isLoadingSharedUsers,
    isSubmitting,
    selectedUserIds,
    sharedUsers,
  } = useShareDeliveryNoteForm({ closeForm, deliveryNoteIds });

  if (!isAuthorized) {
    return null;
  }

  return (
    <BasicForm
      open={open}
      formSuccess={handleSubmit}
      formAbort={handleCancel}
      title="Lieferung mit Benutzern teilen"
      submitButtonTitle="Teilen"
      submittingForm={isSubmitting}
    >
      <div className="min-h-40 w-[600px]">
        {isLoadingSharedUsers ? (
          <Spinner title={'Lade Nutzerdaten...'} className="min-h-40" />
        ) : (
          <>
            <FormHelperText className="mb-1">
              Wähle die Nutzer aus, mit denen diese Lieferung geteilt werden
              soll
            </FormHelperText>
            <MultiSelectUsers value={selectedUserIds} onChange={handleChange} />
            {sharedUsers.length > 0 ? (
              <DeliveryNoteActionMetaData
                className="mt-4"
                title="Bereits geteilt mit:"
                users={sharedUsers}
              />
            ) : null}
          </>
        )}
      </div>
    </BasicForm>
  );
};

export const ShareDeliveryNoteForm = withErrorBoundary(
  ShareDeliveryNoteFormComponent,
);
