import {
  getGridStringOperators,
  getGridNumericOperators,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridSingleSelectOperators,
} from '@mui/x-data-grid-pro';

import { getFilterContext } from '~/utils/filters';

import DeliveryNote from '../deliveries/DeliveryNote';

import { FILTER_OPERATOR_FRONTEND } from './constants';
import FilterNew from './FilterNew';
import FilterProps from './FilterProps';

export default class MuiDataGridFilter {
  /**
   * Get all filter operators for a specific data type in MUI Data Grid that can be used in MUI Data Grid when data is loaded from the backend.
   */
  static getApplicableMuiDataGridOperatorsForMuiDataGridDataType(
    muiDataGridDataType,
  ) {
    const muiDataGridDefaultOperators =
      MuiDataGridFilter.getMuiDataGridDefaultOperators(muiDataGridDataType);
    const applicableOperators =
      MuiDataGridFilter.getApplicableMuiDataGridOperators();

    return muiDataGridDefaultOperators.filter(({ value }) =>
      applicableOperators.includes(value),
    );
  }

  /**
   * Get all default filter operators for a specific data type in MUI Data Grid.
   *
   * @param {any} muiDataGridDataType - The MUI Data Grid data type to get default operators for.
   * @return {any} The default operators for the specified MUI Data Grid data type.
   */
  static getMuiDataGridDefaultOperators(muiDataGridDataType) {
    return MuiDataGridFilter.DEFAULT_OPERATOR_MAPPING.find(
      ({ DATA_TYPE }) => DATA_TYPE === muiDataGridDataType,
    ).DEFAULT_OPERATORS;
  }

  /**
   * Get all MUI Data Grid operators that can be used in MUI Data Grid when data is loaded from the backend.
   *
   * @return {array} The list of applicable MUI Data Grid operators.
   */
  static getApplicableMuiDataGridOperators() {
    return FilterProps.OPERATOR_STRING_MAPPING.filter(({ BACKEND_OPERATOR }) =>
      Boolean(BACKEND_OPERATOR),
    ).map(({ MUI_DATA_GRID_OPERATOR }) => MUI_DATA_GRID_OPERATOR);
  }

  static getParsedMuiDataGridFilters(filterModel) {
    return filterModel.items
      .map((muiDataGridFilterModel) =>
        MuiDataGridFilter.getParsedMuiDataGridFilter(
          muiDataGridFilterModel,
          filterModel.booleanOperator,
        ),
      )
      .filter((filter) => !FilterNew.filterContainsEmptyValue(filter));
  }

  static getParsedMuiDataGridFilter(muiDataGridFilter, booleanOperator) {
    // Due to legacy filters, there might still be a filter on the delivery list actions.
    if (muiDataGridFilter.field === 'deliveryListActions') {
      return new FilterNew();
    }

    const filterContext = getFilterContext({
      component: 'filterModel',
      page: 'delivery',
    });

    let field = muiDataGridFilter.field;
    const operator = FilterProps.getOperatorFromMuiDataGridOperator(
      muiDataGridFilter.operator,
    );
    const value = muiDataGridFilter.value;

    // This is a workaround so that article filter from the mui data grid filter model also works on the archive mode.
    // Though, it should be refactored that this mapping is not necessary anymore.
    // When refactoring, it must be ensured that all article filters access the same prop.
    // -> dashboard filter group, delivery filter group, delivery filter model, etc.
    if (field === DeliveryNote.PROPERTY.MAIN_ARTICLE_TYPE.KEY) {
      field = DeliveryNote.PROPERTY.ARTICLE.KEY;
    }

    return new FilterNew({
      booleanOperator,
      field,
      filterContext,
      operator,
      value,
    });
  }

  /**
   * Data types in MUI Data Grid.
   * Applicable filters and operators depend on the data type.
   */
  static DATA_TYPE = {
    ACTIONS: 'actions',
    BOOLEAN: 'boolean',
    DATE: 'date',
    DATE_TIME: 'dateTime',
    NUMBER: 'number',
    SINGLE_SELECT: 'singleSelect',
    STRING: 'string',
  };
  /**
   * Filter operators supported by MUI Data Grid.
   */
  static OPERATOR = FILTER_OPERATOR_FRONTEND;
  /**
   * Default operators for each data type in MUI Data Grid.
   */
  // TODO: this structure requires a lot of unnecessary code to work with it.
  // Better: simple object with keys typed with TS.
  // {
  //   actions: null,
  //   boolean: getGridBooleanOperators(),
  //   date: getGridDateOperators(),
  //   dateTime: getGridDateOperators(true),
  //   number: getGridNumericOperators(),
  //   singleSelect: getGridSingleSelectOperators(),
  //   string: getGridStringOperators(),
  // }
  static DEFAULT_OPERATOR_MAPPING = [
    {
      DATA_TYPE: MuiDataGridFilter.DATA_TYPE.STRING,
      DEFAULT_OPERATORS: getGridStringOperators(),
    },
    {
      DATA_TYPE: MuiDataGridFilter.DATA_TYPE.NUMBER,
      DEFAULT_OPERATORS: getGridNumericOperators(),
    },
    {
      DATA_TYPE: MuiDataGridFilter.DATA_TYPE.DATE,
      DEFAULT_OPERATORS: getGridDateOperators(),
    },
    {
      DATA_TYPE: MuiDataGridFilter.DATA_TYPE.DATE_TIME,
      DEFAULT_OPERATORS: getGridDateOperators(true),
    },
    {
      DATA_TYPE: MuiDataGridFilter.DATA_TYPE.BOOLEAN,
      DEFAULT_OPERATORS: getGridBooleanOperators(),
    },
    {
      DATA_TYPE: MuiDataGridFilter.DATA_TYPE.SINGLE_SELECT,
      DEFAULT_OPERATORS: getGridSingleSelectOperators(),
    },
    {
      DATA_TYPE: MuiDataGridFilter.DATA_TYPE.ACTIONS,
      DEFAULT_OPERATORS: null,
    },
  ];
}
