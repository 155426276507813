import React from 'react';

import { Grid } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import { connect } from 'react-redux';
import { InvoiceFreeTextFilter } from './InvoiceFreeTextFilter';
import {
  setInvoice_dateRange,
  setInvoice_predefinedDateRange,
  setInvoice_individualDateRange,
} from '~/redux/filtersSlice';
import DateRangeSelect from '../../baseComponents/inputs/date/DateRangeSelect';
import { dateUtils } from '~/utils/dateUtils';
import Log from '~/utils/Log';
import ClientPortalTooltip from '../../salesPackages/clientPortal/ClientPortalTooltip';
import FeatureService from '~/services/feature.service';

const mapStateToProps = (state) => ({
  dateRange: state.filters.invoice_dateRange,
  individualDateRange: state.filters.invoice_individualDateRange,
  predefinedDateRange: state.filters.invoice_predefinedDateRange,
  selectedStatus: state.filters.invoice_selectedStatus,
});
const mapDispatchToProps = () => ({
  setInvoice_dateRange,
  setInvoice_individualDateRange,
  setInvoice_predefinedDateRange,
});

type InvoiceFilterBarProps = {
  dateRange: Array<number | Date>;
  individualDateRange: boolean;
  predefinedDateRange: string;
  selectedStatus: string[];
  setInvoice_dateRange: (dateRange: {
    dateRange: Array<number | Date>;
    updateCookie: boolean;
  }) => void;
  setInvoice_individualDateRange: (individualDateRange: boolean) => void;
  setInvoice_predefinedDateRange: (predefinedDateRange: string) => void;
};

const InvoiceFilterBar = (props: InvoiceFilterBarProps) => {
  const handleDateRangeChange = (value: Date[]) => {
    const numberOfDays = dateUtils.getNumberOfDays(value[0], value[1]);
    if (numberOfDays >= 0 && numberOfDays <= 3650) {
      Log.info(
        'Change filter value of selected date range',
        { from: props.dateRange, to: value },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Filter date range', Log.FEATURE.INVOICE_LIST);

      props.setInvoice_dateRange({ dateRange: value, updateCookie: true });
      props.setInvoice_individualDateRange(true);
    }
  };

  const handlePredefinedDateRangeChange = (value: string) => {
    Log.info(
      'Change filter value of selected predefined date range',
      { from: props.predefinedDateRange, to: value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Filter predefined date range: ' + value,
      Log.FEATURE.INVOICE_LIST,
    );

    props.setInvoice_predefinedDateRange(value);
    props.setInvoice_dateRange({
      dateRange: dateUtils.getTimeframeFromDateRange(value),
      updateCookie: true,
    });
    props.setInvoice_individualDateRange(false);
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      className="gap-20px"
    >
      <Grid item>
        <ClientPortalTooltip>
          <DateRangeSelect
            predefinedDateRange={props.predefinedDateRange}
            individualDateRange={props.individualDateRange}
            onPredefinedDateRangeChange={handlePredefinedDateRangeChange}
            dateRange={props.dateRange}
            onDateRangeChange={handleDateRangeChange}
            displayLabel
            disabled={FeatureService.clientPortal()}
          />
        </ClientPortalTooltip>
      </Grid>
      <InvoiceFreeTextFilter />
    </Grid>
  );
};

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(InvoiceFilterBar),
  null,
);
