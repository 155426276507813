import { useDispatch } from 'react-redux';

import Company from '~/models/masterdata/Company';
import UserFeatureFlags from '~/models/masterdata/UserFeatureFlags';

import { FEATURE_FLAG } from '~/constants/FeatureFlag';

import { saveCompanyAccount } from '~/redux/companyAccountSlice';
import {
  setDashboard_filterGroups,
  setDelivery_filterGroups,
  setInvoice_filterGroups,
} from '~/redux/filtersSlice';
import {
  saveDeliveryTabs,
  saveUserActions,
  saveUserCompany,
  saveUserFeatureFlags,
  saveUserPermissions,
  saveUserType,
  updateUserInfos,
} from '~/redux/userinfoSlice';

import LocalStorageService from '~/services/localStorage.service';
import { camelcaseKeysFromApi } from '~/services/kyClient';
import ThirdPartyService from '~/services/thirdParty.service';

export const useSaveLoadedUserData = () => {
  const dispatch = useDispatch();

  /**
   * Saves the loaded user data to the Redux store and initializes third-party services.
   *
   * This function processes the response from the user data API and dispatches actions to update
   * various parts of the Redux store, including company account details, user permissions,
   * user info, feature flags, and webapp settings.
   * It also handles the initialization of third-party services like Sentry
   * and product analytics tools.
   *
   * The function performs the following main tasks:
   * 1. Saves company account information
   * 2. Handles Sentry and Google Analytics feature flags
   * 3. Saves user permissions and info
   * 4. Saves user type and feature flags
   * 5. Saves various webapp settings (article color mapping, delivery tabs, filter groups)
   * 6. Initializes third-party services
   * 7. Loads delivery notes and templates (if applicable)
   *
   * @param {Object} response - The response object from the user data API
   * @param {Object} response.company_account - Company account details
   * @param {boolean} response.is_vestigas_support - Indicates if the user is Vestigas support
   * @param {Array} response.total_permissions - User permissions
   * @param {Object} response.settings - User settings including feature flags and webapp settings
   * @param {Object} response.company - Company details
   */
  async function saveLoadedUserData(response) {
    const responseInCamelCase = camelcaseKeysFromApi(response);

    const featureFlags =
      responseInCamelCase?.companyAccount?.data?.featureFlags;
    const id = responseInCamelCase?.companyAccount?.id;
    const isVestigasSupport = responseInCamelCase?.isVestigasSupport;
    const name = responseInCamelCase?.companyAccount?.name;
    const deliveryListTableConfig = responseInCamelCase?.settings?.deliveryList;

    if (deliveryListTableConfig) {
      // Save the delivery list table config in localStorage.
      LocalStorageService.setObjectAsLocalStorage(
        LocalStorageService.DELIVERY_LIST,
        deliveryListTableConfig,
      );
    }

    dispatch(
      saveCompanyAccount({
        featureFlags,
        id,
        name,
      }),
    );

    /**
     * Store the feature flag sentry_google_analytics_disabled locally and reload the page on change.
     * Storing locally is needed so that on next page load, the webapp already knows
     * if Google Analytics is allowed even before the user data has been loaded from the backend.
     */
    if (
      featureFlags?.[FEATURE_FLAG.SENTRY_GOOGLE_ANALYTICS_DISABLED] &&
      !LocalStorageService.sentryGoogleAnalyticsDisabled()
    ) {
      LocalStorageService.disableSentryGoogleAnalytics();
      window.location.reload();
    }

    if (
      !featureFlags?.[FEATURE_FLAG.SENTRY_GOOGLE_ANALYTICS_DISABLED] &&
      LocalStorageService.sentryGoogleAnalyticsDisabled()
    ) {
      LocalStorageService.enableSentryGoogleAnalytics();
      window.location.reload();
    }

    dispatch(saveUserPermissions(response?.total_permissions ?? []));

    dispatch(
      updateUserInfos({
        firstname: response?.first_name,
        isVestigasSupport,
        lastname: response?.last_name,
        phone: response?.settings?.contact_information,
        position: response?.settings?.position,
      }),
    );

    dispatch(saveUserType(response?.settings?.user_type));

    dispatch(
      saveUserFeatureFlags(
        new UserFeatureFlags(response?.settings?.feature_flags),
      ),
    );

    dispatch(saveDeliveryTabs(response?.settings?.webapp_delivery_tabs ?? []));

    dispatch(
      setDashboard_filterGroups(
        response?.settings?.webapp_dashboard_filter_groups ?? [],
      ),
    );
    if (response?.settings?.webapp_delivery_filter_groups) {
      dispatch(
        setDelivery_filterGroups(
          response?.settings?.webapp_delivery_filter_groups ?? [],
        ),
      );
    }

    dispatch(
      setInvoice_filterGroups(
        response?.settings?.webapp_invoice_filter_groups ?? [],
      ),
    );

    dispatch(saveUserActions(response?.settings?.webapp_user_actions ?? {}));

    const company = new Company(response?.company);
    dispatch(saveUserCompany(company));

    ThirdPartyService.initSentryCompany(company.name);
    ThirdPartyService.initProductAnalyticsTools(
      featureFlags?.[FEATURE_FLAG.PRODUCT_ANALYTICS_TOOLS],
      company.name,
      isVestigasSupport,
    );
  }

  return {
    saveLoadedUserData,
  };
};
