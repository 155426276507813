import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import { Button, MenuItem, Select } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';

import { PieChartOutlinedIcon } from '~/assets/icons';

import { ROUTE } from '~/constants/Route';

import {
  getSearchBody,
  useQueryDeliveryNoteAnalytics,
  type DeliveryNoteAnalyticsValue,
} from '~/data/deliveryNote';

import {
  setDashboard_individualDateRange,
  setDashboard_selectedDateRange,
  setDashboard_selectedUnit,
  setDashboard_selectedPredefinedDateRange,
} from '~/redux/filtersSlice';

import DashboardService from '~/services/dashboard.service';

import FilterNew from '~/models/filters/FilterNew';

import Log from '~/utils/Log';

import {
  assignColorsToChartData,
  ChartLegend,
  DoughnutChart,
  getLegendData,
} from '~/modules/dashboard';

import { Spinner } from '~/components/Spinner';

import { withErrorBoundary } from '~/ui/atoms';

import { Tile } from './Tile';

const selectCostCenters = ({ filters }) => filters.selectedCostCenters;
const selectSites = ({ filters }) => filters.selectedSites;
const selectPredefinedDateRange = ({ filters }) =>
  filters.home_selectedPredefinedDateRange;
const selectDateRange = ({ filters }) => filters.home_selectedDateRange;
const selectIndividualDateRange = ({ filters }) =>
  filters.home_individualDateRange;

export const selectPieChartData = (data: DeliveryNoteAnalyticsValue[]) => {
  const chartData =
    DashboardService.createPieChartDatasetsFromAnalyticsData(data);

  const coloredChartData = assignColorsToChartData(chartData);
  const legendData = getLegendData(coloredChartData);

  coloredChartData.legendData = legendData;

  return coloredChartData;
};

export const ChartTile = withErrorBoundary(
  ({ className, style }: ComponentStyling) => {
    const history = useHistory();

    const dispatch = useDispatch();

    const selectedSites = useSelector(selectSites);
    const selectedCostCenters = useSelector(selectCostCenters);
    const selectedPredefinedDateRange = useSelector(selectPredefinedDateRange);
    const selectedDateRange = useSelector(selectDateRange);
    const individualDateRangeIsSelected = useSelector(
      selectIndividualDateRange,
    );

    const selectableUnits =
      DashboardService.getMajorSelectableUnitsForArchiveMode();

    const [selectedUnit, setSelectedUnit] = useState(selectableUnits[0].id);

    const analyticsSearchBody = getSearchBody({
      filterGroups: {
        dateRange: selectedDateRange,
        selectedCostCenters,
        selectedSites,
      },
    });

    const timeAggregate =
      DashboardService.getAggregationFromDateRange(selectedDateRange);

    const {
      data: deliveryNoteAnalyticsData,
      isError,
      isFetching,
    } = useQueryDeliveryNoteAnalytics(
      {
        ...analyticsSearchBody,
        timeAggregate,
        unitType: selectedUnit,
      },
      {
        enabled: Boolean(selectedUnit) && Boolean(timeAggregate),
        placeholderData: keepPreviousData,
        select: selectPieChartData,
      },
    );

    const handleUnitChange = (event) => {
      Log.info(
        'Change filter value of unit',
        {
          from: selectedUnit,
          to: event.target.value,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Filter unit', Log.FEATURE.HOME);

      setSelectedUnit(event.target.value);
    };

    const openDashboard = () => {
      Log.productAnalyticsEvent('Open dashboard', Log.FEATURE.HOME);

      dispatch(setDashboard_selectedUnit(selectedUnit));
      dispatch(
        setDashboard_selectedPredefinedDateRange(selectedPredefinedDateRange),
      );
      dispatch(setDashboard_selectedDateRange(selectedDateRange));
      dispatch(setDashboard_individualDateRange(individualDateRangeIsSelected));

      FilterNew.removeNonApplicableBackendFiltersForDashboardPage();

      history.push({
        pathname: ROUTE.DASHBOARD.ROUTE,
      });
    };

    const hasAnalyticsData =
      deliveryNoteAnalyticsData?.datasets?.[0]?.data?.length > 0;

    return (
      <Tile
        title="Artikel"
        icon={<PieChartOutlinedIcon className="icon-medium" />}
        width={2}
        isError={isError}
        className={className}
        style={style}
      >
        <div className="flex h-full items-stretch justify-between gap-4">
          <div className="flex h-full flex-1 flex-col items-start justify-center overflow-hidden">
            <ChartLegend
              data={deliveryNoteAnalyticsData?.legendData}
              selectedUnit={selectedUnit ?? ''}
            />
          </div>
          <div className="relative flex w-60 flex-none items-center justify-center">
            {hasAnalyticsData || isFetching ? (
              <div className="relative h-full w-full">
                {isFetching && (
                  <div className="absolute inset-0 z-10 flex items-center justify-center">
                    <Spinner />
                  </div>
                )}
                {hasAnalyticsData && (
                  <DoughnutChart chartData={deliveryNoteAnalyticsData} />
                )}
              </div>
            ) : null}
            {!hasAnalyticsData && !isFetching && (
              <p className="w-full text-center text-gray-400">
                Keine Lieferungen gefunden für diese Kombination von Einheit und
                Zeitraum.
              </p>
            )}
          </div>
          <div className="flex flex-1 flex-col items-end justify-between">
            <Select
              value={selectedUnit ?? ''}
              onChange={handleUnitChange}
              size="small"
              className="-mt-10 w-40"
            >
              {selectableUnits.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <Button
              className="primary-button w-full"
              onClick={openDashboard}
              endIcon={<KeyboardArrowRightIcon />}
            >
              Zu den Statistiken
            </Button>
          </div>
        </div>
      </Tile>
    );
  },
  null,
);

ChartTile.displayName = 'ChartTile';
