import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Divider } from '@mui/material';

import { ROUTE } from '~/constants/Route';
import { TAB } from '~/constants/Tab';

import SiteService from '~/services/site.service';

import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

import { withErrorBoundary } from '~/ui/atoms';

import { DeliveryFilterBar } from '../DeliveryFilterBar';
import { DeliveryFilterGroups } from '../DeliveryFilterGroups';
import { DeliveryList } from '../DeliveryList';

import { DeliveryNoteActions } from './DeliveryNoteActions';
import { mapStateToProps } from './mapStateToProps';
import { mapDispatchToProps } from './mapDispatchToProps';
import { getDefaultDateRange, handleFilterChange } from './utils';

const DeliveryOverviewComponent = (props) => {
  const {
    deliveryNotes,
    filterModel,
    history,
    selectedTab,
    setDelivery_dateRange,
    setPageTitle,
    userFeatureFlags,
    userinfo,
  } = props;

  useEffect(() => {
    setDefaultDateRange(deliveryNotes.deliveryNotes);
    setPageTitle('Alle Lieferungen');
    document.title = 'VESTIGAS - Alle Lieferungen';
  }, []);

  useEffect(() => {
    setDefaultDateRange(deliveryNotes.deliveryNotes);
  }, [deliveryNotes.deliveryNotesVersion]);

  const setDefaultDateRange = (deliveryNotes) => {
    const dateRange = getDefaultDateRange(deliveryNotes);
    if (dateRange) {
      setDelivery_dateRange(dateRange);
    }
  };

  const handleFilterChangeRefactorMe = (type, customField, filterValue) => {
    handleFilterChange(type, customField, filterValue, props);
  };

  const getCalculatedFilterModel = () => {
    const isUnassignedDLNsTab =
      selectedTab === TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES.INDEX;

    const isPermittedSiteAllowedUser =
      userFeatureFlags.accessPermittedSites ||
      UserUtils.isPermittedSiteAllowedUser();

    const nextFilterModel =
      isUnassignedDLNsTab && isPermittedSiteAllowedUser
        ? SiteService.getUnassignedDeliveriesFilters()
        : filterModel;

    return nextFilterModel;
  };

  const handleClickCreateDln = () => {
    Log.productAnalyticsEvent('Open form', Log.FEATURE.CREATE_DELIVERY_NOTE);
    history.push({
      pathname: ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
    });
  };

  const handleClickTemplate = (templateId) => {
    Log.productAnalyticsEvent(
      'Import template',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );

    const template = deliveryNotes.deliveryNoteTemplates.find(
      ({ id }) => id === templateId,
    );

    if (template) {
      return history.push({
        pathname: ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
        state: {
          template,
        },
      });
    }

    Log.error(`Failed to find template by id. id: ${templateId}`);
    history.push({
      pathname: ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
    });
  };

  const handleClickCreateServiceNote = () => {
    Log.productAnalyticsEvent('Open form', Log.FEATURE.CREATE_DELIVERY_NOTE);
    history.push({
      pathname: ROUTE.CREATE_SERVICE_NOTE.ROUTE,
    });
  };

  const handleClickServiceNoteTemplate = (templateId) => {
    Log.productAnalyticsEvent(
      'Import template',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
    );

    const template = deliveryNotes.deliveryNoteTemplates.find(
      ({ id }) => id === templateId,
    );

    if (template) {
      return history.push({
        pathname: ROUTE.CREATE_SERVICE_NOTE.ROUTE,
        state: {
          template,
        },
      });
    }

    Log.error(`Failed to find template by id. id: ${templateId}`);
    history.push({
      pathname: ROUTE.CREATE_SERVICE_NOTE.ROUTE,
    });
  };

  return (
    <div className="main-padding flex h-full flex-col">
      <DeliveryFilterGroups
        onChangeValue={handleFilterChangeRefactorMe}
        calculatedFilterModel={getCalculatedFilterModel()}
      />
      <Divider sx={{ my: 2 }} />
      <div className="flex w-full items-center justify-between">
        <DeliveryFilterBar />
        <DeliveryNoteActions
          handleClickCreateDln={handleClickCreateDln}
          handleClickCreateServiceNote={handleClickCreateServiceNote}
          handleClickTemplate={handleClickTemplate}
          handleClickServiceNoteTemplate={handleClickServiceNoteTemplate}
        />
      </div>
      <DeliveryList
        history={history}
        calculatedFilterModel={getCalculatedFilterModel()}
        userinfo={userinfo}
      />
      <div className="min-h-2rem" />
    </div>
  );
};

export const DeliveryOverview = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(DeliveryOverviewComponent),
  'Lieferungen konnten nicht geladen werden.',
);

DeliveryOverview.displayName = 'DeliveryOverview';
