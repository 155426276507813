import { type UUID } from '~/types/common';

import { type UsersQueryParams } from './useQueryUsers';

/**
 * Query key factory for user-related queries.
 */
export const queryKeysUser = {
  base: () => 'users' as const,
  get: (userId: UUID) => [queryKeysUser.base(), 'detail', { userId }] as const,
  getAll: (queryParams: Partial<UsersQueryParams>) =>
    [queryKeysUser.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<UsersQueryParams>) =>
    [queryKeysUser.base(), 'list', 'infinite', queryParams] as const,
  getBulk: (userIds: UUID[]) =>
    [queryKeysUser.base(), 'bulk', { userIds }] as const,
  getCompany: (userId: UUID) =>
    [queryKeysUser.base(), 'company', { userId }] as const,
  getCompanyAccount: () => [queryKeysUser.base(), 'companyAccount'] as const,
  getData: (includeCompanyInfo: boolean) =>
    [queryKeysUser.base(), 'data', { includeCompanyInfo }] as const,
  getFeatureFlags: (userId: UUID) =>
    [queryKeysUser.base(), 'featureFlags', { userId }] as const,
  getProfilePicture: () => [queryKeysUser.base(), 'profilePicture'] as const,
  getPushNotificationSettings: (userId: UUID) =>
    [queryKeysUser.base(), 'pushNotifications', { userId }] as const,
  getSettings: (userId: UUID) =>
    [queryKeysUser.base(), 'settings', { userId }] as const,
  getSignatureRoles: (userId: UUID) =>
    [queryKeysUser.base(), 'signatureRoles', { userId }] as const,
} as const;
