import { forwardRef } from 'react';

import { cn } from '~/utils/tailwind';

type P = {
  text: string;
} & ComponentStyling;

export const Badge = forwardRef(({ className, style, text }: P, ref) => (
  <div
    ref={ref}
    className={cn(
      'bg-error500 px-0.25 absolute -right-1 -top-1.5 h-4 min-w-4 rounded-lg text-center text-xs font-semibold leading-4 text-white shadow-sm',
      className,
    )}
    style={style}
  >
    {text}
  </div>
));
