import { useCallback, useEffect, useRef, useState } from 'react';
import {
  gridExpandedSortedRowIdsSelector,
  gridFilterModelSelector,
} from '@mui/x-data-grid';

import DashboardService from '~/services/dashboard.service';
import LocalStorageService from '~/services/localStorage.service';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import { precision } from '~/utils/number';
import UnitUtils from '~/utils/unitUtils';

import { BasicTable } from '~/components/BasicTable';

type AnalyticsData = {
  article: string;
  dates: string[];
  total: number;
  values: Array<number | undefined>;
};

type P = {
  data: AnalyticsData;
  isLoading: boolean;
  onSelectedRowChange: (selectedRow: any) => void;
  selectedUnit: string;
};

export const DashboardDetailOverview = ({
  data,
  isLoading,
  onSelectedRowChange,
  selectedUnit,
}: P) => {
  const [rows, setRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [sortModel, setSortModel] = useState([
    {
      field: 'value',
      sort: 'desc',
    },
  ]);

  const visibleRows = useRef([]);
  const filterModel = useRef([]);
  const filterModelUpdated = useRef(false);

  useEffect(() => {
    updateStates(data);
  }, [data]);

  const updateStates = useCallback(
    (data) => {
      if (!data) {
        return;
      }

      const newRows =
        DashboardService.getValuePerArticleFromAnalyticsData(
          data,
          selectedUnit,
        ) ?? [];

      const newSelectionModel = newRows.map((_, index) => index);

      setRows(newRows);
      setRowSelectionModel(newSelectionModel);
    },
    [selectedUnit],
  );

  const publishSelectedArticlesToDashboard = useCallback(
    (rowSelectionModel, visibleRows) => {
      const selectedAndVisibleRows = ArrayUtils.getOverlappingValues(
        rowSelectionModel,
        visibleRows,
      );

      const selectedAndVisibleMaterial = rows.map((row) => {
        if (selectedAndVisibleRows.includes(row.id)) {
          return row.article;
        }
      });

      onSelectedRowChange(selectedAndVisibleMaterial);
    },
    [rows, onSelectedRowChange],
  );

  const onRowSelectionModelChange = useCallback(
    (event) => {
      Log.info(
        'Change selection value of selected articles',
        {
          from: rowSelectionModel,
          to: event,
        },
        Log.BREADCRUMB.SELECTION_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('(De)select article', Log.FEATURE.DASHBOARD);

      setRowSelectionModel(event);
      publishSelectedArticlesToDashboard(event, visibleRows.current);
    },
    [rows, publishSelectedArticlesToDashboard],
  );

  const onSortModelChange = useCallback((event) => {
    setSortModel(event);
  }, []);

  const getColumns = () => {
    let maxPrecision = 0;

    for (const row of rows) {
      const p = precision(row.value);

      if (p > maxPrecision && p <= 2) {
        maxPrecision = p;
      }
    }

    return [
      {
        field: 'article',
        flex: 6,
        headerName: 'Artikel',
        resizableText: true,
        sortable: true,
      },
      {
        field: 'value',
        flex: 2,
        headerName: 'Menge',
        renderCell: ({ value }) =>
          UnitUtils.formatDeWithPrecision_safe(
            value,
            maxPrecision,
            maxPrecision,
          ),
        resizableText: true,
        sortable: true,
        type: 'number',
      },
      {
        field: 'unit',
        flex: 1,
        headerName: 'Einheit',
        resizableText: true,
        sortable: true,
      },
    ];
  };

  const updateVisibleRows = (state) => {
    const newVisibleRows = gridExpandedSortedRowIdsSelector(state);
    const newFilterModel = gridFilterModelSelector(state).items;

    if (
      JSON.stringify(visibleRows.current) !== JSON.stringify(newVisibleRows) &&
      filterModelUpdated.current
    ) {
      publishSelectedArticlesToDashboard(rowSelectionModel, newVisibleRows);
    }

    filterModelUpdated.current =
      JSON.stringify(filterModel.current) !== JSON.stringify(newFilterModel);
    visibleRows.current = newVisibleRows;
    filterModel.current = newFilterModel;
  };

  const getExcelData = () => {
    return rows.map((row) => ({
      ...row,
      unit: UnitUtils.getAbbreviatedUnitString(row.unitKey),
    }));
  };

  const getSelectionModelProps = () => {
    return {
      checkboxSelection: true,
      onRowSelectionModelChange,
      rowSelectionModel,
    };
  };

  return (
    <div className="h-[475px]">
      <BasicTable
        className="rounded-md border"
        rows={rows}
        columns={getColumns()}
        excelData={getExcelData()}
        excelColumns={getColumns()}
        {...getSelectionModelProps()}
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        onStateChange={updateVisibleRows}
        localStorageKey={LocalStorageService.DASHBOARD}
        isLoading={isLoading}
      />
    </div>
  );
};

DashboardDetailOverview.displayName = 'DashboardDetailOverview';
