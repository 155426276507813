import { createSelector } from '@reduxjs/toolkit';

export const selectFilters = (state) => state.filters;

export const selectDeliveryFilterGroups = createSelector(
  [selectFilters],
  (filters) => ({
    filterGroupOpen: filters.delivery_filterGroupOpen,
    filterGroups: filters.delivery_filterGroups,
    filterRows: filters.delivery_filterRows,
    selectedFilterGroup: filters.delivery_selectedFilterGroup,
    selectedTab: filters.delivery_tab,
  }),
);
