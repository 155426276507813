import { useMemo } from 'react';
import { keepPreviousData } from '@tanstack/react-query';
import { useQueryDeliveryNoteAnalytics } from '~/data/deliveryNote';

import { selectPieChartData } from '~/modules/home/components';

import { Spinner } from '~/components/Spinner';

import { withErrorBoundary } from '~/ui/atoms';

import { TimeSeriesChart } from './TimeSeriesChart';
import { DashboardDetailOverview } from './DashboardDetailOverview';
import { DoughnutChart } from './DoughnutChart';

type P = {
  onSelectedArticleInDetailOverviewChange: (
    selectedArticleInDetailOverview: string,
  ) => void;
  searchBody: any;
  selectedDateRange: Date[];
  selectedUnit: string;
  timeAggregate: string;
};

export const DashboardCharts = withErrorBoundary(
  ({
    onSelectedArticleInDetailOverviewChange,
    selectedUnit,
    searchBody,
    timeAggregate,
    selectedDateRange,
  }: P) => {
    const { data, isLoading } = useQueryDeliveryNoteAnalytics(
      {
        ...searchBody,
        timeAggregate,
        unitType: selectedUnit,
      },
      {
        enabled: Boolean(selectedUnit) && Boolean(timeAggregate),
        placeholderData: keepPreviousData,
      },
    );

    const distributionData = useMemo(() => {
      if (!data) {
        return undefined;
      }

      return selectPieChartData(data);
    }, [data]);

    // Callback from DetailOverview so that Dashboard selectedArticleInDetailOverview is up-to-date
    const handleSelectedArticleInDetailOverviewChange = (
      selectedArticleInDetailOverview,
    ) => {
      onSelectedArticleInDetailOverviewChange(selectedArticleInDetailOverview);
    };

    if ((!data || data.length === 0) && !isLoading) {
      return (
        <p className="flex h-full w-full items-center justify-center text-gray-400">
          Keine Lieferungen gefunden für den gewählten Filter.
        </p>
      );
    }

    if (!selectedUnit) {
      return (
        <div className="flex h-96 w-full flex-col items-center justify-center">
          <div>
            Bitte wähle eine Einheit aus, für die die Diagramme erstellt werden
            sollen.
          </div>
        </div>
      );
    }

    const hasDistributionData =
      distributionData?.datasets?.[0]?.data?.length > 0;

    return (
      <div className="flex flex-col gap-8 pt-4">
        <div className="flex flex-col gap-1">
          <h2 className="text-xl font-medium">Verteilung</h2>
          <div className="flex w-full flex-col gap-4 md:flex-row">
            <div className="w-full md:w-2/3 lg:w-1/2">
              <DashboardDetailOverview
                data={data}
                onSelectedRowChange={
                  handleSelectedArticleInDetailOverviewChange
                }
                selectedUnit={selectedUnit}
                isLoading={isLoading}
              />
            </div>
            <div className="relative flex min-h-80 w-full items-center justify-center rounded-md border p-4 md:w-1/3 lg:w-1/2">
              {isLoading && (
                <div className="absolute inset-0 z-10 flex items-center justify-center">
                  <Spinner />
                </div>
              )}
              {hasDistributionData && (
                <DoughnutChart
                  chartData={distributionData}
                  className="h-full w-full"
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <h2 className="text-xl font-medium">Zeitlicher Verlauf</h2>
          <TimeSeriesChart
            className="rounded-md border px-4 pb-2 pt-4"
            data={data}
            selectedDateRange={selectedDateRange}
            selectedUnit={selectedUnit}
            isLoading={isLoading}
          />
        </div>
      </div>
    );
  },
  'Diagramme konnten nicht geladen werden.',
);

DashboardCharts.displayName = 'DashboardCharts';
