import clsx from 'clsx';

import DeliveriesService from '~/services/deliveries.service';

import BilledItem from '~/models/billingState/BilledItem';
import DeliveryNote from '~/models/deliveries/DeliveryNote';

import { LightTooltip } from '~/utils/componentUtils';

import {
  AttentionIcon,
  DeclinedIcon,
  PartlySettledIcon,
  SettledIcon,
} from '~/assets/icons';
import { withErrorBoundary } from '~/ui/atoms';

export const DeliveryStatus = withErrorBoundary(
  ({ centerIcon, combinedState, processState, settledStatus }) => {
    const getIconForCombinedState = () => {
      const className = clsx('absolute -left-2 rounded-full', {
        'top-1/2 -translate-y-1/2': centerIcon,
      });

      switch (combinedState) {
        case DeliveryNote.COMBINED_STATE.DECLINED.KEY: {
          return (
            <span className={className}>
              <DeclinedIcon />
            </span>
          );
        }

        case DeliveryNote.COMBINED_STATE.SIGNATURE_NEEDED.KEY: {
          return (
            <span className={className}>
              <AttentionIcon />
            </span>
          );
        }

        default: {
          return null;
        }
      }
    };

    const getIconForSettledStatus = () => {
      const classNameBase =
        'absolute -right-2 flex size-6 items-center justify-center rounded-full';

      switch (settledStatus) {
        case BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY: {
          return (
            <div
              className={clsx('bg-successLight', classNameBase, {
                'top-1/2 -translate-y-1/2': centerIcon,
              })}
            >
              <SettledIcon className="h-20px w-20px text-successBase" />
            </div>
          );
        }

        case BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY: {
          return (
            <div
              className={clsx('bg-warningLight', classNameBase, {
                'top-1/2 -translate-y-1/2': centerIcon,
              })}
            >
              <PartlySettledIcon className="h-20px w-20px text-warningBase" />
            </div>
          );
        }

        default: {
          return null;
        }
      }
    };

    const getCombinedStateDescription = () => {
      const combinedState2 = Object.keys(DeliveryNote.COMBINED_STATE).find(
        (x) => DeliveryNote.COMBINED_STATE[x].KEY === combinedState,
      );

      return combinedState2
        ? DeliveryNote.COMBINED_STATE[combinedState2].DESCRIPTION
        : null;
    };

    const getSettledStatusDescription = () => {
      const settledStatus2 = Object.keys(BilledItem.SETTLED_STATUS).find(
        (x) => BilledItem.SETTLED_STATUS[x].KEY === settledStatus,
      );

      return settledStatus2
        ? BilledItem.SETTLED_STATUS[settledStatus2].DESCRIPTION
        : null;
    };

    return (
      <div
        className={clsx(
          'relative flex h-full w-full items-center justify-center rounded font-semibold',
          DeliveriesService.switchClassName(processState),
        )}
      >
        {getIconForCombinedState() && (
          <LightTooltip title={getCombinedStateDescription()}>
            {getIconForCombinedState()}
          </LightTooltip>
        )}
        {getIconForSettledStatus() && (
          <LightTooltip title={getSettledStatusDescription()}>
            {getIconForSettledStatus()}
          </LightTooltip>
        )}
        {processState}
      </div>
    );
  },
  null,
);

DeliveryStatus.displayName = 'DeliveryStatus';
