export const DeliveriesTestIds = {
  CHANGES_CHIP: 'delivery-filter-group_group_Änderungen',
  DATE_RANGE_BUTTON: 'delivery-date-range-button',
  DELIVERIES_TABLE: 'delivery-table',
  FILTER_GROUPS: {
    ADD_FILTER_BUTTON: 'delivery-filter-group_add_filter_button',
    BASE: 'delivery-filter-group',
    CONTAINER: 'delivery-filter-group_container',
    DELETE_GROUP_BUTTON: 'delivery-filter-group_delete_group_button',
    DELETE_GROUP_FORM: 'delivery-filter-group_delete_group_form',
    DELETE_GROUP_FORM_SUBMIT_BUTTON:
      'delivery-filter-group_delete_group_form_submit_button',
    FILTER_GROUP_EXPAND_BUTTON:
      'delivery-filter-group_filter_group_expand_button',
    // FilterRows
    FILTER_ROW: 'delivery-filter-group_filter_row',
    FILTER_ROW_DELETE_BUTTON: 'delivery-filter-group_filter_row_delete_button',
    FILTER_ROW_FILTER_OPERATOR_VALUE_SELECT:
      'delivery-filter-group_filter_row_filter_operator_value_select_popup_indicator',
    FILTER_ROW_FILTER_OPERATOR_VALUE_SELECT_POPPER:
      'delivery-filter-group_filter_row_filter_operator_value_select_popper',
    FILTER_ROW_OPERATOR_SELECT:
      'delivery-filter-group_filter_row_operator_select',
    FILTER_ROWS_ACCORDION: 'delivery-filter-group_filter_rows_accordion',
    GROUP_FORM: 'delivery-filter-group_form_modal',
    GROUP_FORM_SUBMIT_BUTTON: 'delivery-filter-group_form_modal_submit_button',
    GROUP_NAME_INPUT: 'delivery-filter-group_group_name_input',
    NEW_GROUP_BUTTON: 'delivery-filter-group_new_group_button',
    UNASSIGNED: 'delivery-filter-group_group_Nicht_zugeordnet',
    UPDATE_GROUP_BUTTON: 'delivery-filter-group_update_group_button',
  },
  FREE_TEXT_SEARCH: 'delivery-free-text-search',
  TABLE_LOADING_SPINNER: 'delivery-table-loading-spinner',
} as const;
