import {
  AddBoxOutlined as AddBoxOutlinedIcon,
  BrokenImageOutlined as BrokenImageOutlinedIcon,
  Check as CheckIcon,
  CloseOutlined as CloseOutlinedIcon,
  ContentPasteOffOutlined as ContentPasteOffOutlinedIcon,
  IndeterminateCheckBoxOutlined as IndeterminateCheckBoxOutlinedIcon,
} from '@mui/icons-material';
import { Grid } from '@mui/material';

import AcceptArticle from '~/models/acceptState/AcceptArticle';
import SignatureRoles from '~/models/masterdata/SignatureRoles';

import { LightTooltip } from '~/utils/componentUtils';
import UnitUtils from '~/utils/unitUtils';

import { Attachment } from '~/components/attachments/Attachment';

import { withErrorBoundary } from '~/ui/atoms';

export const ArticleStatus = withErrorBoundary(({ acceptArticle, party }) => {
  console.log('acceptArticle', acceptArticle);

  const getAcceptStatusText = (amount, description, icon) => {
    if (!amount || amount <= 0) {
      return null;
    }

    return (
      <div className="flex items-center font-bold">
        <div className="flex w-32 items-center">
          <LightTooltip title={description}>{icon}</LightTooltip>
          {UnitUtils.formatValueUnitPair_safe(
            amount,
            acceptArticle?.unit,
            UnitUtils.getAbbreviatedUnit,
          ) ?? ''}
        </div>
        ({description})
      </div>
    );
  };

  // Check if the chainId has already been loaded from the asset_chains
  const attachments =
    acceptArticle?.attachments?.filter((attachment) => attachment.chainId) ??
    [];

  const articleStatus = (
    <>
      <div className="font-bold">
        Artikel {acceptArticle?.acceptState} (
        {SignatureRoles.getSignatureRole(party)})
      </div>
      {acceptArticle?.acceptStatus ? (
        <div>Artikel {acceptArticle?.getDescriptiveAcceptStatus()}</div>
      ) : (
        <div>
          {getAcceptStatusText(
            acceptArticle?.partialAcceptStatus?.valid,
            AcceptArticle.ACCEPT_STATUS.VALID.DESCRIPTION,
            <CheckIcon className="mr-1" fontSize="small" />,
          )}
          {getAcceptStatusText(
            acceptArticle?.partialAcceptStatus?.damaged,
            AcceptArticle.ACCEPT_STATUS.DAMAGED.DESCRIPTION,
            <BrokenImageOutlinedIcon className="mr-1" fontSize="small" />,
          )}
          {getAcceptStatusText(
            acceptArticle?.partialAcceptStatus?.wrong,
            AcceptArticle.ACCEPT_STATUS.WRONG.DESCRIPTION,
            <ContentPasteOffOutlinedIcon className="mr-1" fontSize="small" />,
          )}
          {getAcceptStatusText(
            acceptArticle?.partialAcceptStatus?.declined,
            AcceptArticle.ACCEPT_STATUS.DECLINED.DESCRIPTION,
            <CloseOutlinedIcon className="mr-1" fontSize="small" />,
          )}
          {getAcceptStatusText(
            acceptArticle?.partialAcceptStatus?.delta,
            'fehlt',
            <IndeterminateCheckBoxOutlinedIcon
              className="mr-1"
              fontSize="small"
            />,
          )}
          {getAcceptStatusText(
            acceptArticle?.partialAcceptStatus?.delta,
            'überschüssig',
            <AddBoxOutlinedIcon className="mr-1" fontSize="small" />,
          )}
        </div>
      )}
    </>
  );

  const reason = (
    <>
      <div>Kommentar</div>
      <div className="font-bold">{acceptArticle?.reason ?? '-'}</div>
    </>
  );

  const attachmentDiv = (
    <div className="mt-4 flex flex-wrap gap-2">
      {attachments.map((attachment) => (
        <Attachment key={attachment.chainId} attachment={attachment} />
      ))}
    </div>
  );

  return (
    <div>
      <Grid container spacing={4} justifyContent="space-between">
        <Grid item xs={12} lg={6}>
          {articleStatus}
        </Grid>
        <Grid item xs={12} lg={6}>
          {reason}
        </Grid>
      </Grid>
      {attachments.length > 0 ? attachmentDiv : null}
    </div>
  );
}, 'Artikelstatus konnte nicht geladen werden.');

ArticleStatus.displayName = 'ArticleStatus';
