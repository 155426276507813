import { Spinner } from '~/components/Spinner';

import { withErrorBoundary } from '~/ui/atoms';

import {
  ChartTile,
  DataListHeader,
  DeliveryTile,
  HeaderBackground,
  IncomingInvoiceTile,
  InvoicesListTile,
  OutgoingInvoiceTile,
  PageHeading,
  SalesTile,
  SupportTile,
  UserTile,
} from './components';
import { useHome } from './useHome';

export const Home = withErrorBoundary(() => {
  const {
    currentUser,
    displayTile,
    filters,
    handleDateRangeChange,
    handlePredefinedDateRangeChange,
    isLoadingUserData,
    timeframe,
  } = useHome();

  if (isLoadingUserData) {
    return <Spinner title="Berechtigungen werden geladen..." />;
  }

  return (
    <main className="relative z-0 flex flex-col items-center gap-4">
      <HeaderBackground className="absolute inset-x-2 top-0 z-[-1]" />
      <div className="flex w-full max-w-screen-lg flex-col items-center gap-4 p-4">
        <div className="mx-4 my-8 flex h-20 w-full items-center justify-center">
          <PageHeading user={currentUser} />
        </div>
        <div className="w-full @container/tiles">
          <div className="grid w-full grid-flow-dense grid-cols-2 gap-4 @4xl/tiles:grid-cols-3">
            <DataListHeader
              featureFlags={
                currentUser?.companyAccountInfo?.data?.featureFlags ?? {}
              }
              filters={filters}
              handleDateRangeChange={handleDateRangeChange}
              handlePredefinedDateRangeChange={handlePredefinedDateRangeChange}
              className="col-span-full"
            />
            {displayTile.deliveries && <DeliveryTile />}
            {displayTile.dashboard && <ChartTile />}
            {displayTile.outgoingInvoices && (
              <OutgoingInvoiceTile timeframe={timeframe} />
            )}
            {displayTile.invoiceSignatureIssues && (
              <InvoicesListTile timeframe={timeframe} />
            )}
            {displayTile.incomingInvoices && (
              <IncomingInvoiceTile timeframe={timeframe} />
            )}
            <UserTile user={currentUser} />
            {displayTile.sales && <SalesTile />}
            <SupportTile user={currentUser} />
          </div>
        </div>
      </div>
    </main>
  );
}, 'Startseite konnte nicht geladen werden.');
