export const allExportedColumns = [
  'accounting_references',
  'article_name',
  'article_number',
  'buyer_order',
  'buyer',
  'carrier',
  'confirmed_site',
  'created_on',
  'customer_number',
  'delivery_terms',
  'dln_date',
  'dln_nr',
  'gross_weight_amount',
  'gross_weight_unit',
  'license_plate',
  'loading_location_number',
  'loading_location',
  'modified_on',
  'process_state',
  'project',
  'quantity_type',
  'quantity_unit',
  'recipient',
  'remaining_items_count',
  'seller_order',
  'seller',
  'signed_by_id',
  'supplier_assigned_site_address',
  'supplier_assigned_site_name',
  'supplier',
  'tare_weight_amount',
  'tare_weight_unit',
  'trader',
  'weight_amount',
  'weight_unit',
] as const;
