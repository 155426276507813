import { v4 as uuidv4 } from 'uuid';

/**
 * Push the default tabs to the front of the list of filter groups if they are not already present.
 */
export const fixTabsOrder = ({
  defaultTabs,
  dispatch,
  filterGroups,
  getFilterGroupObject,
  setDelivery_filterGroups,
}) => {
  const defaultFilterGroups = defaultTabs.map(({ NAME }) =>
    getFilterGroupObject(uuidv4(), NAME, [], true),
  );

  // Replace default filter groups with filter groups from state if they already exist there.
  let newFilterGroups = defaultFilterGroups.map((filterGroup) => {
    const modifiedDefaultFilterGroup = filterGroups.find(
      ({ name }) => name === filterGroup.name,
    );

    return modifiedDefaultFilterGroup ?? filterGroup;
  });

  // Add filter groups from state that are not based on the default filter groups.
  newFilterGroups = [
    ...newFilterGroups,
    ...filterGroups.filter(({ name }) => {
      if (name === 'Änderungen') {
        return false;
      }

      const isNotDefaultTab = !defaultTabs.some(({ NAME }) => NAME === name);

      return isNotDefaultTab;
    }),
  ];

  dispatch(setDelivery_filterGroups(newFilterGroups));
};
