import DeliveryNote from '~/models/deliveries/DeliveryNote';
import MuiDataGridFilter from '~/models/filters/MuiDataGridFilter';

import BrowserUtils from '~/utils/browserUtils';
import DatagridUtils from '~/utils/datagridUtils';
import { dateUtils } from '~/utils/dateUtils';
import UnitUtils from '~/utils/unitUtils';
import UserUtils from '~/utils/userUtils';

import { DeliveryCategoryIcon } from '~/components/deliveries/DeliveryCategoryIcon';
import DeliveryListActions from '~/components/deliveries/deliveryColumns/DeliveryListActions';

import {
  DeliveryStatusCell,
  PermittedToCostCentersCell,
  PermittedToSitesCell,
} from '../components';

export const getColumns = ({
  companyAccountInfo,
  customFields = [],
  featureFlags,
  onRequestSingleSignatures,
  onShareSingleDeliveryNote,
}) => {
  const isSchickerAccount = UserUtils.isSchickerAccount(companyAccountInfo);

  const showFullDlnDateDateTime =
    UserUtils.showFullDlnDateDateTime(companyAccountInfo);

  const columns = [
    {
      field: DeliveryNote.PROPERTY.CATEGORY.KEY,
      headerName: DeliveryNote.PROPERTY.CATEGORY.STRING,
      renderCell: ({ value }) => (
        <div className="flex h-full w-full items-center justify-center">
          <DeliveryCategoryIcon
            category={DeliveryNote.getProcessCategoryKeyFromString(value)}
            className="size-8"
          />
        </div>
      ),
      sortable: false,
      width: 100,
    },
    {
      field: DeliveryNote.PROPERTY.STATUS.KEY,
      headerName: DeliveryNote.PROPERTY.STATUS.STRING,
      renderCell({ value }) {
        return <DeliveryStatusCell value={value} />;
      },
      sortable: false,
      valueFormatter(params) {
        const processState = params.split(';')?.[0];

        return processState;
      },
      width: BrowserUtils.isWideScreen() ? 200 : 120,
    },
    {
      field: 'deliveryListActions',
      filterable: false,
      headerName: 'Aktionen',
      renderCell: (params) => (
        <DeliveryListActions
          params={params}
          onRequestSignatures={onRequestSingleSignatures}
          onShareDeliveryNote={onShareSingleDeliveryNote}
        />
      ),
      sortable: false,
      valueFormatter: () => '',
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.NUMBER.KEY,
      headerName: DeliveryNote.PROPERTY.NUMBER.STRING,
      resizableText: true,
      sortable: false,
      width: 200,
    },
    {
      field: DeliveryNote.PROPERTY.DLN_DATE.KEY,
      headerName: DeliveryNote.PROPERTY.DLN_DATE.STRING,
      renderCell: ({ value }) =>
        dateUtils.getFormattedDateWithoutMidnight_safe(
          value,
          showFullDlnDateDateTime
            ? dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss
            : dateUtils.DATE_FORMAT.DD_MM_YYYY,
        ),
      resizableText: true,
      sortable: true,
      type: MuiDataGridFilter.DATA_TYPE.DATE,
      valueGetter: (value) => new Date(value),
      width: 170,
    },
    {
      field: DeliveryNote.PROCESS_ROLE.SUPPLIER.KEY,
      headerName: DeliveryNote.PROCESS_ROLE.SUPPLIER.STRING,
      resizableText: true,
      sortable: true,
      width: 250,
    },
    {
      field: DeliveryNote.CONTRACT_ROLE.BUYER.KEY,
      headerName: DeliveryNote.CONTRACT_ROLE.BUYER.STRING,
      resizableText: true,
      sortable: false,
      width: 250,
    },
    {
      field: DeliveryNote.CONTRACT_ROLE.SELLER.KEY,
      headerName: DeliveryNote.CONTRACT_ROLE.SELLER.STRING,
      resizableText: true,
      sortable: false,
      width: 250,
    },
    {
      field: DeliveryNote.PROCESS_ROLE.RECIPIENT.KEY,
      headerName: DeliveryNote.PROCESS_ROLE.RECIPIENT.STRING,
      resizableText: true,
      sortable: false,
      width: 250,
    },
    {
      field: DeliveryNote.PROPERTY.TRADER.KEY,
      headerName: DeliveryNote.PROPERTY.TRADER.STRING,
      resizableText: true,
      sortable: false,
      width: 250,
    },
    {
      field: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.KEY,
      headerName: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING,
      resizableText: true,
      sortable: true,
      width: 400,
    },
    {
      field: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_ADDRESS.KEY,
      headerName: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_ADDRESS.STRING,
      resizableText: true,
      sortable: false,
      width: 400,
    },
    {
      field: DeliveryNote.PROPERTY.COST_CENTERS.KEY,
      headerName: DeliveryNote.PROPERTY.COST_CENTERS.STRING,
      resizableText: true,
      sortable: false,
      width: 150,
      ...DatagridUtils.customColumnTypeArray(),
    },
    {
      field: DeliveryNote.PROPERTY.MAIN_ARTICLE_NUMBER.KEY,
      headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_NUMBER.STRING,
      resizableText: true,
      sortable: false,
      width: 200,
    },
    {
      field: DeliveryNote.PROPERTY.MAIN_ARTICLE_TYPE.KEY,
      headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_TYPE.STRING,
      resizableText: true,
      sortable: false,
      width: 250,
    },
    {
      field: DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_VALUE.KEY,
      headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_VALUE.STRING,
      resizableText: true,
      sortable: false,
      type: MuiDataGridFilter.DATA_TYPE.NUMBER,
      valueGetter: (value) => UnitUtils.formatDe_safe(value),
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_UNIT.KEY,
      headerName: DeliveryNote.PROPERTY.MAIN_ARTICLE_AMOUNT_UNIT.STRING,
      resizableText: true,
      sortable: false,
      valueGetter: (value) => UnitUtils.getAbbreviatedUnit(value),
      width: 100,
    },
    {
      field: DeliveryNote.PROPERTY.TOTAL_WEIGHT_GROSS.KEY,
      headerName: DeliveryNote.PROPERTY.TOTAL_WEIGHT_GROSS.STRING,
      resizableText: true,
      sortable: false,
      width: 200,
    },
    {
      field: DeliveryNote.PROPERTY.TOTAL_WEIGHT_NET.KEY,
      headerName: DeliveryNote.PROPERTY.TOTAL_WEIGHT_NET.STRING,
      resizableText: true,
      sortable: false,
      width: 200,
    },
    {
      field: DeliveryNote.PROPERTY.TOTAL_WEIGHT_TARE.KEY,
      headerName: DeliveryNote.PROPERTY.TOTAL_WEIGHT_TARE.STRING,
      resizableText: true,
      sortable: false,
      width: 200,
    },
    {
      field: DeliveryNote.PROPERTY.DESCRIPTION.KEY,
      headerName: DeliveryNote.PROPERTY.DESCRIPTION.STRING,
      resizableText: true,
      sortable: false,
      width: 250,
    },
    {
      field: DeliveryNote.PROPERTY.COMMENTS.KEY,
      headerName: DeliveryNote.PROPERTY.COMMENTS.STRING,
      resizableText: true,
      sortable: false,
      width: 250,
    },
    {
      field: DeliveryNote.PROPERTY.CUSTOM_DATA.KEY,
      headerName: DeliveryNote.PROPERTY.CUSTOM_DATA.STRING,
      resizableText: true,
      sortable: false,
      width: 400,
    },
    {
      field: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.KEY,
      headerName: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.STRING,
      resizableText: true,
      sortable: false,
      width: 400,
    },
    {
      field: DeliveryNote.PROPERTY.PROCESS_STATE_CHANGE_USER.KEY,
      headerName: DeliveryNote.PROPERTY.PROCESS_STATE_CHANGE_USER.STRING,
      resizableText: true,
      sortable: false,
      width: 200,
    },
    {
      field: DeliveryNote.PROPERTY.FROM_SITE.KEY,
      headerName: DeliveryNote.PROPERTY.FROM_SITE.STRING,
      resizableText: true,
      sortable: false,
      width: 400,
    },
    {
      field: DeliveryNote.PROPERTY.FROM_SITE_ISSUER_ASSIGNED_ID.KEY,
      headerName: DeliveryNote.PROPERTY.FROM_SITE_ISSUER_ASSIGNED_ID.STRING,
      resizableText: true,
      sortable: false,
      width: 200,
    },
    {
      field: DeliveryNote.PROCESS_ROLE.CARRIER.KEY,
      headerName: DeliveryNote.PROCESS_ROLE.CARRIER.STRING,
      resizableText: true,
      sortable: false,
      width: 250,
    },
    {
      field: DeliveryNote.PROPERTY.LICENSE_PLATE.KEY,
      headerName: DeliveryNote.PROPERTY.LICENSE_PLATE.STRING,
      resizableText: true,
      sortable: false,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.SELLER_ORDER_REFERENCES.KEY,
      headerName: DeliveryNote.PROPERTY.SELLER_ORDER_REFERENCES.STRING,
      resizableText: true,
      sortable: false,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.BUYER_ORDER_REFERENCES.KEY,
      headerName: DeliveryNote.PROPERTY.BUYER_ORDER_REFERENCES.STRING,
      resizableText: true,
      sortable: false,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.CONSTRUCTION_PLANS.KEY,
      headerName: DeliveryNote.PROPERTY.CONSTRUCTION_PLANS.STRING,
      resizableText: true,
      sortable: false,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.CONSTRUCTION_COMPONENTS.KEY,
      headerName: DeliveryNote.PROPERTY.CONSTRUCTION_COMPONENTS.STRING,
      resizableText: true,
      sortable: false,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.MOVEMENT_MEANS.KEY,
      headerName: DeliveryNote.PROPERTY.MOVEMENT_MEANS.STRING,
      resizableText: true,
      sortable: false,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.PROJECT.KEY,
      headerName: DeliveryNote.PROPERTY.PROJECT.STRING,
      resizableText: true,
      sortable: false,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.BUYER_ID.KEY,
      headerName: DeliveryNote.PROPERTY.BUYER_ID.STRING,
      resizableText: true,
      sortable: false,
      width: 150,
    },
    {
      field: DeliveryNote.PROPERTY.DELIVERY_TYPE.KEY,
      headerName: DeliveryNote.PROPERTY.DELIVERY_TYPE.STRING,
      resizableText: true,
      sortable: false,
      width: 150,
    },
    {
      field: 'createdOn',
      headerName: DeliveryNote.PROPERTY.CREATION_DATE.STRING,
      renderCell: ({ value }) =>
        dateUtils.getFormattedDateWithoutMidnight_safe(
          value,
          isSchickerAccount
            ? dateUtils.DATE_FORMAT.DD_MM_YYYY
            : dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
        ),
      resizableText: true,
      sortable: true,
      type: MuiDataGridFilter.DATA_TYPE.DATE,
      valueFormatter: (value) =>
        dateUtils.getFormattedDateWithoutMidnight_safe(
          value,
          isSchickerAccount
            ? dateUtils.DATE_FORMAT.DD_MM_YYYY
            : dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
        ),
      valueGetter: (value) => new Date(value),
      width: 180,
    },
    {
      field: 'modifiedOn',
      headerName: DeliveryNote.PROPERTY.MODIFIED_DATE.STRING,
      renderCell: ({ value }) =>
        dateUtils.getFormattedDateWithoutMidnight_safe(
          value,
          isSchickerAccount
            ? dateUtils.DATE_FORMAT.DD_MM_YYYY
            : dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
        ),
      resizableText: true,
      sortable: true,
      type: MuiDataGridFilter.DATA_TYPE.DATE,
      valueFormatter: (value) =>
        dateUtils.getFormattedDateWithoutMidnight_safe(
          value,
          isSchickerAccount
            ? dateUtils.DATE_FORMAT.DD_MM_YYYY
            : dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
        ),
      valueGetter: (value) => new Date(value),
      width: 180,
    },
    {
      field: DeliveryNote.PROPERTY.CARRIER_ID.KEY,
      headerName: DeliveryNote.PROPERTY.CARRIER_ID.STRING,
      resizableText: true,
      sortable: false,
      width: 150,
    },
  ];

  if (
    featureFlags.accessPermittedSites ||
    UserUtils.isPermittedSiteAllowedUser()
  ) {
    columns.push(
      {
        field: DeliveryNote.PROPERTY.PERMITTED_TO_SITES.KEY,
        headerName: DeliveryNote.PROPERTY.PERMITTED_TO_SITES.STRING,
        renderCell({ value }) {
          return value ? <PermittedToSitesCell value={value} /> : null;
        },
        resizableText: true,
        sortable: false,
        valueFormatter(params) {
          const permittedToSites = params
            .split(';;;')
            .filter(Boolean)
            .map((value) => JSON.parse(value));

          return permittedToSites
            .filter(({ active }) => active)
            .map(({ name }) => name)
            .join(', ');
        },
        width: 400,
      },
      {
        field: DeliveryNote.PROPERTY.PERMITTED_COST_CENTERS.KEY,
        headerName: DeliveryNote.PROPERTY.PERMITTED_COST_CENTERS.STRING,
        renderCell({ value }) {
          return value ? <PermittedToCostCentersCell value={value} /> : null;
        },
        resizableText: true,

        sortable: false,
        valueFormatter(params) {
          const permittedToCostCenters = params
            .split(';;;')
            .filter(Boolean)
            .map((value) => JSON.parse(value));

          return permittedToCostCenters
            .filter(({ active }) => active)
            .map(({ name }) => name)
            .join(', ');
        },
        width: 400,
      },
    );
  }

  for (const customField of customFields) {
    columns.push({
      field: customField.key,
      headerName: customField.displayName,
      renderCell: ({ value }) =>
        value && Array.isArray(value) ? value.join(', ') : value,
      resizableText: true,
      sortable: false,
      width: 400,
    });
  }

  return columns;
};
