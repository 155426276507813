import cloneDeep from 'lodash/cloneDeep';
import React, { useState, useCallback } from 'react';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { FormHelperText, IconButton } from '@mui/material';

import { AMOUNT_UNITS, WEIGHT_UNITS } from '~/constants/units';

import ToastService from '~/services/toast.service';

import Address from '~/models/masterdata/Address';

import BrowserUtils from '~/utils/browserUtils';
import Log from '~/utils/Log';
import { isInteger } from '~/utils/number';
import UnitUtils from '~/utils/unitUtils';

import ArticleTextField from './ArticleTextField';
import ArticleSelect from './ArticleSelect';

const COLUMNS = {
  MANUFACTURER_NAME: 'manufacturer-name',
  NUMBER: 'number',
  TYPE: 'type',
};

export const ArticleList = ({
  addArticle,
  articles,
  articleTemplates,
  error,
  onChange,
  removeArticle,
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [focusedColumn, setFocusedColumn] = useState(null);
  const [focusedRow, setFocusedRow] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);

  const handleChangeNumber = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      newArticles[index].number = event.target.value;
      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeType = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      newArticles[index].type = event.target.value;
      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeQuantityValue = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      try {
        newArticles[index].quantity.value = UnitUtils.complexFormatDe(
          event.target.value,
        );
      } catch {
        Log.productAnalyticsEvent(
          'Invalid quantity',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
        invalidInputToast();
      }

      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeQuantityUnit = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      newArticles[index].quantity.unit = event.target.value;
      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeWeightValue = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      try {
        newArticles[index].weight.value = UnitUtils.complexFormatDe(
          event.target.value,
        );
      } catch {
        Log.productAnalyticsEvent(
          'Invalid weight',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
        invalidInputToast();
      }

      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeWeightUnit = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      newArticles[index].weight.unit = event.target.value;
      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeGrossValue = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      try {
        newArticles[index].weighingInformation.gross.weight.value =
          UnitUtils.complexFormatDe(event.target.value);
      } catch {
        Log.productAnalyticsEvent(
          'Invalid gross weight',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
        invalidInputToast();
      }

      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeTareValue = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      try {
        newArticles[index].weighingInformation.tare.weight.value =
          UnitUtils.complexFormatDe(event.target.value);
      } catch {
        Log.productAnalyticsEvent(
          'Invalid tare weight',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
        invalidInputToast();
      }

      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeEan = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      if (!event.target.value) {
        newArticles[index].ean = '';
      }

      if (isInteger(event.target.value)) {
        newArticles[index].ean = event.target.value;
      }

      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeManufacturerName = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      newArticles[index].manufacturer.name = event.target.value;
      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeManufacturerStreetName = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      newArticles[index].manufacturer.address.streetName = event.target.value;
      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeManufacturerBuildingNumber = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      newArticles[index].manufacturer.address.buildingNumber =
        event.target.value;
      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeManufacturerPostCode = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      newArticles[index].manufacturer.address.postCode = event.target.value;
      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeManufacturerCity = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      newArticles[index].manufacturer.address.city = event.target.value;
      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeManufacturerCountry = useCallback(
    (event, id) => {
      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      newArticles[index].manufacturer.address.country = event.target.value;
      onChange(newArticles);
    },
    [articles, onChange],
  );

  const handleChangeAccordion = useCallback(
    (id) => {
      if (expandedRow === id) {
        setExpandedRow(null);
        setFocusedRow(null);
      } else {
        setExpandedRow(id);
        setFocusedRow(id);
      }
    },
    [expandedRow],
  );

  const articleIsActive = useCallback(
    (id) => {
      if (focusedRow !== null) {
        return focusedRow === id;
      }

      if (hoveredRow !== null) {
        return hoveredRow === id;
      }

      return false;
    },
    [focusedRow, hoveredRow],
  );

  const invalidInputToast = useCallback(() => {
    ToastService.warning(
      ['Ungültige Eingabe.'],
      ToastService.ID.CREATE_DLN_INVALID_INPUT,
    );
    Log.productAnalyticsEvent(
      'Invalid input for article(s)',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
      Log.TYPE.FAILED_VALIDATION,
    );
  }, []);

  const matchedMasterDataArticle = useCallback(
    (article) => {
      let matchedArticle = null;

      if (focusedColumn === COLUMNS.NUMBER) {
        if (article.number.length < 3) {
          return null;
        }

        const matchedArticles = articleTemplates.filter((item) =>
          item.article.article_nr.startsWith(article.number),
        );
        matchedArticle = matchedArticles[0];

        for (const item of matchedArticles) {
          if (
            item.article.article_nr.length <
            matchedArticle.article.article_nr.length
          ) {
            matchedArticle = item;
          }
        }
      }

      if (focusedColumn === COLUMNS.TYPE) {
        if (article.type.length < 3) {
          return null;
        }

        const matchedArticles = articleTemplates.filter((item) =>
          item.article.article_name.startsWith(article.type),
        );
        matchedArticle = matchedArticles[0];

        for (const item of matchedArticles) {
          if (
            item.article.article_name.length <
            matchedArticle.article.article_name.length
          ) {
            matchedArticle = item;
          }
        }
      }

      if (focusedColumn === COLUMNS.MANUFACTURER_NAME) {
        if (article.manufacturer.name.length < 3) {
          return null;
        }

        const matchedArticles = articleTemplates
          .filter((item) =>
            item.article.manufacturer_name.startsWith(
              article.manufacturer.name,
            ),
          )
          .map((item) => ({
            ...item,
            article: {
              manufacturer_city: item.article.manufacturer_city,
              manufacturer_country: item.article.manufacturer_country,
              manufacturer_house_number: item.article.manufacturer_house_number,
              manufacturer_name: item.article.manufacturer_name,
              manufacturer_post_code: item.article.manufacturer_post_code,
              manufacturer_street: item.article.manufacturer_street,
            },
          }));
        matchedArticle = matchedArticles[0];

        for (const item of matchedArticles) {
          if (
            item.article.manufacturer_name.length <
            matchedArticle.article.manufacturer_name.length
          ) {
            matchedArticle = item;
          }
        }
      }

      return matchedArticle?.article;
    },
    [articleTemplates, focusedColumn],
  );

  const handleAutofill = useCallback(
    (e, id, autofillArticle) => {
      if (!autofillArticle) {
        return;
      }

      const newArticles = cloneDeep(articles);
      const index = newArticles.findIndex((article) => article.id === id);

      if (index === -1) {
        Log.error('Failed to find article by id. id: ' + id);
        return;
      }

      // right arrow: only autofill the focused column
      if (e.keyCode === 39) {
        if (focusedColumn === COLUMNS.NUMBER) {
          Log.productAnalyticsEvent(
            'Autofill article number',
            Log.FEATURE.CREATE_DELIVERY_NOTE,
          );
          newArticles[index].number = autofillArticle.article_nr;
        }

        if (focusedColumn === COLUMNS.TYPE) {
          Log.productAnalyticsEvent(
            'Autofill article type',
            Log.FEATURE.CREATE_DELIVERY_NOTE,
          );
          newArticles[index].type = autofillArticle.article_name;
        }

        if (focusedColumn === COLUMNS.MANUFACTURER_NAME) {
          Log.productAnalyticsEvent(
            'Autofill article manufacturer',
            Log.FEATURE.CREATE_DELIVERY_NOTE,
          );
          newArticles[index].manufacturer.name =
            autofillArticle.manufacturer_name;
        }

        onChange(newArticles);
      }

      // enter 13; tab 9
      if (e.keyCode !== 13 && e.keyCode !== 9) {
        return;
      }

      // autofill the whole article
      if (focusedColumn === COLUMNS.NUMBER || focusedColumn === COLUMNS.TYPE) {
        if (focusedColumn === COLUMNS.NUMBER) {
          Log.productAnalyticsEvent(
            'Autofill article by number',
            Log.FEATURE.CREATE_DELIVERY_NOTE,
          );
        } else {
          Log.productAnalyticsEvent(
            'Autofill article by type',
            Log.FEATURE.CREATE_DELIVERY_NOTE,
          );
        }

        newArticles[index].number = autofillArticle.article_nr;
        newArticles[index].type = autofillArticle.article_name;
        newArticles[index].quantity.unit = autofillArticle.article_amount_unit;
        newArticles[index].weight.unit = autofillArticle.article_weight_unit;
        newArticles[index].ean = autofillArticle.article_ean;
        newArticles[index].manufacturer.name =
          autofillArticle.manufacturer_name;
        newArticles[index].manufacturer.address.streetName =
          autofillArticle.manufacturer_street;
        newArticles[index].manufacturer.address.buildingNumber =
          autofillArticle.manufacturer_house_number;
        newArticles[index].manufacturer.address.postCode =
          autofillArticle.manufacturer_post_code;
        newArticles[index].manufacturer.address.city =
          autofillArticle.manufacturer_city;
        newArticles[index].manufacturer.address.country =
          autofillArticle.manufacturer_country;

        onChange(newArticles);
      }

      // only autofill the manufacturer information
      if (focusedColumn === COLUMNS.MANUFACTURER_NAME) {
        Log.productAnalyticsEvent(
          'Autofill article manufacturer by manufacturer name',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );

        newArticles[index].manufacturer.name =
          autofillArticle.manufacturer_name;
        newArticles[index].manufacturer.address.streetName =
          autofillArticle.manufacturer_street;
        newArticles[index].manufacturer.address.buildingNumber =
          autofillArticle.manufacturer_house_number;
        newArticles[index].manufacturer.address.postCode =
          autofillArticle.manufacturer_post_code;
        newArticles[index].manufacturer.address.city =
          autofillArticle.manufacturer_city;
        newArticles[index].manufacturer.address.country =
          autofillArticle.manufacturer_country;

        onChange(newArticles);
      }
    },
    [articles, focusedColumn, onChange],
  );

  const getManufacturerFirstRow = useCallback(
    (article, autofillArticle) => {
      if (BrowserUtils.isWideScreen()) {
        return (
          <>
            <div className="flex-1">
              <ArticleTextField
                value={article.manufacturer.address.streetName}
                placeholder={
                  autofillArticle?.manufacturer_street
                    ? autofillArticle.manufacturer_street
                    : 'Straße des Herstellers'
                }
                onChange={(event) =>
                  handleChangeManufacturerStreetName(event, article.id)
                }
                onFocus={() => setFocusedRow(article.id)}
                onBlur={() => {
                  if (focusedRow === article.id) {
                    setFocusedRow(null);
                  }
                }}
              />
            </div>
            <div className="w-70px">
              <ArticleTextField
                value={article.manufacturer.address.buildingNumber}
                placeholder={
                  autofillArticle?.manufacturer_house_number
                    ? autofillArticle.manufacturer_house_number
                    : 'Nr.'
                }
                onChange={(event) =>
                  handleChangeManufacturerBuildingNumber(event, article.id)
                }
                onFocus={() => setFocusedRow(article.id)}
                onBlur={() => {
                  if (focusedRow === article.id) {
                    setFocusedRow(null);
                  }
                }}
              />
            </div>
            <div className="w-80px">
              <ArticleTextField
                value={article.manufacturer.address.postCode}
                placeholder={
                  autofillArticle?.manufacturer_post_code
                    ? autofillArticle.manufacturer_post_code
                    : 'PLZ'
                }
                onChange={(event) =>
                  handleChangeManufacturerPostCode(event, article.id)
                }
                onFocus={() => setFocusedRow(article.id)}
                onBlur={() => {
                  if (focusedRow === article.id) {
                    setFocusedRow(null);
                  }
                }}
              />
            </div>
            <div className="w-170px">
              <ArticleTextField
                value={article.manufacturer.address.city}
                placeholder={
                  autofillArticle?.manufacturer_city
                    ? autofillArticle.manufacturer_city
                    : 'Stadt'
                }
                onChange={(event) =>
                  handleChangeManufacturerCity(event, article.id)
                }
                onFocus={() => setFocusedRow(article.id)}
                onBlur={() => {
                  if (focusedRow === article.id) {
                    setFocusedRow(null);
                  }
                }}
              />
            </div>
            <div className="w-70px">
              <ArticleSelect
                value={article.manufacturer.address.country}
                options={Address.getCountryCodeOptions()}
                onChange={(event) =>
                  handleChangeManufacturerCountry(event, article.id)
                }
                placeholder={
                  autofillArticle?.manufacturer_country
                    ? autofillArticle.manufacturer_country
                    : 'DE'
                }
                displayId
              />
            </div>
          </>
        );
      }

      return (
        <>
          <div className="flex-1">
            <ArticleTextField
              value={article.manufacturer.address.streetName}
              placeholder={
                autofillArticle?.manufacturer_street
                  ? autofillArticle.manufacturer_street
                  : 'Straße des Herstellers'
              }
              onChange={(event) =>
                handleChangeManufacturerStreetName(event, article.id)
              }
              onFocus={() => setFocusedRow(article.id)}
              onBlur={() => {
                if (focusedRow === article.id) {
                  setFocusedRow(null);
                }
              }}
            />
          </div>
          <div className="w-70px">
            <ArticleTextField
              value={article.manufacturer.address.buildingNumber}
              placeholder={
                autofillArticle?.manufacturer_house_number
                  ? autofillArticle.manufacturer_house_number
                  : 'Nr.'
              }
              onChange={(event) =>
                handleChangeManufacturerBuildingNumber(event, article.id)
              }
              onFocus={() => setFocusedRow(article.id)}
              onBlur={() => {
                if (focusedRow === article.id) {
                  setFocusedRow(null);
                }
              }}
            />
          </div>
        </>
      );
    },
    [focusedRow],
  );

  const getManufacturerSecondRow = useCallback(
    (article, autofillArticle) => {
      if (BrowserUtils.isWideScreen()) {
        return null;
      }

      return (
        <>
          <div className="w-80px">
            <ArticleTextField
              value={article.manufacturer.address.postCode}
              placeholder={
                autofillArticle?.manufacturer_post_code
                  ? autofillArticle.manufacturer_post_code
                  : 'PLZ'
              }
              onChange={(event) =>
                handleChangeManufacturerPostCode(event, article.id)
              }
              onFocus={() => setFocusedRow(article.id)}
              onBlur={() => {
                if (focusedRow === article.id) {
                  setFocusedRow(null);
                }
              }}
            />
          </div>
          <div className="flex-1">
            <ArticleTextField
              value={article.manufacturer.address.city}
              placeholder={
                autofillArticle?.manufacturer_city
                  ? autofillArticle.manufacturer_city
                  : 'Stadt'
              }
              onChange={(event) =>
                handleChangeManufacturerCity(event, article.id)
              }
              onFocus={() => setFocusedRow(article.id)}
              onBlur={() => {
                if (focusedRow === article.id) {
                  setFocusedRow(null);
                }
              }}
            />
          </div>
          <div className="w-70px">
            <ArticleSelect
              value={article.manufacturer.address.country}
              options={Address.getCountryCodeOptions()}
              onChange={(event) =>
                handleChangeManufacturerCountry(event, article.id)
              }
              placeholder={
                autofillArticle?.manufacturer_country
                  ? autofillArticle.manufacturer_country
                  : 'DE'
              }
            />
          </div>
          <div className="w-260px"></div>
        </>
      );
    },
    [focusedRow],
  );

  return (
    <div className="relative w-full">
      <div className="border-grey400 rounded-4px mt-10px">
        <div className="bg-grey100 rounded-top-4px border-bottom-grey400 bold flex-s-c gap-20px p-10px">
          <div className="pl-10px w-150px">Artikel-Nr.*</div>
          <div className="pl-10px flex-1">Artikelname*</div>
          <div className="pr-10px w-100px text-end">Menge*</div>
          <div className="pl-10px w-100px">Einheit*</div>
          <div className="pl-10px w-20px" />
        </div>
        {articles.map((article, index) => {
          const autofillArticle = matchedMasterDataArticle(article);

          return (
            <div
              className={
                'p-10px transition-500ms w-full ' +
                (articleIsActive(article.id) ? 'bg-grey100' : '') +
                (articles.length === index + 1
                  ? 'rounded-bottom-4px'
                  : 'border-bottom-grey400')
              }
              onMouseEnter={() => setHoveredRow(article.id)}
              onMouseLeave={() => setHoveredRow(null)}
              key={article.id}
            >
              <div className="flex-s-c gap-20px">
                <div className="w-150px">
                  <ArticleTextField
                    value={article.number}
                    error={article.error.number}
                    placeholder="Artikel-Nr.*"
                    autofillvalue={autofillArticle?.article_nr}
                    onChange={(event) => handleChangeNumber(event, article.id)}
                    onFocus={() => {
                      setFocusedColumn(COLUMNS.NUMBER);
                      setFocusedRow(article.id);
                    }}
                    onBlur={() => {
                      if (focusedRow === article.id) {
                        setFocusedColumn(null);
                        setFocusedRow(null);
                      }
                    }}
                    onKeyDown={(e) =>
                      handleAutofill(e, article.id, autofillArticle)
                    }
                  />
                </div>
                <div className="flex-1">
                  <ArticleTextField
                    value={article.type}
                    error={article.error.type}
                    placeholder="Artikelname*"
                    autofillvalue={autofillArticle?.article_name}
                    onChange={(event) => handleChangeType(event, article.id)}
                    onFocus={() => {
                      setFocusedColumn(COLUMNS.TYPE);
                      setFocusedRow(article.id);
                    }}
                    onBlur={() => {
                      if (focusedRow === article.id) {
                        setFocusedColumn(null);
                        setFocusedRow(null);
                      }
                    }}
                    onKeyDown={(e) =>
                      handleAutofill(e, article.id, autofillArticle)
                    }
                  />
                </div>
                <div className="w-100px">
                  <ArticleTextField
                    value={article.quantity.value}
                    error={article.error.quantityValue}
                    placeholder="Menge*"
                    onChange={(event) =>
                      handleChangeQuantityValue(event, article.id)
                    }
                    onFocus={() => setFocusedRow(article.id)}
                    onBlur={() => {
                      if (focusedRow === article.id) {
                        setFocusedRow(null);
                      }
                    }}
                    textend={1}
                  />
                </div>
                <div className="w-100px">
                  <ArticleSelect
                    value={article.quantity.unit}
                    error={article.error.quantityUnit}
                    options={AMOUNT_UNITS}
                    onChange={(event) =>
                      handleChangeQuantityUnit(event, article.id)
                    }
                    placeholder={
                      autofillArticle?.article_amount_unit
                        ? UnitUtils.getAbbreviatedUnit(
                            autofillArticle.article_amount_unit,
                          )
                        : 'Einheit*'
                    }
                  />
                </div>
                <div className="flex-c-c w-20px">
                  <IconButton
                    size="small"
                    onClick={() => removeArticle(article.id)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              {expandedRow === article.id && articleIsActive(article.id) ? (
                <>
                  <div className="mt-10px flex-s-c gap-20px">
                    <div className="w-150px">
                      <ArticleTextField
                        value={article.ean}
                        placeholder={
                          autofillArticle?.article_ean
                            ? autofillArticle.article_ean
                            : 'EAN'
                        }
                        onChange={(event) => handleChangeEan(event, article.id)}
                        onFocus={() => setFocusedRow(article.id)}
                        onBlur={() => {
                          if (focusedRow === article.id) {
                            setFocusedRow(null);
                          }
                        }}
                      />
                    </div>
                    <div className="flex-1">
                      <ArticleTextField
                        value={article.manufacturer.name}
                        placeholder="Name des Herstellers"
                        autofillvalue={autofillArticle?.manufacturer_name}
                        onChange={(event) =>
                          handleChangeManufacturerName(event, article.id)
                        }
                        onFocus={() => {
                          setFocusedColumn(COLUMNS.MANUFACTURER_NAME);
                          setFocusedRow(article.id);
                        }}
                        onBlur={() => {
                          if (focusedRow === article.id) {
                            setFocusedColumn(null);
                            setFocusedRow(null);
                          }
                        }}
                        onKeyDown={(e) =>
                          handleAutofill(e, article.id, autofillArticle)
                        }
                      />
                    </div>
                    <div className="w-100px">
                      <ArticleTextField
                        value={article.weight.value}
                        error={article.error.weightValue}
                        placeholder="Gewicht"
                        onChange={(event) =>
                          handleChangeWeightValue(event, article.id)
                        }
                        onFocus={() => setFocusedRow(article.id)}
                        onBlur={() => {
                          if (focusedRow === article.id) {
                            setFocusedRow(null);
                          }
                        }}
                        textend={1}
                      />
                    </div>
                    <div className="w-100px">
                      <ArticleSelect
                        value={article.weight.unit}
                        error={article.error.weightUnit}
                        options={WEIGHT_UNITS}
                        onChange={(event) =>
                          handleChangeWeightUnit(event, article.id)
                        }
                        placeholder={
                          autofillArticle?.article_weight_unit
                            ? UnitUtils.getAbbreviatedUnit(
                                autofillArticle.article_weight_unit,
                              )
                            : 'Einheit'
                        }
                      />
                    </div>
                    <div className="w-20px"></div>
                  </div>
                  <div className="mt-10px flex-s-c gap-20px">
                    {getManufacturerFirstRow(article, autofillArticle)}
                    <div className="w-100px">
                      <ArticleTextField
                        value={article.weighingInformation.tare.weight.value}
                        placeholder="Tara"
                        onChange={(event) =>
                          handleChangeTareValue(event, article.id)
                        }
                        onFocus={() => setFocusedRow(article.id)}
                        onBlur={() => {
                          if (focusedRow === article.id) {
                            setFocusedRow(null);
                          }
                        }}
                        textend={1}
                      />
                    </div>
                    <div className="w-100px">
                      <ArticleTextField
                        value={article.weighingInformation.gross.weight.value}
                        placeholder="Brutto"
                        onChange={(event) =>
                          handleChangeGrossValue(event, article.id)
                        }
                        onFocus={() => setFocusedRow(article.id)}
                        onBlur={() => {
                          if (focusedRow === article.id) {
                            setFocusedRow(null);
                          }
                        }}
                        textend={1}
                      />
                    </div>
                    <div className="w-20px"></div>
                  </div>
                  {BrowserUtils.isWideScreen() ? null : (
                    <div className="mt-10px flex-s-c gap-20px">
                      {getManufacturerSecondRow(article, autofillArticle)}
                    </div>
                  )}
                </>
              ) : null}
              <div
                className={
                  'ml-10px bold text-primary500 cursor-pointer ' +
                  (articleIsActive(article.id) ? 'mt-10px' : 'hidden')
                }
                onClick={() => handleChangeAccordion(article.id)}
              >
                {expandedRow === article.id ? 'Einklappen' : 'Weitere Details'}
              </div>
            </div>
          );
        })}
      </div>
      {error ? (
        <div className="flex-s-s w-full">
          <FormHelperText className="text-mui-error-red absolute">
            {error}
          </FormHelperText>
        </div>
      ) : null}
      <div className="flex-c-c mt-10px w-full">
        <IconButton onClick={addArticle} size="large">
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
};
