import ms from 'ms';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysDeliveryNote } from './queryKeys';

/**
 * Fetches handwritten signature data from the API.
 * @param {UUID} chainId - The ID of the delivery note chain.
 * @returns {Promise<Blob>} The signature data as a blob if successful.
 * @see https://app.dev.vestigas.com/redoc#tag/Delivery-Note/operation/get_handwritten_signature_for_doc_asset_delivery_note_chain__doc_id__handwritten_signature_get
 */
export const fetchHandwrittenSignature = async (
  chainId: UUID,
): Promise<Blob> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.DELIVERY_NOTE.GET_HANDWRITTEN_SIGNATURE(chainId))
      .blob();

    return response;
  } catch (error) {
    console.error('Error fetching handwritten signature:', error);

    Log.productAnalyticsEvent(
      'Failed to load attachment',
      Log.FEATURE.OTHER_FEATURE,
      Log.TYPE.ERROR,
    );

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting a handwritten signature for a delivery note.
 * @param {UUID} chainId - The ID of the delivery note chain.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<Blob>} The result of the useQuery hook.
 */
export const useQueryHandwrittenSignature = (
  chainId: UUID,
  options?: Record<string, unknown>,
): UseQueryResult<Blob> =>
  useQuery({
    enabled: Boolean(chainId),
    gcTime: ms('10m'),
    queryFn: async () => fetchHandwrittenSignature(chainId),
    queryKey: queryKeysDeliveryNote.getHandwrittenSignature(chainId),
    staleTime: Infinity,
    ...options,
  });
