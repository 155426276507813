import { dateUtils, parseDate } from '~/utils/dateUtils';

export const SELECTABLE_COLUMNS = [
  {
    disableReorder: true,
    field: 'processState',
    flex: 6,
    headerName: 'Status',
    resizableText: true,
    sortable: true,
  },
  {
    disableReorder: true,
    field: 'acceptState',
    flex: 6,
    headerName: 'Angenommen',
    resizableText: true,
    sortable: true,
  },
  {
    disableReorder: true,
    field: 'billedState',
    flex: 6,
    headerName: 'Abgerechnet',
    resizableText: true,
    sortable: true,
  },
  {
    disableReorder: true,
    field: 'dlnDate',
    flex: 6,
    headerName: 'LFS-Datum',
    renderCell: ({ value }) =>
      dateUtils.getFormattedDate_safe(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY,
        dateUtils.DATE_FORMAT.YYYY_MM_DD,
      ),
    resizableText: true,
    sortable: true,
    type: 'date',
    valueGetter: (value) => parseDate(value),
  },
  {
    disableReorder: true,
    field: 'dlnNr',
    flex: 6,
    headerName: 'LFS-Nr.',
    resizableText: true,
    sortable: true,
  },
  {
    disableReorder: true,
    field: 'supplierName',
    flex: 6,
    headerName: 'Lieferant',
    resizableText: true,
    sortable: true,
  },
  {
    disableReorder: true,
    field: 'recipientName',
    flex: 6,
    headerName: 'Abnehmer',
    resizableText: true,
    sortable: true,
  },
  {
    disableReorder: true,
    field: 'toSiteRecipientName',
    flex: 6,
    headerName: 'Bestätigter Lieferort',
    resizableText: true,
    sortable: true,
  },
  {
    disableReorder: true,
    field: 'toSiteSupplierName',
    flex: 6,
    headerName: 'Lieferort (Lieferantenbezeichnung)',
    resizableText: true,
    sortable: true,
  },
  {
    disableReorder: true,
    field: 'confirmedAccountingReferenceName',
    flex: 6,
    headerName: 'Kostenstelle',
    resizableText: true,
    sortable: true,
  },
  {
    disableReorder: true,
    field: 'articleNumber',
    flex: 4,
    headerName: 'Artikel-Nr.',
    resizableText: true,
    sortable: true,
  },
  {
    disableReorder: true,
    field: 'articleName',
    flex: 6,
    headerName: 'Artikel',
    resizableText: true,
    sortable: true,
  },
];
