import React, { useState, useCallback, memo } from 'react';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';
import { ComponentTestIds } from '~/constants/test-ids';

import { useQueryOrganizationalUnits } from '~/data/organizationalUnit';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../SettingsTable';

import { columns } from './constants';
import { selectOrganizationalUnits } from './selectOrganizationalUnits';

export const OrganizationalUnitsTable = withErrorBoundary(
  memo(() => {
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: PAGINATION_PAGE_SIZE_DEFAULT,
    });
    const [sortModel, setSortModel] = useState([
      {
        field: 'name',
        sort: 'asc',
      },
    ]);
    const [searchString, setSearchString] = useState(undefined);

    const { data, isFetching, isPending, isPlaceholderData, refetch } =
      useQueryOrganizationalUnits(
        {
          limit: paginationModel.pageSize,
          offset: paginationModel.page * paginationModel.pageSize,
          orderBy: sortModel[0]?.field,
          searchString,
          sort: sortModel[0]?.sort,
        },
        {
          select: selectOrganizationalUnits,
        },
      );

    const handlePaginationModelChange = useCallback((newModel) => {
      setPaginationModel(newModel);
    }, []);

    const handleSortModelChange = useCallback((newModel) => {
      setSortModel(newModel);
    }, []);

    const handleSearchStringChange = useCallback((newSearchString) => {
      // Reset to first page when search string changes
      setPaginationModel((previousModel) => ({
        ...previousModel,
        page: 0,
      }));
      setSearchString(newSearchString);
    }, []);

    const filterFunction = useCallback((string) => string, []); // We filter server side -> prevent the DataGrid from filtering.

    return (
      <SettingsTable
        key="OrganizationalUnitsTable"
        entity="organisationalGroup"
        title="Organisations-Gruppen"
        rows={data?.rows ?? []}
        columns={columns}
        filterFunction={filterFunction}
        items={data?.organisationalGroups ?? []}
        isLoading={isPending || (isFetching && isPlaceholderData)}
        multiPermissionGrantDefaultEntityType={
          PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
        }
        multiPermissionGrantFixedPicker={PermissionGrant.TYPE.ENTITY}
        onPaginationModelChange={handlePaginationModelChange}
        onSearchStringChange={handleSearchStringChange}
        onSortModelChange={handleSortModelChange}
        paginationModel={paginationModel}
        refreshData={refetch}
        sortModel={sortModel}
        totalRowCount={data?.totalCount ?? 0}
        hasMultiPermissionGrantEditing
        searchInputTestId={ComponentTestIds.SEARCH_INPUT.INPUT}
      />
    );
  }),
  'Organisations-Gruppen konnten nicht geladen werden.',
);

OrganizationalUnitsTable.displayName = 'OrganizationalUnitsTable';
