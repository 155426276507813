import React from 'react';

import { AttentionIcon, DeclinedIcon } from '~/assets/icons';

import DeliveryNoteValidationResult from '~/models/deliveries/DeliveryNoteValidationResult';

import { LightTooltip } from '~/utils/componentUtils';

import { withErrorBoundary } from '~/ui/atoms';

export const DeliveryNoteDataQualityPath = withErrorBoundary(
  ({ description, name, validationResult, value }) => {
    // Helper function to determine icon based on validation status
    const getStatusIcon = () => {
      const className = 'h-20px w-20px absolute r--7px t--7px';

      switch (validationResult?.status) {
        case DeliveryNoteValidationResult.STATUS.WARNING: {
          return (
            <LightTooltip title={validationResult.message}>
              <AttentionIcon className={className} />
            </LightTooltip>
          );
        }

        case DeliveryNoteValidationResult.STATUS.ERROR: {
          return (
            <LightTooltip title={validationResult.message}>
              <DeclinedIcon className={className} />
            </LightTooltip>
          );
        }

        default: {
          return null;
        }
      }
    };

    // Helper function to determine border styling based on validation status
    const getBorderClassName = () => {
      switch (validationResult?.status) {
        case DeliveryNoteValidationResult.STATUS.WARNING: {
          return 'border-warningBase';
        }

        case DeliveryNoteValidationResult.STATUS.ERROR: {
          return 'border-error500';
        }

        default: {
          return 'border-grey';
        }
      }
    };

    return (
      <div className={`rounded-5px p-10px relative ${getBorderClassName()}`}>
        <div className="flex-sb-c">
          <div className="flex-s-c gap-10px">
            <div>{name}</div>
          </div>
          <div className="flex-s-c gap-10px">
            <div>{value}</div>
            {getStatusIcon()}
          </div>
        </div>
        {description && (
          <div className="text-error500 mt-10px">{description}</div>
        )}
      </div>
    );
  },
  'Daten konnten nicht geladen werden.',
);
