import { useAuth } from 'oidc-react';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  AccountCircle as AccountCircleIcon,
  ArrowBack as ArrowBackIcon,
  FactCheck as FactCheckIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import {
  Button,
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ROUTE } from '~/constants/Route';
import { ComponentTestIds } from '~/constants/test-ids';

import { useQueryCompanyLogo } from '~/data/company';
import { useQueryUserData, useQueryUserProfilePicture } from '~/data/user';

import UserService from '~/services/user.service';

import Config, { environmentInfo } from '~/Config';

import BrowserUtils from '~/utils/browserUtils';
import { LightTooltip } from '~/utils/componentUtils';
import ConfigUtils from '~/utils/configUtils';
import Log from '~/utils/Log';

import RouteUtils from '~/utils/routeUtils';

import { SitesCostCentersSelection } from './SitesCostCentersSelection';

export const TopBar = memo(({ drawerWidth }) => {
  const { data: profilePicture, isSuccess: isSuccessProfilePicture } =
    useQueryUserProfilePicture();

  const useStyles = makeStyles((theme) => ({
    appBar: {
      backgroundColor: 'white',
      color: '#343A4D',
      marginLeft: drawerWidth,
      // This fixed a bug. Without it, the burger menu would move to the left when the date range select
      // in the home screen is opened (in case of a screen width of around 729px).
      paddingRight: '0 !important',

      transition: 'width 200ms ease-out',

      width: `calc(100% - ${drawerWidth}px)`,
    },
  }));

  const classes = useStyles();

  const { data: currentUser } = useQueryUserData(true);

  const companyInfo = currentUser?.companyInfo ?? {};
  const userInfo = currentUser?.userSettings ?? {};
  const userPermissions = currentUser?.userPermissions ?? [];
  const featureFlags =
    currentUser?.companyAccountInfo?.data?.featureFlags ?? {};
  const userName = [userInfo?.firstName, userInfo?.lastName]
    .filter(Boolean)
    .join(' ');

  const [anchorElement, setAnchorElement] = useState(null);
  const isMenuOpen = Boolean(anchorElement);

  const auth = useAuth();

  const history = useHistory();

  const menu = useSelector((state) => state.menu);

  const logout = () => {
    Log.productAnalyticsEvent('Logout', Log.FEATURE.AUTHENTICATION);

    // Clear all local storage items.
    localStorage.clear();

    auth.signOutRedirect({
      post_logout_redirect_uri: Config.redirectUrl,
    });
  };

  const { data: companyLogo } = useQueryCompanyLogo(companyInfo?.id);

  const handleMenuClose = (event) => {
    Log.productAnalyticsEvent('Close burger menu', Log.FEATURE.MENU);
    setAnchorElement(null);
  };

  const handleMenuOpen = (event) => {
    Log.productAnalyticsEvent('Open burger menu', Log.FEATURE.MENU);
    setAnchorElement(event.currentTarget);
  };

  const handleNavigateBack = () => {
    Log.productAnalyticsEvent('Navigate back', Log.FEATURE.MENU);
    history.goBack();
  };

  const handleClickDeliveryDataQuality = () => {
    Log.productAnalyticsEvent(
      'Open delivery note data quality',
      Log.FEATURE.DELIVERY_NOTE,
    );
    history.push(
      ROUTE.DELIVERY_NOTE_DATA_QUALITY.ROUTE +
        '/' +
        RouteUtils.getUuid(history.location.pathname),
    );
  };

  const menuId = 'account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorElement}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      id={menuId}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      MenuListProps={{
        sx: { padding: 0 },
      }}
      keepMounted
    >
      <div className="max-w-48">
        <div className="flex flex-col items-center gap-4 p-4 text-center">
          {isSuccessProfilePicture && profilePicture ? (
            <img src={profilePicture} className="size-16 rounded-full" />
          ) : (
            <AccountCircleIcon fontSize="large" className="my-4 size-16" />
          )}
          <div>
            {userName ? <div className="font-bold">{userName}</div> : null}
            {userInfo?.position ? <div>{userInfo?.position}</div> : null}
            {companyInfo?.name ? <div>{companyInfo?.name}</div> : null}
          </div>
        </div>
        <div className="border-b border-gray-300" />
      </div>
      {UserService.userIsAuthorizedForPage(
        ROUTE.DASHBOARD.ROUTE,
        userPermissions,
        featureFlags,
      ) ? (
        <MenuItem
          onClick={handleMenuClose}
          style={{ paddingBottom: 11, paddingTop: 11 }}
          component={Link}
          to="/dashboard"
        >
          Statistiken
        </MenuItem>
      ) : null}
      {/* workaround time: MenuItem with link to external site didn't work. Hence this beautiful solution of an a tag, styled the same as a MenuItem */}
      {userInfo?.userHasLoggedInViaSSO ? null : (
        <MenuItem>
          <a
            className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters"
            target="_blank"
            rel="noopener noreferrer"
            href="https://login.vestigas.com/auth/realms/vestigas/account/password"
          >
            Passwort ändern
          </a>
        </MenuItem>
      )}
      <MenuItem
        onClick={handleMenuClose}
        className="py-2"
        component={Link}
        to={ROUTE.SETTINGS.ROUTE}
      >
        Benutzerdaten ändern
      </MenuItem>
      <MenuItem>
        <a
          className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters"
          target="_blank"
          rel="noopener noreferrer"
          href="https://vestigas.notion.site/Herzlich-Willkommen-in-einer-Welt-ohne-Lieferscheine-c4db904d5ff4405fa1feb1f5cf8bd0c2"
        >
          Zur Schulung
        </a>
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        className="py-2"
        component={Link}
        to={ROUTE.SETTINGS.ROUTE}
      >
        Einstellungen/Admin
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        className="py-2"
        component={Link}
        to={ROUTE.SETTINGS_IMPRESSUM.ROUTE}
      >
        Impressum
      </MenuItem>
      <MenuItem
        className="border-t border-gray-300 pt-2"
        style={{ borderTop: '1px solid rgb(209, 213, 219)' }}
        onClick={logout}
      >
        Abmelden
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton onClick={handleNavigateBack} size="large">
          <ArrowBackIcon />
        </IconButton>
        <div className="flex flex-1 items-center gap-4 pl-4">
          <span
            data-testid={ComponentTestIds.TOPBAR.PAGETITLE}
            className="truncate text-3xl"
          >
            {menu.pageTitle}
          </span>
          {RouteUtils.getBaseUrl(history.location.pathname) ===
            ROUTE.DELIVERY_NOTE.ROUTE &&
          ConfigUtils.isDevelopmentStagingEnvironment() ? (
            <div>
              <LightTooltip title="Öffne die Datenansicht der Lieferung und überprüfe, ob die Informationen der Lieferung der gewünschten Datenqualität entsprechen.">
                <Button
                  variant="outlined"
                  startIcon={<FactCheckIcon />}
                  component="span"
                  onClick={handleClickDeliveryDataQuality}
                >
                  Datenqualität prüfen
                </Button>
              </LightTooltip>
            </div>
          ) : null}
        </div>
        <div className="flex items-center gap-4">
          <SitesCostCentersSelection />
          <div className="h-12 w-px bg-gray-300" />
          {ConfigUtils.isProductionEnvironment() ? null : (
            <>
              <div className="text-sm font-bold text-green-600">
                {environmentInfo}
              </div>
              <div className="h-12 w-px bg-gray-300" />
            </>
          )}
          {companyLogo && BrowserUtils.isWideScreen() ? (
            <>
              <img className="h-12" src={companyLogo} />
              <div className="h-12 w-px bg-gray-300" />
            </>
          ) : null}
          <Button
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleMenuOpen}
            color="inherit"
            startIcon={<AccountCircleIcon fontSize="large" />}
            endIcon={<KeyboardArrowDownIcon fontSize="large" />}
          >
            {userName ?? 'Menü'}
          </Button>
          {renderMenu}
        </div>
      </Toolbar>
    </AppBar>
  );
});

TopBar.displayName = 'TopBar';
