import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { queryKeysDeliveryNote } from './queryKeys';
import { type DeliveryNoteSearchParams } from './types';

type DeliveryNoteSearchCountResponse = {
  totalAssets: number;
};

/**
 * Fetches the count of delivery notes based on search parameters from the API.
 *
 * @param {DeliveryNoteSearchParams} searchParams - The search parameters for filtering delivery notes
 * @returns {Promise<number>} The total count of matching delivery notes
 */
export const fetchDeliveryNoteSearchCount = async (
  searchParams: DeliveryNoteSearchParams,
): Promise<number> => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.DELIVERY_NOTE.GET_SEARCH_COUNT(), {
        json: searchParams,
      })
      .json<DeliveryNoteSearchCountResponse>();

    return response.totalAssets;
  } catch (error) {
    console.error('Error fetching delivery note search count:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the count of delivery notes matching the search criteria.
 *
 * @param {DeliveryNoteSearchParams} searchParams - The search parameters for filtering delivery notes
 * @param {Object} options - Additional options for the useQuery hook
 * @returns {UseQueryResult<number>} The result of the useQuery hook containing the count
 */
export const useQueryDeliveryNoteSearchCount = (
  searchParams: DeliveryNoteSearchParams,
  options?: Omit<UseQueryOptions<number, Error>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery({
    queryFn: async () => fetchDeliveryNoteSearchCount(searchParams),
    queryKey: queryKeysDeliveryNote.getSearchCount(searchParams),
    ...options,
  });
};
