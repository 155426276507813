import { useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import ToastService from '~/services/toast.service';

import { MissingPermissionsTooltip } from '~/utils/componentUtils';
import ImageUtils from '~/utils/imageUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import BasicForm from './BasicForm';

const MAX_IMAGE_HEIGHT_AND_WIDTH = 800;

export const ImageUpload = ({
  image,
  missingPermissionsToWrite,
  onDelete,
  profilePicture,
  setImage,
  uploadText,
  withCrop,
}) => {
  // Form related state
  const [formOpen, setFormOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [controlledInputValue, setControlledInputValue] = useState('');

  // Image related state
  const [selectedImage, setSelectedImage] = useState(null);
  const [crop, setCrop] = useState(null);

  // UI state
  const [hovered, setHovered] = useState(false);

  const handleInputChange = (event) => {
    Log.info('Upload image', null, Log.BREADCRUMB.USER_ACTION.KEY);
    Log.productAnalyticsEvent('Upload image', Log.FEATURE.IMAGE_UPLOAD);

    const imageFile = event.target.files[0];

    ImageUtils.getImageResolution(imageFile, (imageResolution) => {
      const heightFactor = imageResolution.height / MAX_IMAGE_HEIGHT_AND_WIDTH;
      const widthFactor = imageResolution.width / MAX_IMAGE_HEIGHT_AND_WIDTH;

      let reductionPercentage =
        heightFactor > widthFactor ? 100 / heightFactor : 100 / widthFactor;
      if (reductionPercentage > 100) {
        reductionPercentage = 100;
      }

      ImageUtils.reduceImageResolution(
        imageFile,
        reductionPercentage,
        (reducedImage) => {
          if (withCrop) {
            setFormOpen(true);
            setSelectedImage(reducedImage);
          } else {
            setImage(reducedImage);
          }
        },
      );
    });
  };

  const handleMouseEnter = () => setHovered(true);

  const handleMouseLeave = () => setHovered(false);

  const closeForm = () => {
    setFormOpen(false);
    setControlledInputValue('');
    setCrop(null);
    setSelectedImage(null);
  };

  const formSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setSubmittingForm(true);

    Log.info('Crop profile image', crop, Log.BREADCRUMB.USER_ACTION.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.IMAGE_UPLOAD);

    const [croppedImage, error] = await promiseHandler(
      ImageUtils.getCroppedImg(selectedImage, crop, 'image.jpg'),
    );

    if (error) {
      ToastService.error(['Bild konnte nicht gespeichert werden.']);

      Log.error('Failed to crop image.', error);
      Log.productAnalyticsEvent(
        'Failed to submit form',
        Log.FEATURE.IMAGE_UPLOAD,
        Log.TYPE.ERROR,
      );

      setSubmittingForm(false);
      closeForm();
      return;
    }

    setSubmittingForm(false);
    setImage(croppedImage);
    closeForm();
  };

  const formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.IMAGE_UPLOAD);
    closeForm();
  };

  const handleCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const setDefaultCrop = (event) => {
    if (crop !== null) {
      return;
    }

    const { naturalHeight: height, naturalWidth: width } = event.currentTarget;

    setCrop(
      centerCrop(
        makeAspectCrop({ unit: 'px', width: width * 0.9 }, 1, width, height),
        width,
        height,
      ),
    );
  };

  const getAddButton = () => {
    if (missingPermissionsToWrite) {
      return (
        <MissingPermissionsTooltip>
          <IconButton component="span" disabled size="large">
            <AddIcon />
          </IconButton>
        </MissingPermissionsTooltip>
      );
    }

    return (
      <label htmlFor="input-image-upload">
        <input
          type="file"
          accept="image/*,video/*,.jpg,.jpeg,.png"
          className="hidden"
          id="input-image-upload"
          value={controlledInputValue}
          onChange={handleInputChange}
        />
        <IconButton component="span" size="large">
          <AddIcon />
        </IconButton>
      </label>
    );
  };

  const getDeleteButton = () => {
    if (missingPermissionsToWrite) {
      return (
        <MissingPermissionsTooltip>
          <IconButton component="span" disabled size="large">
            <DeleteIcon />
          </IconButton>
        </MissingPermissionsTooltip>
      );
    }

    return (
      <IconButton component="span" onClick={onDelete} size="large">
        <DeleteIcon />
      </IconButton>
    );
  };

  const displayImage = image?.size > 0;

  return (
    <>
      <div
        className={
          'upload-image-container relative flex items-center justify-center ' +
          (profilePicture ? 'rounded-full' : 'rounded-lg border')
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {displayImage ? (
          <img
            className={
              'upload-image-size ' + (profilePicture ? 'rounded-full' : '')
            }
            src={URL.createObjectURL(image)}
          />
        ) : (
          <div className="main-text flex h-24 items-center justify-center p-1 text-center">
            {uploadText}
          </div>
        )}
        <div
          className={
            'transparent-overlay absolute ' +
            (profilePicture ? 'h-full w-full' : 'upload-image-overlay') +
            (!image || hovered ? 'flex items-center justify-center' : 'hidden')
          }
        >
          {getAddButton()}
          {getDeleteButton()}
        </div>
      </div>
      <BasicForm
        title="Bild zuschneiden"
        open={formOpen}
        formAbort={formAbort}
        formSuccess={formSuccess}
        submittingForm={submittingForm}
      >
        {selectedImage?.size > 0 ? (
          <ReactCrop crop={crop} onChange={handleCropChange} aspect={1}>
            <img
              src={URL.createObjectURL(selectedImage)}
              onLoad={setDefaultCrop}
            />
          </ReactCrop>
        ) : null}
      </BasicForm>
    </>
  );
};
