import { useCallback, useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {
  Archive as ArchiveIcon,
  ArrowForward as ArrowForwardIcon,
  Check as CheckIcon,
  Create as CreateIcon,
  CreateNewFolder as CreateNewFolderIcon,
  DoDisturbAlt as DoDisturbAltIcon,
  DoneAll as DoneAllIcon,
  Share as ShareIcon,
} from '@mui/icons-material';

import { DeclinedIconLight, DeclinedIconLightGrey } from '~/assets/icons';

import { type LoadingStateValues } from '~/constants/types';

import FeatureService from '~/services/feature.service';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import DeliveryNoteAction from '~/models/deliveries/DeliveryNoteAction';

import DatagridUtils from '~/utils/datagridUtils';
import { dateUtils, parseDate } from '~/utils/dateUtils';
import Log from '~/utils/Log';

import { withErrorBoundary } from '~/ui/atoms';

import { type SortModel } from '../deliveryOverview/DeliveryList/types';

import DeliveryNoteHistoryDetailPanel from './DeliveryNoteHistoryDetailPanel';
import { type DeliveryNoteHistoryRowData, type AcceptState } from './types';

export type DeliveryNoteHistoryProps = {
  currentAcceptState: AcceptState;
  deliveryNoteActions: Array<Record<string, any>>;
  loading: LoadingStateValues;
  showChanges?: boolean;
};

const DeliveryNoteHistory = ({
  currentAcceptStateCarrier,
  currentAcceptStateOnBehalfCarrier,
  currentAcceptStateOnBehalfRecipient,
  currentAcceptStateOnBehalfSupplier,
  currentAcceptStateRecipient,
  currentAcceptStateSupplier,
  deliveryNoteActions,
  showChanges,
}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [sortModel, setSortModel] = useState([
    {
      field: 'datetime',
      sort: 'desc',
    },
  ]);

  useEffect(() => {
    loadRows();
  }, [deliveryNoteActions]);

  const loadRows = () => {
    const rows = [];
    const expandedRows = [];

    if (deliveryNoteActions)
      for (const [index, deliveryNoteAction] of deliveryNoteActions.entries()) {
        rows.push({
          acceptState: deliveryNoteAction.acceptState,
          action: deliveryNoteAction.action,
          approvedArticles: deliveryNoteAction.approvedArticles,
          attachments: deliveryNoteAction.attachments,
          changes: deliveryNoteAction.changes,
          company: deliveryNoteAction.company?.name,
          datetime: deliveryNoteAction.datetime,
          declinedArticles: deliveryNoteAction.declinedArticles,
          icon: deliveryNoteAction.icon,
          id: index,
          signerCompanyName: deliveryNoteAction.signerCompany?.name,
          signerTradeContactPersonName:
            deliveryNoteAction.signerTradeContact?.personName,
          user: deliveryNoteAction.user?.getNameAndRole()
            ? deliveryNoteAction.user?.getNameAndRole()
            : '-',
        });

        if (
          (deliveryNoteAction.action ===
            DeliveryNoteAction.ACTION.DECLINED_SUPPLIER.STRING &&
            currentAcceptStateSupplier !==
              AcceptStateCalculator.ACCEPT_STATE.APPROVED) ||
          (deliveryNoteAction.action ===
            DeliveryNoteAction.ACTION.DECLINED_CARRIER.STRING &&
            currentAcceptStateCarrier !==
              AcceptStateCalculator.ACCEPT_STATE.APPROVED) ||
          (deliveryNoteAction.action ===
            DeliveryNoteAction.ACTION.DECLINED_RECIPIENT.STRING &&
            currentAcceptStateRecipient !==
              AcceptStateCalculator.ACCEPT_STATE.APPROVED) ||
          (deliveryNoteAction.action ===
            DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_SUPPLIER.STRING &&
            currentAcceptStateOnBehalfSupplier !==
              AcceptStateCalculator.ACCEPT_STATE.APPROVED) ||
          (deliveryNoteAction.action ===
            DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_CARRIER.STRING &&
            currentAcceptStateOnBehalfCarrier !==
              AcceptStateCalculator.ACCEPT_STATE.APPROVED) ||
          (deliveryNoteAction.action ===
            DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_RECIPIENT.STRING &&
            currentAcceptStateOnBehalfRecipient !==
              AcceptStateCalculator.ACCEPT_STATE.APPROVED)
        ) {
          expandedRows.push(index);
        } else if (
          showChanges &&
          deliveryNoteAction.changes.length > 0 &&
          !expandedRows.includes(deliveryNoteAction.id)
        ) {
          expandedRows.push(index);
        }
      }

    setExpandedRows(expandedRows);
    setRows(rows);
  };

  const onSortModelChange = (event: SortModel) => {
    setSortModel(event);
  };

  const onRowClick = (rowData: DeliveryNoteHistoryRowData) => {
    Log.productAnalyticsEvent('Open history entry', Log.FEATURE.DELIVERY_NOTE);

    let newExpandedRows = expandedRows;

    if (expandedRows.includes(rowData.id)) {
      newExpandedRows = newExpandedRows.filter((id) => id !== rowData.id);
    } else {
      newExpandedRows.push(rowData.id);
    }

    setExpandedRows(newExpandedRows);
  };

  const getColumns = () => {
    const columns = [
      {
        field: DeliveryNoteAction.PROPERTY.ICON.KEY,
        headerName: DeliveryNoteAction.PROPERTY.ICON.STRING,
        renderCell: (params) => (
          <div className="flex h-full items-center justify-center">
            {getIcon(params.value)}
          </div>
        ),
        sortable: true,
        width: 60,
      },
      {
        field: DeliveryNoteAction.PROPERTY.DATETIME.KEY,
        headerName: DeliveryNoteAction.PROPERTY.DATETIME.STRING,
        renderCell: (params) =>
          dateUtils.getFormattedDate_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
          ),
        sortable: true,
        type: 'date',
        valueGetter: parseDate,
        width: 180,
      },
      {
        field: DeliveryNoteAction.PROPERTY.ACTION.KEY,
        headerName: DeliveryNoteAction.PROPERTY.ACTION.STRING,
        sortable: true,
        width: 430,
      },
    ];

    if (FeatureService.showUserInDlnHistory()) {
      columns.push({
        field: DeliveryNoteAction.PROPERTY.USER.KEY,
        headerName: DeliveryNoteAction.PROPERTY.USER.STRING,
        renderCell: DatagridUtils.displayCellTooltip,
        sortable: true,
        width: 270,
      });
    }

    columns.push({
      field: DeliveryNoteAction.PROPERTY.COMPANY.KEY,
      headerName: DeliveryNoteAction.PROPERTY.COMPANY.STRING,
      renderCell: DatagridUtils.displayCellTooltip,
      sortable: true,
      width: 400,
    });

    return columns;
  };

  const getIcon = (icon: string) => {
    switch (icon) {
      case DeliveryNoteAction.ACTION.CREATED.ICON: {
        return <CreateNewFolderIcon />;
      }

      case DeliveryNoteAction.ACTION.EDITED.ICON: {
        return <CreateIcon />;
      }

      case DeliveryNoteAction.ACTION.CONFIRMED_SUPPLIER.ICON:
      case DeliveryNoteAction.ACTION.CONFIRMED_CARRIER.ICON: {
        return <CheckIcon />;
      }

      case DeliveryNoteAction.ACTION.CONFIRMED_RECIPIENT.ICON: {
        return <DoneAllIcon />;
      }

      case DeliveryNoteAction.ACTION.DECLINED_SUPPLIER.ICON:
      case DeliveryNoteAction.ACTION.DECLINED_CARRIER.ICON:
      case DeliveryNoteAction.ACTION.DECLINED_RECIPIENT.ICON:
      case DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_SUPPLIER.ICON:
      case DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_CARRIER.ICON:
      case DeliveryNoteAction.ACTION.DECLINED_ON_BEHALF_RECIPIENT.ICON: {
        return currentAcceptStateSupplier ===
          AcceptStateCalculator.ACCEPT_STATE.APPROVED ? (
          <DeclinedIconLightGrey className="h-4" />
        ) : (
          <DeclinedIconLight className="h-4" />
        );
      }

      case DeliveryNoteAction.ACTION.ARRIVED.ICON: {
        return <ArrowForwardIcon />;
      }

      case DeliveryNoteAction.ACTION.CANCELLED.ICON: {
        return <DoDisturbAltIcon />;
      }

      case DeliveryNoteAction.ACTION.ARCHIVED.ICON:
      case DeliveryNoteAction.ACTION.UNARCHIVED.ICON: {
        return <ArchiveIcon />;
      }

      case DeliveryNoteAction.ACTION.SHARED.ICON: {
        return <ShareIcon />;
      }

      default: {
        return null;
      }
    }
  };

  const getDetailPanelContent = useCallback(
    (params) => <DeliveryNoteHistoryDetailPanel {...params} />,
    [],
  );

  return (
    <div>
      <DataGridPro
        className="border-none"
        columns={getColumns()}
        rows={rows}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        components={{
          DetailPanel: DeliveryNoteHistoryDetailPanel,
        }}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
        expandedRowIds={expandedRows}
        onRowClick={onRowClick}
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default withErrorBoundary(DeliveryNoteHistory);
