import React from 'react';

export const InvoiceMetaDataItem = ({ category, value }) => {
  return (
    <>
      <div>{category}</div>
      <div className="font-bold">{value}</div>
    </>
  );
};
