import cloneDeep from 'lodash/cloneDeep';

import { getOptimallyDistinctColor } from '~/utils/color';
import UnitUtils from '~/utils/unitUtils';

export const assignColorsToChartData = (chartData) => {
  const newChartData = cloneDeep(chartData);

  const articleColorMapping = [];
  const totalColors = newChartData.labels.length;

  for (const [index, article] of newChartData.labels.entries()) {
    const color = getOptimallyDistinctColor(index, totalColors);

    articleColorMapping.push({
      article,
      color,
    });

    newChartData.datasets[0].backgroundColor[index] = color;
  }

  return newChartData;
};

export const getLegendData = (chartData, maxLegendItems = 6) => {
  const legendData = [];

  if (!chartData) {
    return legendData;
  }

  for (let index = 0; index < maxLegendItems; index++) {
    if (!chartData.labels[index]) {
      break;
    }

    legendData.push({
      amount:
        UnitUtils.roundAndFormatDe_safe(chartData.datasets[0].data[index]) ??
        '',
      color: chartData.datasets[0].backgroundColor[index],
      name: chartData.labels[index],
    });
  }

  return legendData;
};
