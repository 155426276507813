import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Check as CheckIcon } from '@mui/icons-material';
import { Chip, Stack } from '@mui/material';

type P = {
  onClick: (column: string) => void;
  onReorderColumns: (sourceIndex: number, destinationIndex: number) => void;
  selectableColumns: Array<{ headerName: string }>;
  selectedColumns: string[];
};

export const DashboardReportChips = ({
  onClick,
  onReorderColumns,
  selectableColumns,
  selectedColumns,
}: P) => {
  // Helper function to check if a column is currently selected
  const isColumnSelected = (column) => selectedColumns.includes(column);

  // Handle the end of drag-and-drop operations
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    onReorderColumns(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="columns" direction="horizontal">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="flex overflow-x-auto py-4"
          >
            <Stack direction="row" spacing={1}>
              {selectableColumns.map(({ headerName }, index) => (
                <Draggable
                  key={headerName}
                  draggableId={headerName}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Chip
                        label={headerName}
                        onClick={() => {
                          onClick(headerName);
                        }}
                        icon={
                          isColumnSelected(headerName) ? (
                            <CheckIcon className="text-white" />
                          ) : null
                        }
                        className={
                          isColumnSelected(headerName)
                            ? 'bg-primary500 text-white'
                            : null
                        }
                        style={
                          isColumnSelected(headerName)
                            ? { cursor: 'grab' }
                            : null
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </Stack>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
