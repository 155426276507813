import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';

/**
 * Saves a new filter group and adds it to the existing filter groups.
 *
 * @param {Object} filterGroup - The new filter group to save.
 * @param {array} filterGroups - The existing filter groups.
 * @param {function} setDelivery_filterGroupOpen - Callback function to set the filter group open state.
 * @param {function} setDelivery_filterGroups - Callback function to set the filter groups.
 * @param {function} setDelivery_selectedFilterGroup - Callback function to set the selected filter group.
 * @param {function} handleChangeFilterGroup - Callback function to handle the filter group change.
 * @return {void}
 */
export const saveNewFilterGroup = async (
  dispatch,
  filterGroup,
  filterGroups,
  setDelivery_filterGroupOpen,
  setDelivery_filterGroups,
  setDelivery_selectedFilterGroup,
  handleChangeFilterGroup,
) => {
  const newFilterGroups = [...filterGroups, filterGroup];

  const [, error] = await promiseHandler(
    UserService.updateDeliveryFilterGroups(newFilterGroups),
  );

  if (error) {
    ToastService.error(['Filter konnte nicht hinzugefügt werden.']);

    Log.error('Failed to save new filter group.', error);

    throw error; // Re-throw error to be caught by calling function
  }

  dispatch(setDelivery_filterGroups(newFilterGroups));
  dispatch(setDelivery_selectedFilterGroup(filterGroup.id));
  dispatch(setDelivery_filterGroupOpen(true));

  handleChangeFilterGroup(filterGroup);
};
