import { memo } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { useGetFilterConfig } from '~/data/deliveryNote';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import DeliveryNote from '~/models/deliveries/DeliveryNote';

import { FreeTextSearch } from '~/components/baseComponents/inputs/FreeTextSearch';
import { Select } from '~/components/baseComponents/inputs/select/Select';

import {
  getFilterContext,
  isCustomFieldFilter,
  type FilterContext,
} from '~/utils/filters';
import { toCamelCase } from '~/utils/string';

import {
  MultiSelectCostCenters,
  MultiSelectFilterValues,
  MultiSelectSites,
} from '~/ui/molecules/SelectServerDriven';

type Filter = {
  allOptions: string[];
  disabled: boolean;
  filteredOptions: string[];
  id: string;
  name: string;
};

type P = {
  filterContext: FilterContext;
  hideDeleteButton: boolean;
  isDisabled: boolean;
  onChangeProperty: (event) => void;
  onChangeValue: (event) => void;
  onDeleteRow: () => void;
  selectableFilters: Filter[];
  selectedFilter: Filter;
  selectedValues: string[] | boolean;
  testId: string;
};

export const FilterRow = memo(
  ({
    filterContext = getFilterContext(),
    hideDeleteButton,
    isDisabled,
    onChangeProperty,
    onChangeValue,
    onDeleteRow,
    selectableFilters,
    selectedFilter,
    selectedValues,
    testId,
  }: P) => {
    const filterConfig = useGetFilterConfig({ filterContext });

    const filterName = selectedFilter?.id?.replace(/^selected/, '');
    const normalizedFilterName = toCamelCase(filterName) ?? '';

    const InputComponent = () => {
      if (
        ['acceptState', 'processState', 'settledStatus'].includes(
          normalizedFilterName,
        )
      ) {
        let options = [];
        switch (normalizedFilterName) {
          case 'acceptState': {
            options = AcceptStateCalculator.getAcceptStates();
            break;
          }

          case 'processState': {
            options = DeliveryNote.getProcessStateOptions();
            break;
          }

          case 'settledStatus': {
            options = ['Ja', 'Nein'];
            break;
          }
        }

        return (
          <ToggleButtonGroup
            value={selectedValues}
            onChange={(event, value) => {
              onChangeValue(event, value);
            }}
            className="bg-white"
            size="small"
          >
            {options.map((option) => (
              <ToggleButton
                key={option}
                value={option}
                className="min-w-16 px-4"
              >
                {option}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        );
      }

      if (normalizedFilterName === 'permittedToSites') {
        return (
          <MultiSelectSites
            key={filterName}
            data-testid={`${testId}_filter_operator_value_select`}
            className="min-w-96"
            placeholder="Lieferorte auswählen"
            value={selectedValues}
            onChange={(values) => {
              onChangeValue(
                {},
                values.map(({ id }) => id),
              );
            }}
          />
        );
      }

      if (normalizedFilterName === 'permittedCostCenters') {
        return (
          <MultiSelectCostCenters
            key={filterName}
            data-testid={`${testId}_filter_operator_value_select`}
            className="min-w-96"
            placeholder="Kostenstellen auswählen"
            value={selectedValues}
            onChange={(values) => {
              onChangeValue(
                {},
                values.map(({ id }) => id),
              );
            }}
          />
        );
      }

      if (isCustomFieldFilter(filterName)) {
        return (
          <FreeTextSearch
            value={selectedValues.join('; ') ?? ''}
            onChange={(value) => {
              onChangeValue(
                {},
                value.split(';').map((v) => v.trim()),
              );
            }}
            debounceTime={800}
            placeholder={'Werte eingeben, getrennt durch Strichpunkte'}
            testId={`${testId}_filter_operator_value_select`}
            startAdornment={null}
            className="w-96"
          />
        );
      }

      return (
        <MultiSelectFilterValues
          key={filterName}
          className="min-w-96"
          data-testid={`${testId}_filter_operator_value_select`}
          filterConfig={filterConfig}
          filterName={filterName}
          isDisabled={isDisabled}
          onChange={(values) => {
            onChangeValue({}, values);
          }}
          placeholder="Wert auswählen"
          value={selectedValues}
        />
      );
    };

    return (
      <div className="flex gap-2" data-testid={testId}>
        <div className="min-w-80">
          <Select
            data-testid={`${testId}_operator_select`}
            value={selectedFilter.id}
            onChange={onChangeProperty}
            options={selectableFilters}
            className="bg-white"
            size="small"
            fullWidth
          />
        </div>
        <InputComponent />
        {hideDeleteButton ? null : (
          <IconButton
            size="small"
            onClick={onDeleteRow}
            data-testid={`${testId}_delete_button`}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    );
  },
);

FilterRow.displayName = 'FilterRow';
