import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import { ROUTE } from '~/constants/Route';

import { useQueryUserData, type UserData } from '~/data/user';

import {
  setHome_selectedPredefinedDateRange,
  setHome_selectedDateRange,
  setHome_individualDateRange,
} from '~/redux/filtersSlice';
import { setPageTitle } from '~/redux/menuSlice';

import AuthService from '~/services/auth.service';
import FeatureService from '~/services/feature.service';
import LocalStorageService from '~/services/localStorage.service';
import UserService from '~/services/user.service';

import { dateUtils } from '~/utils/dateUtils';
import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

type DeliveryNotesState = {
  filteredDeliveryNotes: any[];
  filteredDeliveryNotesVersion: number;
};

type HomeState = {
  archiveDeliveryNotesCountAppliedFilters: number;
};

type FiltersState = {
  home_individualDateRange: boolean;
  home_selectedDateRange: [Date, Date];
  home_selectedPredefinedDateRange: string;
  oldestFilteredDlnDate: string;
  selectedCostCenters: string[];
  selectedSites: string[];
};

type InvoicesState = {
  incomingInvoices: any[];
  outgoingInvoices: any[];
};

type RootState = {
  deliveryNotes: DeliveryNotesState;
  filters: FiltersState;
  home: HomeState;
  invoices: InvoicesState;
};

type DisplayTile = {
  dashboard: boolean;
  deliveries: boolean;
  incomingInvoices: boolean;
  invoiceSignatureIssues: boolean;
  outgoingInvoices: boolean;
  sales: boolean;
};

type UseHomeReturn = {
  currentUser: UserData;
  displayTile: DisplayTile;
  filters: {
    individualDateRange: boolean;
    selectedDateRange: [Date, Date];
    selectedPredefinedDateRange: string;
  };
  handleDateRangeChange: (value: [Date, Date]) => void;
  handlePredefinedDateRangeChange: (value: string) => void;
  isLoadingUserData: boolean;
  timeframe: {
    from: Date;
    to: Date;
  };
};

// Base selectors
const getDeliveryNotes = (state: RootState) => state.deliveryNotes;
const getFilters = (state: RootState) => state.filters;
const getHome = (state: RootState) => state.home;
const getInvoices = (state: RootState) => state.invoices;

const selectDeliveryNotesData = createSelector(
  [getDeliveryNotes],
  (deliveryNotes) => ({
    filteredDeliveryNotes: deliveryNotes.filteredDeliveryNotes,
    filteredDeliveryNotesVersion: deliveryNotes.filteredDeliveryNotesVersion,
  }),
);

const selectHomeArchiveData = createSelector([getHome], (home) => ({
  archiveDeliveryNotesCountAppliedFilters:
    home.archiveDeliveryNotesCountAppliedFilters,
}));

const selectFilterData = createSelector([getFilters], (filters) => ({
  home_individualDateRange: filters.home_individualDateRange,
  oldestFilteredDlnDate: filters.oldestFilteredDlnDate,
  selectedCostCenters: filters.selectedCostCenters,
  selectedDateRange: filters.home_selectedDateRange,
  selectedPredefinedDateRange: filters.home_selectedPredefinedDateRange,
  selectedSites: filters.selectedSites,
}));

const selectInvoicesData = createSelector([getInvoices], (invoices) => ({
  incomingInvoices: invoices.incomingInvoices,
  outgoingInvoices: invoices.outgoingInvoices,
}));

export const useHome = (): UseHomeReturn => {
  const [filterId, setFilterId] = useState<number>(0);
  const dispatch = useDispatch();

  const { filteredDeliveryNotes, filteredDeliveryNotesVersion } = useSelector(
    selectDeliveryNotesData,
  );
  const { archiveDeliveryNotesCountAppliedFilters } = useSelector(
    selectHomeArchiveData,
  );
  const {
    home_individualDateRange: individualDateRange,
    oldestFilteredDlnDate,
    selectedCostCenters,
    selectedDateRange,
    selectedPredefinedDateRange,
    selectedSites,
  } = useSelector(selectFilterData);
  const { incomingInvoices, outgoingInvoices } =
    useSelector(selectInvoicesData);

  const userId = AuthService.getUserIdFromAccessToken();
  const { data: currentUser, isLoading: isLoadingUserData } = useQueryUserData(
    true,
    {
      select: (data) => ({
        companyInfo: data.company ?? {},
        userPermissions: data.totalPermissions ?? [],
        userSettings: data.settings ?? {},
        ...data,
      }),
    },
  );

  const userPermissions = currentUser?.userPermissions ?? [];
  const featureFlags =
    currentUser?.companyAccountInfo?.data?.featureFlags ?? {};

  const displayTile: DisplayTile = {
    dashboard: UserService.userIsAuthorizedForPage(
      ROUTE.DASHBOARD.ROUTE,
      userPermissions,
      featureFlags,
    ),
    deliveries: UserService.userIsAuthorizedForPage(
      ROUTE.DELIVERIES.ROUTE,
      userPermissions,
      featureFlags,
    ),
    incomingInvoices: checkIncomingInvoicesPermission(
      userPermissions,
      featureFlags,
      incomingInvoices,
    ),
    invoiceSignatureIssues: UserUtils.isDelayedSignatureHomeScreenAllowedUser(
      userId,
      ROUTE.DELIVERIES.ROUTE,
    ),
    outgoingInvoices: checkOutgoingInvoicesPermission(
      userPermissions,
      featureFlags,
      outgoingInvoices,
    ),
    sales: FeatureService.clientPortal(),
  };

  useEffect(() => {
    setInvoiceTileCookies();

    dispatch(setPageTitle('Home'));
    document.title = 'VESTIGAS - Home';
  }, []);

  const setInvoiceTileCookies = () => {
    if (incomingInvoices.length > 0) {
      LocalStorageService.setLocalStorage(
        LocalStorageService.DISPLAY_HOME_INCOMING_INVOICE_TILE,
        true,
      );
    }

    if (outgoingInvoices.length > 0) {
      LocalStorageService.setLocalStorage(
        LocalStorageService.DISPLAY_HOME_OUTGOING_INVOICE_TILE,
        true,
      );
    }
  };

  const handleDateRangeChange = (value: [Date, Date]) => {
    const numberOfDays = dateUtils.getNumberOfDays(value[0], value[1]);
    if (!(numberOfDays >= 0 && numberOfDays <= 3650)) {
      return;
    }

    Log.info(
      'Change filter value of selected date range',
      {
        from: selectedDateRange,
        to: value,
      },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter date range', Log.FEATURE.HOME);

    dispatch(setHome_selectedDateRange(value));
    dispatch(setHome_individualDateRange(true));
  };

  const handlePredefinedDateRangeChange = (value: string) => {
    Log.info(
      'Change filter value of selected predefined date range',
      {
        from: selectedPredefinedDateRange,
        to: value,
      },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      `Filter predefined date range: ${value}`,
      Log.FEATURE.HOME,
    );

    dispatch(setHome_selectedPredefinedDateRange(value));
    dispatch(
      setHome_selectedDateRange(dateUtils.getTimeframeFromDateRange(value)),
    );
    dispatch(setHome_individualDateRange(false));
  };

  const timeframe = dateUtils.extractTimeframe(selectedDateRange);

  return {
    currentUser,
    displayTile,
    filters: {
      individualDateRange,
      selectedDateRange,
      selectedPredefinedDateRange,
    },
    handleDateRangeChange,
    handlePredefinedDateRangeChange,
    isLoadingUserData,
    timeframe,
  };
};

function checkIncomingInvoicesPermission(
  userPermissions: string[],
  featureFlags: Record<string, boolean>,
  incomingInvoices: any[],
): boolean {
  return (
    UserService.userIsAuthorizedForPage(
      ROUTE.INCOMING_INVOICES.ROUTE,
      userPermissions,
      featureFlags,
    ) &&
    (incomingInvoices.length > 0 ||
      LocalStorageService.getLocalStorage(
        LocalStorageService.DISPLAY_HOME_INCOMING_INVOICE_TILE,
      )) &&
    !FeatureService.clientPortal() &&
    !FeatureService.moduleInvoiceRestriction()
  );
}

function checkOutgoingInvoicesPermission(
  userPermissions: string[],
  featureFlags: Record<string, boolean>,
  outgoingInvoices: any[],
): boolean {
  return (
    UserService.userIsAuthorizedForPage(
      ROUTE.OUTGOING_INVOICES.ROUTE,
      userPermissions,
      featureFlags,
    ) &&
    (outgoingInvoices.length > 0 ||
      LocalStorageService.getLocalStorage(
        LocalStorageService.DISPLAY_HOME_OUTGOING_INVOICE_TILE,
      )) &&
    !FeatureService.clientPortal() &&
    !FeatureService.moduleInvoiceRestriction()
  );
}
