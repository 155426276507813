import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';

import { type UserData } from '~/data/user';

import UniversalCommunication from '~/models/masterdata/UniversalCommunication';

import { withErrorBoundary } from '~/ui/atoms';

import { Tile } from './Tile';

type P = {
  user: UserData;
} & ComponentStyling;

export const SupportTile = withErrorBoundary(
  ({ className, style, user }: P) => {
    const companyAccountId = user?.companyAccount?.id;

    const { email, phone } =
      UniversalCommunication.getSupportContact(companyAccountId);

    const rows = [
      {
        title: 'E-Mail',
        value: (
          <a href={`mailto:${email}`} target="_blank">
            {email}
          </a>
        ),
      },
      {
        title: 'Telefon',
        value: <a href={`tel:${phone}`}>{phone}</a>,
      },
      {
        title: 'Schulung',
        value: (
          <a
            href="https://vestigas.notion.site/Herzlich-Willkommen-in-einer-Welt-ohne-Lieferscheine-c4db904d5ff4405fa1feb1f5cf8bd0c2"
            target="_blank"
            className="text-primary500 flex items-center gap-1"
            rel="noreferrer"
          >
            <span>Zu unserer Schulung</span>
            <NavigateNextIcon className="m-0 p-0" />
          </a>
        ),
      },
    ];

    return (
      <Tile title={'Support'} className={className} style={style}>
        <table className="border-separate border-spacing-y-2">
          <tbody>
            {rows.map(({ title, value }) => (
              <tr key={title} className="text-left align-top">
                <th className="pr-2 font-normal text-gray-400">{title}</th>
                <td className="w-full font-semibold">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="absolute bottom-0 w-full text-center font-semibold">
          Wir stehen jederzeit zu deiner Verfügung!
        </div>
      </Tile>
    );
  },
  null,
);

SupportTile.displayName = 'SupportTile';
