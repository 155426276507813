import clsx from 'clsx';

import UnitUtils from '~/utils/unitUtils';

import { type UncefactUnitType } from '~/types/uncefactUnit';

const LegendItem = ({ amount, color, name, selectedUnit }) => (
  <div className="flex w-full items-center gap-1.5">
    <div className="h-7 w-1.5 flex-none" style={{ backgroundColor: color }} />
    <div className="min-w-0 flex-1 text-start text-xs leading-tight">
      <div className="truncate">{name}</div>
      <div>
        <span>{amount}</span>
        <span className="pl-[0.5ch] text-gray-500">
          {UnitUtils.getAbbreviatedUnit(selectedUnit)}
        </span>
      </div>
    </div>
  </div>
);

type P = {
  data: Array<{
    amount: number;
    color: string;
    name: string;
  }>;
  selectedUnit: UncefactUnitType;
} & ComponentStyling;

export const ChartLegend = ({ className, data, selectedUnit, style }: P) =>
  data?.length > 0 ? (
    <div
      className={clsx('flex w-full flex-col items-start gap-1', className)}
      style={style}
    >
      {data.map(({ amount, color, name }) => (
        <LegendItem
          key={name}
          amount={amount}
          color={color}
          name={name}
          selectedUnit={selectedUnit}
        />
      ))}
    </div>
  ) : null;
