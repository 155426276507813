import { SettingsTestIds } from '~/constants/test-ids';

import { useQueryUserData } from '~/data/user';

import UniversalCommunication from '~/models/masterdata/UniversalCommunication';

export const SupportContact = () => {
  const { data: companyAccountId } = useQueryUserData(true, {
    select: (data) => data.company?.companyAccountId,
  });

  const { email, phone } =
    UniversalCommunication.getSupportContact(companyAccountId);

  return (
    <div className="h-full rounded-md bg-white p-4 shadow-lg">
      <div
        className="mb-4 font-bold"
        data-testid={SettingsTestIds.SUPPORT.TITLE}
      >
        Support kontaktieren
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex w-full flex-wrap">
          <span className="w-20 font-bold">Hotline:</span>
          <span className="ml-auto break-words">{phone}</span>
        </div>
        <div className="flex flex-wrap">
          <span className="w-20 font-bold">E-Mail:</span>
          <span className="ml-auto break-words">{email}</span>
        </div>
      </div>
    </div>
  );
};
