import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import DeliveriesService from '~/services/deliveries.service';
import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';

import { queryKeysDeliveryNote } from './queryKeys';
import type DeliveryNote from 'models/deliveries/DeliveryNote';

type DeliveryNoteResponse = {
  id: UUID;
  assetState: {
    body: Record<string, any>;
  };
};

/**
 * Fetches delivery note data from the API and optionally initializes it.
 * @param {UUID} deliveryNoteId - The ID of the delivery note to fetch
 * @param {boolean} [shouldInitializeDln=true] - Whether to initialize the delivery note after fetching
 * @returns {Promise<DeliveryNote|undefined>} The delivery note data if successful
 * @throws {Error} If the API request fails
 * @see https://app.dev.vestigas.com/redoc#tag/Delivery-Note/operation/delivery_note_asset_delivery_note_get
 */
export const fetchDeliveryNote = async (
  deliveryNoteId: UUID,
  shouldInitializeDln = true,
): Promise<DeliveryNoteResponse | undefined> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.DELIVERY_NOTE.GET(deliveryNoteId))
      .json<DeliveryNote>();

    if (!shouldInitializeDln) {
      return response;
    }

    const initializedAsset = await DeliveriesService.initDlns([response]);

    return initializedAsset?.[0] ?? null;
  } catch (error) {
    Log.error('Error fetching delivery note:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for a delivery note with a given ID.
 * @param {UUID} deliveryNoteId - The ID of the delivery note to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<DeliveryNote|undefined>} The result of the useQuery hook.
 */
export const useQueryDeliveryNote = (
  deliveryNoteId: UUID,
  options?: Record<string, unknown>,
): UseQueryResult<DeliveryNote | undefined> =>
  useQuery({
    enabled: Boolean(deliveryNoteId),
    queryFn: async () => fetchDeliveryNote(deliveryNoteId),
    queryKey: queryKeysDeliveryNote.get(deliveryNoteId),
    ...options,
  });
