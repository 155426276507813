import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import { TAB } from '~/constants/Tab';

import { promiseHandler } from '~/utils/promiseHandler';
import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';

/**
 * Deletes a filter group from the list of filter groups.
 *
 * @param {Array} filterGroups - The list of filter groups.
 * @param {string} selectedFilterGroupId - The ID of the filter group to be deleted.
 * @param {function} setDelivery_filterGroups - The function to set the updated filter groups.
 * @param {function} setDelivery_selectedFilterGroup - The function to set the selected filter group.
 * @param {function} handleChangeFilterGroup - The function to handle changes of the selected filter group.
 * @return {void}
 */
export const deleteFilterGroup = async (
  dispatch,
  filterGroups,
  selectedFilterGroupId,
  setDelivery_filterGroups,
  setDelivery_selectedFilterGroup,
  handleChangeFilterGroup,
) => {
  const newFilterGroups = ArrayUtils.removeByKey(
    filterGroups,
    'id',
    selectedFilterGroupId,
  );

  const [, error] = await promiseHandler(
    UserService.updateDeliveryFilterGroups(newFilterGroups),
  );

  if (error) {
    Log.error('Failed to delete filter group.', error);
    ToastService.error(['Filter konnte nicht gelöscht werden.']);
    throw error; // Re-throw error to be caught by calling function
  }

  dispatch(setDelivery_filterGroups(newFilterGroups));
  dispatch(
    setDelivery_selectedFilterGroup(filterGroups[TAB.DELIVERY.LIST.INDEX].id),
  );

  handleChangeFilterGroup(filterGroups[TAB.DELIVERY.LIST.INDEX]);
};
