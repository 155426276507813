import { withErrorBoundary } from '~/ui/atoms';

import { InvoiceCheckCategory } from './InvoiceCheckCategory';

export const PriceCheck = withErrorBoundary(
  ({ category }) => <InvoiceCheckCategory category={category} />,
  'Prüfschritt "Preisprüfung" konnte nicht geladen werden.',
);

PriceCheck.displayName = 'PriceCheck';
