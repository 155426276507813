import { withErrorBoundary } from '~/ui/atoms';

import InvoiceCheck from '../InvoiceCheck';
import { DeliveryNoteLinks } from '~/utils/componentUtils';

import { InvoiceCheckCategory } from './InvoiceCheckCategory';

export const AmountApprovedCheck = withErrorBoundary(({ category }) => {
  const errorInvoiceChecks = (
    <>
      {category.errorChecks.map((checkResult, index) => (
        <InvoiceCheck key={index}>
          <div className="w-full">
            Der Artikel{' '}
            <span className="text-error500">
              "{checkResult.articleName ?? ''}"
            </span>{' '}
            wurde in der{' '}
            <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />{' '}
            reklamiert oder nicht bestätigt.
          </div>
        </InvoiceCheck>
      ))}
    </>
  );

  const successInvoiceChecks = (
    <>
      {category.successChecks.map((checkResult, index) => (
        <InvoiceCheck key={index}>
          <div className="w-full">
            Der Artikel "{checkResult.articleName ?? ''}" wurde in der{' '}
            <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />{' '}
            bestätigt.
          </div>
        </InvoiceCheck>
      ))}
    </>
  );

  return (
    <InvoiceCheckCategory
      category={category}
      errorInvoiceChecks={errorInvoiceChecks}
      successInvoiceChecks={successInvoiceChecks}
    />
  );
}, 'Prüfschritt "Keine Reklamationen" konnte nicht geladen werden.');

AmountApprovedCheck.displayName = 'AmountApprovedCheck';
