import { useEffect, useState } from 'react';
import { Check as CheckIcon } from '@mui/icons-material';
import { Chip } from '@mui/material';

import Log from '~/utils/Log';

const PAGINATION_SIZE = 5;

type P = {
  onUnitChange: (unit: string | undefined) => void;
  selectableUnits: Array<{ name: string; unit: string }>;
  selectedUnit: string | undefined;
};

export const DashboardFilterUnitChips = ({
  onUnitChange,
  selectableUnits,
  selectedUnit,
}: P) => {
  const [displayedUnits, setDisplayedUnits] = useState([]);

  useEffect(() => {
    const selectedUnitIndex = selectableUnits?.findIndex(
      ({ unit }) => unit === selectedUnit,
    );

    if (selectedUnitIndex === -1) {
      Log.error(
        'Failed to find selected unit in list of selectable units for pagination.',
      );
    }

    // Ensure we show at least PAGINATION_SIZE items or enough items to include the selected unit
    setDisplayedUnits(
      selectableUnits.slice(
        0,
        Math.max(PAGINATION_SIZE, selectedUnitIndex + 1),
      ),
    );
  }, [selectableUnits, selectedUnit]);

  const handlePaginationClick = (event) => {
    event.currentTarget.blur();

    Log.info('Load the next paginated set of selectable units.', {
      cursor: displayedUnits.length,
    });
    Log.productAnalyticsEvent(
      'Load paginated selectable units',
      Log.FEATURE.DASHBOARD,
    );

    setDisplayedUnits(
      selectableUnits.slice(0, displayedUnits.length + PAGINATION_SIZE),
    );
  };

  return (
    <div className="flex flex-wrap items-center gap-2">
      <Chip
        label="Alle Einheiten"
        onClick={() => {
          onUnitChange(null);
        }}
        icon={selectedUnit ? null : <CheckIcon className="text-white" />}
        className={selectedUnit ? '' : 'bg-primary500 cursor-auto text-white'}
      />
      {displayedUnits.map(({ name, unit }) => (
        <Chip
          key={name}
          label={name}
          onClick={() => {
            onUnitChange(unit);
          }}
          icon={
            unit === selectedUnit ? <CheckIcon className="text-white" /> : null
          }
          className={
            unit === selectedUnit ? 'bg-primary500 cursor-auto text-white' : ''
          }
        />
      ))}
      {displayedUnits.length < selectableUnits.length && (
        <Chip
          key="more"
          label={`+${
            selectableUnits.length - displayedUnits.length
          } weitere Einheiten`}
          onClick={handlePaginationClick}
        />
      )}
    </div>
  );
};
