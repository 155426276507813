import clsx from 'clsx';

import ClientPortalTooltip from '~/components/salesPackages/clientPortal/ClientPortalTooltip';
import DateRangeSelect from '~/components/baseComponents/inputs/date/DateRangeSelect';

type P = {
  featureFlags: {
    clientPortal?: boolean;
  };
  filters: {
    selectedPredefinedDateRange: string;
    individualDateRange: [Date, Date];
    selectedDateRange: [Date, Date];
  };
  handleDateRangeChange: (dateRange: Date[]) => void;
  handlePredefinedDateRangeChange: (predefinedDateRange: string) => void;
} & ComponentStyling;

export const DataListHeader = ({
  className,
  featureFlags,
  filters,
  handleDateRangeChange,
  handlePredefinedDateRangeChange,
  style,
}: P) => (
  <div
    className={clsx(
      'flex w-full items-center justify-between gap-2',
      className,
    )}
    style={style}
  >
    <h2 className="whitespace-nowrap pl-4 text-2xl font-medium text-white">
      Neue Ereignisse
    </h2>
    <ClientPortalTooltip>
      <DateRangeSelect
        predefinedDateRange={filters.selectedPredefinedDateRange}
        individualDateRange={filters.individualDateRange}
        onPredefinedDateRangeChange={handlePredefinedDateRangeChange}
        dateRange={filters.selectedDateRange}
        onDateRangeChange={handleDateRangeChange}
        disabled={featureFlags.clientPortal}
      />
    </ClientPortalTooltip>
  </div>
);
