import { useEffect, useState } from 'react';

import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';

import ArrayUtils from '~/utils/arrayUtils';
import { DeliveryNoteLinks } from '~/utils/componentUtils';
import { pluralizeWord } from '~/utils/pluralize';

import { withErrorBoundary } from '~/ui/atoms';

import InvoiceCheck from '../InvoiceCheck';

import { InvoiceCheckCategory } from './InvoiceCheckCategory';

export const ArticleExistsCheck = withErrorBoundary(({ category }) => {
  const [groupedErrorArticles, setGroupedErrorArticles] = useState([]);
  const [groupedSuccessArticles, setGroupedSuccessArticles] = useState([]);

  const getGroupedArticles = (checkResults) => {
    const articles = ArrayUtils.removeDuplicates(
      checkResults.map(({ articleName }) => articleName),
    );

    return articles.map((article) => ({
      article,
      checkResults: checkResults.filter(
        ({ articleName }) => articleName === article,
      ),
    }));
  };

  const loadGroupedArticles = () => {
    setGroupedErrorArticles(getGroupedArticles(category.errorChecks));
    setGroupedSuccessArticles(getGroupedArticles(category.successChecks));
  };

  useEffect(() => {
    loadGroupedArticles();
  }, [category.errorChecks.length, category.successChecks.length]);

  const errorInvoiceChecks = (
    <>
      {groupedErrorArticles.map((groupedArticle, index) => (
        <InvoiceCheck
          key={index}
          checkResults={groupedArticle.checkResults}
          checkResultFormatter={(checkResult) => (
            <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
          )}
        >
          <div className="w-full">
            Der Artikel{' '}
            <span className="text-error500">
              "{groupedArticle.article ?? ''}"
            </span>{' '}
            ist in der Rechnung referenziert, konnte aber in{' '}
            {pluralizeWord(
              InvoiceCheckResult.getDeliveryNoteNumbers(
                groupedArticle.checkResults,
              ).length,
              'Lieferung',
              true,
            )}{' '}
            nicht gefunden werden.
          </div>
        </InvoiceCheck>
      ))}
    </>
  );

  const successInvoiceChecks = (
    <>
      {groupedSuccessArticles.map((groupedArticle, index) => (
        <InvoiceCheck
          key={index}
          checkResults={groupedArticle.checkResults}
          checkResultFormatter={(checkResult) => (
            <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
          )}
        >
          <div className="w-full">
            Der Artikel "{groupedArticle.article}" ist in der Rechnung
            referenziert und konnte in{' '}
            {pluralizeWord(
              InvoiceCheckResult.getDeliveryNoteNumbers(
                groupedArticle.checkResults,
              ).length,
              'Lieferung',
              true,
            )}{' '}
            gefunden werden.
          </div>
        </InvoiceCheck>
      ))}
    </>
  );

  return (
    <InvoiceCheckCategory
      category={category}
      errorInvoiceChecks={errorInvoiceChecks}
      successInvoiceChecks={successInvoiceChecks}
    />
  );
}, 'Prüfschritt "Artikel vorhanden" konnte nicht geladen werden.');

ArticleExistsCheck.displayName = 'ArticleExistsCheck';
