import { Grid, TextField } from '@mui/material';

import {
  ComponentTestIds,
  OrgUnitsSettingsTestIds,
} from '~/constants/test-ids';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import Log from '~/utils/Log';

import UserUtils from '~/utils/userUtils';

import BasicForm from '~/components/BasicForm';

import {
  MultiItemsManagerCompanies,
  MultiItemsManagerCostCenters,
  MultiItemsManagerOrganizationalUnits,
  MultiItemsManagerSites,
  MultiItemsManagerUserGroups,
  MultiItemsManagerUsers,
  MultiItemsManagerVehicles,
} from '~/ui/molecules/MultiItemsManager';

import { OrganizationalUnitPaths } from '../../paths/OrganizationalUnitPaths';
import { PermissionGrantEntityTable } from '../../permissionGrant/PermissionsTable';

import { useOrganizationalUnitForm } from './useOrganizationalUnitForm';

export const OrganizationalUnitForm = ({
  closeForm,
  onOpenCompany,
  onOpenCostCenter,
  onOpenOrganisationalGroup,
  onOpenSite,
  onOpenUser,
  onOpenUserGroup,
  onOpenVehicle,
  onRefreshEntities,
  onUpdatedCompaniesChange,
  onUpdatedCostCentersChange,
  onUpdatedSitesChange,
  onUpdatedUserGroupsChange,
  onUpdatedUsersChange,
  onUpdatedVehiclesChange,
  open,
  organizationalUnitId,
  type,
}) => {
  const {
    handleCancel,
    handleChangeEntity,
    handleDeleteOrganizationalUnit,
    handleInputChange,
    handleSubmit,
    isCreatingOrganizationalUnit,
    isDeleting,
    isError,
    isLoading,
    isSubmitting,
    organisationalGroup,
    refetchOrganizationalUnit,
    unsavedChanges,
  } = useOrganizationalUnitForm({
    closeForm,
    onRefreshEntities,
    organizationalUnitId,
    type,
  });

  const isHighestOrganisationalGroup = Boolean(
    organisationalGroup?.isHighestOrganisationalGroup?.(),
  );
  const shouldRenderGroupPaths =
    organisationalGroup?.parentOrganizationalUnitPaths?.length > 0 &&
    !isHighestOrganisationalGroup;

  if (isError) {
    Log.error('Could not render form. Error loading organizational unit data'); // TODO: improve error handling
    return null;
  }

  return (
    <BasicForm
      open={open}
      formSuccess={handleSubmit}
      formAbort={handleCancel}
      formDelete={
        !isCreatingOrganizationalUnit && isHighestOrganisationalGroup
          ? handleDeleteOrganizationalUnit
          : null
      }
      title={
        'Organisations-Gruppe ' +
        (isCreatingOrganizationalUnit
          ? 'erstellen'
          : (organisationalGroup.name ?? ''))
      }
      submittingForm={isSubmitting}
      deletingForm={isDeleting}
      id={organisationalGroup?.id}
      unsavedChanges={unsavedChanges}
      missingPermissionsToSubmit={
        isCreatingOrganizationalUnit
          ? !UserUtils.isOrganisationalGroupCreateAllowedUser()
          : !UserUtils.isOrganisationalGroupWriteAllowedUser()
      }
      missingPermissionsToDelete={
        !UserUtils.isOrganisationalGroupDeleteAllowedUser()
      }
      fullWidth
      testId={ComponentTestIds.FORM_MODAL.SUBMIT_BUTTON}
    >
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              {isHighestOrganisationalGroup ? (
                <div className="flex items-center gap-2 rounded-md bg-gray-200 p-4">
                  Diese Organisations-Gruppe ist die höchste Gruppe in der
                  Hierarchie.
                </div>
              ) : null}
              {shouldRenderGroupPaths ? (
                <OrganizationalUnitPaths
                  id={organisationalGroup?.id}
                  organizationalUnitPaths={organisationalGroup?.parentOrganizationalUnitPaths?.map(
                    (path) => path.slice(0, -1),
                  )}
                  onOpenOrganizationalUnit={(organizationalUnit) =>
                    onOpenOrganisationalGroup(
                      organizationalUnit,
                      unsavedChanges,
                    )
                  }
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3 className="main-text mt-0">Organisations-Gruppe</h3>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <TextField
                name="name"
                label="Name"
                type="text"
                fullWidth
                required
                value={organisationalGroup.name}
                onChange={handleInputChange}
                autoFocus
                autoComplete="off"
                inputProps={{
                  'data-testid': OrgUnitsSettingsTestIds.MODAL_FIELDS.NAME,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3 className="main-text mb-1 mt-4">Enthält die folgenden...</h3>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={8}>
              <MultiItemsManagerUsers
                isCreatingNewEntity={isCreatingOrganizationalUnit}
                parentOrganizationalUnitId={organisationalGroup.id}
                onSaveSelection={(users) => handleChangeEntity('users', users)} // TODO VGS-6963: it's not possible to do it like this when we haven't loaded all of the associated entities but only know the diff
                onSelectedItemClick={(user) => onOpenUser(user, unsavedChanges)}
                onChange={onUpdatedUsersChange}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <MultiItemsManagerSites
                isCreatingNewEntity={isCreatingOrganizationalUnit}
                parentOrganizationalUnitId={organisationalGroup.id}
                onSaveSelection={(sites) => handleChangeEntity('sites', sites)} // TODO VGS-6963: it's not possible to do it like this when we haven't loaded all of the associated entities but only know the diff
                onSelectedItemClick={(site) => onOpenSite(site, unsavedChanges)}
                onChange={onUpdatedSitesChange}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <MultiItemsManagerCostCenters
                isCreatingNewEntity={isCreatingOrganizationalUnit}
                parentOrganizationalUnitId={organisationalGroup.id}
                onSaveSelection={
                  (costCenters) =>
                    handleChangeEntity('costCenters', costCenters) // TODO VGS-6963: it's not possible to do it like this when we haven't loaded all of the associated entities but only know the diff
                }
                onSelectedItemClick={(costCenter) =>
                  onOpenCostCenter(costCenter, unsavedChanges)
                }
                onChange={onUpdatedCostCentersChange}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <MultiItemsManagerVehicles
                isCreatingNewEntity={isCreatingOrganizationalUnit}
                parentOrganizationalUnitId={organisationalGroup.id}
                onSaveSelection={
                  (vehicles) => handleChangeEntity('vehicles', vehicles) // TODO VGS-6963: it's not possible to do it like this when we haven't loaded all of the associated entities but only know the diff
                }
                onSelectedItemClick={(vehicle) =>
                  onOpenVehicle(vehicle, unsavedChanges)
                }
                onChange={onUpdatedVehiclesChange}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <MultiItemsManagerCompanies
                isCreatingNewEntity={isCreatingOrganizationalUnit}
                parentOrganizationalUnitId={organisationalGroup.id}
                onSaveSelection={
                  (companies) => handleChangeEntity('companies', companies) // TODO VGS-6963: it's not possible to do it like this when we haven't loaded all of the associated entities but only know the diff
                }
                onSelectedItemClick={(company) =>
                  onOpenCompany(company, unsavedChanges)
                }
                onChange={onUpdatedCompaniesChange}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <MultiItemsManagerOrganizationalUnits
                isCreatingNewEntity={isCreatingOrganizationalUnit}
                parentOrganizationalUnitId={organisationalGroup.id}
                onSaveSelection={(childOrganisationalGroups) =>
                  handleChangeEntity(
                    'childOrganisationalGroups',
                    childOrganisationalGroups,
                  )
                }
                onSelectedItemClick={(organisationalGroup) =>
                  onOpenOrganisationalGroup(organisationalGroup, unsavedChanges)
                }
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <MultiItemsManagerUserGroups
                isCreatingNewEntity={isCreatingOrganizationalUnit}
                parentOrganizationalUnitId={organisationalGroup.id}
                onSaveSelection={
                  (userGroups) => handleChangeEntity('userGroups', userGroups) // TODO VGS-6963: it's not possible to do it like this when we haven't loaded all of the associated entities but only know the diff
                }
                onSelectedItemClick={(userGroup) =>
                  onOpenUserGroup(userGroup, unsavedChanges)
                }
                onChange={onUpdatedUserGroupsChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3 className="main-text mb-1 mt-4">Ist Teil von...</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <MultiItemsManagerOrganizationalUnits
                isCreatingNewEntity={isCreatingOrganizationalUnit}
                filterByEntity={{
                  orgUnit: organizationalUnitId,
                }}
                onSaveSelection={(parentOrganisationalGroups) =>
                  handleChangeEntity(
                    'parentOrganisationalGroups',
                    parentOrganisationalGroups,
                  )
                }
                onSelectedItemClick={(organisationalGroup) =>
                  onOpenOrganisationalGroup(organisationalGroup, unsavedChanges)
                }
                openSelectTestId={
                  OrgUnitsSettingsTestIds.SELECT_ITEM
                    .PART_OF_ORGANIZATIONAL_GROUP
                }
              />
            </Grid>
          </Grid>
        </Grid>
        {isCreatingOrganizationalUnit ? null : (
          <Grid item xs={12}>
            <PermissionGrantEntityTable
              title="Wer ist auf diese Organisations-Gruppe berechtigt?"
              permissionGrantsFrom={organisationalGroup.permissionGrantsFrom}
              defaultEntities={[organisationalGroup?.id]}
              defaultEntityType={
                PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
              }
              fixedPicker={PermissionGrant.TYPE.ENTITY}
              refreshData={refetchOrganizationalUnit}
              loading={isLoading}
            />
          </Grid>
        )}
      </Grid>
    </BasicForm>
  );
};
