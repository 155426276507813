import { type History } from 'history';
import {
  useEffect,
  useMemo,
  useState,
  type MouseEvent,
  type MutableRefObject,
} from 'react';
import { connect } from 'react-redux';
import { gridFilteredSortedRowEntriesSelector } from '@mui/x-data-grid';
import { type GridStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';

import { LOADING_STATE } from '~/constants/LoadingState';
import { ROUTE } from '~/constants/Route';

import { type UUID } from '~/types/common';

import {
  setInvoice_filterModel,
  setInvoice_sortModel,
  setInvoice_query,
  setInvoice_selectedStatus,
  setInvoice_selectedSeller,
  setInvoice_selectedBuyer,
  setInvoice_selectedNumber,
  setInvoice_selectedToSite,
} from '~/redux/filtersSlice';
import { replaceBrowsableInvoices } from '~/redux/invoicesSlice';
import { setPageTitle } from '~/redux/menuSlice';

import ExportService from '~/services/export.service';
import InvoicesService from '~/services/invoices.service';
import LocalStorageService from '~/services/localStorage.service';
import ToastService from '~/services/toast.service';

import Invoice from '~/models/invoices/Invoice';

import ArrayUtils from '~/utils/arrayUtils';
import BrowserUtils from '~/utils/browserUtils';
import { dateUtils, parseDate } from '~/utils/dateUtils';
import Log from '~/utils/Log';
import UnitUtils from '~/utils/unitUtils';
import UserUtils from '~/utils/userUtils';

import { BasicTable } from '~/components/BasicTable';
import ContextMenu from '~/components/menu/ContextMenu';
import { InvoiceColumnCombinedStatus } from '~/components/invoices/invoiceColumns/InvoiceColumnCombinedStatus';
import { InvoiceColumnUnsignedDeliveryNoteIds } from '~/components/invoices/invoiceColumns/InvoiceColumnUnsignedDeliveryNoteIds';
import { RequestSignatureForm } from '~/components/deliveries/deliveryNoteActions/RequestSignatureForm';

import { withErrorBoundary } from '~/ui/atoms';

import { type FilterModel, type InvoiceType, type SortModel } from '../types';

import InvoiceFilterBar from './InvoiceFilterBar';
import { InvoiceFilterGroups } from './InvoiceFilterGroups';

const mapStateToProps = (state) => ({
  dateRange: state.filters.invoice_dateRange,
  filterGroups: state.filters.invoice_filterGroups,
  filterModel: state.filters.invoice_filterModel,
  invoices: state.invoices,
  query: state.filters.invoice_query,
  selectedBuyer: state.filters.invoice_selectedBuyer,
  selectedFilterGroup: state.filters.invoice_selectedFilterGroup,
  selectedNumber: state.filters.invoice_selectedNumber,
  selectedSeller: state.filters.invoice_selectedSeller,
  selectedStatus: state.filters.invoice_selectedStatus,
  selectedToSite: state.filters.invoice_selectedToSite,
  selectField: state.filters.invoice_selectField,
  sortModel: state.filters.invoice_sortModel,
});
const mapDispatchToProps = () => ({
  replaceBrowsableInvoices,
  setInvoice_filterModel,
  setInvoice_query,
  setInvoice_selectedBuyer,
  setInvoice_selectedNumber,
  setInvoice_selectedSeller,
  setInvoice_selectedStatus,
  setInvoice_selectedToSite,
  setInvoice_sortModel,
  setPageTitle,
});

type InvoiceOverviewProps = {
  dateRange: Array<number | Date>;
  filterGroups: Array<Record<string, any>>;
  filterModel: FilterModel;
  sortModel: SortModel;
  history: History;
  invoices: InvoiceType;
  location: Location;
  query: string;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  selectField: string;
  selectedFilterGroup: string | undefined;
  selectedBuyer: string[];
  selectedNumber: string[];
  selectedSeller: string[];
  selectedStatus: string[];
  selectedToSite: string[];
  setPageTitle: (title: string) => void;
  setInvoice_filterModel: (filterModel: FilterModel) => void;
  setInvoice_sortModel: (sortModel: SortModel) => void;
  setInvoice_query: (query: string) => void;
  setInvoice_selectedBuyer: (selectedBuyer: string[]) => void;
  setInvoice_selectedNumber: (selectedNumber: string[]) => void;
  setInvoice_selectedSeller: (selectedSeller: string[]) => void;
  setInvoice_selectedStatus: (selectedStatus: string[]) => void;
  setInvoice_selectedToSite: (selectedToSite: string[]) => void;
  replaceBrowsableInvoices: (
    browsableInvoices: Array<Record<string, any>>,
  ) => void;
};

const InvoiceOverview = (props: InvoiceOverviewProps) => {
  const [
    requestDeliveryNoteSignatureFormOpenDeliveryNoteIds,
    setRequestDeliveryNoteSignatureFormOpenDeliveryNoteIds,
  ] = useState([]);
  const [
    requestDeliveryNoteSignatureFormOpenInvoiceId,
    setRequestDeliveryNoteSignatureFormOpenInvoiceId,
  ] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [invoiceSum, setInvoiceSum] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const isIncoming = useMemo(
    () => props.match.path === ROUTE.INCOMING_INVOICES.ROUTE,
    [props.match.path],
  );

  const setPageTitle = () => {
    const pageTitle = isIncoming ? 'Eingangsrechnungen' : 'Ausgangsrechnungen';

    props.setPageTitle(pageTitle);
    document.title = 'VESTIGAS - ' + pageTitle;
  };

  const filterRows = () => {
    setIsLoading(true);

    const data = {
      dateRange: props.dateRange,
      query: props.query,
      rows: isIncoming
        ? props.invoices.filteredIncomingInvoiceRows
        : props.invoices.filteredOutgoingInvoiceRows,
      selectedBuyer: props.selectedBuyer,
      selectedNumber: props.selectedNumber,
      selectedSeller: props.selectedSeller,
      selectedStatus: props.selectedStatus,
      selectedToSite: props.selectedToSite,
      selectField: props.selectField,
    };

    const filteredRows = InvoicesService.filterRows(data);
    setFilteredRows(filteredRows);
    setInvoiceSum(
      ArrayUtils.sumByKey(filteredRows, Invoice.PROPERTY.TOTAL_PRICE_GROSS.KEY),
    );
    setIsLoading(false);
  };

  const getColumns = () => {
    return [
      {
        field: 'combinedStatus',
        headerName: 'Status',
        renderCell: (params) => <InvoiceColumnCombinedStatus params={params} />,
        sortable: true,
        width: 130,
      },
      {
        field: Invoice.PROPERTY.SELLER.KEY,
        headerName: Invoice.PROPERTY.SELLER.STRING,
        resizableText: true,
        sortable: true,
        width: 400,
      },
      {
        field: Invoice.PROPERTY.BUYER.KEY,
        headerName: Invoice.PROPERTY.BUYER.STRING,
        resizableText: true,
        sortable: true,
        width: 400,
      },
      {
        field: Invoice.PROPERTY.NUMBER.KEY,
        headerName: Invoice.PROPERTY.NUMBER.STRING,
        resizableText: true,
        sortable: true,
        width: 200,
      },
      {
        field: Invoice.PROPERTY.TOTAL_PRICE_GROSS.KEY,
        headerName: Invoice.PROPERTY.TOTAL_PRICE_GROSS.STRING,
        renderCell: (params) =>
          UnitUtils.formatDeMoneyAmount_safe(params.value),
        resizableText: true,
        sortable: true,
        type: 'number',
        width: 150,
      },
      {
        field: Invoice.PROPERTY.CURRENCY.KEY,
        headerName: Invoice.PROPERTY.CURRENCY.STRING,
        resizableText: true,
        sortable: true,
        width: 100,
      },
      {
        field: Invoice.PROPERTY.DATE.KEY,
        headerName: Invoice.PROPERTY.DATE.STRING,
        renderCell: (params) =>
          dateUtils.getFormattedDate_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ),
        resizableText: true,
        sortable: true,
        type: 'date',
        valueGetter: parseDate,
        width: 150,
      },
      {
        field: Invoice.PROPERTY.PARSED_DATE.KEY,
        headerName: Invoice.PROPERTY.PARSED_DATE.STRING,
        renderCell: (params) =>
          dateUtils.getFormattedDate_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ),
        resizableText: true,
        sortable: true,
        type: 'date',
        valueGetter: parseDate,
        width: 150,
      },
      {
        field: Invoice.PROPERTY.TO_SITE.KEY,
        headerName: Invoice.PROPERTY.TO_SITE.STRING,
        resizableText: true,
        sortable: true,
        width: 300,
      },
      {
        field: 'unsignedDeliveryNoteIds',
        headerName: 'Aktionen',
        renderCell: (params) => (
          <InvoiceColumnUnsignedDeliveryNoteIds
            params={params}
            onRequestSignatures={onRequestSignatures}
          />
        ),
        sortable: true,
        width: 100,
      },
    ];
  };

  const getDefaultHiddenColumns = () => {
    const defaultHiddenColumns = [Invoice.PROPERTY.PARSED_DATE.KEY];

    if (isIncoming) {
      defaultHiddenColumns.push(Invoice.PROPERTY.BUYER.KEY);
    } else {
      defaultHiddenColumns.push(Invoice.PROPERTY.SELLER.KEY);
    }

    return defaultHiddenColumns;
  };

  const onPdfExport = async (downloadOption: string) => {
    ExportService.exportInvoices(rowSelectionModel, downloadOption);
  };

  const onExcelInvoiceExport = async () => {
    if (rowSelectionModel.length === 0) {
      ToastService.info([
        'Bitte wähle für den Excel-Download mindestens einen Eintrag aus der Tabelle aus.',
      ]);
      Log.productAnalyticsEvent(
        'No row selected in invoices Excel-Download',
        Log.FEATURE.EXCEL_DOWNLOAD,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    Log.productAnalyticsEvent(
      'Download invoices as Excel',
      Log.FEATURE.EXCEL_DOWNLOAD,
    );

    ExportService.exportInvoicesAsExcelZip(
      rowSelectionModel,
      isIncoming ? Invoice.DIRECTION.INCOMING : Invoice.DIRECTION.OUTGOING,
    );
  };

  const onRowSelectionModelChange = (event: string[]) => {
    Log.info(
      'Change selection value of selected invoices',
      { from: rowSelectionModel, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('(De)select invoice', Log.FEATURE.INVOICE_LIST);
    setRowSelectionModel(event);
  };

  const onFilterModelChange = (event: FilterModel) => {
    Log.info(
      'Change filter value of filter model',
      {
        from: props.filterModel,
        to: event,
      },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter', Log.FEATURE.INVOICE_LIST);
    props.setInvoice_filterModel(event);
  };

  const onSortModelChange = (event: SortModel) => {
    Log.productAnalyticsEvent('Sort', Log.FEATURE.INVOICE_LIST);
    props.setInvoice_sortModel(event);
  };

  const onOpenInvoice = (id: UUID) => {
    Log.productAnalyticsEvent('Open invoice', Log.FEATURE.INVOICE_LIST);
    props.replaceBrowsableInvoices(rowSelectionModel);
    props.history.push(getInvoiceUrl() + '/' + id);
  };

  const onOpenInvoiceInNewTab = () => {
    Log.productAnalyticsEvent(
      'Open invoice in new tab',
      Log.FEATURE.INVOICE_LIST,
    );
    props.replaceBrowsableInvoices(rowSelectionModel);
    BrowserUtils.openNewTab(getInvoiceUrl() + '/' + contextMenu.id);
    onCloseContextMenu();
  };

  const onOpenContextMenu = (event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();

    if (contextMenu) {
      // Repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu. Other native context menus might behave different.
      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context men
      setContextMenu(null);
      return;
    }

    Log.productAnalyticsEvent('Open context menu', Log.FEATURE.MENU);
    setContextMenu({
      id: event.currentTarget.dataset.id,
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
    });
  };

  const onCloseContextMenu = () => {
    Log.productAnalyticsEvent('Close context menu', Log.FEATURE.MENU);
    setContextMenu(null);
  };

  const onRequestSignatures = (id: UUID, deliveryNoteIds: UUID[]) => {
    setRequestDeliveryNoteSignatureFormOpenDeliveryNoteIds(deliveryNoteIds);
    setRequestDeliveryNoteSignatureFormOpenInvoiceId(id);
  };

  // When the filter in the data grid is changed, the invoice sum in the footer should be updated.
  const onStateChange = (
    state: MutableRefObject<{
      state: GridStateCommunity;
      instanceId: UUID;
    }>,
  ) => {
    // We need to filter out the undefined rows because otherwise the InvoiceOverview crashes when we change a filter
    // in the filter group. For some reason, the filtered rows from the filter group filter are still inside the
    // gridFilteredSortedRowEntriesSelector but are undefined.
    const filteredRows = gridFilteredSortedRowEntriesSelector(state)
      .map((row) => row.model)
      .filter((row) => row !== undefined);
    const newInvoiceSum = ArrayUtils.sumByKey(
      filteredRows,
      Invoice.PROPERTY.TOTAL_PRICE_GROSS.KEY,
    );

    if (newInvoiceSum === invoiceSum) {
      return;
    }

    setInvoiceSum(invoiceSum);
  };

  const getInvoiceUrl = () => {
    if (isIncoming) {
      return ROUTE.INCOMING_INVOICE.ROUTE;
    }

    return ROUTE.OUTGOING_INVOICE.ROUTE;
  };

  const getLoadingState = () => {
    if (isLoading) {
      return LOADING_STATE.LOADING;
    }

    return isIncoming
      ? props.invoices.incomingInvoicesLoading
      : props.invoices.outgoingInvoicesLoading;
  };

  useEffect(() => {
    filterRows();
    setPageTitle();
  }, []);

  useEffect(() => {
    filterRows();
  }, [
    props.match.path,
    props.selectedStatus,
    props.selectedSeller,
    props.selectedBuyer,
    props.selectedNumber,
    props.selectedToSite,
    props.selectField,
    props.query,
    props.dateRange,
    isIncoming
      ? props.invoices.filteredIncomingInvoicesVersion
      : props.invoices.filteredOutgoingInvoicesVersion,
  ]);

  useEffect(() => {
    setExcelData(
      filteredRows.filter((row) => rowSelectionModel.includes(row.id)),
    );
  }, [rowSelectionModel.length]);

  useEffect(() => {
    setPageTitle();
  }, [props.match.path]);

  return (
    <div className="main-padding flexdir-column flex h-full">
      <InvoiceFilterGroups
        data={
          isIncoming
            ? props.invoices.filteredIncomingInvoiceRows
            : props.invoices.filteredOutgoingInvoiceRows
        }
      />
      <div className="mt-20px mb-20px min-h-1px bg-grey200 w-full" />
      <InvoiceFilterBar />
      {/* Data grid table should only be rendered when columns are set in state. Otherwise, table is rendered with no columns and filter model is reset. */}
      {getColumns().length > 0 ? (
        <div className="mt-20px rounded-5px box-shadow-blue h-[600px] flex-1 bg-white">
          <BasicTable
            rows={filteredRows}
            columns={getColumns()}
            checkboxSelection
            onRowClick={(rowData) => {
              onOpenInvoice(rowData.row.id);
            }}
            onRowRightClick={onOpenContextMenu}
            onRowSelectionModelChange={onRowSelectionModelChange}
            rowSelectionModel={rowSelectionModel}
            onFilterModelChange={onFilterModelChange}
            filterModel={props.filterModel}
            withFilterModel
            onSortModelChange={onSortModelChange}
            sortModel={props.sortModel}
            onPdfExport={onPdfExport}
            multiplePdfDownload={rowSelectionModel.length > 1}
            excelData={excelData}
            excelColumns={getColumns()}
            onExcelInvoiceExport={
              UserUtils.isInvoiceExcelAllowedUser()
                ? onExcelInvoiceExport
                : null
            }
            loading={getLoadingState()}
            onStateChange={onStateChange}
            localStorageKey={LocalStorageService.INVOICE_OVERVIEW}
            productAnalyticsFeature={Log.FEATURE.INVOICE_LIST}
            defaultHiddenColumns={getDefaultHiddenColumns()}
            footerText={
              'Rechnungsbeträge gesamt: ' +
              UnitUtils.formatDeMoneyAmount_safe(invoiceSum) +
              ' €'
            }
          />
          <ContextMenu
            contextMenu={contextMenu}
            onClose={onCloseContextMenu}
            onOpen={() => {
              onOpenInvoice(contextMenu.id);
            }}
            onOpenInNewTab={onOpenInvoiceInNewTab}
          />
          {/* For an explanation why the form is not in the InvoiceColumnUnsignedDeliveryNoteIds component, check the comments in DeliveryList.js */}
          {requestDeliveryNoteSignatureFormOpenInvoiceId ? (
            <RequestSignatureForm
              permittedUserIds={[]}
              deliveryNoteIds={
                requestDeliveryNoteSignatureFormOpenDeliveryNoteIds
              }
              open={requestDeliveryNoteSignatureFormOpenInvoiceId}
              closeForm={() => {
                setRequestDeliveryNoteSignatureFormOpenDeliveryNoteIds([]);
                setRequestDeliveryNoteSignatureFormOpenInvoiceId(null);
              }}
            />
          ) : null}
        </div>
      ) : null}
      <div
        className="min-h-2rem"
        /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
        /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
        /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
      />
    </div>
  );
};

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(InvoiceOverview),
  'Rechnungen konnten nicht geladen werden.',
);
