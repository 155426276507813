import cloneDeep from 'lodash/cloneDeep';
import { useState } from 'react';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { FormHelperText, IconButton } from '@mui/material';

import { AMOUNT_UNITS } from '~/constants/units';

import ToastService from '~/services/toast.service';

import UnitUtils from '~/utils/unitUtils';
import Log from '~/utils/Log';

import ArticleSelect from '../deliveryNoteForm/ArticleSelect';
import ArticleTextField from '../deliveryNoteForm/ArticleTextField';

const COLUMNS = {
  MANUFACTURER_NAME: 'manufacturer-name',
  NUMBER: 'number',
  TYPE: 'type',
};

export const ServiceList = ({
  addArticle,
  articles,
  articleTemplates,
  error,
  onChange,
  removeArticle,
}) => {
  const [focusedColumn, setFocusedColumn] = useState(null);
  const [focusedRow, setFocusedRow] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);

  const handleChangeNumber = (event, id) => {
    const newArticles = cloneDeep(articles);
    const index = newArticles.findIndex((article) => article.id === id);

    if (index === -1) {
      Log.error('Failed to find article by id. id: ' + id);
      return;
    }

    newArticles[index].number = event.target.value;
    onChange(newArticles);
  };

  const handleChangeType = (event, id) => {
    const newArticles = cloneDeep(articles);
    const index = newArticles.findIndex((article) => article.id === id);

    if (index === -1) {
      Log.error('Failed to find article by id. id: ' + id);
      return;
    }

    newArticles[index].type = event.target.value;
    onChange(newArticles);
  };

  const handleChangeQuantityValue = (event, id) => {
    const newArticles = cloneDeep(articles);
    const index = newArticles.findIndex((article) => article.id === id);

    if (index === -1) {
      Log.error('Failed to find article by id. id: ' + id);
      return;
    }

    try {
      newArticles[index].quantity.value = UnitUtils.complexFormatDe(
        event.target.value,
      );
    } catch {
      Log.productAnalyticsEvent(
        'Invalid quantity',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );
      handleInvalidInputToast();
    }

    onChange(newArticles);
  };

  const handleChangeQuantityUnit = (event, id) => {
    const newArticles = cloneDeep(articles);
    const index = newArticles.findIndex((article) => article.id === id);

    if (index === -1) {
      Log.error('Failed to find article by id. id: ' + id);
      return;
    }

    newArticles[index].quantity.unit = event.target.value;
    onChange(newArticles);
  };

  const isArticleActive = (id) => {
    if (focusedRow !== null) {
      return focusedRow === id;
    }

    if (hoveredRow !== null) {
      return hoveredRow === id;
    }

    return false;
  };

  const handleInvalidInputToast = () => {
    ToastService.warning(
      ['Ungültige Eingabe.'],
      ToastService.ID.CREATE_DLN_INVALID_INPUT,
    );

    Log.productAnalyticsEvent(
      'Invalid input for article(s)',
      Log.FEATURE.CREATE_DELIVERY_NOTE,
      Log.TYPE.FAILED_VALIDATION,
    );
  };

  const getMatchedMasterDataArticle = (article) => {
    let matchedArticle = null;

    if (focusedColumn === COLUMNS.NUMBER) {
      if (article.number.length < 3) {
        return null;
      }

      const matchedArticles = articleTemplates.filter((item) =>
        item.article.article_nr.startsWith(article.number),
      );
      matchedArticle = matchedArticles[0];

      for (const item of matchedArticles) {
        if (
          item.article.article_nr.length <
          matchedArticle?.article.article_nr.length
        ) {
          matchedArticle = item;
        }
      }
    }

    if (focusedColumn === COLUMNS.TYPE) {
      if (article.type.length < 3) {
        return null;
      }

      const matchedArticles = articleTemplates.filter((item) =>
        item.article.article_name.startsWith(article.type),
      );
      matchedArticle = matchedArticles[0];

      for (const item of matchedArticles) {
        if (
          item.article.article_name.length <
          matchedArticle?.article.article_name.length
        ) {
          matchedArticle = item;
        }
      }
    }

    if (focusedColumn === COLUMNS.MANUFACTURER_NAME) {
      if (article.manufacturer.name.length < 3) {
        return null;
      }

      const matchedArticles = articleTemplates
        .filter((item) =>
          item.article.manufacturer_name.startsWith(article.manufacturer.name),
        )
        .map((item) => {
          const {
            manufacturer_city,
            manufacturer_country,
            manufacturer_house_number,
            manufacturer_name,
            manufacturer_post_code,
            manufacturer_street,
          } = item.article;

          return {
            ...item,
            article: {
              manufacturer_city,
              manufacturer_country,
              manufacturer_house_number,
              // only make suggestions for manufacturer information
              manufacturer_name,
              manufacturer_post_code,
              manufacturer_street,
            },
          };
        });
      matchedArticle = matchedArticles[0];

      for (const item of matchedArticles) {
        if (
          item.article.manufacturer_name.length <
          matchedArticle?.article.manufacturer_name.length
        ) {
          matchedArticle = item;
        }
      }
    }

    return matchedArticle?.article;
  };

  const handleAutofill = (e, id, autofillArticle) => {
    if (!autofillArticle) {
      return;
    }

    const newArticles = cloneDeep(articles);
    const index = newArticles.findIndex((article) => article.id === id);

    if (index === -1) {
      Log.error('Failed to find article by id. id: ' + id);
      return;
    }

    // Right arrow: only autofill the focused column
    if (e.keyCode === 39) {
      if (focusedColumn === COLUMNS.NUMBER) {
        Log.productAnalyticsEvent(
          'Autofill article number',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
        newArticles[index].number = autofillArticle.article_nr;
      }

      if (focusedColumn === COLUMNS.TYPE) {
        Log.productAnalyticsEvent(
          'Autofill article type',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
        newArticles[index].type = autofillArticle.article_name;
      }

      if (focusedColumn === COLUMNS.MANUFACTURER_NAME) {
        Log.productAnalyticsEvent(
          'Autofill article manufacturer',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
        newArticles[index].manufacturer.name =
          autofillArticle.manufacturer_name;
      }

      onChange(newArticles);
    }

    // enter 13; tab 9
    if (e.keyCode !== 13 && e.keyCode !== 9) {
      return;
    }

    // autofill the whole article
    if (focusedColumn === COLUMNS.NUMBER || focusedColumn === COLUMNS.TYPE) {
      if (focusedColumn === COLUMNS.NUMBER) {
        Log.productAnalyticsEvent(
          'Autofill article by number',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
      } else {
        Log.productAnalyticsEvent(
          'Autofill article by type',
          Log.FEATURE.CREATE_DELIVERY_NOTE,
        );
      }

      newArticles[index].number = autofillArticle.article_nr;
      newArticles[index].type = autofillArticle.article_name;
      newArticles[index].quantity.unit = autofillArticle.article_amount_unit;
      newArticles[index].weight.unit = autofillArticle.article_weight_unit;
      newArticles[index].ean = autofillArticle.article_ean;
      newArticles[index].manufacturer.name = autofillArticle.manufacturer_name;
      newArticles[index].manufacturer.address.streetName =
        autofillArticle.manufacturer_street;
      newArticles[index].manufacturer.address.buildingNumber =
        autofillArticle.manufacturer_house_number;
      newArticles[index].manufacturer.address.postCode =
        autofillArticle.manufacturer_post_code;
      newArticles[index].manufacturer.address.city =
        autofillArticle.manufacturer_city;
      newArticles[index].manufacturer.address.country =
        autofillArticle.manufacturer_country;

      onChange(newArticles);
    }

    // only autofill the manufacturer information
    if (focusedColumn === COLUMNS.MANUFACTURER_NAME) {
      Log.productAnalyticsEvent(
        'Autofill article manufacturer by manufacturer name',
        Log.FEATURE.CREATE_DELIVERY_NOTE,
      );

      newArticles[index].manufacturer.name = autofillArticle.manufacturer_name;
      newArticles[index].manufacturer.address.streetName =
        autofillArticle.manufacturer_street;
      newArticles[index].manufacturer.address.buildingNumber =
        autofillArticle.manufacturer_house_number;
      newArticles[index].manufacturer.address.postCode =
        autofillArticle.manufacturer_post_code;
      newArticles[index].manufacturer.address.city =
        autofillArticle.manufacturer_city;
      newArticles[index].manufacturer.address.country =
        autofillArticle.manufacturer_country;

      onChange(newArticles);
    }
  };

  return (
    <div className="relative w-full">
      <div className="border-grey400 rounded-4px mt-2">
        <div className="bg-grey100 rounded-top-4px border-bottom-grey400 bold flex-s-c gap-4 p-2">
          <div className="w-150px pl-2">Artikel-Nr.*</div>
          <div className="flex-1 pl-2">Artikelname*</div>
          <div className="w-100px pr-2 text-end">Menge*</div>
          <div className="w-100px pl-2">Einheit*</div>
          <div className="w-4 pl-2"></div>
        </div>
        {articles.map((article, index) => {
          const autofillArticle = getMatchedMasterDataArticle(article);

          return (
            <div
              className={
                'transition-500ms w-full p-2 ' +
                (isArticleActive(article.id) ? 'bg-grey100' : '') +
                (articles.length === index + 1
                  ? 'rounded-bottom-4px'
                  : 'border-bottom-grey400')
              }
              onMouseEnter={() => setHoveredRow(article.id)}
              onMouseLeave={() => setHoveredRow(null)}
              key={article.id}
            >
              <div className="flex-s-c gap-4">
                <div className="w-150px">
                  <ArticleTextField
                    value={article.number}
                    error={article.error.number}
                    placeholder="Artikel-Nr.*"
                    autofillvalue={autofillArticle?.article_nr}
                    onChange={(event) => handleChangeNumber(event, article.id)}
                    onFocus={() => {
                      setFocusedColumn(COLUMNS.NUMBER);
                      setFocusedRow(article.id);
                    }}
                    onBlur={() => {
                      if (focusedRow === article.id) {
                        setFocusedColumn(null);
                        setFocusedRow(null);
                      }
                    }}
                    onKeyDown={(e) =>
                      handleAutofill(e, article.id, autofillArticle)
                    }
                  />
                </div>
                <div className="flex-1">
                  <ArticleTextField
                    value={article.type}
                    error={article.error.type}
                    placeholder="Artikelname*"
                    autofillvalue={autofillArticle?.article_name}
                    onChange={(event) => handleChangeType(event, article.id)}
                    onFocus={() => {
                      setFocusedColumn(COLUMNS.TYPE);
                      setFocusedRow(article.id);
                    }}
                    onBlur={() => {
                      if (focusedRow === article.id) {
                        setFocusedColumn(null);
                        setFocusedRow(null);
                      }
                    }}
                    onKeyDown={(e) =>
                      handleAutofill(e, article.id, autofillArticle)
                    }
                  />
                </div>
                <div className="w-100px">
                  <ArticleTextField
                    value={article.quantity.value}
                    error={article.error.quantityValue}
                    placeholder="Menge*"
                    onChange={(event) =>
                      handleChangeQuantityValue(event, article.id)
                    }
                    onFocus={() => setFocusedRow(article.id)}
                    onBlur={() => {
                      if (focusedRow === article.id) {
                        setFocusedRow(null);
                      }
                    }}
                    textend={1}
                  />
                </div>
                <div className="w-100px">
                  <ArticleSelect
                    value={article.quantity.unit}
                    error={article.error.quantityUnit}
                    options={AMOUNT_UNITS}
                    onChange={(event) =>
                      handleChangeQuantityUnit(event, article.id)
                    }
                    placeholder={
                      autofillArticle?.article_amount_unit
                        ? UnitUtils.getAbbreviatedUnit(
                            autofillArticle.article_amount_unit,
                          )
                        : 'Einheit*'
                    }
                  />
                </div>
                <div className="flex-c-c w-4">
                  <IconButton
                    size="small"
                    onClick={() => removeArticle(article.id)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {error ? (
        <div className="flex-s-s w-full">
          <FormHelperText className="text-mui-error-red absolute">
            {error}
          </FormHelperText>
        </div>
      ) : null}
      <div className="flex-c-c mt-2 w-full">
        <IconButton onClick={addArticle} size="large">
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
};
