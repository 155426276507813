import { MenuItem } from '@mui/material';

import Select from './Select';

import Address from '~/models/masterdata/Address';

export default function CompanySelect(props) {
  const renderOptions = (entity) => {
    return (
      <MenuItem value={entity.id} key={entity.id}>
        <div className="text-12px pt-5px pb-5px w-full">
          <div className="bold">{entity.name}</div>
          <div className="">
            {Address.getConcatenatedAddress(entity.address)}
          </div>
        </div>
      </MenuItem>
    );
  };

  return <Select {...props} renderOptions={renderOptions} />;
}
