import { CircularProgress } from '@mui/material';

import { cn } from '~/utils/tailwind';

export const Spinner = ({ alignLeft, className, size, title, white }) => (
  <div
    className={cn(
      'h-full',
      {
        'flex w-full items-center justify-center': !alignLeft,
        'text-white': white,
      },
      className,
    )}
  >
    <CircularProgress
      size={size ?? 20}
      style={{ color: white ? 'white' : 'currentColor' }}
    />
    {title ? <div className="ml-2">{title}</div> : null}
  </div>
);
