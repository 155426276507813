import DeliveryNote from '~/models/deliveries/DeliveryNote';

export const hiddenColumns = [
  DeliveryNote.CONTRACT_ROLE.BUYER.KEY,
  DeliveryNote.CONTRACT_ROLE.SELLER.KEY,
  DeliveryNote.PROPERTY.BUYER_ID.KEY,
  DeliveryNote.PROPERTY.CARRIER_ID.KEY,
  DeliveryNote.PROPERTY.CATEGORY.KEY,
  DeliveryNote.PROPERTY.COMMENTS.KEY,
  DeliveryNote.PROPERTY.CONSTRUCTION_COMPONENTS.KEY,
  DeliveryNote.PROPERTY.CONSTRUCTION_PLANS.KEY,
  DeliveryNote.PROPERTY.DELIVERY_SHARED_WITH.KEY,
  DeliveryNote.PROPERTY.FROM_SITE_ISSUER_ASSIGNED_ID.KEY,
  DeliveryNote.PROPERTY.FROM_SITE.KEY,
  DeliveryNote.PROPERTY.MODIFIED_DATE.KEY,
  DeliveryNote.PROPERTY.SIGNATURES_REQUESTED_FROM.KEY,
  DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_ADDRESS.KEY,
  DeliveryNote.PROPERTY.TOTAL_WEIGHT_GROSS.KEY,
  DeliveryNote.PROPERTY.TOTAL_WEIGHT_NET.KEY,
  DeliveryNote.PROPERTY.TOTAL_WEIGHT_TARE.KEY,
  DeliveryNote.PROPERTY.TRADER.KEY,
] as const;
