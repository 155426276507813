import { Divider, Tab, Tabs } from '@mui/material';

import { TAB } from '~/constants/Tab';

import DashboardService from '~/services/dashboard.service';

import ClientPortalTooltip from '~/components/salesPackages/clientPortal/ClientPortalTooltip';
import DateRangeSelect from '~/components/baseComponents/inputs/date/DateRangeSelect';
import { PackageBasicRestrictionTooltip } from '~/components/salesPackages/packageBasicRestriction/MF_PackageBasicRestrictionTooltip';

import { withErrorBoundary } from '~/ui/atoms';

import {
  DashboardCharts,
  DashboardFilterGroups,
  DashboardFilterUnitChips,
  DashboardReport,
} from './components';

import { useDashboard } from './useDashboard';

export const Dashboard = withErrorBoundary(() => {
  const {
    featureFlags,
    handleChangeTab,
    handleDateRangeChange,
    handleFilterChange,
    handlePredefinedDateRangeChange,
    handleSelectedArticleInDetailOverviewChange,
    handleUnitChange,
    individualDateRange,
    resetFilters,
    searchBody,
    selectedDateRange,
    selectedPredefinedDateRange,
    selectedTab,
    selectedUnit,
    timeAggregate,
  } = useDashboard();

  return (
    <div className="main-padding flex h-full flex-col gap-4">
      <div className="rounded-md bg-white p-4 shadow-lg">
        <h3 className="text-lg font-medium">Filter auswählen</h3>
        <DashboardFilterGroups
          onChangeValue={(id, customField, filterValue) => {
            handleFilterChange(id, customField, filterValue);
          }}
          resetFilters={resetFilters}
        />
        <Divider sx={{ my: 2 }} />
        <div>
          <h3 className="pb-1 text-lg font-medium">Einheit</h3>
          <DashboardFilterUnitChips
            selectedUnit={selectedUnit}
            selectableUnits={DashboardService.getMajorSelectableUnitsForArchiveMode()}
            onUnitChange={handleUnitChange}
          />
        </div>
        <Divider sx={{ my: 2 }} />
        <div>
          <h3 className="pb-1 text-lg font-medium">Zeitraum</h3>
          <PackageBasicRestrictionTooltip>
            <ClientPortalTooltip>
              <DateRangeSelect
                predefinedDateRange={selectedPredefinedDateRange}
                individualDateRange={individualDateRange}
                onPredefinedDateRangeChange={handlePredefinedDateRangeChange}
                dateRange={selectedDateRange}
                onDateRangeChange={handleDateRangeChange}
                disabled={
                  featureFlags.clientPortal ||
                  featureFlags.packageBasicRestriction
                }
              />
            </ClientPortalTooltip>
          </PackageBasicRestrictionTooltip>
        </div>
      </div>
      <div className="flex flex-1 flex-col rounded-md bg-white p-4 shadow-lg">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          className="border-b"
        >
          <Tab
            label={TAB.DASHBOARD.CHARTS.NAME}
            data-testid={TAB.DASHBOARD.CHARTS.TESTID}
          />
          <Tab
            data-testid={TAB.DASHBOARD.REPORT.TESTID}
            label={TAB.DASHBOARD.REPORT.NAME}
            disabled={
              featureFlags.clientPortal || featureFlags.packageBasicRestriction
            }
          />
        </Tabs>
        {selectedTab === TAB.DASHBOARD.CHARTS.INDEX && (
          <DashboardCharts
            searchBody={searchBody}
            selectedDateRange={selectedDateRange}
            selectedUnit={selectedUnit}
            timeAggregate={timeAggregate}
            onSelectedArticleInDetailOverviewChange={
              handleSelectedArticleInDetailOverviewChange
            }
          />
        )}
        {selectedTab === TAB.DASHBOARD.REPORT.INDEX && (
          <DashboardReport searchBody={searchBody} />
        )}
      </div>
      <div
        className="min-h-2rem"
        /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
        /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
        /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
      />
    </div>
  );
}, 'Statistiken konnten nicht geladen werden.');

Dashboard.displayName = 'Dashboard';
