import React from 'react';
import { Grid } from '@mui/material';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import { withErrorBoundary } from '~/ui/atoms';
import { EditingHistorySafe as EditingHistory } from './EditingHistorySafe';
import ValueGroup from '~/models/deliveries/ValueGroup';
import Address from '~/models/masterdata/Address';
import { dateUtils } from '~/utils/dateUtils';
import {
  type CompanyType,
  type DeliveryNoteUserType,
  type DeliveryNoteType,
} from './types';

type DeliveryNotePartyProps = {
  type: DeliveryNoteUserType;
  deliveryNote: DeliveryNoteType;
  company: CompanyType;
  displayContractParties?: boolean;
};

const DeliveryNoteParty = ({
  type,
  deliveryNote,
  company,
  displayContractParties,
}: DeliveryNotePartyProps) => {
  const getEmailSubject = () => {
    return (
      'Lieferung ' +
      ValueGroup.getCurrentValue(deliveryNote.number) +
      ' vom ' +
      dateUtils.getFormattedDate_safe(
        ValueGroup.getCurrentValue(deliveryNote.dlnDate),
        dateUtils.DATE_FORMAT.DD_MM_YYYY,
        dateUtils.DATE_FORMAT.YYYY_MM_DD__HH_mm_ss_SSSSSS,
      )
    );
  };

  return (
    <>
      <Grid container spacing="20px">
        <Grid item xs={12} lg={3}>
          <div>{DeliveryNote.getProcessRole(type)}</div>
          <div className="bold">
            <EditingHistory value={company?.name} />
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div>Adresse</div>
          <div className="bold">
            <EditingHistory
              value={ValueGroup.applyFunction(
                company?.address,
                Address.getConcatenatedAddress,
              )}
            />
          </div>
        </Grid>
        {type === DeliveryNote.PROCESS_ROLE.CARRIER.KEY && (
          <Grid item xs={12} lg={3}>
            <div>Kfz-Kennzeichen</div>
            <div className="bold">
              <EditingHistory value={deliveryNote?.carrierLicensePlate} />
            </div>
          </Grid>
        )}
        {type === DeliveryNote.PROCESS_ROLE.CARRIER.KEY &&
        ValueGroup.getCurrentValue(deliveryNote?.carrierVehicleNumber) ? (
          <Grid item xs={12} lg={3}>
            <div>Fahrzeug-Nr.</div>
            <div className="bold">
              <EditingHistory value={deliveryNote?.carrierVehicleNumber} />
            </div>
          </Grid>
        ) : null}
        {type === DeliveryNote.PROCESS_ROLE.RECIPIENT.KEY && (
          <Grid item xs={12} lg={3}>
            <div>Kostenstellen</div>
            <div className="bold">
              <EditingHistory
                value={deliveryNote?.costCenters}
                callback={(costCenters) =>
                  costCenters.filter(Boolean).join(', ')
                }
              />
            </div>
          </Grid>
        )}
        {type === DeliveryNote.PROCESS_ROLE.RECIPIENT.KEY &&
          !displayContractParties && (
            <Grid item xs={12} lg={3}>
              <div>Kunden-Nr.</div>
              <div className="bold">
                <EditingHistory value={deliveryNote?.buyerId} />
              </div>
            </Grid>
          )}
      </Grid>
      {company?.universalCommunication?.hasCommunicationInformation() && (
        <Grid container className="mt-20px">
          <Grid item xs={12} lg={3}>
            <div>Kontaktdaten</div>
            {company?.universalCommunication?.hasPhone() && (
              <div
                className="bold cursor-pointer"
                onClick={() =>
                  (window.location =
                    'tel:' +
                    ValueGroup.getCurrentValue(
                      company?.universalCommunication?.phone,
                    ))
                }
              >
                <EditingHistory
                  value={company?.universalCommunication?.phone}
                />
              </div>
            )}
            {company?.universalCommunication?.hasEmail() && (
              <div
                className="bold cursor-pointer"
                onClick={() =>
                  (window.location =
                    'mailto:' +
                    ValueGroup.getCurrentValue(
                      company?.universalCommunication?.email,
                    ) +
                    '?subject=' +
                    getEmailSubject())
                }
              >
                <EditingHistory
                  value={company?.universalCommunication?.email}
                />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withErrorBoundary(
  DeliveryNoteParty,
  'Daten der Lieferpartei konnten nicht geladen werden.',
);
