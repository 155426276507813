import { useState } from 'react';
import StepWizard from 'react-step-wizard';
import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { Step, StepLabel, Stepper, Button } from '@mui/material';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import { withErrorBoundary } from '~/ui/atoms';

import BasicForm from './BasicForm';
import BasicModal from './BasicModal';
import { Spinner } from './Spinner';

export const Wizard = withErrorBoundary(
  ({
    closeWizard: onCloseWizard,
    fullWidth,
    open,
    steps,
    submittingWizard,
    title,
    unsavedChanges,
    wizardSuccess,
  }) => {
    const [abortFormOpen, setAbortFormOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [SW, setSW] = useState(null);

    const handleSetInstance = (SW) => {
      setSW(SW);
    };

    const handlePreviousStep = () => {
      Log.productAnalyticsEvent('Go to previous step', Log.FEATURE.WIZARD);

      SW?.previousStep();
      setCurrentStep((previousStep) => previousStep - 1);
    };

    const handleNextStep = async (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (steps[currentStep].preventNextStep?.()) {
        return;
      }

      if (isNextStepSubmit()) {
        const [successfulSubmit] = await promiseHandler(wizardSuccess());

        if (successfulSubmit) {
          setCurrentStep(0);
        }

        return;
      }

      Log.productAnalyticsEvent('Go to next step', Log.FEATURE.WIZARD);

      SW?.nextStep();
      setCurrentStep((previous) => previous + 1);
    };

    const handleCloseWizard = () => {
      if (unsavedChanges?.length > 0) {
        setAbortFormOpen(true);

        return;
      }

      setCurrentStep(0);
      onCloseWizard();
    };

    const handleCloseAbortForm = () => {
      setAbortFormOpen(false);
    };

    const handleAbortFormSuccess = (event) => {
      event.stopPropagation();
      event.preventDefault();

      Log.productAnalyticsEvent('Submit abort form', Log.FEATURE.WIZARD);

      setCurrentStep(0);
      onCloseWizard();
      handleCloseAbortForm();
    };

    const handleAbortFormAbort = () => {
      Log.productAnalyticsEvent('Abort abort form', Log.FEATURE.WIZARD);

      handleCloseAbortForm();
    };

    const isNextStepSubmit = () => currentStep === steps.length - 1;

    return (
      <BasicModal
        open={open}
        closeModal={handleCloseWizard}
        title={title}
        fullWidth={fullWidth}
      >
        <form onSubmit={handleNextStep}>
          <div className="pb-16">
            <Stepper activeStep={currentStep} className="pb-8">
              {steps.map((step) => (
                <Step key={step.title}>
                  <StepLabel>{step.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className="relative z-0">
              <StepWizard instance={handleSetInstance}>
                {/* Using "hidden" is a hacky workaround to prevent that the different steps influence each other's height. */}
                {steps.map((step, index) => (
                  <div
                    key={step.title}
                    className={index === currentStep ? '' : 'hidden'}
                  >
                    {step.component}
                  </div>
                ))}
              </StepWizard>
            </div>
          </div>
          <div
            className="sticky-dialog-footer z-10 flex items-center justify-between"
            style={{ width: `calc(100% - 48px)` }}
          >
            <Button variant="text" className="mr-4" onClick={handleCloseWizard}>
              Abbrechen
            </Button>
            <BasicForm
              open={abortFormOpen}
              formSuccess={handleAbortFormSuccess}
              formAbort={handleAbortFormAbort}
              title="Ungespeicherte Änderungen"
              submitButtonTitle="Fortfahren"
            >
              Ungespeicherte Änderungen gehen verloren. Willst du wirklich
              fortfahren?
            </BasicForm>
            <div>
              <Button
                variant="text"
                className="mr-4"
                onClick={handlePreviousStep}
                disabled={currentStep === 0 || submittingWizard}
                startIcon={<NavigateBeforeIcon />}
              >
                Zurück
              </Button>
              <Button
                variant={isNextStepSubmit() ? 'contained' : undefined}
                color={isNextStepSubmit() ? 'primary' : undefined}
                startIcon={
                  isNextStepSubmit() && !submittingWizard ? (
                    <SaveIcon />
                  ) : undefined
                }
                endIcon={
                  isNextStepSubmit() || submittingWizard ? undefined : (
                    <NavigateNextIcon />
                  )
                }
                className="primary-button"
                type="submit"
                disabled={
                  steps[currentStep].isNextStepDisabled || submittingWizard
                }
              >
                {!submittingWizard && !isNextStepSubmit() ? 'Weiter' : null}
                {!submittingWizard && isNextStepSubmit() ? 'Speichern' : null}
                {submittingWizard ? (
                  <Spinner white title="Speichern ..." />
                ) : null}
              </Button>
            </div>
          </div>
        </form>
      </BasicModal>
    );
  },
  null,
);
