import React from 'react';

import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { MenuItem, Select, InputLabel } from '@mui/material';

import { connect } from 'react-redux';
import UserTypeService from '~/services/usertype.service';
import { saveUserType } from '~/redux/userinfoSlice';
import User from '~/models/masterdata/User';
import { LightTooltip } from '~/utils/componentUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';
import { withErrorBoundary } from '~/ui/atoms';
import ToastService from '~/services/toast.service';

const mapStateToProps = (state) => ({
  userType: state.userinfo.userinfo.userType,
});
const mapDispatchToProps = () => ({
  saveUserType,
});

class ProfileUserSettings extends React.Component {
  constructor(props) {
    super(props);

    this.userTypes = [
      {
        id: User.TYPE.SUPPLIER.KEY,
        name: User.TYPE.SUPPLIER.NAME,
      },
      {
        id: User.TYPE.CARRIER.KEY,
        name: User.TYPE.CARRIER.NAME,
      },
      {
        id: User.TYPE.RECIPIENT.KEY,
        name: User.TYPE.RECIPIENT.NAME,
      },
    ];

    this.state = {
      switchChecked: true,
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  async handleSelectChange(e) {
    Log.info(
      'Change selected user type',
      { from: this.props.userType, to: e.target.value },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Change selected user type', Log.FEATURE.PROFILE);

    const [data, error] = await promiseHandler(
      UserTypeService.updateUserType(e.target.value),
    );

    if (error) {
      ToastService.httpError(
        [ToastService.MESSAGE.USER_TYPE_UPDATE_FAILED],
        error.response,
      );
      Log.error('Failed to update user type.', error);
      Log.productAnalyticsEvent(
        'Failed to change selected user type',
        Log.FEATURE.PROFILE,
        Log.TYPE.ERROR,
      );
      return;
    }

    this.props.saveUserType(e.target.value);
  }

  handleSwitchChange(e) {
    this.setState({
      switchChecked: e.target.checked,
    });
  }

  render() {
    return (
      <>
        <div className="bold mb-20px">Benutzer Einstellungen</div>
        <div className="bold">
          Benutzertyp
          <LightTooltip title="Durch die Änderung des Benutzertyps werden die verfügbaren Funktionalitäten in der mobilen App und die Anzeige in der Webapp angepasst. Der Benutzertyp kann auch in der mobilen App geändert werden.">
            <InfoOutlinedIcon fontSize="small" />
          </LightTooltip>
        </div>
        <InputLabel
          id="demo-multiple-name-label"
          className="main-text text-13px mt-8px"
        >
          Verwendung der mobilen App und Webapp als...
        </InputLabel>
        <Select
          labelId="company-select"
          id="company-select"
          value={this.props.userType}
          key="company-select-0"
          fullWidth
          className="max-w-80"
          onChange={(e) => this.handleSelectChange(e)}
          size="small"
        >
          {this.userTypes.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
        {/* <div className="profile-detail-item">
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch size="small" checked={this.state.switchChecked} onChange={this.handleSwitchChange} />}
                            label="Webapp-Ansicht nach Benutzertyp" />
                    </FormGroup>
                </div> */}
      </>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(ProfileUserSettings),
  'Benutzer Einstellungen konnten nicht geladen werden',
);
