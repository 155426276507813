import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/LoadingState';

import {
  setSelectedSites as setGlobalSelectedSites,
  setSelectedCostCenters as setGlobalSelectedCostCenters,
} from '~/redux/filtersSlice';
import {
  replaceFilteredDeliveryNotes,
  setDeliveryNotesLoading,
} from '~/redux/deliveryNotesSlice';

import DeliveriesService from '~/services/deliveries.service';

import Log from '~/utils/Log';
import ArrayUtils from '~/utils/arrayUtils';
import { usePrevious } from '~/utils/customHooks';

// Memoized selectors
const selectFilters = createSelector(
  [(state) => state.filters],
  (filtersState) => ({
    globalSelectedCostCenters: filtersState.selectedCostCenters,
    globalSelectedSites: filtersState.selectedSites,
  }),
);

const selectDeliveryNotesData = createSelector(
  [(state) => state.deliveryNotes],
  (deliveryNotesState) => ({
    deliveryNotes: deliveryNotesState.deliveryNotes,
    deliveryNotesVersion: deliveryNotesState.deliveryNotesVersion,
  }),
);

export const useSitesCostCentersSelection = () => {
  const { globalSelectedCostCenters, globalSelectedSites } =
    useSelector(selectFilters);
  const { deliveryNotes, deliveryNotesVersion } = useSelector(
    selectDeliveryNotesData,
  );
  const dispatch = useDispatch();

  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedCostCenters, setSelectedCostCenters] = useState([]);
  const [anchorElement, setAnchorElement] = useState(null);

  useEffect(() => {
    setSelectedSites([...globalSelectedSites]);
    setSelectedCostCenters([...globalSelectedCostCenters]);
  }, [anchorElement, globalSelectedCostCenters, globalSelectedSites]);

  const previousDeliveryNotesVersion = usePrevious(deliveryNotesVersion);

  const filterDeliveryNotes = useCallback(
    (globalSelectedSites, globalSelectedCostCenters, deliveryNotes) => {
      return deliveryNotes.filter((deliveryNote) => {
        const permittedToSiteIds = deliveryNote.permittedToSites.map(
          ({ id }) => id,
        );
        const permittedCostCenterIds = deliveryNote.permittedCostCenters.map(
          ({ id }) => id,
        );

        const isCorrectPermittedToSite =
          ArrayUtils.getOverlappingValues(
            globalSelectedSites,
            permittedToSiteIds,
          ).length > 0;

        const isCorrectPermittedCostCenter =
          ArrayUtils.getOverlappingValues(
            globalSelectedCostCenters,
            permittedCostCenterIds,
          ).length > 0;

        return isCorrectPermittedToSite || isCorrectPermittedCostCenter;
      });
    },
    [],
  );

  useEffect(() => {
    const updateByBulkLoad =
      previousDeliveryNotesVersion !== deliveryNotesVersion;

    if (
      globalSelectedSites.length === 0 &&
      globalSelectedCostCenters.length === 0
    ) {
      dispatch(
        replaceFilteredDeliveryNotes({ deliveryNotes, updateByBulkLoad }),
      );
      return;
    }

    const filteredDeliveryNotes = filterDeliveryNotes(
      globalSelectedSites,
      globalSelectedCostCenters,
      deliveryNotes,
    );

    dispatch(
      replaceFilteredDeliveryNotes({
        deliveryNotes: filteredDeliveryNotes,
        updateByBulkLoad,
      }),
    );
  }, [
    deliveryNotes,
    deliveryNotesVersion,
    dispatch,
    filterDeliveryNotes,
    globalSelectedCostCenters,
    globalSelectedSites,
    previousDeliveryNotesVersion,
  ]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      Log.info(
        'Submit current site and cost center selection',
        null,
        Log.BREADCRUMB.FORM_SUBMIT.KEY,
      );
      Log.productAnalyticsEvent(
        'Submit',
        Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
      );

      dispatch(setGlobalSelectedSites(selectedSites));
      dispatch(setGlobalSelectedCostCenters(selectedCostCenters));

      setAnchorElement(null);
    },
    [dispatch, selectedCostCenters, selectedSites],
  );

  const handleCancel = useCallback(() => {
    Log.productAnalyticsEvent(
      'Abort',
      Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
    );

    setSelectedSites([]);
    setSelectedCostCenters([]);
    setAnchorElement(null);
  }, []);

  const handleChangeSites = useCallback(
    (sites, data) => {
      const siteIds = sites.map(({ id }) => id);

      Log.info(
        'Change form value of sites',
        { from: selectedSites, to: siteIds },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change sites',
        Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
      );

      setSelectedSites(siteIds);

      const siteCostCenterIds =
        data.flatMap(({ accountingReferences }) =>
          accountingReferences?.map(({ id }) => id),
        ) ?? [];
      const newCostCenterIds = [
        ...new Set([...selectedCostCenters, ...siteCostCenterIds]),
      ];

      setSelectedCostCenters(newCostCenterIds);
    },
    [selectedCostCenters],
  );

  const handleChangeCostCenters = useCallback((costCenters) => {
    const costCenterIds = costCenters.map(({ id }) => id);

    Log.info(
      'Change form value of cost centers',
      { from: selectedCostCenters, to: costCenterIds },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change cost centers',
      Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
    );

    setSelectedCostCenters(costCenterIds);
  }, []);

  const handleDelete = useCallback(() => {
    Log.info(
      'Delete current site and cost center selection',
      null,
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Delete',
      Log.FEATURE.CURRENT_SITE_AND_COST_CENTER,
    );

    setSelectedSites([]);
    setSelectedCostCenters([]);
  }, []);

  const handleOpenPopover = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorElement(event.currentTarget);
  }, []);

  const getSelectedSitesString = useMemo(() => {
    if (globalSelectedSites.length === 0) {
      return 'Kein Standort ausgewählt';
    }

    return `${globalSelectedSites.length} ${
      globalSelectedSites.length === 1 ? 'Standort' : 'Standorte'
    } ausgewählt`;
  }, [globalSelectedSites]);

  const getSelectedCostCentersString = useMemo(() => {
    if (globalSelectedCostCenters.length === 0) {
      return 'Keine Kostenstelle ausgewählt';
    }

    return `${globalSelectedCostCenters.length} ${
      globalSelectedCostCenters.length === 1 ? 'Kostenstelle' : 'Kostenstellen'
    } ausgewählt`;
  }, [globalSelectedCostCenters]);

  const hasSetDifferences = useCallback((set1, set2) => {
    return (
      [...set1].some((item) => !set2.has(item)) ||
      [...set2].some((item) => !set1.has(item))
    );
  }, []);

  const hasChanges = useMemo(
    () =>
      hasSetDifferences(new Set(selectedSites), new Set(globalSelectedSites)) ||
      hasSetDifferences(
        new Set(selectedCostCenters),
        new Set(globalSelectedCostCenters),
      ),
    [
      globalSelectedCostCenters,
      globalSelectedSites,
      hasSetDifferences,
      selectedCostCenters,
      selectedSites,
    ],
  );

  const hasSelectedData = selectedSites.length + selectedCostCenters.length > 0;
  const hasGlobalSelectedData =
    (globalSelectedCostCenters?.length ?? 0) +
      (globalSelectedSites?.length ?? 0) >
    0;

  return {
    anchorElement,
    getSelectedCostCentersString,
    getSelectedSitesString,
    handleCancel,
    handleChangeCostCenters,
    handleChangeSites,
    handleDelete,
    handleOpenPopover,
    handleSubmit,
    hasChanges,
    hasGlobalSelectedData,
    hasSelectedData,
    selectedCostCenters,
    selectedSites,
  };
};
