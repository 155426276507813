import ms from 'ms';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasSyncGateway } from '~/services/kyClient';

import DeliveryNoteAction from '~/models/deliveries/DeliveryNoteAction';

import Log from '~/utils/Log';

import { queryKeysDeliveryNote } from './queryKeys';

const POLLING_INTERVAL = ms('10s');

type SyncGatewayDocument = {
  assetId?: string;
  body?: {
    header?: {
      id: string;
    };
  };
  documentType: string;
  id: string;
  rev: string;
};

type SyncGatewayResponse = {
  lastSeq: number;
  results: Array<{
    doc: SyncGatewayDocument;
    seq: string;
  }>;
};

type DeliveryNoteChange = {
  dlns: Array<{
    dlnNr: string;
    id: string;
  }>;
  message: string;
};

type ProcessedChanges = {
  assetMains: SyncGatewayDocument[];
  dlnChanges: DeliveryNoteChange[];
  lastSequence: number;
};

/**
 * Processes the sync gateway response and extracts the relevant information.
 * Filters and groups delivery note changes by message type and tracks the last sequence number.
 *
 * @param data - The raw response from the sync gateway containing change results
 * @returns ProcessedChanges object containing filtered asset changes and last sequence number
 * @throws Error if processing fails
 */
const selectSyncGatewayChanges = (
  data: SyncGatewayResponse,
): ProcessedChanges => {
  try {
    // const assetMains =
    //   data.results
    //     ?.filter(({ doc }) => doc?.documentType === 'asset_main')
    //     .map(({ doc }) => doc) ?? [];

    const dlnChanges = data.results?.reduce<DeliveryNoteChange[]>(
      (changes, item) => {
        if (!item) {
          return changes;
        }

        const isAssetChain = item.doc?.documentType === 'asset_chain';
        const isLatestRevision = item.doc?.rev?.startsWith('1-');
        const isCompleteChange = !item.seq?.toString().includes(':');

        if (isAssetChain && isLatestRevision && isCompleteChange) {
          try {
            const message = new DeliveryNoteAction(item.doc).action;
            const id = item.doc.assetId ?? item.doc.id;
            const dlnNr = item.doc.body?.header?.id;

            if (!dlnNr) {
              return changes;
            }

            const existingChange = changes.find(
              (change) => Boolean(change.message) && change.message === message,
            );

            const changedAsset = { dlnNr, id };

            if (existingChange) {
              existingChange.dlns.push(changedAsset);
            } else {
              changes.push({
                dlns: [changedAsset],
                message,
              });
            }
          } catch (error) {
            Log.error('Error processing delivery note action', error, item.doc);
          }
        }

        return changes;
      },
      [],
    );

    return {
      // assetMains,
      dlnChanges,
      lastSequence: data.lastSeq,
    };
  } catch (error) {
    Log.error('Error processing sync gateway changes', error);

    throw new Error('Failed to process sync gateway changes');
  }
};

/**
 * Fetches delivery note changes from the sync gateway since a given timestamp.
 *
 * @param changesCutoffTimestamp - Optional timestamp to fetch changes since
 * @returns Promise resolving to the sync gateway response
 * @throws Error if the fetch fails or response format is invalid
 */
const fetchSyncGatewayChanges = async (
  changesCutoffTimestamp?: number,
): Promise<SyncGatewayResponse> => {
  try {
    const response = await vestigasSyncGateway
      .get(
        ENDPOINT.DELIVERY_NOTE.GET_SYNC_GATEWAY_CHANGES(changesCutoffTimestamp),
      )
      .json<SyncGatewayResponse>();

    if (!response || !Array.isArray(response.results)) {
      throw new Error('Invalid response format from sync gateway');
    }

    return response;
  } catch (error) {
    Log.error('Error fetching delivery note updates:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Calculates exponential backoff delay for retries with minimum and maximum bounds.
 * The delay doubles with each retry attempt up to a maximum of 5 minutes.
 *
 * @param attemptIndex - The current retry attempt number (0-based)
 * @returns Delay in milliseconds to wait before next retry
 */
const getRetryDelay = (attemptIndex: number): number => {
  const MIN_RETRY_DELAY = POLLING_INTERVAL;
  const MAX_RETRY_DELAY = ms('5m');

  const delay = Math.min(MIN_RETRY_DELAY * 2 ** attemptIndex, MAX_RETRY_DELAY);

  return delay;
};

/**
 * Custom React Query hook for polling delivery note updates from the Vestigas sync gateway.
 *
 * @param changesCutoffTimestamp - Optional timestamp to fetch changes since
 * @param options - Additional React Query options to customize the query behavior
 * @returns React Query result object containing the processed changes data
 */
export const useQueryPollDeliveryNoteUpdates = (
  changesCutoffTimestamp: number | undefined,
  options?: Omit<UseQueryOptions<ProcessedChanges>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    gcTime: Number(POLLING_INTERVAL) * 2, // aggressive clean-up of stale data
    queryFn: async () => fetchSyncGatewayChanges(changesCutoffTimestamp),
    queryKey: queryKeysDeliveryNote.getSyncGatewayChanges(
      changesCutoffTimestamp,
    ),
    refetchInterval: POLLING_INTERVAL,
    refetchIntervalInBackground: false,
    retry: 10,
    retryDelay: getRetryDelay,
    select: selectSyncGatewayChanges,
    ...options,
  });
