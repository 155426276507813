import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';

import { queryKeysDeliveryNote } from './queryKeys';
import { type DeliveryNoteListItem } from './types';

type DeliveryNoteChainsResponse = {
  assets: DeliveryNoteListItem[];
  hasNextPage: boolean | undefined;
  nextLink: string;
  paginatedCount: number | undefined;
  totalAssets: number;
  updatedLink: string;
};

/**
 * Fetches delivery note chains via GET request.
 * @param {UUID[]} deliveryNoteChainIds - Delivery note chain UUID's to fetch.
 * @returns {Promise<DeliveryNoteType[]>} Array of delivery note chains data.
 * @see https://app.dev.vestigas.com/redoc#tag/Delivery-Note/operation/get_chain_elements_for_delivery_notes_asset_delivery_note_ids_chains_get
 */
export const fetchDeliveryNoteChains = async (
  deliveryNoteChainIds: UUID[],
): Promise<DeliveryNoteListItem[]> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.DELIVERY_NOTE.GET_CHAINS(), {
        searchParams: { ids: deliveryNoteChainIds },
      })
      .json<DeliveryNoteChainsResponse>();

    return response.assets ?? [];
  } catch (error) {
    Log.error('Error fetching delivery note chains', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting delivery note.
 * @param {UUID[]} deliveryNoteChainIds - Array of site ID to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<Site[]>} The query result containing array of sites.
 */
export const useQueryDeliveryNoteChains = (
  deliveryNoteChainIds: UUID[],
  options?: Omit<
    UseQueryOptions<DeliveryNoteListItem[], Error>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery({
    enabled: Boolean(deliveryNoteChainIds?.length > 0),
    queryFn: async () => fetchDeliveryNoteChains(deliveryNoteChainIds),
    queryKey: queryKeysDeliveryNote.getChains(deliveryNoteChainIds),
    ...options,
  });
