import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';

import UnitUtils from '~/utils/unitUtils';

import { withErrorBoundary } from '~/ui/atoms';

import InvoiceCheck from '../InvoiceCheck';

import { InvoiceCheckCategory } from './InvoiceCheckCategory';

export const FormalCheck = withErrorBoundary(({ category }) => {
  const getErrorMessage = (checkResult) => {
    switch (checkResult.name) {
      case 'SenderNameExists': {
        return 'Der Name des Senders fehlt.';
      }

      case 'SenderAddressExists': {
        return 'Die Anschrift des Senders fehlt.';
      }

      case 'ReceiverNameExists': {
        return 'Der Name des Empfängers fehlt.';
      }

      case 'ReceiverAddressExists': {
        return 'Die Anschrift des Empfängers fehlt.';
      }

      case 'TaxIDExists': {
        return 'Die Steuernummer/Ust-ID fehlt.';
      }

      case 'InvoiceDateExists': {
        return 'Das Datum der Ausstellung fehlt.';
      }

      case 'InvoiceIDExists': {
        return 'Die Rechnungsnummer fehlt.';
      }

      case 'TotalPriceExists': {
        return 'Das Entgelt fehlt.';
      }

      case 'TaxAmountExists': {
        return 'Der Steuerbetrag fehlt.';
      }

      case 'TaxRateExists': {
        return 'Der Steuersatz oder die Steuerfreiheit fehlen.';
      }

      case 'TaxRateCheck': {
        return (
          'Der referenzierte Gesamtsteuersatz von ' +
          checkResult.invoiceValue +
          '% entspricht nicht den gesetzlichen Vorgaben von 7% oder 19%.'
        );
      }

      case 'TotalTaxAmountCalculation': {
        return (
          <div className="w-full">
            <div>
              Der Steuerbetrag der Rechnung über{' '}
              {UnitUtils.formatDeMoneyAmount_safe(checkResult.invoiceValue)}€
              entspricht nicht dem Produkt aus Nettobetrag und Steuersatz.
            </div>
            <div className="flex w-full items-end">
              <div className="text-error500 w-1/2">
                Rechnung:{' '}
                {UnitUtils.formatDeMoneyAmount_safe(checkResult.invoiceValue) ??
                  '-'}
                €
              </div>
              <div className="w-1/2">
                Soll:{' '}
                {UnitUtils.formatDeMoneyAmount_safe(
                  checkResult.expectedValue,
                ) ?? '-'}
                €
              </div>
            </div>
          </div>
        );
      }

      case 'TotalGrossPriceCalculation': {
        return (
          <div className="w-full">
            <div>
              Die Summe aus Nettobetrag und Steuerbetrag entspricht nicht dem
              Gesamtbetrag.
            </div>
            <div className="flex w-full items-end">
              <div className="text-error500 w-1/2">
                Rechnung:{' '}
                {UnitUtils.formatDeMoneyAmount_safe(checkResult.invoiceValue) ??
                  '-'}
                €
              </div>
              <div className="w-1/2">
                Soll:{' '}
                {UnitUtils.formatDeMoneyAmount_safe(
                  checkResult.expectedValue,
                ) ?? '-'}
                €
              </div>
            </div>
          </div>
        );
      }

      case 'TotalNetPriceCalculation': {
        return (
          <div className="w-full">
            <div>
              Die Summe aus allen Materialnettopreisen entspricht nicht dem
              Gesamtnettobetrag.
            </div>
            <div className="flex w-full items-end">
              <div className="text-error500 w-1/2">
                Rechnung:{' '}
                {UnitUtils.formatDeMoneyAmount_safe(checkResult.invoiceValue) ??
                  '-'}
                €
              </div>
              <div className="w-1/2">
                Soll:{' '}
                {UnitUtils.formatDeMoneyAmount_safe(
                  checkResult.expectedValue,
                ) ?? '-'}
                €
              </div>
            </div>
          </div>
        );
      }

      case 'UniqueInvoiceID': {
        return 'Die Rechnungsnummer wurde bereits in einer Rechnung in VESTIGAS verwendet.';
      }
    }
  };

  const getSuccessMessage = (checkResult) => {
    switch (checkResult.name) {
      case 'SenderNameExists': {
        return 'Der Name des Senders ist vorhanden.';
      }

      case 'SenderAddressExists': {
        return 'Die Anschrift des Senders ist vorhanden.';
      }

      case 'ReceiverNameExists': {
        return 'Der Name des Empfängers ist vorhanden.';
      }

      case 'ReceiverAddressExists': {
        return 'Die Anschrift des Empfängers ist vorhanden.';
      }

      case 'TaxIDExists': {
        return 'Die Steuernummer/Ust-ID ist vorhanden.';
      }

      case 'InvoiceDateExists': {
        return 'Das Datum der Ausstellung ist vorhanden.';
      }

      case 'InvoiceIDExists': {
        return 'Die Rechnungsnummer ist vorhanden.';
      }

      case 'TotalPriceExists': {
        return 'Das Entgelt ist vorhanden.';
      }

      case 'TaxAmountExists': {
        return 'Der Steuerbetrag ist vorhanden.';
      }

      case 'TaxRateExists': {
        return 'Der Steuersatz oder die Steuerfreiheit sind vorhanden.';
      }

      case 'TaxRateCheck': {
        return 'Der referenzierte Gesamtsteuersatz entspricht den gesetzlichen Vorgaben von 7% oder 19%.';
      }

      case 'TotalTaxAmountCalculation': {
        return 'Der Steuerbetrag der Rechnung entspricht dem Produkt aus Nettobetrag und Steuersatz.';
      }

      case 'TotalGrossPriceCalculation': {
        return 'Die Summe aus Nettobetrag und Steuerbetrag entspricht dem Gesamtbetrag.';
      }

      case 'TotalNetPriceCalculation': {
        return 'Die Summe aus allen Materialnettopreisen entspricht dem Gesamtnettobetrag.';
      }

      case 'UniqueInvoiceID': {
        return 'Die Rechnungsnummer wurde noch nicht in einer Rechnung in VESTIGAS verwendet.';
      }
    }
  };

  const getInvoiceChecks = (checkResults, status) => {
    const invoiceChecks = [];
    let materialTaxRateCheckSet = false;
    let materialNetPriceCalculationSet = false;

    for (const checkResult of checkResults) {
      if (
        checkResult.name === 'MaterialTaxRateCheck' &&
        !materialTaxRateCheckSet
      ) {
        if (
          checkResults.filter(({ name }) => name === 'MaterialTaxRateCheck')
            .length === 1
        ) {
          invoiceChecks.push(
            <InvoiceCheck key={invoiceChecks.length}>
              {status === InvoiceCheckResult.STATUS.ERROR ? (
                <>
                  Der referenzierte Steuersatz des Artikels "
                  {checkResult.articleName ?? ''}" (Lieferung{' '}
                  {checkResult.getDeliveryNoteNumbers().join(', ')}) entspricht
                  nicht den gesetzlich erlaubten Optionen von 7% oder 19%.
                </>
              ) : (
                <>
                  Der referenzierte Steuersatz des Artikels "
                  {checkResult.articleName ?? ''}" (Lieferung{' '}
                  {checkResult.getDeliveryNoteNumbers().join(', ')}) entspricht
                  den gesetzlich erlaubten Optionen von 7% oder 19%.
                </>
              )}
            </InvoiceCheck>,
          );
        } else {
          invoiceChecks.push(
            <InvoiceCheck
              key={invoiceChecks.length}
              checkResults={checkResults.filter(
                ({ name }) => name === 'MaterialTaxRateCheck',
              )}
              checkResultFormatter={(checkResult) =>
                (checkResult.articleName ?? '') +
                ' (Lieferung ' +
                checkResult.getDeliveryNoteNumbers().join(', ') +
                ')'
              }
            >
              {status === InvoiceCheckResult.STATUS.ERROR ? (
                <>
                  Der referenzierte Steuersatz von{' '}
                  {
                    checkResults.filter(
                      ({ name }) => name === 'MaterialTaxRateCheck',
                    ).length
                  }{' '}
                  Artikeln entspricht nicht den gesetzlich erlaubten Optionen
                  von 7% oder 19%.
                </>
              ) : (
                <>
                  Der referenzierte Steuersatz von{' '}
                  {
                    checkResults.filter(
                      ({ name }) => name === 'MaterialTaxRateCheck',
                    ).length
                  }{' '}
                  Artikeln entspricht den gesetzlich erlaubten Optionen von 7%
                  oder 19%.
                </>
              )}
            </InvoiceCheck>,
          );
        }

        materialTaxRateCheckSet = true;
      }

      if (
        checkResult.name === 'MaterialTaxRateCheck' &&
        materialTaxRateCheckSet
      ) {
        continue;
      }

      if (
        checkResult.name === 'MaterialNetPriceCalculation' &&
        !materialNetPriceCalculationSet
      ) {
        if (
          checkResults.filter(
            ({ name }) => name === 'MaterialNetPriceCalculation',
          ).length === 1
        ) {
          if (status === InvoiceCheckResult.STATUS.ERROR) {
            invoiceChecks.push(
              <InvoiceCheck key={invoiceChecks.length}>
                <div className="w-full">
                  <div>
                    Der Nettopreis des Artikels "{checkResult.articleName ?? ''}
                    " (Lieferung{' '}
                    {checkResult.getDeliveryNoteNumbers().join(', ')})
                    entspricht nicht dem Produkt aus Artikelpreis und
                    Artikelmenge.
                  </div>
                  <div className="flex w-full items-end">
                    <div className="text-error500 w-1/2">
                      Rechnung:{' '}
                      {UnitUtils.formatDeMoneyAmount_safe(
                        checkResult.invoiceValue,
                      ) ?? '-'}
                      €
                    </div>
                    <div className="w-1/2">
                      Soll:{' '}
                      {UnitUtils.formatDeMoneyAmount_safe(
                        checkResult.expectedValue,
                      ) ?? '-'}
                      €
                    </div>
                  </div>
                </div>
              </InvoiceCheck>,
            );
          } else {
            invoiceChecks.push(
              <InvoiceCheck key={invoiceChecks.length}>
                Der Nettopreis des Artikels "{checkResult.articleName ?? ''}"
                (Lieferung {checkResult.getDeliveryNoteNumbers().join(', ')})
                entspricht dem Produkt aus Artikelpreis und Artikelmenge.
              </InvoiceCheck>,
            );
          }
        } else if (status === InvoiceCheckResult.STATUS.ERROR) {
          invoiceChecks.push(
            <InvoiceCheck
              key={invoiceChecks.length}
              checkResults={checkResults.filter(
                ({ name }) => name === 'MaterialNetPriceCalculation',
              )}
              checkResultFormatter={(checkResult) => (
                <>
                  <div>
                    {checkResult.articleName ?? ''} (Lieferung{' '}
                    {checkResult.getDeliveryNoteNumbers().join(', ')})
                  </div>
                  <div className="flex w-full items-end">
                    <div className="text-error500 w-1/2">
                      Rechnung:{' '}
                      {UnitUtils.formatDeMoneyAmount_safe(
                        checkResult.invoiceValue,
                      ) ?? '-'}
                      €
                    </div>
                    <div className="w-1/2">
                      Soll:{' '}
                      {UnitUtils.formatDeMoneyAmount_safe(
                        checkResult.expectedValue,
                      ) ?? '-'}
                      €
                    </div>
                  </div>
                </>
              )}
            >
              Die Nettopreise von{' '}
              {
                checkResults.filter(
                  ({ name }) => name === 'MaterialNetPriceCalculation',
                ).length
              }{' '}
              Artikeln entsprechen nicht dem Produkt aus Artikelpreis und
              Artikelmenge.
            </InvoiceCheck>,
          );
        } else {
          invoiceChecks.push(
            <InvoiceCheck
              key={invoiceChecks.length}
              checkResults={checkResults.filter(
                ({ name }) => name === 'MaterialNetPriceCalculation',
              )}
              checkResultFormatter={(checkResult) =>
                (checkResult.articleName ?? '') +
                ' (Lieferung ' +
                checkResult.getDeliveryNoteNumbers().join(', ') +
                ')'
              }
            >
              Die Nettopreise von{' '}
              {
                checkResults.filter(
                  ({ name }) => name === 'MaterialNetPriceCalculation',
                ).length
              }{' '}
              Artikeln entsprechen dem Produkt aus Artikelpreis und
              Artikelmenge.
            </InvoiceCheck>,
          );
        }

        materialNetPriceCalculationSet = true;
      }

      if (
        checkResult.name === 'MaterialNetPriceCalculation' &&
        materialNetPriceCalculationSet
      ) {
        continue;
      }

      invoiceChecks.push(
        <InvoiceCheck key={invoiceChecks.length}>
          {status === InvoiceCheckResult.STATUS.ERROR
            ? getErrorMessage(checkResult)
            : getSuccessMessage(checkResult)}
        </InvoiceCheck>,
      );
    }

    return invoiceChecks;
  };

  const getErrorInvoiceChecks = (checkResults) => {
    return getInvoiceChecks(checkResults, InvoiceCheckResult.STATUS.ERROR);
  };

  const getSuccessInvoiceChecks = (checkResults) => {
    return getInvoiceChecks(checkResults, InvoiceCheckResult.STATUS.SUCCESS);
  };

  return (
    <InvoiceCheckCategory
      category={category}
      errorInvoiceChecks={getErrorInvoiceChecks(category.errorChecks)}
      successInvoiceChecks={getSuccessInvoiceChecks(category.successChecks)}
    />
  );
}, 'Prüfschritt "Formale Prüfung" konnte nicht geladen werden.');

FormalCheck.displayName = 'FormalCheck';
