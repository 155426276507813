import deLocale from 'date-fns/locale/de';
import moment from 'moment';
import React, { useCallback } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MuiDateRangePicker from '@mui/lab/DateRangePicker';
import { TextField } from '@mui/material';

export const DateRangePicker = ({
  dateRange,
  disabled,
  maxDate,
  onDateRangeChange,
}) => {
  const setDate = useCallback(
    (params, index) => {
      if (!dateRange[index]) {
        return params;
      }

      const date = moment(dateRange[index]);
      const day = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'][date.day()];

      params.inputProps.value = `${day} ${date.format('DD.MM.YYYY')}`;

      return params;
    },
    [dateRange],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
      <MuiDateRangePicker
        startText="Von"
        endText="Bis"
        value={dateRange}
        onChange={onDateRangeChange}
        disabled={disabled}
        maxDate={maxDate}
        inputFormat="__/__/____"
        calendars={3}
        defaultCalendarMonth={new Date(moment().subtract(2, 'months').format())}
        renderInput={(startProps, endProps) => (
          <>
            <TextField
              InputProps={{
                className: 'bg-white w-36 mr-2',
              }}
              size="small"
              onKeyDown={(event) => {
                event.preventDefault(); // Disable manual changes
              }}
              {...setDate(startProps, 0)}
            />
            <TextField
              InputProps={{
                className: 'bg-white w-36',
              }}
              size="small"
              onKeyDown={(event) => {
                event.preventDefault(); // Disable manual changes
              }}
              {...setDate(endProps, 1)}
            />
          </>
        )}
      />
    </LocalizationProvider>
  );
};

export default DateRangePicker;
