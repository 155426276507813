import Article from '~/models/articles/Article';

export const AMOUNT_UNITS = [
  {
    id: Article.UNIT.TNE.STANDARDISED,
    name: Article.UNIT.TNE.ABBREVIATED,
  },
  {
    id: Article.UNIT.KGM.STANDARDISED,
    name: Article.UNIT.KGM.ABBREVIATED,
  },
  {
    id: Article.UNIT.MTQ.STANDARDISED,
    name: Article.UNIT.MTQ.ABBREVIATED,
  },
  {
    id: Article.UNIT.LTR.STANDARDISED,
    name: Article.UNIT.LTR.ABBREVIATED,
  },
  {
    id: Article.UNIT.EA.STANDARDISED,
    name: Article.UNIT.EA.ABBREVIATED,
  },
  {
    id: Article.UNIT.MTR.STANDARDISED,
    name: Article.UNIT.MTR.ABBREVIATED,
  },
  {
    id: Article.UNIT.MTK.STANDARDISED,
    name: Article.UNIT.MTK.ABBREVIATED,
  },
  {
    id: Article.UNIT.MIN.STANDARDISED,
    name: Article.UNIT.MIN.ABBREVIATED,
  },
  {
    id: Article.UNIT.HUR.STANDARDISED,
    name: Article.UNIT.HUR.ABBREVIATED,
  },
  {
    id: Article.UNIT.SA.STANDARDISED,
    name: Article.UNIT.SA.DESCRIPTIVE,
  },
  {
    id: Article.UNIT.RO.STANDARDISED,
    name: Article.UNIT.RO.DESCRIPTIVE,
  },
  {
    id: Article.UNIT.PF.STANDARDISED,
    name: Article.UNIT.PF.DESCRIPTIVE,
  },
  {
    id: Article.UNIT.PA.STANDARDISED,
    name: Article.UNIT.PA.DESCRIPTIVE,
  },
  {
    id: Article.UNIT.BJ.STANDARDISED,
    name: Article.UNIT.BJ.DESCRIPTIVE,
  },
  {
    id: Article.UNIT.BG.STANDARDISED,
    name: Article.UNIT.BG.DESCRIPTIVE,
  },
  {
    id: Article.UNIT.PK.STANDARDISED,
    name: Article.UNIT.PK.DESCRIPTIVE,
  },
  {
    id: Article.UNIT.TU.STANDARDISED,
    name: Article.UNIT.TU.DESCRIPTIVE,
  },
  {
    id: Article.UNIT.CA.STANDARDISED,
    name: Article.UNIT.CA.DESCRIPTIVE,
  },
  {
    id: Article.UNIT.CI.STANDARDISED,
    name: Article.UNIT.CI.DESCRIPTIVE,
  },
  {
    id: Article.UNIT.PR.STANDARDISED,
    name: Article.UNIT.PR.DESCRIPTIVE,
  },
];

export const WEIGHT_UNITS = [
  {
    id: Article.UNIT.TNE.STANDARDISED,
    name: Article.UNIT.TNE.ABBREVIATED,
  },
  {
    id: Article.UNIT.KGM.STANDARDISED,
    name: Article.UNIT.KGM.ABBREVIATED,
  },
];
