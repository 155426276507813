import { Grid } from '@mui/material';

import Address from '~/models/masterdata/Address';
import ValueGroup from '~/models/deliveries/ValueGroup';

import { EditingHistorySafe as EditingHistory } from '~/components/deliveries/deliveryNote/EditingHistorySafe';

import { withErrorBoundary } from '~/ui/atoms';

export const ArticleManufacturer = withErrorBoundary(
  ({ manufacturer }) => (
    <Grid container spacing={4} justifyContent="space-between">
      <Grid item xs={12} lg={6}>
        <div>Hersteller</div>
        <div className="font-bold">
          <EditingHistory value={manufacturer?.name} />
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <div>Adresse</div>
        <div className="font-bold">
          <EditingHistory
            value={ValueGroup.applyFunction(
              manufacturer?.address,
              Address.getConcatenatedAddress,
            )}
          />
        </div>
      </Grid>
    </Grid>
  ),
  'Daten des Herstellers konnten nicht geladen werden.',
);

ArticleManufacturer.displayName = 'ArticleManufacturer';
