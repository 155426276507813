import { InputLabel, Checkbox, FormControlLabel } from '@mui/material';

import { LightTooltipWide } from '~/utils/componentUtils';

import BasicForm from '~/components/BasicForm';

import { withErrorBoundary } from '~/ui/atoms';
import { MultiSelectSites } from '~/ui/molecules/SelectServerDriven';

import { useBlacklistSiteForm } from './useBlacklistSiteForm';

export const BlacklistSiteForm = withErrorBoundary(
  ({
    closeForm,
    defaultSelectedSiteId,
    deliveryNote,
    open,
    refreshDeliveryNote,
  }) => {
    const { handleCancel, handleSubmit, setValue, state } =
      useBlacklistSiteForm({
        closeForm,
        defaultSelectedSiteId,
        deliveryNote,
        refreshDeliveryNote,
      });

    return (
      <BasicForm
        open={open}
        formSuccess={handleSubmit}
        formAbort={handleCancel}
        title="Standort von Zuordnung ausschließen"
        submitButtonTitle="Standort ausschließen"
        submittingForm={state.submittingForm}
        noDeleteForm
      >
        <div className="text-13px text-grey600 w-[600px]">
          Wähle aus, welcher Standort von der Zuordnung zum Lieferort der
          Lieferung ausgeschlossen und niemals zu diesem zugeordnet werden soll.
        </div>
        <InputLabel className="text-13px mt-20px">Standort</InputLabel>
        <div className="w-[600px]">
          <MultiSelectSites
            placeholder="Standort auswählen"
            value={state.form.selectedSite ? [state.form.selectedSite] : []}
            onChange={(site, siteData) => {
              setValue('selectedSite', site?.id ?? '', siteData);
            }}
            isMultiSelect={false}
          />
        </div>
        <div>
          <LightTooltipWide title="Eine Zuordnung basiert normalerweise auf der vollständigen Adresse des Orts. Wenn die Adresse ignoriert wird, wird nur die Bezeichnung des Orts in der Zuordnung verwendet.">
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.form.ignoreAddressInfo}
                  onChange={(e) =>
                    setValue('ignoreAddressInfo', e.target.checked)
                  }
                  name="ignoreAddressInfo"
                />
              }
              label="Nur Lieferort-Bezeichnung verwenden und -Adresse ignorieren für Zuordnung"
            />
          </LightTooltipWide>
        </div>
      </BasicForm>
    );
  },
  null,
);
