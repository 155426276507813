import { useQueriesSites } from '~/data/site';
import { type UUID } from '~/types/common';
import { useQueriesUsers } from '~/data/user';

type PermittedUsersData = {
  data: Record<string, any>;
  isLoading: boolean;
  isError: boolean;
};

/**
 * This hook combines results from the sites and user queries and returns a list of permitted users,
 * along with the loading and error states.
 *
 * @param {UUID[]} siteIds - An array of site IDs to fetch associated users.
 *
 * @returns {PermittedUsersData} - The result object contains the following properties:
 *   - `data` (Array<User>): A filtered list of permitted users associated with the given sites.
 *   - `isLoading` (boolean): Indicates whether any of the queries (site or user) are still loading.
 *   - `isError` (boolean): Indicates whether any of the queries (site or user) have encountered an error.
 */

export const useGetPermittedUsersOfSites = (
  siteIds: UUID[],
): PermittedUsersData => {
  const sitesData = useQueriesSites(siteIds);

  const userIds = sitesData.flatMap(
    (siteData) =>
      siteData?.data?.permissionsFrom
        .filter((permissionGrant) => permissionGrant.subjectType === 'user')
        .map((permissionGrant) => permissionGrant.subjectId) || [],
  );

  const usersResults = useQueriesUsers(userIds);

  const isLoading =
    sitesData.some((site) => site.isLoading) ||
    usersResults.some((result) => result.isLoading);

  const isError =
    sitesData.some((site) => site.isError) ||
    usersResults.some((result) => result.isError);

  const permittedUsers = usersResults
    .map((result) => result.data)
    .filter(Boolean);

  return { data: permittedUsers, isError, isLoading };
};
