import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LOADING_STATE } from '~/constants/LoadingState';
import { ROUTE } from '~/constants/Route';

import { UserSignatureIcon } from '~/assets/icons';

import LocalStorageService from '~/services/localStorage.service';

import Invoice from '~/models/invoices/Invoice';
import InvoiceCheckCategory from '~/models/invoices/InvoiceCheckCategory';

import BrowserUtils from '~/utils/browserUtils';
import { dateUtils, parseDate } from '~/utils/dateUtils';
import Log from '~/utils/Log';
import unitUtils from '~/utils/unitUtils';

import { BasicTable } from '~/components/BasicTable';
import ContextMenu from '~/components/menu/ContextMenu';
import { InvoiceColumnCombinedStatus } from '~/components/invoices/invoiceColumns/InvoiceColumnCombinedStatus';
import { InvoiceColumnUnsignedDeliveryNoteIds } from '~/components/invoices/invoiceColumns/InvoiceColumnUnsignedDeliveryNoteIds';
import { RequestSignatureForm } from '~/components/deliveries/deliveryNoteActions/RequestSignatureForm';

import { withErrorBoundary } from '~/ui/atoms';

import { Tile } from './Tile';

type P = {
  timeframe: {
    from: number;
    to: number;
  };
  type: 'missingSignatures' | 'delayedSigned';
} & ComponentStyling;

export const InvoicesListTile = withErrorBoundary(
  ({ className, style, timeframe, type = 'missingSignatures' }: P) => {
    const history = useHistory();

    const [contextMenu, setContextMenu] = useState(null);
    const [filteredRows, setFilteredRows] = useState([]);

    const [loading, setLoading] = useState(LOADING_STATE.NOT_LOADED);
    const [
      requestDeliveryNoteSignatureFormOpenDeliveryNoteIds,
      setRequestDeliveryNoteSignatureFormOpenDeliveryNoteIds,
    ] = useState([]);
    const [
      requestDeliveryNoteSignatureFormOpenInvoiceId,
      setRequestDeliveryNoteSignatureFormOpenInvoiceId,
    ] = useState(null);

    const invoices = useSelector((state) => state.invoices);
    const oldestFilteredDlnDate = useSelector(
      (state) => state.filters.oldestFilteredDlnDate,
    );

    const filterRows = useCallback(() => {
      setLoading(LOADING_STATE.LOADING);

      const filtered = invoices.filteredIncomingInvoiceRows.filter(
        ({ date, delayedSigned, errorAndWarningCategories }) => {
          const inDateRange =
            Date.parse(date) >= timeframe.from &&
            Date.parse(date) <= timeframe.to;

          if (!inDateRange) {
            return false;
          }

          if (
            type === 'missingSignatures' &&
            errorAndWarningCategories.includes(
              InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY,
            )
          ) {
            return true;
          }

          if (type === 'delayedSigned' && delayedSigned) {
            return true;
          }

          return false;
        },
      );

      setFilteredRows(filtered);
      setLoading(LOADING_STATE.SUCCEEDED);
    }, [invoices.filteredIncomingInvoiceRows, timeframe, type]);

    useEffect(() => {
      filterRows();
    }, [filterRows]);

    const onOpenInvoice = (id) => {
      Log.productAnalyticsEvent('Open invoice', Log.FEATURE.HOME);
      history.push(ROUTE.INCOMING_INVOICE.ROUTE + '/' + id);
    };

    const onOpenInvoiceInNewTab = () => {
      Log.productAnalyticsEvent('Open invoice in new tab', Log.FEATURE.HOME);
      BrowserUtils.openNewTab(
        ROUTE.INCOMING_INVOICE.ROUTE + '/' + contextMenu.id,
      );
      onCloseContextMenu();
    };

    const onOpenContextMenu = (event) => {
      event.preventDefault();

      if (contextMenu) {
        // Repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu. Other native context menus might behave different.
        // With this behavior we prevent contextmenu from the backdrop to re-locale existing context men
        setContextMenu(null);
        return;
      }

      Log.productAnalyticsEvent('Open context menu', Log.FEATURE.MENU);
      setContextMenu({
        id: event.currentTarget.dataset.id,
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      });
    };

    const onCloseContextMenu = () => {
      Log.productAnalyticsEvent('Close context menu', Log.FEATURE.MENU);
      setContextMenu(null);
    };

    const onRequestSignatures = (id, deliveryNoteIds) => {
      setRequestDeliveryNoteSignatureFormOpenDeliveryNoteIds(deliveryNoteIds);
      setRequestDeliveryNoteSignatureFormOpenInvoiceId(id);
    };

    const getColumns = () => {
      const columns = [];

      if (type === 'missingSignatures') {
        columns.push({
          field: 'unsignedDeliveryNoteIds',
          headerName: 'Aktionen',
          renderCell: (params) => (
            <InvoiceColumnUnsignedDeliveryNoteIds
              params={params}
              onRequestSignatures={onRequestSignatures}
            />
          ),
          sortable: true,
          width: 100,
        });
      }

      columns.push(
        {
          field: 'combinedStatus',
          headerName: 'Status',
          renderCell: (params) => (
            <div className="flex h-full items-center gap-1 py-0.5">
              <InvoiceColumnCombinedStatus params={params} />
            </div>
          ),
          sortable: true,
          width: 130,
        },
        {
          field: Invoice.PROPERTY.SELLER.KEY,
          headerName: Invoice.PROPERTY.SELLER.STRING,
          resizableText: true,
          sortable: true,
          width: 200,
        },
        {
          field: Invoice.PROPERTY.NUMBER.KEY,
          headerName: Invoice.PROPERTY.NUMBER.STRING,
          resizableText: true,
          sortable: true,
          width: 100,
        },
        {
          field: Invoice.PROPERTY.TOTAL_PRICE_GROSS.KEY,
          headerName: Invoice.PROPERTY.TOTAL_PRICE_GROSS.STRING,
          renderCell: (params) =>
            unitUtils.formatDeMoneyAmount_safe(params.value),
          resizableText: true,
          sortable: true,
          type: 'number',
          width: 100,
        },
        {
          field: Invoice.PROPERTY.CURRENCY.KEY,
          headerName: Invoice.PROPERTY.CURRENCY.STRING,
          resizableText: true,
          sortable: true,
          width: 10,
        },
        {
          field: Invoice.PROPERTY.DATE.KEY,
          headerName: Invoice.PROPERTY.DATE.STRING,
          renderCell: (params) =>
            dateUtils.getFormattedDate_safe(
              params.value,
              dateUtils.DATE_FORMAT.DD_MM_YYYY,
            ),
          resizableText: true,
          sortable: true,
          type: 'date',
          valueGetter: parseDate,
          width: 120,
        },
        {
          field: Invoice.PROPERTY.TO_SITE.KEY,
          headerName: Invoice.PROPERTY.TO_SITE.STRING,
          resizableText: true,
          sortable: true,
          width: 300,
        },
      );

      return columns;
    };

    const getTitle = () => {
      if (type === 'missingSignatures') {
        return 'Rechnungen mit fehlenden Signaturen: ' + filteredRows.length;
      }

      if (type === 'delayedSigned') {
        return (
          'Rechnungen mit nachträglichen Signaturen: ' + filteredRows.length
        );
      }

      return null;
    };

    const getRightAnnotation = () => {
      if (!oldestFilteredDlnDate) {
        return null;
      }

      const fromDate = dateUtils.getFormattedDate_safe(
        timeframe.from,
        dateUtils.DATE_FORMAT.DD_MM_YYYY,
      );

      const toDate = dateUtils.getFormattedDate_safe(
        timeframe.to,
        dateUtils.DATE_FORMAT.DD_MM_YYYY,
      );

      return `(${fromDate} - ${toDate})`;
    };

    const getCookieId = () => {
      if (type === 'missingSignatures') {
        return LocalStorageService.MISSING_SIGNATURES_INVOICES_TILE;
      }

      if (type === 'delayedSigned') {
        return LocalStorageService.DELAYED_SIGNATURES_INVOICES_TILE;
      }

      return null;
    };

    const getIsLoading = () =>
      invoices.incomingInvoicesLoading === LOADING_STATE.LOADING;
    const getIsError = () =>
      invoices.incomingInvoicesLoading === LOADING_STATE.FAILED;

    return (
      <Tile
        title={getTitle()}
        rightAnnotation={getRightAnnotation()}
        icon={<UserSignatureIcon className="icon-medium" />}
        width={2}
        isLoading={getIsLoading()}
        isError={getIsError()}
        className={className}
        style={style}
      >
        <div className="mt-2 flex-1">
          <BasicTable
            className="h-60"
            rows={filteredRows}
            columns={getColumns()}
            pageSize={100_000}
            onRowClick={(rowData) => {
              onOpenInvoice(rowData.row.id);
            }}
            onRowRightClick={onOpenContextMenu}
            sortModel={[
              {
                field: Invoice.PROPERTY.DATE.KEY,
                sort: 'desc',
              },
            ]}
            loading={loading}
            localStorageKey={getCookieId()}
            productAnalyticsFeature={Log.FEATURE.HOME}
            hideFooter
            hideHeader
          />
          <ContextMenu
            contextMenu={contextMenu}
            onClose={onCloseContextMenu}
            onOpen={() => {
              onOpenInvoice(contextMenu.id);
            }}
            onOpenInNewTab={onOpenInvoiceInNewTab}
          />
          {/* For an explanation why the form is not in the InvoiceColumnUnsignedDeliveryNoteIds component, check the comments in DeliveryList.js */}
          {requestDeliveryNoteSignatureFormOpenInvoiceId ? (
            <RequestSignatureForm
              permittedUserIds={[]}
              deliveryNoteIds={
                requestDeliveryNoteSignatureFormOpenDeliveryNoteIds
              }
              open={requestDeliveryNoteSignatureFormOpenInvoiceId}
              closeForm={() => {
                setRequestDeliveryNoteSignatureFormOpenDeliveryNoteIds([]);
                setRequestDeliveryNoteSignatureFormOpenInvoiceId(null);
              }}
            />
          ) : null}
        </div>
      </Tile>
    );
  },
  null,
);

InvoicesListTile.displayName = 'InvoicesListTile';
