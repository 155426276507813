import Article from '~/models/articles/Article';
import ValueGroup from '~/models/deliveries/ValueGroup';

import { withErrorBoundary } from '~/ui/atoms';

export const DeliveryNoteArticleSummary = withErrorBoundary(
  ({ deliveryDirection, deliveryNote }) => {
    const getTotalWeight = () => {
      return deliveryDirection ===
        Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY
        ? ValueGroup.getCurrentValue(deliveryNote?.totalWeightDeliveredArticles)
        : ValueGroup.getCurrentValue(deliveryNote?.totalWeightReturnedArticles);
    };

    const getArticleCount = () => {
      return deliveryDirection ===
        Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY
        ? deliveryNote?.deliveredArticles?.length
        : deliveryNote?.returnedArticles?.length;
    };

    return (
      <div className="flex items-end justify-between border-b border-gray-200 px-4 py-2">
        <div>
          <div className="font-bold">
            {deliveryDirection ===
            Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY
              ? 'Auslieferung'
              : 'Retoure'}
          </div>
          <div>
            <span className="font-bold">{getArticleCount() ?? '-'}</span>{' '}
            Positionen
          </div>
        </div>
        <div>
          <div className="text-end">
            Gesamtgewicht:{' '}
            <span className="font-bold">{getTotalWeight() ?? '-'}</span>
          </div>
        </div>
      </div>
    );
  },
  'Gewicht und Anzahl der Artikel konnten nicht geladen werden.',
);
