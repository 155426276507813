export const NavigationTestIds = {
  DELIVERY: {
    BUTTON: 'Navigation-delivery-button',
    CHILDREN: {
      CHANGES: {
        BUTTON: 'Navigation-delivery-changes-button',
      },
      CONCRETE_DIARY: {
        BUTTON: 'Navigation-delivery-concreate-diary-button',
      },
      CREATE_DELIVERY_NOTE: {
        BUTTON: 'Navigation-delivery-create-delivery-note-button',
      },
      CREATE_SERVICE_NOTE: {
        BUTTON: 'Navigation-delivery-create-service-note-button',
      },
      LIST: {
        BUTTON: 'Navigation-delivery-list-button',
      },
    },
    EXPAND_BUTTON: 'Navigation-delivery-expand-button',
  },
  HOME: {
    BUTTON: 'Navigation-home-button',
  },
  INVOICE: {
    BUTTON: 'Navigation-invoice-button',
    CHILDREN: {
      INCOMING_INVOICES: {
        BUTTON: 'Navigation-invoice-incoming-invoices-button',
      },
      OUTGOING_INVOICES: {
        BUTTON: 'Navigation-invoice-outgoing-invoices-button',
      },
    },
  },
  SETTINGS: {
    BUTTON: 'Navigation-settings-button',
  },
  STATISTIC: {
    BUTTON: 'Navigation-statistic-button',
    CHILDREN: {
      CHARTS: {
        BUTTON: 'Navigation-statistic-chart-button',
      },
      REPORT: {
        BUTTON: 'Navigation-statistic-report-button',
      },
    },
    EXPAND_BUTTON: 'Navigation-statistic-expand-button',
  },
  SUPPLIER: {
    BUTTON: 'Navigation-supplier-button',
  },
} as const;
