import { memo, useCallback, useMemo } from 'react';
import { Grid, TextField } from '@mui/material';

import {
  ComponentTestIds,
  OrgUnitsSettingsTestIds,
  UserGroupsSettingsTestIds,
} from '~/constants/test-ids';

import PermissionGrant from '~/models/masterdata/PermissionGrant';

import UserUtils from '~/utils/userUtils';

import BasicForm from '~/components/BasicForm';
import {
  MultiItemsManagerOrganizationalUnits,
  MultiItemsManagerUserGroups,
  MultiItemsManagerUsers,
} from '~/ui/molecules/MultiItemsManager';

import { OrganizationalUnitPaths } from '../../paths/OrganizationalUnitPaths';
import { UserGroupPaths } from '../../paths/UserGroupPaths';
import { PermissionGrantSubjectTable } from '../../permissionGrant/PermissionsTable';

import { useUserGroupForm } from './useUserGroupForm';

const propsAreEqual = (prevProps, nextProps) =>
  prevProps.open === nextProps.open &&
  prevProps.type === nextProps.type &&
  prevProps.userGroupId === nextProps.userGroupId &&
  prevProps.onOpenOrganisationalGroup === nextProps.onOpenOrganisationalGroup &&
  prevProps.onOpenUser === nextProps.onOpenUser &&
  prevProps.onOpenUserGroup === nextProps.onOpenUserGroup;

export const UserGroupForm = memo(
  ({
    closeForm,
    onOpenOrganisationalGroup,
    onOpenUser,
    onOpenUserGroup,
    onRefreshEntities,
    onUpdatedOrganisationalGroupsChange,
    onUpdatedUsersChange,
    open,
    type,
    userGroupId,
  }) => {
    const {
      handleCancel,
      handleChangeChildUserGroups,
      handleChangeOrganisationalGroups,
      handleChangeParentUserGroups,
      handleChangeUsers,
      handleDelete,
      handleInputChange,
      handleSubmit,
      isCreatingUserGroup,
      isDeleting,
      isError,
      isLoading,
      isSubmitting,
      refetchUserGroup,
      unsavedChanges,
      userGroup,
    } = useUserGroupForm({
      closeForm,
      type,
      userGroupId,
    });

    const shouldRenderGroupPaths = useMemo(
      () =>
        userGroup?.parentOrganizationalUnitPaths?.length > 0 ||
        userGroup?.userGroupPaths?.length > 0,
      [
        userGroup?.parentOrganizationalUnitPaths?.length,
        userGroup?.userGroupPaths?.length,
      ],
    );

    const missingPermissionsToSubmit = useMemo(
      () =>
        isCreatingUserGroup
          ? !UserUtils.isUserGroupCreateAllowedUser()
          : !UserUtils.isUserGroupWriteAllowedUser(),
      [isCreatingUserGroup],
    );

    const missingPermissionsToDelete = useMemo(
      () => !UserUtils.isUserGroupDeleteAllowedUser(),
      [],
    );

    const handleOpenUser = useCallback(
      (user, unsavedChanges) => {
        onOpenUser(user, unsavedChanges);
      },
      [onOpenUser],
    );

    const handleOpenUserGroup = useCallback(
      (userGroup, unsavedChanges) => {
        onOpenUserGroup(userGroup, unsavedChanges);
      },
      [onOpenUserGroup],
    );

    const handleOpenOrganisationalGroup = useCallback(
      (group, unsavedChanges) => {
        onOpenOrganisationalGroup(group, unsavedChanges);
      },
      [onOpenOrganisationalGroup],
    );

    return (
      <BasicForm
        open={open}
        formSuccess={handleSubmit}
        formAbort={handleCancel}
        formDelete={isCreatingUserGroup ? null : handleDelete}
        title={`Benutzer-Gruppe ${
          isCreatingUserGroup ? 'erstellen' : userGroup?.name
        }`}
        fullWidth
        submittingForm={isSubmitting}
        deletingForm={isDeleting}
        id={userGroup?.id}
        unsavedChanges={unsavedChanges}
        missingPermissionsToSubmit={missingPermissionsToSubmit}
        missingPermissionsToDelete={missingPermissionsToDelete}
        testId={ComponentTestIds.FORM_MODAL.SUBMIT_BUTTON}
      >
        <Grid container direction="row" spacing={3} space={4}>
          {shouldRenderGroupPaths ? (
            <Grid item xs={12}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <OrganizationalUnitPaths
                    id={userGroup?.id}
                    organizationalUnitPaths={
                      userGroup?.parentOrganizationalUnitPaths
                    }
                    onOpenOrganizationalUnit={(organizationalUnit) =>
                      handleOpenOrganisationalGroup(
                        organizationalUnit,
                        unsavedChanges,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <UserGroupPaths
                    id={userGroup.id}
                    userGroupPaths={userGroup.userGroupPaths}
                    onOpenUserGroup={(userGroup) =>
                      handleOpenUserGroup(userGroup, unsavedChanges)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <h3 className="main-text mt-0">Benutzer-Gruppe</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="name-input"
                  name="name"
                  label="Name"
                  type="text"
                  fullWidth
                  required
                  value={userGroup?.name}
                  onChange={handleInputChange}
                  autoFocus
                  autoComplete="off"
                  inputProps={{
                    'data-testid': UserGroupsSettingsTestIds.MODAL_FIELDS.NAME,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3 className="mt-20px main-text">Enthält die folgenden...</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <MultiItemsManagerUsers
                  isCreatingNewEntity={isCreatingUserGroup}
                  parentUserGroupId={userGroupId}
                  onSaveSelection={handleChangeUsers}
                  onSelectedItemClick={(user) =>
                    handleOpenUser(user, unsavedChanges)
                  }
                  onChange={onUpdatedUsersChange}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <MultiItemsManagerUserGroups
                  isCreatingNewEntity={isCreatingUserGroup}
                  parentUserGroupId={userGroupId}
                  onSaveSelection={handleChangeChildUserGroups}
                  onSelectedItemClick={(userGroup) =>
                    handleOpenUserGroup(userGroup, unsavedChanges)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3 className="mt-20px main-text">Ist Teil von...</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <MultiItemsManagerOrganizationalUnits
                  isCreatingNewEntity={isCreatingUserGroup}
                  filterByEntity={{
                    userGroup: userGroupId,
                  }}
                  onSaveSelection={handleChangeOrganisationalGroups}
                  onSelectedItemClick={(organisationalGroup) =>
                    handleOpenOrganisationalGroup(
                      organisationalGroup,
                      unsavedChanges,
                    )
                  }
                  onChange={onUpdatedOrganisationalGroupsChange}
                  openSelectTestId={
                    OrgUnitsSettingsTestIds.SELECT_ITEM
                      .PART_OF_ORGANIZATIONAL_GROUP
                  }
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <MultiItemsManagerUserGroups
                  isCreatingNewEntity={isCreatingUserGroup}
                  filterByEntity={{
                    userGroup: userGroupId,
                  }}
                  onSaveSelection={handleChangeParentUserGroups}
                  onSelectedItemClick={(userGroup) =>
                    handleOpenUserGroup(userGroup, unsavedChanges)
                  }
                  openSelectTestId={
                    UserGroupsSettingsTestIds.SELECT_ITEM
                      .PART_OF_USER_GROUP_BUTTON
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {isCreatingUserGroup ? null : (
            <Grid item xs={12}>
              <PermissionGrantSubjectTable
                title="Worauf ist diese Benutzer-Gruppe berechtigt?"
                permissionGrantsOn={userGroup?.permissionGrantsOn}
                defaultSubjects={[userGroup?.id]}
                defaultSubjectType={PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY}
                fixedPicker={PermissionGrant.TYPE.SUBJECT}
                refreshData={refetchUserGroup}
                isError={isError}
                isLoading={isLoading}
              />
            </Grid>
          )}
        </Grid>
      </BasicForm>
    );
  },
  propsAreEqual,
);

UserGroupForm.displayName = 'UserGroupForm';
