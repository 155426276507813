import { memo, useCallback, useState } from 'react';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { useQueryCompaniesLegacy } from '~/data/company';
import { useQueryCostCentersLegacy } from '~/data/costCenter';
import { useQueryCustomFieldsLegacy } from '~/data/customField';
import { useQuerySignatureFields } from '~/data/signatureField';
import { useQuerySiteLegacy } from '~/data/site';

import LocalStorageService from '~/services/localStorage.service';

import { withErrorBoundary } from '~/ui/atoms';

import { SettingsTable } from '../../SettingsTable';

import { columns } from './constants';
import { selectSignatureFields } from './selectSignatureFields';

export const SignatureFieldsTable = withErrorBoundary(
  memo(() => {
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: PAGINATION_PAGE_SIZE_DEFAULT,
    });
    const [sortModel, setSortModel] = useState([
      {
        field: 'name',
        sort: 'asc',
      },
    ]);

    const { data: sites } = useQuerySiteLegacy();
    const { data: companies } = useQueryCompaniesLegacy();
    const { data: costCenters } = useQueryCostCentersLegacy();
    const { data: customFields } = useQueryCustomFieldsLegacy();

    const [searchString, setSearchString] = useState(undefined);

    const { data, isPending, isFetching, isPlaceholderData, refetch } =
      useQuerySignatureFields(
        {
          limit: paginationModel.pageSize,
          offset: paginationModel.page * paginationModel.pageSize,
          orderBy: sortModel[0]?.field,
          searchString,
          sort: sortModel[0]?.sort,
        },
        {
          select: (data) =>
            selectSignatureFields(
              data,
              customFields,
              companies,
              sites,
              costCenters,
            ),
        },
      );

    const handlePaginationModelChange = useCallback((newModel) => {
      setPaginationModel(newModel);
    }, []);

    const handleSortModelChange = useCallback((newModel) => {
      setSortModel(newModel);
    }, []);

    const handleSearchStringChange = useCallback((newSearchString) => {
      // Show results from start when search string changes.
      setPaginationModel({
        ...paginationModel,
        page: 0,
      });
      setSearchString(newSearchString);
    }, []);

    const filterFunction = useCallback((string) => string, []); // We filter server side -> prevent the DataGrid from filtering.

    return (
      <SettingsTable
        entity="signatureField"
        title="Signaturfelder"
        columns={columns}
        filterFunction={filterFunction}
        items={data?.signatureFields ?? []}
        isLoading={isPending || (isFetching && isPlaceholderData)}
        localStorageKey={LocalStorageService.SIGNATURE_FIELD_TABLE}
        onPaginationModelChange={handlePaginationModelChange}
        onSearchStringChange={handleSearchStringChange}
        onSortModelChange={handleSortModelChange}
        paginationModel={paginationModel}
        refreshData={refetch}
        sortModel={sortModel}
        rows={data?.rows ?? []}
        sortBy="name"
        totalRowCount={data?.totalCount ?? 0}
      />
    );
  }),
  'Benutzer-Gruppen konnten nicht geladen werden.',
);

SignatureFieldsTable.displayName = 'SignatureFieldsTable';
