import { type UUID } from '~/types/common';

import { type CostCentersQueryParams } from './useQueryCostCenters';

/**
 * Query key factory for cost center-related queries.
 */
export const queryKeysCostCenter = {
  base: () => 'costCenters' as const,
  get: (costCenterId: UUID) =>
    [queryKeysCostCenter.base(), 'detail', { costCenterId }] as const,
  getAll: (queryParams: Partial<CostCentersQueryParams>) =>
    [queryKeysCostCenter.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<CostCentersQueryParams>) =>
    [queryKeysCostCenter.base(), 'list', 'infinite', queryParams] as const,
  getAllLegacy: () => [queryKeysCostCenter.base(), 'list', 'LEGACY'] as const,
  getBulk: (ids: UUID[]) =>
    [queryKeysCostCenter.base(), 'bulk', { ids }] as const,
} as const;
