import { useSelector, useDispatch } from 'react-redux';

import { setInvoice_query } from '~/redux/filtersSlice';

import Log from '~/utils/Log';

import { FreeTextSearch } from '~/components/baseComponents/inputs/FreeTextSearch';

export const InvoiceFreeTextFilter = () => {
  const dispatch = useDispatch();

  const query = useSelector((state) => state.filters.invoice_query);

  const handleSearchChange = (params) => {
    dispatch(setInvoice_query(params));
  };

  return (
    <div className="w-80">
      <FreeTextSearch
        value={query}
        onChange={handleSearchChange}
        productAnalyticsFeature={Log.FEATURE.INVOICE_LIST}
        debounceTime={600}
        autoFocus
      />
    </div>
  );
};
