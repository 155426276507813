export const columns = [
  {
    cellClassName: 'name-cell',
    field: 'name',
    headerClassName: 'name-header',
    headerName: 'Name',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    cellClassName: 'address-cell',
    field: 'address',
    headerClassName: 'address-header',
    headerName: 'Adresse',
    resizableText: true,
    sortable: false, // The API is only sorting by city or country.
    width: 400,
  },
  {
    cellClassName: 'id-cell',
    field: 'id',
    headerClassName: 'id-header',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
];
