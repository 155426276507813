import { useDispatch, useSelector } from 'react-redux';

import { LOADING_STATE } from '~/constants/LoadingState';
import { ROUTE } from '~/constants/Route';

import { IncomingInvoiceOutlinedIcon } from '~/assets/icons';

import {
  setInvoice_dateRange,
  setInvoice_individualDateRange,
  setInvoice_predefinedDateRange,
} from '~/redux/filtersSlice';

import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';

import { dateUtils } from '~/utils/dateUtils';
import ArrayUtils from '~/utils/arrayUtils';

import { withErrorBoundary } from '~/ui/atoms';

import { NumberTile } from './NumberTile';

type P = {
  timeframe: {
    from: number; // epoch timestamp
    to: number; // epoch timestamp
  };
} & ComponentStyling;

export const IncomingInvoiceTile = withErrorBoundary(
  ({ className, style, timeframe }: P) => {
    const dispatch = useDispatch();

    const { filteredIncomingInvoices, incomingInvoicesLoading } = useSelector(
      ({ invoices }) => invoices,
    );
    const selectedPredefinedDateRange = useSelector(
      ({ filters }) => filters.home_selectedPredefinedDateRange,
    );
    const selectedDateRange = useSelector(
      ({ filters }) => filters.home_selectedDateRange,
    );
    const individualDateRangeIsSelected = useSelector(
      ({ filters }) => filters.home_individualDateRange,
    );

    const filteredInvoices = filteredIncomingInvoices.filter(
      ({ date }) =>
        Date.parse(date) >= timeframe.from && Date.parse(date) <= timeframe.to,
    );

    const lastInvoiceDate = ArrayUtils.sortByKey(
      filteredIncomingInvoices,
      'date',
      true,
    )[0]?.date;
    const formattedLastInvoiceDate = dateUtils.getFormattedDate_safe(
      lastInvoiceDate,
      dateUtils.DATE_FORMAT.DD_MM_YYYY,
    );

    const openInvoices = () => {
      dispatch(setInvoice_predefinedDateRange(selectedPredefinedDateRange));
      dispatch(
        setInvoice_dateRange({
          dateRange: selectedDateRange,
          updateCookie: true,
        }),
      );
      dispatch(setInvoice_individualDateRange(individualDateRangeIsSelected));
    };

    return (
      <NumberTile
        title={'Eingangsrechnungen'}
        icon={<IncomingInvoiceOutlinedIcon className="icon-medium" />}
        isLoading={incomingInvoicesLoading === LOADING_STATE.LOADING}
        subheader={
          formattedLastInvoiceDate
            ? 'Letzter Eingang: ' + formattedLastInvoiceDate
            : null
        }
        buttonTitle={'Zu den Eingangsrechnungen'}
        buttonLink={ROUTE.INCOMING_INVOICES.ROUTE}
        buttonCallback={openInvoices}
        firstNumberTitle={'Gesamt'}
        firstNumber={filteredInvoices.length}
        secondNumberTitle={'Fehlerhaft'}
        secondNumber={
          filteredInvoices.filter(({ status }) =>
            [
              InvoiceCheckResult.STATUS.ERROR,
              InvoiceCheckResult.STATUS.WARNING,
            ].includes(status),
          ).length
        }
        className={className}
        style={style}
      />
    );
  },
  null,
);

IncomingInvoiceTile.displayName = 'IncomingInvoiceTile';
