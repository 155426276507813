import ms from 'ms';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { type UUID } from '~/types/common';

import { queryKeysDeliveryNote } from './queryKeys';

/**
/**
 * Fetches delivery note attachment data from the API.
 * @param {UUID} chainId - The ID of the delivery note chain.
 * @param {string} digest - The digest of the attachment.
 * @returns {Promise<Blob>} The attachment data as a blob if successful.
 * @see https://app.dev.vestigas.com/redoc#tag/Delivery-Note/operation/get_delivery_note_attachment_asset_delivery_note__id__attachment_get
 */
export const fetchAttachment = async (
  chainId: UUID,
  digest: string,
): Promise<Blob> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.DELIVERY_NOTE.GET_ATTACHMENT(chainId, digest))
      .blob();

    return response;
  } catch (error) {
    console.error('Error fetching attachment:', error);

    Log.productAnalyticsEvent(
      'Failed to load attachment',
      Log.FEATURE.OTHER_FEATURE,
      Log.TYPE.ERROR,
    );

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting an attachment for a delivery note.
 * @param {UUID} chainId - The ID of the delivery note chain.
 * @param {string} digest - The digest of the attachment.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<Blob>} The result of the useQuery hook.
 */
export const useQueryAttachment = (
  { chainId, digest }: { chainId: UUID; digest: string },
  options?: Record<string, unknown>,
): UseQueryResult<Blob> =>
  useQuery({
    enabled: Boolean(chainId) && Boolean(digest),
    gcTime: ms('10m'),
    queryFn: async () => fetchAttachment(chainId, digest),
    queryKey: queryKeysDeliveryNote.getAttachment(chainId, digest),
    staleTime: Infinity,
    ...options,
  });
