export const columns = [
  {
    cellClassName: 'email-cell',
    field: 'email',
    headerClassName: 'email-header',
    headerName: 'E-Mail',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    cellClassName: 'companyName-cell',
    field: 'companyName',
    headerClassName: 'companyName-header',
    headerName: 'Firma',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    cellClassName: 'isActive-cell',
    field: 'isActive',
    headerClassName: 'isActive-header',
    headerName: 'Aktiv',
    resizableText: true,
    sortable: true,
    width: 100,
  },
  {
    cellClassName: 'isActive-cell',
    field: 'id',
    headerClassName: 'id-header',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
] as const;
