import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  InfoOutlined as InfoOutlinedIcon,
  Language as LanguageIcon,
  Lock as LockIcon,
  MailOutline as MailOutlineIcon,
  SettingsSharp as SettingsSharpIcon,
  Storage as StorageIcon,
} from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { useQueryUserData } from '~/data/user';

import UserService from '~/services/user.service';

import { ROUTE } from '~/constants/Route';

import RouteUtils from '~/utils/routeUtils';
import BrowserUtils from '~/utils/browserUtils';
import Log from '~/utils/Log';

import { ArticleOutlinedIcon } from '~/assets/icons';

const menuItems = [
  {
    area: 'general',
    component: <div>Profil</div>,
    icon: <MailOutlineIcon />,
    name: 'Profil',
    route: ROUTE.SETTINGS_PROFILE.ROUTE,
  },
  {
    area: 'general',
    component: <div>Support</div>,
    icon: <LanguageIcon />,
    name: 'Support',
    route: ROUTE.SETTINGS_SUPPORT.ROUTE,
  },
  {
    area: 'general',
    component: <div>Stammdaten</div>,
    icon: <StorageIcon />,
    name: 'Stammdaten',
    route: ROUTE.SETTINGS_MASTERDATA.ROUTE,
  },
  {
    area: 'masterdata',
    component: <div className="submenu-item word-break">Benutzer</div>,
    icon: '',
    name: 'Benutzer',
    route: ROUTE.SETTINGS_USER.ROUTE,
  },
  {
    area: 'masterdata',
    component: <div className="submenu-item word-break">Standorte</div>,
    icon: '',
    name: 'Standorte',
    route: ROUTE.SETTINGS_SITE.ROUTE,
  },
  {
    area: 'masterdata',
    component: <div className="submenu-item word-break">Kostenstellen</div>,
    icon: '',
    name: 'Kostenstellen',
    route: ROUTE.SETTINGS_COST_CENTER.ROUTE,
  },
  {
    area: 'masterdata',
    component: <div className="submenu-item word-break">Fahrzeuge</div>,
    icon: '',
    name: 'Fahrzeuge',
    route: ROUTE.SETTINGS_VEHICLE.ROUTE,
  },
  {
    area: 'masterdata',
    component: <div className="submenu-item word-break">Firmen</div>,
    icon: '',
    name: 'Firmen',
    route: ROUTE.SETTINGS_COMPANY.ROUTE,
  },
  {
    area: 'masterdata',
    component: (
      <div className="submenu-item word-break">Organisations-Gruppen</div>
    ),
    icon: '',
    name: 'Organisations-Gruppen',
    route: ROUTE.SETTINGS_ORGANISATIONAL_GROUP.ROUTE,
  },
  {
    area: 'masterdata',
    component: <div className="submenu-item word-break">Benutzer-Gruppen</div>,
    icon: '',
    name: 'Benutzer-Gruppen',
    route: ROUTE.SETTINGS_USER_GROUP.ROUTE,
  },
  {
    area: 'articleMaster',
    component: <div className="submenu-item word-break">Artikel</div>,
    icon: '',
    name: 'Artikel',
    route: ROUTE.SETTINGS_ARTICLES.ROUTE,
  },
  {
    area: 'articleMaster',
    component: <div className="submenu-item word-break">Kategorien</div>,
    icon: '',
    name: 'Kategorien',
    route: ROUTE.SETTINGS_CATEGORIES.ROUTE,
  },
  {
    area: 'admin',
    component: <div className="submenu-item word-break">Daten ausleiten</div>,
    icon: '',
    name: 'Daten ausleiten',
    route: ROUTE.SETTINGS_DATA_SUBSCRIPTIONS.ROUTE,
  },
  {
    area: 'admin',
    component: <div className="submenu-item word-break">Flexible Felder</div>,
    icon: '',
    name: 'Flexible Felder',
    route: ROUTE.SETTINGS_CUSTOM_FIELDS.ROUTE,
  },
  {
    area: 'admin',
    component: <div className="submenu-item word-break">Signaturfelder</div>,
    icon: '',
    name: 'Signaturfelder',
    route: ROUTE.SETTINGS_SIGNATURE_FIELDS.ROUTE,
  },
  {
    area: 'admin',
    component: (
      <div className="submenu-item word-break">Lieferungen-Updates</div>
    ),
    icon: '',
    name: 'Lieferungen-Updates',
    route: ROUTE.SETTINGS_CUSTOMER_SUBSCRIPTIONS.ROUTE,
  },
  {
    area: 'admin',
    component: <div className="submenu-item word-break">PDF Einstellungen</div>,
    icon: '',
    name: 'PDF Einstellungen',
    route: ROUTE.SETTINGS_PDF.ROUTE,
  },
  {
    area: 'admin',
    component: (
      <div className="submenu-item word-break">Ignorierte Artikel</div>
    ),
    icon: '',
    name: 'Ignorierte Artikel',
    route: ROUTE.SETTINGS_INVOICE_CHECK_IGNORED_ARTICLES.ROUTE,
  },
  {
    area: 'admin',
    component: <div className="submenu-item word-break">Demo</div>,
    icon: '',
    name: 'Demo',
    route: ROUTE.SETTINGS_DEMO.ROUTE,
  },
  {
    area: 'general',
    component: <div>Datenschutz</div>,
    icon: <LockIcon />,
    name: 'Datenschutz',
    route: ROUTE.SETTINGS_DATA_PROTECTION.ROUTE,
  },
  {
    area: 'general',
    component: <div>Impressum</div>,
    icon: <InfoOutlinedIcon />,
    name: 'Impressum',
    route: ROUTE.SETTINGS_IMPRESSUM.ROUTE,
  },
];

export const SettingsNav = () => {
  const location = useLocation();
  const history = useHistory();

  const {
    data: currentUser,
    isError: isErrorUserData,
    isLoading: isLoadingUserData,
    isSuccess: isSuccessUserData,
  } = useQueryUserData(true);

  const userPermissions = currentUser?.userPermissions ?? [];
  const featureFlags =
    currentUser?.companyAccountInfo?.data?.featureFlags ?? {};

  const [openMenuItem, setOpenMenuItem] = useState('Stammdaten'); // When component mounts, 'Stammdaten' page is displayed.
  const [masterDataIsExpanded, setMasterDataIsExpanded] = useState(false);

  // If the URL changes, we need to open the corresponding menu item.
  // Otherwise, the correct menu item wouldn't be expanded when user opens URL directly via browser.
  useEffect(() => {
    for (const item of menuItems) {
      if (
        location?.pathname &&
        RouteUtils.getBestFittingUrls(location.pathname).includes(item.route)
      ) {
        setOpenMenuItem(item.name);
        if (item.area === 'masterdata') {
          setMasterDataIsExpanded(true);
        }
      }
    }
  }, [location.pathname]);

  const onMenuItemClick = (name, route) => {
    Log.productAnalyticsEvent('Open ' + name, Log.FEATURE.MENU);

    // If user clicks on the already selected "Stammdaten", change the expanded/hidden status of the collapse.
    if (openMenuItem === 'Stammdaten' && name === 'Stammdaten') {
      setMasterDataIsExpanded(!masterDataIsExpanded);
    }

    setOpenMenuItem(name);

    history.push(route);
  };

  const onContextMenu = (e, name, route) => {
    e.preventDefault();

    Log.productAnalyticsEvent(
      'Open ' + name + ' via context menu',
      Log.FEATURE.MENU,
    );

    BrowserUtils.openNewTab(route);
  };

  const onExpandClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (masterDataIsExpanded) {
      Log.productAnalyticsEvent('Hide Stammdaten', Log.FEATURE.MENU);
    } else {
      Log.productAnalyticsEvent('Expand Stammdaten', Log.FEATURE.MENU);
    }

    setMasterDataIsExpanded(!masterDataIsExpanded);
  };

  // Display the menu items to the user to whose paths the user has access to.
  const displayedMenuItems = [];
  let adminMenuItemAdded = false;
  let articleMasterMenuItemAdded = false;
  for (const item of menuItems) {
    if (
      UserService.userIsAuthorizedForPage(
        item.route,
        userPermissions,
        featureFlags,
      )
    ) {
      // If the user sees at least one admin menu item, the header menu item "Admin" is added to the list.
      if (item.area === 'admin' && !adminMenuItemAdded) {
        displayedMenuItems.push({
          component: <div>Admin</div>,
          icon: <SettingsSharpIcon />,
          route: item.route,
        });
        adminMenuItemAdded = true;
      }

      // If the user sees at least one article master menu item, the header menu item "Artikelstamm" is added to the list.
      if (item.area === 'articleMaster' && !articleMasterMenuItemAdded) {
        displayedMenuItems.push({
          component: <div>Artikelstamm</div>,
          icon: <ArticleOutlinedIcon className="icon-small" />,
          route: item.route,
        });
        articleMasterMenuItemAdded = true;
      }

      displayedMenuItems.push(item);
    }
  }

  return (
    <div className="h-full rounded-md bg-white shadow-lg">
      <List>
        {/* Display all menu items except the ones that belong to Stammdaten. */}
        {displayedMenuItems
          .filter(({ area }) => area !== 'masterdata')
          .map((item) => (
            <div key={[item.name, item.route].join('-')}>
              <ListItem
                button
                onMouseDown={() => onMenuItemClick(item.name, item.route)}
                onContextMenu={(e) => onContextMenu(e, item.name, item.route)}
                selected={
                  item.area
                    ? RouteUtils.getBestFittingUrls(location.pathname).includes(
                        item.route,
                      )
                    : false
                }
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.component} />
                {item.name === 'Stammdaten' && masterDataIsExpanded ? (
                  <ArrowDropUpIcon onMouseDown={onExpandClick} />
                ) : null}
                {item.name === 'Stammdaten' && !masterDataIsExpanded ? (
                  <ArrowDropDownIcon onMouseDown={onExpandClick} />
                ) : null}
              </ListItem>
              {/* Display the menu items that belong to Stammdaten separately because they can be collapsed. */}
              {item.name === 'Stammdaten' ? (
                <Collapse
                  in={masterDataIsExpanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <List className="pb-0 pt-0">
                    {displayedMenuItems
                      .filter((item) => item.area === 'masterdata')
                      .map((item, index) => (
                        <ListItem
                          button
                          onMouseDown={() =>
                            onMenuItemClick(item.name, item.route)
                          }
                          key={index}
                          onContextMenu={(e) =>
                            onContextMenu(e, item.name, item.route)
                          }
                          selected={RouteUtils.getBestFittingUrls(
                            location.pathname,
                          ).includes(item.route)}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.component} />
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
              ) : null}
            </div>
          ))}
      </List>
    </div>
  );
};
