import { forwardRef, useImperativeHandle } from 'react';
import { InputLabel, MenuItem, Select, Button } from '@mui/material';

import {
  ENTITY_TYPES,
  SUBJECT_TYPES,
} from '~/models/masterdata/PermissionGrant';
import Permissions from '~/models/masterdata/Permissions';

import { LightTooltip } from '~/utils/componentUtils';

import { PermissionForm } from './PermissionForm';
import { PermissionGrantMultiPicker } from './PermissionGrantMultiPicker';
import { usePermissionGrantPickerItem } from './usePermissionGrantPickerItem';

const PermissionGrantPickerItem = forwardRef(
  (
    {
      fixedPicker,
      defaultSubjects,
      defaultSubjectType,
      defaultEntityType,
      defaultEntities,
    },
    ref,
  ) => {
    const {
      handleCancel,
      handleChangeEntities,
      handleChangeEntityType,
      handleChangeRole,
      handleChangeSubjects,
      handleChangeSubjectType,
      handleSubmit,
      isDisabledEntityPicker,
      isDisabledSubjectPicker,
      openPermissionForm,
      permissionFormOpen,
      permissions,
      pickedEntities,
      pickedEntityType,
      pickedSubjects,
      pickedSubjectType,
      roles,
      submit,
    } = usePermissionGrantPickerItem(fixedPicker, {
      defaultEntities,
      defaultEntityType,
      defaultSubjects,
      defaultSubjectType,
    });

    useImperativeHandle(
      ref,
      () => ({
        submit,
      }),
      [submit],
    );

    const renderRoleSelectValue = (roleKey) => {
      if (!permissions.permissionGranted()) {
        return <span className="text-mui-not-selected-grey">Rolle wählen</span>;
      }

      return roles.find(({ id }) => id === roleKey)?.name;
    };

    const getRoleSelectValue = () => {
      return permissions.getDefaultRoleName() ?? 'None';
    };

    return (
      <div className="rounded-md border border-gray-300 p-4">
        <div className="flex items-center gap-4">
          <div>
            <InputLabel className="text-13px pb-0.5">
              Berechtigung für
            </InputLabel>
            <Select
              value={pickedSubjectType}
              key={0}
              disabled={isDisabledSubjectPicker}
              onChange={handleChangeSubjectType}
              className="w-80"
              size="small"
            >
              {Object.entries(SUBJECT_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_for"
            subjectType={pickedSubjectType}
            pickedIds={pickedSubjects}
            onChange={handleChangeSubjects}
            disabled={isDisabledSubjectPicker}
            fullWidth
          />
        </div>

        <div className="mt-4 flex items-center gap-4">
          <div>
            <InputLabel className="text-13px pb-0.5">
              Berechtigung auf
            </InputLabel>
            <Select
              value={pickedEntityType}
              key={0}
              disabled={isDisabledEntityPicker}
              onChange={handleChangeEntityType}
              className="w-80"
              size="small"
            >
              {Object.entries(ENTITY_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_to"
            entityType={pickedEntityType}
            pickedIds={pickedEntities}
            onChange={handleChangeEntities}
            disabled={isDisabledEntityPicker}
            subjectType={pickedSubjectType}
            subjects={pickedSubjects}
            fullWidth // Needed for the enhanced label
          />
        </div>

        <div className="mt-4 flex items-end gap-4">
          <div>
            <InputLabel className="text-13px pb-0.5">
              Berechtigung als
            </InputLabel>
            <Select
              value={getRoleSelectValue()} // A random String has to be chosen as fallback value because otherwise renderValue wouldn't catch the case if(!permissions.permissionGranted())
              onChange={handleChangeRole}
              className="w-80"
              size="small"
              renderValue={renderRoleSelectValue}
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="mb-0.5">
            <LightTooltip title="Detaillierte Berechtigungen einsehen">
              <Button
                variant="outlined"
                color="primary"
                onClick={openPermissionForm}
              >
                Details verwalten
              </Button>
            </LightTooltip>
          </div>
        </div>

        <PermissionForm
          open={permissionFormOpen}
          formSuccess={handleSubmit}
          formAbort={handleCancel}
          permissions={permissions}
        />
      </div>
    );
  },
);

PermissionGrantPickerItem.displayName = 'PermissionGrantPickerItem';

export default PermissionGrantPickerItem;
