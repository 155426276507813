import { memo } from 'react';

import DatagridUtils from '~/utils/datagridUtils';

import { InactiveIcon } from '~/components/InactiveIcon';

type P = {
  value: string;
};

export const PermittedToSitesCell = memo(({ value }: P) => {
  const permittedToSites = value.split(';;;').map((part) => JSON.parse(part));

  if (permittedToSites.every(({ active }) => active)) {
    return DatagridUtils.displayCellTooltipControlled(
      permittedToSites.map(({ name }) => name).join(', '),
      permittedToSites.map(({ name }) => name).join(', '),
    );
  }

  return (
    <div className="flex items-center gap-1">
      {permittedToSites.map(({ active, name }) => (
        <div key={name} className="flex items-center gap-1">
          {name}
          {!active && <InactiveIcon />}
        </div>
      ))}
    </div>
  );
});
