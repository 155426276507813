import cloneDeep from 'lodash/cloneDeep';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Check as CheckIcon,
  Add as AddIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from '@mui/icons-material';
import { Button } from '@mui/material';

import FeatureService from '~/services/feature.service';

import FilterGroupFilter from '~/models/filters/FilterGroupFilter';
import FilterNew from '~/models/filters/FilterNew';

import Log from '~/utils/Log';

import BasicForm from '~/components/BasicForm';
import { AccordionBody } from '~/components/baseComponents/accordion/AccordionBody';
import ClientPortalTooltip from '~/components/salesPackages/clientPortal/ClientPortalTooltip';
import { PackageBasicRestrictionTooltip } from '~/components/salesPackages/packageBasicRestriction/MF_PackageBasicRestrictionTooltip';

import { withErrorBoundary } from '~/ui/atoms';

import { FilterGroupsList } from './FilterGroupsList';
import { FilterRow } from './FilterRow';

export const FilterGroups = withErrorBoundary(
  ({
    baseTestId,
    currentFilterGroupObject,
    deleteFilterGroup,
    disableDeleteButton,
    disableUpdateButton,
    filterContext,
    filterGroupMarks,
    filterGroupOpen,
    filterGroups,
    filterRows,
    hideAccordion,
    hideEditNameIcon,
    hideUpdateDeleteButton,
    onChangeValue,
    onClickExpandFilterGroup,
    onClickFilterGroup,
    onOpenFilterRow,
    originalFilterGroupObject,
    renderFilterRow,
    resetSelectedFilterGroup,
    saveNewFilterGroup,
    selectableFilters,
    selectedFilterGroup,
    updateFilterGroup,
    updateFilterGroupName: updateFilterGroupNameProperty,
    updateFilterRows,
    withExpandableFilterGroup,
  }) => {
    const [formType, setFormType] = useState('create');
    const [formOpen, setFormOpen] = useState(false);
    const [deleteFormOpen, setDeleteFormOpen] = useState(false);
    const [newFilterGroupName, setNewFilterGroupName] = useState('');

    const resetSelectedFilterGroupHandler = () => {
      if (!selectedFilterGroup) {
        return false;
      }

      if (filterGroups.some(({ id }) => id === selectedFilterGroup)) {
        return false;
      }

      resetSelectedFilterGroup();
      return true;
    };

    const getFilterRowsFromFilterGroup = (filterGroup) => {
      const filterRows = [...filterGroup.filterRows];

      // Extend the filter rows by the props that are filtered on.
      for (const [key, value] of Object.entries(filterGroup.filters)) {
        // If no filter is applied or the filter is already in the list of filter rows, there is no use in extending the filter row list.
        if (value.length === 0 || filterRows.includes(key)) {
          continue;
        }

        // If the filter is not the custom fields filter, we can handle it normally.
        if (key !== FilterGroupFilter.FILTER.SELECTED_CUSTOM_FIELDS) {
          filterRows.push(key);
          continue;
        }

        // If the filter is the custom fields filter, we have to handle it differently because custom fields filters are stored with a different format.
        for (const customField of value) {
          // Prevent duplicate filter rows.
          if (filterRows.includes(customField.key)) {
            continue;
          }

          filterRows.push(customField.key);
        }
      }

      return filterRows;
    };

    const initFilterRows = () => {
      // Get filter rows from current filter group
      let newFilterRows = getFilterRowsFromFilterGroup(
        currentFilterGroupObject,
      );

      // If no filter rows found, try to get them from localStorage
      if (newFilterRows.length === 0 && originalFilterGroupObject?.filterRows) {
        newFilterRows = [...originalFilterGroupObject.filterRows];
      }

      // Filter out disabled filters
      newFilterRows = newFilterRows.filter((filterRow) => {
        const selectableFilter = selectableFilters.find(
          ({ id }) => id === filterRow,
        );
        return selectableFilter && !selectableFilter.disabled;
      });

      // Add default filter if empty
      if (newFilterRows.length === 0) {
        const selectableFilter = selectableFilters.find(
          ({ disabled }) => !disabled,
        );
        if (selectableFilter) {
          newFilterRows.push(selectableFilter.id);
        }
      }

      updateFilterRows(newFilterRows);
    };

    const removeDisabledFilterRows = () => {
      const newFilterRows = [...filterRows].filter((filterRow) => {
        const selectableFilter = selectableFilters.find(
          ({ id }) => id === filterRow,
        );

        if (!selectableFilter) {
          return true;
        }

        return !selectableFilter.disabled;
      });

      updateFilterRows(newFilterRows);
    };

    useEffect(() => {
      const selectedFilterGroupReset = resetSelectedFilterGroupHandler();
      if (!selectedFilterGroupReset) {
        initFilterRows();
      }
    }, []);

    useEffect(() => {
      if (currentFilterGroupObject?.id) {
        initFilterRows();
      }
    }, [currentFilterGroupObject.id]);

    useEffect(() => {
      if (filterRows.length > 0) {
        removeDisabledFilterRows();
      }
    }, [selectableFilters]);

    const handleClickFilterGroup = (filterGroupId: string) => {
      if (selectedFilterGroup === filterGroupId) {
        Log.info(
          'Deselect filter group',
          filterGroupId,
          Log.BREADCRUMB.USER_ACTION.KEY,
        );
        Log.productAnalyticsEvent(
          'Deselect filter group',
          Log.FEATURE.FILTER_GROUPS,
        );
      } else {
        const filterGroup = filterGroups.find(({ id }) => id === filterGroupId);

        Log.info(
          'Select filter group',
          filterGroupId,
          Log.BREADCRUMB.USER_ACTION.KEY,
        );
        Log.productAnalyticsEvent(
          'Select filter group',
          Log.FEATURE.FILTER_GROUPS,
          undefined,
          {
            filterGroupId,
            filterGroupName: filterGroup?.name,
            filterNames: filterGroup?.filterRows,
          },
        );
      }

      onClickFilterGroup(filterGroupId);
    };

    const handleClickNewFilterGroup = (event) => {
      event.currentTarget.blur();

      setFormOpen(true);
      setFormType('create');
    };

    const handleClickChangeFilterGroupName = (event) => {
      event.stopPropagation();
      event.currentTarget.blur();

      setFormOpen(true);
      setFormType('edit');
      setNewFilterGroupName(
        filterGroups.find(({ id }) => id === selectedFilterGroup)?.name,
      );
    };

    const handleClickExpandFilterGroup = (event) => {
      event.stopPropagation();

      if (filterGroupOpen) {
        Log.info(
          'Hide filters',
          selectedFilterGroup,
          Log.BREADCRUMB.USER_ACTION.KEY,
        );
        Log.productAnalyticsEvent('Hide filters', Log.FEATURE.FILTER_GROUPS);
      } else {
        Log.info(
          'Expand filters',
          selectedFilterGroup,
          Log.BREADCRUMB.USER_ACTION.KEY,
        );
        Log.productAnalyticsEvent('Expand filters', Log.FEATURE.FILTER_GROUPS);
      }

      onClickExpandFilterGroup();
    };

    const handleSaveNewFilterGroup = (event) => {
      event.preventDefault();

      Log.info(
        'Save filter',
        newFilterGroupName,
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent('Submit form', Log.FEATURE.FILTER_GROUPS);

      if (formType === 'create') {
        saveNewFilterGroup(
          uuidv4(),
          newFilterGroupName,
          filterRows,
          !filterGroupHasBeenUpdated(),
        );
      } else {
        updateFilterGroupNameProperty(selectedFilterGroup, newFilterGroupName);
      }

      setFormOpen(false);
      setNewFilterGroupName('');
    };

    const handleAbortNewFilterGroup = () => {
      Log.productAnalyticsEvent('Abort form', Log.FEATURE.FILTER_GROUPS);

      setFormOpen(false);
      setNewFilterGroupName('');
    };

    const handleUpdateFilterGroup = () => {
      Log.info(
        'Update filter group',
        selectedFilterGroup,
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent(
        'Update filter group',
        Log.FEATURE.FILTER_GROUPS,
      );

      updateFilterGroup(filterRows);
    };

    const handleChangeProperty = (oldFilterRow, newFilterRow) => {
      Log.info(
        'Update property of filter',
        oldFilterRow,
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent(
        'Update property of filter',
        Log.FEATURE.FILTER_GROUPS,
      );

      const oldSelectedFilter = selectableFilters.find(
        ({ id }) => id === oldFilterRow,
      );

      // Reset old filter row value.
      onChangeValue(oldFilterRow, oldSelectedFilter.customField, []);

      // Replace old filter row with new filter row.
      const filterRowIndex = filterRows.indexOf(oldFilterRow);
      const newFilterRows = [...filterRows];
      newFilterRows[filterRowIndex] = newFilterRow;

      updateFilterRows(newFilterRows);
    };

    const handleDeleteRow = (deletedFilterRow) => {
      Log.info(
        'Delete filter',
        deletedFilterRow,
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent('Delete filter', Log.FEATURE.FILTER_GROUPS);

      const oldSelectedFilter = selectableFilters.find(
        (selectableFilter) => selectableFilter.id === deletedFilterRow,
      );

      onChangeValue(deletedFilterRow, oldSelectedFilter.customField, []);

      // If there is only one filter left, just reset it, but don't remove it so that the list of filters isn't empty.
      if (filterRows.length === 1) {
        return;
      }

      const filterRowIndex = filterRows.indexOf(deletedFilterRow);
      const newFilterRows = [...filterRows];
      newFilterRows.splice(filterRowIndex, 1);

      updateFilterRows(newFilterRows);
    };

    const handleOpenFilterRow = (filterId) => {
      Log.info('Open filter row', filterId, Log.BREADCRUMB.USER_ACTION.KEY);
      Log.productAnalyticsEvent('Open filter row', Log.FEATURE.FILTER_GROUPS);

      if (onOpenFilterRow) {
        onOpenFilterRow(filterId);
      }
    };

    const handleAddFilterRow = () => {
      Log.info('Add filter', null, Log.BREADCRUMB.USER_ACTION.KEY);
      Log.productAnalyticsEvent('Add filter', Log.FEATURE.FILTER_GROUPS);

      const availableFilters = selectableFilters.filter(
        ({ disabled, id }) => !filterRows.includes(id) && !disabled,
      );

      if (availableFilters.length === 0) {
        return;
      }

      updateFilterRows([...filterRows, availableFilters[0].id]);
    };

    const sortFilterValues = (filters) => {
      const sortedFilters = {};
      const sortedKeys = Object.keys(filters).sort();

      for (const key of sortedKeys) {
        sortedFilters[key] = Array.isArray(filters[key])
          ? [...filters[key]].sort()
          : filters[key];
      }

      return sortedFilters;
    };

    const filterValuesAreDifferent = (currentFilters, originalFilters) => {
      const sortedCurrentFilters = sortFilterValues(currentFilters);
      const sortedOriginalFilters = sortFilterValues(originalFilters);

      return (
        JSON.stringify(sortedCurrentFilters) !==
        JSON.stringify(sortedOriginalFilters)
      );
    };

    const filterNamesAreDifferent = (
      currentFilterNames,
      originalFilterNames,
    ) => {
      return (
        JSON.stringify(currentFilterNames) !==
        JSON.stringify(originalFilterNames)
      );
    };

    const filterGroupHasBeenUpdated = () => {
      if (
        filterNamesAreDifferent(
          filterRows,
          originalFilterGroupObject?.filterRows,
        )
      ) {
        return true;
      }

      return filterValuesAreDifferent(
        currentFilterGroupObject.filters,
        originalFilterGroupObject.filters,
      );
    };

    const isAccordionBodyOpen = () => {
      if (hideAccordion) {
        return false;
      }

      if (filterGroupOpen) {
        return true;
      }

      return filterGroups.length === 0;
    };

    const handleDeleteFormSuccess = (event) => {
      event.preventDefault();
      event.stopPropagation();

      Log.info(
        'Delete filter group',
        selectedFilterGroup,
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent(
        'Delete filter group',
        Log.FEATURE.FILTER_GROUPS,
      );

      deleteFilterGroup();
      setDeleteFormOpen(false);
    };

    const getUpdateButton = () => (
      <Button
        data-testid={`${baseTestId}_update_group_button`}
        variant={
          !filterGroupHasBeenUpdated() || disableUpdateButton
            ? 'text'
            : 'contained'
        }
        color="primary"
        onClick={handleUpdateFilterGroup}
        disabled={!filterGroupHasBeenUpdated() || disableUpdateButton}
        startIcon={
          !filterGroupHasBeenUpdated() || disableUpdateButton ? (
            <CheckIcon />
          ) : null
        }
      >
        {!filterGroupHasBeenUpdated() || disableUpdateButton
          ? 'Filtergruppe gespeichert'
          : 'Filtergruppe speichern'}
      </Button>
    );

    const getDeleteButton = () => (
      <>
        <Button
          data-testid={`${baseTestId}_delete_group_button`}
          variant="outlined"
          color="secondary"
          onClick={() => setDeleteFormOpen(true)}
          disabled={disableDeleteButton}
          startIcon={<DeleteOutlinedIcon />}
          className="normal-case"
        >
          Filtergruppe löschen
        </Button>
        <BasicForm
          testId={`${baseTestId}_delete_group_form`}
          open={deleteFormOpen}
          formSuccess={handleDeleteFormSuccess}
          formAbort={() => setDeleteFormOpen(false)}
          title="Filtergruppe löschen"
          submitButtonTitle="Löschen"
        >
          Willst du mit dem Löschen fortfahren?
        </BasicForm>
      </>
    );

    const getButtons = () => {
      if (
        !selectedFilterGroup ||
        filterGroups.length === 0 ||
        hideUpdateDeleteButton
      ) {
        return null;
      }

      return (
        <div className="mt-4 flex items-center justify-between gap-4">
          {getUpdateButton()}
          {getDeleteButton()}
        </div>
      );
    };

    const hasUnusedFilters = selectableFilters.some(
      ({ id }) => !filterRows.includes(id),
    );
    const everyAppliedFilterHasAValue = filterRows
      .map((filterRow) => {
        const selectedFilter = selectableFilters.find(
          ({ id }) => id === filterRow,
        );
        const selectedValues =
          (selectedFilter?.customField
            ? currentFilterGroupObject?.filters?.selectedCustomFields?.find(
                ({ key }) => key === selectedFilter.customField.key,
              )?.filterValue
            : currentFilterGroupObject.filters[filterRow]) ?? [];

        return selectedValues.filter(Boolean);
      })
      .every((filterValues) => filterValues.length > 0);
    const canAddFilter = everyAppliedFilterHasAValue && hasUnusedFilters;

    return (
      <>
        <FilterGroupsList
          abortNewFilterGroup={handleAbortNewFilterGroup}
          baseTestId={baseTestId}
          filterGroupHasBeenUpdated={filterGroupHasBeenUpdated()}
          filterGroupMarks={filterGroupMarks}
          isCollapsed={!filterGroupOpen}
          filterGroups={filterGroups}
          hideAccordion={hideAccordion}
          hideEditNameIcon={hideEditNameIcon}
          isSubmitting={false}
          onClickFilterGroup={handleClickFilterGroup}
          onClickNewFilterGroup={handleClickNewFilterGroup}
          isCreating={formType === 'create'}
          saveNewFilterGroup={handleSaveNewFilterGroup}
          selectedFilterGroup={selectedFilterGroup}
          onChangeName={setNewFilterGroupName}
          state={{ formOpen, formType, newFilterGroupName }}
          withExpandableFilterGroup={withExpandableFilterGroup}
          onClickChangeFilterGroupName={handleClickChangeFilterGroupName}
          onClickExpandFilterGroup={handleClickExpandFilterGroup}
        />
        <AccordionBody
          testId={`${baseTestId}_filter_rows_accordion`}
          id="filter_accordion"
          open={isAccordionBodyOpen()}
          className="flex-none"
        >
          <div className="mt-2 flex flex-col gap-2">
            {filterRows.map((filterRow) => {
              const newSelectedFilter = cloneDeep(
                selectableFilters.find(({ id }) => id === filterRow),
              );

              if (!newSelectedFilter) {
                return null;
              }

              const newSelectedValues =
                (newSelectedFilter.customField
                  ? currentFilterGroupObject.filters.selectedCustomFields.find(
                      ({ key }) => key === newSelectedFilter.customField.key,
                    )?.filterValue
                  : cloneDeep(currentFilterGroupObject.filters[filterRow])) ??
                [];

              const FilterRowComponent = renderFilterRow || FilterRow;

              return (
                <FilterRowComponent
                  testId={`${baseTestId}_filter_row`}
                  key={newSelectedFilter.id}
                  selectedValues={newSelectedValues}
                  selectedFilter={newSelectedFilter}
                  selectableFilters={selectableFilters.filter(
                    ({ id }) => id === filterRow || !filterRows.includes(id),
                  )}
                  operator={FilterNew.OPERATOR.IS_ANY_OF}
                  onChangeProperty={(event) => {
                    handleChangeProperty(filterRow, event.target.value);
                  }}
                  onChangeOperator={() => {}} // Feature FILTER_OPERATOR
                  onChangeValue={(_, filterValue) =>
                    onChangeValue(
                      filterRow,
                      newSelectedFilter.customField,
                      filterValue,
                    )
                  }
                  onDeleteRow={() => {
                    handleDeleteRow(filterRow);
                  }}
                  hideDeleteButton={filterRows.length === 1}
                  isDisabled={newSelectedFilter.disabled}
                  onOpen={() => {
                    handleOpenFilterRow(newSelectedFilter.id);
                  }}
                  filterContext={filterContext}
                />
              );
            })}
          </div>
          {canAddFilter && (
            <PackageBasicRestrictionTooltip>
              <ClientPortalTooltip>
                <Button
                  data-testid={`${baseTestId}_add_filter_button`}
                  variant="outlined"
                  color="primary"
                  onMouseDown={handleAddFilterRow}
                  className="mt-2 w-80"
                  disabled={
                    FeatureService.clientPortal() ||
                    FeatureService.packageBasicRestriction()
                  }
                  startIcon={<AddIcon />}
                >
                  Weiterer Filter
                </Button>
              </ClientPortalTooltip>
            </PackageBasicRestrictionTooltip>
          )}
          {getButtons()}
        </AccordionBody>
      </>
    );
  },
  'Filter konnten nicht geladen werden.',
);

FilterGroups.displayName = 'FilterGroups';
