import { memo } from 'react';

import { HomeTestIds } from '~/constants/test-ids';

export const PageHeading = memo(
  ({
    user,
  }: {
    user?: {
      firstName: string;
      lastName: string;
    };
  }) => {
    const name = [user?.firstName, user?.lastName].filter(Boolean).join(' ');

    return (
      <h1
        className="text-center text-4xl text-white"
        data-testid={HomeTestIds.WELCOME_MESSAGE}
      >
        {['Willkommen zurück', name].filter(Boolean).join(', ')}!
      </h1>
    );
  },
);

PageHeading.displayName = 'PageHeading';
