import { EyeIcon } from '~/assets/icons';

import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';

import { withErrorBoundary } from '~/ui/atoms';

import InvoiceCheckButtons from '../InvoiceCheckButtons';
import InvoiceCheckIcon from '../InvoiceCheckIcon';

import { InvoiceCheckSummaryText } from './InvoiceCheckSummaryText';

export const InvoiceCheckSummary = withErrorBoundary(
  ({
    activeInvoiceCheckCategories,
    invoice,
    onCategoryClick,
    referencedDeliveryNotes,
  }) => {
    const getComponent = (category) => {
      const active = activeInvoiceCheckCategories.includes(category.name);

      return (
        <div
          className="flex cursor-pointer items-center justify-between gap-4 px-4 transition-colors duration-500"
          style={{ backgroundColor: active ? '#EBF0F9' : null }}
          onClick={() => onCategoryClick(category.name)}
        >
          <div className="flex flex-1 items-center gap-4 py-1">
            <InvoiceCheckIcon
              iconKey={category.key}
              status={category.status}
              className={
                InvoiceCheckResult.getStatusColorClass(category.status) +
                ' icon-medium'
              }
            />
            <div className="flex items-center gap-4">
              {/* Wrapping InvoiceCheckSummaryText inside div is needed because otherwise the flexbox gap would be applied to the summary text. */}
              <div>
                <InvoiceCheckSummaryText category={category} withTooltip />
              </div>
              <InvoiceCheckButtons
                category={category}
                referencedDeliveryNotes={referencedDeliveryNotes}
              />
            </div>
          </div>
          {active ? <EyeIcon className="text-black" /> : null}
        </div>
      );
    };

    return (
      <>
        <div className="mb-2 px-1 text-lg">
          Formale Rechnungsprüfung (nach §14 UStG)
        </div>
        {getComponent(invoice.checkCategory.formalCheck)}
        <div className="mb-2 mt-4 px-1 text-lg">Lieferscheinprüfung</div>
        {getComponent(invoice.checkCategory.dlnCheck)}
        {getComponent(invoice.checkCategory.signatureCheck)}
        <div className="mb-2 mt-4 px-1 text-lg">Mengenprüfung</div>
        {getComponent(invoice.checkCategory.articleExistsCheck)}
        {getComponent(invoice.checkCategory.amountCheck)}
        {getComponent(invoice.checkCategory.amountApprovedCheck)}
        <div className="mb-2 mt-4 px-1 text-lg">Preisprüfung</div>
        {getComponent(invoice.checkCategory.priceCheck)}
      </>
    );
  },
  'Die Zusammenfassung der Rechnungsprüfung konnte nicht geladen werden.',
);

InvoiceCheckSummary.displayName = 'InvoiceCheckSummary';
