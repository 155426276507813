export const SettingsTestIds = {
  DATA_PROTECTION: {
    TITLE: 'Datenschutz',
  },
  HEADER: {
    TITLE: 'Settings-header-title',
  },
  IMPRINT: {
    TITLE: 'Impressum',
  },
  PROFILE: {
    BANNER: 'Settings-profile-banner',
  },
  STATUS: {
    ACTIVE: 'Settings-status-active',
    ALL: 'Settings-status-all',
    NOT_ACTIVE: 'Settings-status-not-active',
  },
  SUPPORT: {
    TITLE: 'Support kontaktieren',
  },
} as const;
