import clsx from 'clsx';
import { Check as CheckIcon } from '@mui/icons-material';

import { Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { Spinner } from '~/components/Spinner';
import { LOADING_STATE } from '~/constants/LoadingState';
import { LightTooltip, LightTooltipWide } from '~/utils/componentUtils';
import ArrayUtils from '~/utils/arrayUtils';

export const Select = ({
  disabled,
  errorText,
  fullWidth,
  loading,
  options,
  sortOptions,
  sortOptionsByKey,
  value,
  withEmptyOption,
  ...rest
}) => {
  const renderValue = () => {
    if (loading === LOADING_STATE.LOADING) {
      return <Spinner title="Laden..." />;
    }

    if (!value) {
      return ArrayUtils.EMPTY_DROPDOWN_OPTION;
    }

    return options.find(({ id }) => id === value)?.name ?? '';
  };

  const getValue = () => {
    if (!value) {
      return 'None';
    } // A random String has to be chosen as fallback value. Otherwise renderValue() wouldn't be executed.

    return value;
  };

  const getOptions = () => {
    let processedOptions = [...options];

    if (sortOptionsByKey) {
      processedOptions = ArrayUtils.sortByKey(
        processedOptions,
        sortOptionsByKey,
      );
    } else if (sortOptions) {
      processedOptions = processedOptions.sort();
    }

    if (withEmptyOption) {
      processedOptions.unshift({
        id: null,
        name: ArrayUtils.EMPTY_DROPDOWN_OPTION,
      });
    }

    return processedOptions;
  };

  const getMenuItem = (item) => {
    const content = (
      <div className="flex w-full items-center justify-between">
        <div className={getValue() === item.id ? 'text-primary500' : null}>
          {item.nameComponent ?? item.name}
        </div>
        {getValue() === item.id ? (
          <CheckIcon className="text-primary500" fontSize="small" />
        ) : null}
      </div>
    );

    if (item.description) {
      return (
        <MenuItem key={item.id} value={item.id} disabled={item.disabled}>
          <LightTooltip title={item.description}>{content}</LightTooltip>
        </MenuItem>
      );
    }

    return (
      <MenuItem key={item.id} value={item.id} disabled={item.disabled}>
        {content}
      </MenuItem>
    );
  };

  let select = (
    <MuiSelect
      {...rest}
      value={getValue()}
      MenuProps={{ autoFocus: false }}
      renderValue={renderValue}
      disabled={
        loading === LOADING_STATE.LOADING ||
        loading === LOADING_STATE.FAILED ||
        disabled
      }
      fullWidth={fullWidth}
    >
      {getOptions().map((item) => getMenuItem(item))}
    </MuiSelect>
  );

  if (loading !== LOADING_STATE.LOADING && disabled) {
    select = (
      <LightTooltipWide title={renderValue()}>{select}</LightTooltipWide>
    );
  }

  return (
    <div className={clsx('relative', fullWidth && 'w-full')}>
      {select}
      {loading === LOADING_STATE.FAILED ? (
        <FormHelperText className="text-mui-error-red absolute">
          {errorText ?? 'Daten konnten nicht geladen werden.'}
        </FormHelperText>
      ) : null}
    </div>
  );
};
