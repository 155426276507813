import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';
import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { vestigasApi } from '~/services/kyClient';

import { type UncefactUnitType } from '~/types/uncefactUnit';

import { queryKeysDeliveryNote } from './queryKeys';
import { type ReportConfigItem, type FilterConfig } from './types';

export type DeliveryNoteReportSearchParams = {
  filterConfig: FilterConfig;
  limit: number;
  offset: number;
  reportConfig: ReportConfigItem[];
  unitType: UncefactUnitType;
};

const defaultSearchParams: DeliveryNoteReportSearchParams = {
  filterConfig: undefined,
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  reportConfig: undefined,
  unitType: undefined,
};

export type DeliveryNoteReportValue = {
  article: string;
  total: number;
};

type DeliveryNoteReportResponse = {
  data: DeliveryNoteReportValue[];
  hasNextPage: boolean;
  paginatedCount: number;
  totalCount: number;
};

/**
 * Fetches report data for delivery notes based on the provided searchParams.
 *
 * @param {DeliveryNoteReportSearchParams} searchParams - Parameters for filtering and configuring the report
 * @returns {Promise<DeliveryNoteReportValue[]>} The report data values
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Analytics/operation/compute_delivery_note_analytics_report_analytics_report_post
 */
export const fetchDeliveryNoteReport = async (
  searchParams: DeliveryNoteReportSearchParams,
): Promise<DeliveryNoteReportResponse> => {
  try {
    const sp = {
      ...defaultSearchParams,
      ...searchParams,
    };

    sp.limit = Math.min(sp.limit, 500);

    for (const key of Object.keys(sp)) {
      if (sp[key] === undefined) {
        delete sp[key];
      }
    }

    const response = await vestigasApi
      .post(ENDPOINT.DELIVERY_NOTE.GET_REPORT(), {
        json: searchParams,
      })
      .json<{
        hasNextPage: boolean;
        paginatedCount: number;
        values: DeliveryNoteReportValue[];
      }>();

    return {
      data: response.values ?? [],
      hasNextPage: response.hasNextPage ?? false,
      paginatedCount: response.paginatedCount ?? 0,
      totalCount: response.totalCount ?? 9999, // TODO: totalCount is not returned by the API yet
    };
  } catch (error) {
    console.error('Failed to get delivery note report data:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for fetching delivery note report data.
 *
 * @param {DeliveryNoteReportSearchParams} searchParams - Parameters for filtering and configuring the report
 * @param {Object} options - Additional options for the useQuery hook
 * @returns {UseQueryResult<DeliveryNoteReportValue[]>} The result of the useQuery hook containing the report data
 */
export const useQueryDeliveryNoteReport = (
  searchParams: DeliveryNoteReportSearchParams,
  options?: Omit<
    UseQueryOptions<DeliveryNoteReportValue[], Error>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery({
    queryFn: async () => fetchDeliveryNoteReport(searchParams),
    queryKey: queryKeysDeliveryNote.getReport(searchParams),
    ...options,
  });
};
