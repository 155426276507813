import { type PropsWithChildren } from 'react';

import DatagridUtils from '~/utils/datagridUtils';
import { cn } from '~/utils/tailwind';

type P = PropsWithChildren & ComponentStyling;

export const DataTable = ({ children, className, style }: P) => (
  <div
    className={cn('rounded-md bg-white shadow-lg', className)}
    style={{
      height: DatagridUtils.TABLE_HEIGHT_10_ROWS,
      ...style,
    }}
  >
    {children}
  </div>
);
