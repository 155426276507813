import { useRef, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import {
  queryKeysDeliveryNote,
  useQueryPollDeliveryNoteUpdates,
} from '~/data/deliveryNote';

import ToastService from '~/services/toast.service';

import DeliveryNoteAction from '~/models/deliveries/DeliveryNoteAction';

export const DeliveryNoteUpdatesHandler = () => {
  const changesCutoffTimestamp = useRef<number | undefined>(null);

  const queryClient = useQueryClient();

  const { data } = useQueryPollDeliveryNoteUpdates(
    changesCutoffTimestamp.current,
  );

  useEffect(() => {
    if (!data?.dlnChanges?.length > 0) {
      return;
    }

    for (const change of data.dlnChanges) {
      const key = Object.keys(DeliveryNoteAction.ACTION).find(
        (action) => DeliveryNoteAction.ACTION[action].STRING === change.message,
      );

      if (DeliveryNoteAction.ACTION[key].SHOW_TOAST) {
        ToastService.dlnInfo(
          [change.message],
          change.dlns,
          change.message === DeliveryNoteAction.ACTION.CREATED.STRING,
        );

        // Refetch all delivery notes lists to make sure the new delivery notes are visible if they should be.
        queryClient.invalidateQueries({
          queryKey: [queryKeysDeliveryNote.base(), 'list'],
        });
      }
    }
  }, [data?.dlnChanges]);

  useEffect(() => {
    if (!data?.lastSequence) {
      return;
    }

    changesCutoffTimestamp.current = data.lastSequence;
  }, [data?.lastSequence]);

  // We don't need to render anything, we just need to poll for changes.
  return null;
};
