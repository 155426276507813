import { DeliveryNoteLinks } from '~/utils/componentUtils';
import { pluralizeString, pluralizeWord } from '~/utils/pluralize';

import { withErrorBoundary } from '~/ui/atoms';

import InvoiceCheck from '../InvoiceCheck';

import { InvoiceCheckCategory } from './InvoiceCheckCategory';

export const SignatureCheck = withErrorBoundary(({ category }) => {
  const errorInvoiceChecks = (
    <InvoiceCheck
      expandedDefault
      checkResults={category.errorChecks}
      checkResultFormatter={(checkResult) => (
        <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
      )}
    >
      <div className="w-full">
        Die {pluralizeWord(category.errorChecks.length, 'folgende')}{' '}
        {pluralizeString(
          category.errorChecks.length,
          'Lieferung wurde',
          category.errorChecks.length > 1,
        )}{' '}
        nicht von einem Mitarbeiter des Rechnungsempfängers signiert.
      </div>
    </InvoiceCheck>
  );

  const delayedSuccessInvoiceChecks = (
    <InvoiceCheck
      expandedDefault
      checkResults={category.delayedSuccessChecks}
      checkResultFormatter={(checkResult) => (
        <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
      )}
    >
      <div className="w-full">
        Die {pluralizeWord(category.delayedSuccessChecks.length, 'folgende')}{' '}
        {pluralizeString(
          category.delayedSuccessChecks.length,
          'Lieferung wurde',
          category.delayedSuccessChecks.length > 1,
        )}{' '}
        nachträglich von einem Mitarbeiter des Rechnungsempfängers signiert.
      </div>
    </InvoiceCheck>
  );

  const successInvoiceChecks = (
    <InvoiceCheck
      expandedDefault
      checkResults={category.successChecks}
      checkResultFormatter={(checkResult) => (
        <DeliveryNoteLinks deliveryNotes={checkResult.deliveryNotes} />
      )}
    >
      <div className="w-full">
        Die {pluralizeWord(category.successChecks.length, 'folgende')}{' '}
        {pluralizeString(
          category.successChecks.length,
          'Lieferung wurde',
          category.successChecks.length > 1,
        )}{' '}
        von einem Mitarbeiter des Rechnungsempfängers signiert.
      </div>
    </InvoiceCheck>
  );

  return (
    <InvoiceCheckCategory
      category={category}
      errorInvoiceChecks={errorInvoiceChecks}
      delayedSuccessInvoiceChecks={delayedSuccessInvoiceChecks}
      successInvoiceChecks={successInvoiceChecks}
    />
  );
}, 'Prüfschritt "Signaturen" konnte nicht geladen werden.');
