import { Grid } from '@mui/material';

import Concrete from '~/models/articles/Concrete';
import ValueGroup from '~/models/deliveries/ValueGroup';

import UnitUtils from '~/utils/unitUtils';

import { EditingHistorySafe as EditingHistory } from '~/components/deliveries/deliveryNote/EditingHistorySafe';

import { withErrorBoundary } from '~/ui/atoms';

export const ArticleConcrete = withErrorBoundary(({ concrete, delivery }) => {
  const getConcatenatedExposureAndMoistureClass = (data) => {
    let classes = data?.exposureClass ?? '';
    classes += classes ? '\u00A0\u00A0\u00A0/\u00A0\u00A0\u00A0' : '';
    classes += data?.moistureClass ?? '';

    return classes;
  };

  const getConcatenatedUsageAndChlorideClass = (data) => {
    let output = data?.usage ?? '';
    output += output ? '\u00A0\u00A0\u00A0/\u00A0\u00A0\u00A0' : '';
    output += data?.chlorideClass ?? '';

    return output;
  };

  const getConcatenatedDelivery = (data) => {
    const requestedValue = UnitUtils.roundAndFormatDe_safe(data.requestedValue);
    const requestedUnit = UnitUtils.getAbbreviatedUnit(data.requestedUnit);

    const actualValue = UnitUtils.roundAndFormatDe_safe(data.actualValue);
    const actualUnit = UnitUtils.getAbbreviatedUnit(data.actualUnit);

    const remainingValue = UnitUtils.roundAndFormatDe_safe(data.remainingValue);
    const remainingUnit = UnitUtils.getAbbreviatedUnit(data?.remainingUnit);

    return (
      <>
        {requestedValue === null ? (
          <>-</>
        ) : (
          <>
            <>{requestedValue + ' '}</>
            {requestedUnit}
          </>
        )}
        <>{'\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0'}</>
        {actualValue === null ? (
          <>-</>
        ) : (
          <>
            <>{actualValue + ' '}</>
            {actualUnit}
          </>
        )}
        <>{'\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0'}</>
        {remainingValue === null ? (
          <>-</>
        ) : (
          <>
            <>{remainingValue + ' '}</>
            {remainingUnit}
          </>
        )}
      </>
    );
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={6}>
        <div>Expositionsklasse / Feuchtigkeitsklasse</div>
        <div className="font-bold">
          <EditingHistory
            value={ValueGroup.applyFunction(
              {
                exposureClass: concrete?.exposureClass,
                moistureClass: concrete?.moistureClass,
              },
              getConcatenatedExposureAndMoistureClass,
            )}
          />
        </div>
      </Grid>
      <Grid item xs={12} lg={3}>
        <div>Festigkeitsklasse</div>
        <div className="font-bold">
          <EditingHistory value={concrete?.strengthClass} />
        </div>
      </Grid>
      <Grid item xs={12} lg={3}>
        <div>Konsistenzklasse</div>
        <div className="font-bold">
          <EditingHistory value={concrete?.consistency} />
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <div>Verwendung/Klasse des Chloridgehaltes</div>
        <div className="font-bold">
          <EditingHistory
            value={ValueGroup.applyFunction(
              {
                chlorideClass: concrete?.chlorideClass,
                usage: concrete?.usage,
              },
              getConcatenatedUsageAndChlorideClass,
            )}
          />
        </div>
      </Grid>
      <Grid item xs={12} lg={3}>
        <div>Größtkorn</div>
        <div className="font-bold">
          <EditingHistory value={concrete?.largestGrain} />
        </div>
      </Grid>
      <Grid item xs={12} lg={3}>
        <div>Festigkeitsentwicklung</div>
        <div className="font-bold">
          <EditingHistory
            value={concrete?.strengthDevelopment}
            callback={Concrete.getDescriptiveStrengthDevelopment}
          />
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <div>Menge (Soll | Ist | Rest)</div>
        <div className="font-bold">
          <EditingHistory
            value={ValueGroup.applyFunction(
              {
                actualUnit: delivery?.actual?.unit,
                actualValue: delivery?.actual?.value,
                remainingUnit: delivery?.remaining?.unit,
                remainingValue: delivery?.remaining?.value,
                requestedUnit: delivery?.requested?.unit,
                requestedValue: delivery?.requested?.value,
              },
              getConcatenatedDelivery,
            )}
          />
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <div>W/Z Verhältnis</div>
        <div className="font-bold">
          <EditingHistory
            value={concrete?.waterCementRatio}
            callback={UnitUtils.formatDe_safe}
          />
        </div>
      </Grid>
    </Grid>
  );
}, 'Betondaten konnten nicht geladen werden.');

ArticleConcrete.displayName = 'ArticleConcrete';
