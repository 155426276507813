import { useDispatch, useSelector } from 'react-redux';

import { ROUTE } from '~/constants/Route';

import { DeliveryOutlinedIcon } from '~/assets/icons';

import {
  getSearchBody,
  useQueryDeliveryNoteSearchCount,
} from '~/data/deliveryNote';

import {
  setDelivery_dateRange,
  setDelivery_individualDateRange,
  setDelivery_predefinedDateRange,
} from '~/redux/filtersSlice';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import FilterNew from '~/models/filters/FilterNew';

import { withErrorBoundary } from '~/ui/atoms';

import { NumberTile } from './NumberTile';

const selectIndividualDateRangeIsSelected = (state) =>
  state.filters.home_individualDateRange;
const selectSelectedCostCenters = (state) => state.filters.selectedCostCenters;
const selectSelectedDateRange = (state) => state.filters.home_selectedDateRange;
const selectSelectedFromSite = (state) => state.filters.selectedFromSite;
const selectSelectedPredefinedDateRange = (state) =>
  state.filters.home_selectedPredefinedDateRange;
const selectSelectedSites = (state) => state.filters.selectedSites;

export const DeliveryTile = withErrorBoundary(
  ({ className, style }: ComponentStyling) => {
    const dispatch = useDispatch();

    const individualDateRangeIsSelected = useSelector(
      selectIndividualDateRangeIsSelected,
    );
    const selectedCostCenters = useSelector(selectSelectedCostCenters);
    const selectedDateRange = useSelector(selectSelectedDateRange);
    const selectedFromSite = useSelector(selectSelectedFromSite);
    const selectedPredefinedDateRange = useSelector(
      selectSelectedPredefinedDateRange,
    );
    const selectedSites = useSelector(selectSelectedSites);

    const deliveryNotesCountBody = getSearchBody({
      filterGroups: {
        dateRange: selectedDateRange,
        fromSite: selectedFromSite,
        selectedCostCenters,
        selectedSites,
      },
    });

    const declinedDeliveryNotesCountBody = getSearchBody({
      filterGroups: {
        acceptStates: [AcceptStateCalculator.ACCEPT_STATE.DECLINED],
        dateRange: selectedDateRange,
        fromSite: selectedFromSite,
        selectedCostCenters,
        selectedSites,
      },
    });

    const { data: deliveryNotesCount, isLoading: isLoadingDeliveryNotesCount } =
      useQueryDeliveryNoteSearchCount(deliveryNotesCountBody);

    const {
      data: declinedDeliveryNotesCount,
      isLoading: isLoadingDeclinedDeliveryNotesCount,
    } = useQueryDeliveryNoteSearchCount(declinedDeliveryNotesCountBody);

    const openDeliveries = () => {
      dispatch(setDelivery_predefinedDateRange(selectedPredefinedDateRange));
      dispatch(
        setDelivery_dateRange({
          dateRange: selectedDateRange,
          updateCookie: true,
        }),
      );
      dispatch(setDelivery_individualDateRange(individualDateRangeIsSelected));

      FilterNew.removeNonApplicableBackendFiltersForDeliveryPage();
    };

    return (
      <NumberTile
        title={'Lieferungen'}
        icon={<DeliveryOutlinedIcon className="icon-medium" />}
        isLoading={
          isLoadingDeliveryNotesCount || isLoadingDeclinedDeliveryNotesCount
        }
        buttonTitle={'Zu den Lieferungen'}
        buttonLink={ROUTE.DELIVERIES.ROUTE}
        buttonCallback={openDeliveries}
        firstNumberTitle={'Gesamt'}
        firstNumber={deliveryNotesCount ?? ''}
        secondNumberTitle={'Abgelehnt'}
        secondNumber={declinedDeliveryNotesCount ?? ''}
        className={className}
        style={style}
      />
    );
  },
  null,
);

DeliveryTile.displayName = 'DeliveryTile';
