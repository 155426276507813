export const columns = [
  {
    cellClassName: 'name-cell',
    field: 'name',
    headerClassName: 'name-header',
    headerName: 'Benutzer-Gruppe',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    cellClassName: 'id-cell',
    field: 'id',
    headerClassName: 'id-header',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
];
