import { useEffect, useState } from 'react';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { PartlySettledIcon, SettledIcon } from '~/assets/icons';

import InvoicesService from '~/services/invoices.service';

import BilledItem from '~/models/billingState/BilledItem';
import InvoiceModel from '~/models/invoices/Invoice';
import ValueGroup from '~/models/deliveries/ValueGroup';

import { InvoiceLink } from '~/utils/componentUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import UnitUtils from '~/utils/unitUtils';

import { withErrorBoundary } from '~/ui/atoms';

export const ArticleBilledItem = withErrorBoundary(
  ({ article, billedItem }) => {
    const [referencedInvoices, setReferencedInvoices] = useState([]);

    const loadReferencedInvoices = async () => {
      const loadedInvoices = [];

      for (let index = 0; index < billedItem?.billingDetails?.length; index++) {
        const [invoice, error] = await promiseHandler(
          InvoicesService.getInvoiceById(
            billedItem.billingDetails[index].invoiceId,
            InvoiceModel.DIRECTION.INCOMING,
          ),
        );

        if (error) {
          Log.error(
            'Failed to load invoice by id: ' +
              billedItem.billingDetails[index].invoiceId,
          );
          Log.productAnalyticsEvent(
            'Failed to load invoice',
            Log.FEATURE.DELIVERY_NOTE,
            Log.TYPE.ERROR,
          );
          continue;
        }

        loadedInvoices.push(invoice);
      }

      setReferencedInvoices(loadedInvoices);
    };

    useEffect(() => {
      loadReferencedInvoices();
    }, [JSON.stringify(billedItem?.billingDetails)]);

    const getIcon = () => {
      if (
        billedItem?.settledStatus ===
        BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY
      ) {
        return <SettledIcon className="text-successBase mr-4" />;
      }

      if (
        billedItem?.settledStatus ===
        BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY
      ) {
        return <PartlySettledIcon className="text-warningBase mr-4" />;
      }

      Log.error(
        null,
        new EnumValueNotFoundException(
          `Invalid settled status: ${billedItem?.settledStatus}`,
        ),
      );
      Log.productAnalyticsEvent(
        'Failed to display settled amount',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );

      return null;
    };

    const getDescription = () => {
      if (
        billedItem?.settledStatus ===
        BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY
      ) {
        return BilledItem.SETTLED_STATUS.FULLY_SETTLED.DESCRIPTION;
      }

      if (
        billedItem?.settledStatus ===
        BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY
      ) {
        return BilledItem.SETTLED_STATUS.PARTLY_SETTLED.DESCRIPTION;
      }

      Log.error(
        null,
        new EnumValueNotFoundException(
          `Invalid settled status: ${billedItem?.settledStatus}`,
        ),
      );
      Log.productAnalyticsEvent(
        'Failed to display settled amount',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );

      return null;
    };

    const getTextClassName = () => {
      if (
        billedItem?.settledStatus ===
        BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY
      ) {
        return 'text-successBase';
      }

      if (
        billedItem?.settledStatus ===
        BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY
      ) {
        return 'text-warningBase';
      }

      Log.error(
        null,
        new EnumValueNotFoundException(
          `Invalid settled status: ${billedItem?.settledStatus}`,
        ),
      );
      Log.productAnalyticsEvent(
        'Failed to display settled amount',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );

      return null;
    };

    return (
      <>
        <div className="flex items-center">
          {getIcon()}
          <div className={getTextClassName()}>
            <div>{getDescription()}</div>
            <div>
              <span className="font-bold">
                {ValueGroup.getCurrentValue(article?.amount?.value) *
                  billedItem?.totalBilledQuotient}
              </span>{' '}
              / {ValueGroup.getCurrentValue(article?.amount?.value)}{' '}
              {UnitUtils.getAbbreviatedUnits(
                ValueGroup.getCurrentValue(article?.amount?.unit),
              )}
            </div>
          </div>
        </div>
        <div className="mt-4">
          In den folgenden Rechnungen abgerechnet:
          <div className="mt-2 flex flex-wrap items-center gap-2">
            {billedItem?.billingDetails?.map((billingDetail) => {
              const referencedInvoice = referencedInvoices.find(
                (referencedInvoice) =>
                  referencedInvoice.id === billingDetail.invoiceId,
              );

              return (
                <div className="rounded-md bg-gray-100 p-2">
                  <InvoiceLink
                    id={referencedInvoice?.id}
                    number={referencedInvoice?.number}
                    className="mr-4 font-bold text-gray-800"
                  />{' '}
                  {UnitUtils.formatValueUnitPair_safe(
                    billingDetail.amount.value,
                    billingDetail.amount.unit,
                    UnitUtils.getAbbreviatedUnit,
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  },
  'Abgerechnete Menge konnte nicht geladen werden.',
);

ArticleBilledItem.displayName = 'ArticleBilledItem';
