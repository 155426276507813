import { dateUtils, fromUTC } from '~/utils/dateUtils';

const formatDateInLocalTime = (date) =>
  dateUtils.getFormattedDate(
    fromUTC(date),
    dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm_ss,
  );

export const columns = [
  {
    cellClassName: 'name-cell',
    field: 'name',
    headerClassName: 'name-header',
    headerName: 'Name',
    resizableText: true,
    sortable: true,
    width: 500,
  },
  {
    cellClassName: 'type-cell',
    field: 'type',
    headerClassName: 'type-header',
    headerName: 'Art',
    resizableText: true,
    sortable: true,
    width: 200,
  },
  {
    cellClassName: 'isActive-cell',
    field: 'isActive',
    headerClassName: 'isActive-header',
    headerName: 'Aktiv',
    resizableText: true,
    sortable: true,
    width: 100,
  },
  {
    cellClassName: 'costCenters-cell',
    field: 'costCenters',
    headerClassName: 'costCenters-header',
    headerName: 'Kostenstellen',
    resizableText: true,
    sortable: false,
    width: 250,
  },
  {
    cellClassName: 'address-cell',
    field: 'address',
    headerClassName: 'address-header',
    headerName: 'Adresse',
    resizableText: true,
    sortable: false,
    width: 400,
  },
  {
    cellClassName: 'companyName-cell',
    field: 'companyName',
    headerClassName: 'companyName-header',
    headerName: 'Firma',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    cellClassName: 'coords-cell',
    field: 'coords',
    headerClassName: 'coords-header',
    headerName: 'GPS-Standort',
    resizableText: true,
    sortable: false,
    width: 200,
  },
  {
    cellClassName: 'id-cell',
    field: 'id',
    headerClassName: 'id-header',
    headerName: 'ID',
    resizableText: true,
    sortable: true,
    width: 400,
  },
  {
    cellClassName: 'start-cell',
    field: 'start',
    headerClassName: 'start-header',
    headerName: 'Startdatum',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    cellClassName: 'end-cell',
    field: 'end',
    headerClassName: 'end-header',
    headerName: 'Enddatum',
    resizableText: true,
    sortable: true,
    width: 300,
  },
  {
    field: 'createdOn',
    headerName: 'Erstellt am',
    resizableText: true,
    sortable: true,
    valueGetter: formatDateInLocalTime,
    width: 300,
  },
  {
    field: 'modifiedOn',
    headerName: 'Geändert am',
    resizableText: true,
    sortable: true,
    valueGetter: formatDateInLocalTime,
    width: 300,
  },
];

export const columnVisibility = {
  createdOn: false,
  modifiedOn: false,
};
