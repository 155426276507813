import { useQueryUserGroupsInfinite } from '~/data/userGroup';

import { type UUID } from '~/types/common';

import { MultiItemsManager } from './MultiItemsManager';
import { type EntityFilter } from './types';
import { getEntityFilter } from './utils';

type P = {
  filterByEntity?: EntityFilter;
  isCreatingNewEntity?: boolean;
  label?: string;
  onChange?: (selectedIds: UUID[]) => void;
  onSaveSelection?: () => void;
  onSelectedItemClick?: (id: UUID) => void;
  openSelectTestId?: string;
  parentOrganizationalUnitId?: UUID;
  parentUserGroupId?: UUID;
};

export const MultiItemsManagerUserGroups = ({
  isCreatingNewEntity = false,
  filterByEntity,
  label = 'Benutzergruppen',
  onChange,
  onSaveSelection,
  onSelectedItemClick,
  openSelectTestId,
  parentOrganizationalUnitId,
  parentUserGroupId,
}: P) => {
  const filterEntityIn = getEntityFilter(
    filterByEntity,
    'filterContainsEntity',
  );
  const filterEntityNotIn = getEntityFilter(
    filterByEntity,
    'filterNotContainsEntity',
  );

  const getterFunctionSelectedItems = (searchString: string) => {
    const query = useQueryUserGroupsInfinite({
      ...filterEntityIn,
      filterParentOu: parentOrganizationalUnitId ?? undefined,
      filterParentUserGroup: parentUserGroupId ?? undefined,
      searchString,
    });

    if (!isCreatingNewEntity) {
      return query;
    }

    return Promise.resolve({
      data: [],
      fetchNextPage() {},
      hasNextPage: false,
      isFetchingNextPage: false,
      isLoading: false,
      totalCount: 0,
    });
  };

  const getterFunctionSelectableItems = (searchString: string) =>
    useQueryUserGroupsInfinite({
      ...filterEntityNotIn,
      filterNotParentOu: parentOrganizationalUnitId ?? undefined,
      filterNotParentUserGroup: parentUserGroupId ?? undefined,
      searchString,
    });

  return (
    <MultiItemsManager
      fieldName="name"
      getterFunctionSelectableItems={getterFunctionSelectableItems}
      getterFunctionSelectedItems={getterFunctionSelectedItems}
      label={label}
      onChange={onChange}
      onSaveSelection={onSaveSelection}
      onSelectedItemClick={onSelectedItemClick}
      openSelectTestId={openSelectTestId}
    />
  );
};
