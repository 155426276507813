import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DeliveriesTestIds } from '~/constants/test-ids';

import {
  selectFilterGroup,
  setFilterValue,
  // ---------------- old:
  setDelivery_selectedFilterGroup,
  setDelivery_filterGroups,
  setDelivery_filterRows,
  setDelivery_filterGroupOpen,
  setDelivery_tab,
  setDeliveryList_filterModel,
  setDelivery_selectedArticleNumber,
  setDelivery_selectedArticle,
  setDelivery_selectedCostCenter,
  setDelivery_selectedFromSite,
  setDelivery_selectedPermittedToSites,
  setDelivery_selectedPermittedCostCenters,
  setDelivery_selectedProcessState,
  setDelivery_selectedAcceptState,
  setDelivery_selectedRecipient,
  setDelivery_selectedSupplier,
  setDelivery_selectedToSiteRecipient,
  setDelivery_selectedToSiteSupplier,
  setDelivery_selectedCustomFields,
  setDelivery_selectedSettledStatus,
} from '~/redux/filtersSlice';

import { useQueryCustomFields } from '~/data/customField';
import {
  useQueryDeliveryNoteSearchCount,
  getUnassignedDeliveryNotesSearchBody,
} from '~/data/deliveryNote';
import { useQueryUserData } from '~/data/user';

import FilterNew from '~/models/filters/FilterNew';

import { TAB } from '~/constants/Tab';

import DatagridUtils from '~/utils/datagridUtils';
import { getFilterContext, isCustomFieldFilter } from '~/utils/filters';

import Log from '~/utils/Log';
import UserUtils from '~/utils/userUtils';

import { FilterGroups } from '~/components/filterBar/FilterGroups';

import { type UUID } from '~/types/common';

import { selectDeliveryFilters } from '~/components/deliveries/deliveryOverview/DeliveryList/utils';

import { withErrorBoundary } from '~/ui/atoms';

import {
  deleteFilterGroup as deleteFilterGroupUtil,
  fixTabsOrder,
  getFilterGroupObject as getFilterGroupObjectUtil,
  getSelectableFilters as getSelectableFiltersUtil,
  handleSelectFilterGroup as handleSelectFilterGroupUtil,
  saveNewFilterGroup as saveNewFilterGroupUtil,
  selectDeliveryFilterGroups,
  updateFilterGroup as updateFilterGroupUtil,
  updateFilterGroupName as updateFilterGroupNameUtil,
} from './utils';

type FilterGroup = {
  id: string;
  filters?: Record<string, any>;
  [key: string]: any;
};

export const DeliveryFilterGroups = withErrorBoundary(
  ({ calculatedFilterModel, onChangeValue }) => {
    const dispatch = useDispatch();

    const deliveryFilters = useSelector(selectDeliveryFilters);
    const {
      filterGroupOpen,
      filterGroups,
      filterRows,
      selectedFilterGroup,
      selectedTab,
    } = useSelector(selectDeliveryFilterGroups);

    const { data: customFields, isLoading: isLoadingCustomFields } =
      useQueryCustomFields();

    const { data: currentUser } = useQueryUserData(true);

    const featureFlags =
      currentUser?.companyAccountInfo?.data?.featureFlags ?? {};

    const [selectableFilters, setSelectableFilters] = useState([]);
    const { data: unassignedDeliveryNotesCount } =
      useQueryDeliveryNoteSearchCount({
        ...getUnassignedDeliveryNotesSearchBody(deliveryFilters.dateRange),
      });

    const getFilterGroups = () => {
      const newFilterGroups = cloneDeep(filterGroups)
        .map((filterGroup) => {
          const activeFilters = FilterNew.getActiveFiltersForFilterGroup(
            filterGroup,
            'delivery',
          );
          const nonApplicableBackendFilters =
            FilterNew.getNonApplicableFilters(activeFilters);

          return {
            ...filterGroup,
            disabled: nonApplicableBackendFilters.length > 0,
          };
        })
        .filter(({ name }) => name !== 'Änderungen'); // Remove 'Änderungen' filter group as the functionality is not supported anymore.

      return newFilterGroups;
    };

    const [filterGroupsState, setFilterGroupsState] =
      useState(getFilterGroups());

    const defaultTabs = useMemo(() => {
      const tabs = [TAB.DELIVERY.LIST];
      if (
        featureFlags.accessPermittedSites ||
        UserUtils.isPermittedSiteAllowedUser()
      ) {
        tabs.push(TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES);
      }

      return tabs;
    }, [featureFlags.accessPermittedSites]);

    const tabOffset = defaultTabs.length;

    const initSelectedFilterGroup = () => {
      if (selectedFilterGroup) {
        return;
      }

      if (filterGroups.length === 0) {
        return;
      }

      dispatch(
        setDelivery_selectedFilterGroup(
          filterGroups[TAB.DELIVERY.LIST.INDEX].id,
        ),
      );
      handleChangeFilterGroup(
        filterGroups[TAB.DELIVERY.LIST.INDEX] as FilterGroup,
      );
    };

    const initSelectedTab = () => {
      const index = filterGroups.findIndex(
        ({ id }) => id === selectedFilterGroup,
      );

      if (index === -1) {
        return;
      }

      dispatch(setDelivery_tab(index));
      dispatch(
        setDeliveryList_filterModel(
          filterGroups[index]?.filterModel ?? DatagridUtils.EMPTY_FILTER_MODEL,
        ),
      );
    };

    const initSelectableFilters = () => {
      setSelectableFilters(getSelectableFilters());
    };

    const initFilterGroups = () => {
      const newFilterGroups = getFilterGroups();
      setFilterGroupsState(newFilterGroups);

      if (
        newFilterGroups.find(({ id }) => id === selectedFilterGroup)?.disabled
      ) {
        dispatch(
          setDelivery_selectedFilterGroup(
            filterGroups[TAB.DELIVERY.LIST.INDEX].id,
          ),
        );
      }
    };

    const resetSelectedFilterGroup = () => {
      if (filterGroups.length === 0) {
        dispatch(setDelivery_selectedFilterGroup(null));
        handleChangeFilterGroup();
        return;
      }

      dispatch(
        setDelivery_selectedFilterGroup(
          filterGroups[TAB.DELIVERY.LIST.INDEX].id,
        ),
      );
      handleChangeFilterGroup(
        filterGroups[TAB.DELIVERY.LIST.INDEX] as FilterGroup,
      );
    };

    /**
     * Disables a selectable filter if the page is in archive mode and the corresponding backend filter is not implemented.
     *
     * @param {string} propertyKey - The key of the filter field.
     * @return {boolean} Returns true if the filter is disabled, false otherwise.
     */

    const selectableFilterDisabled = (propertyKey: string) => {
      // Make sure custom field filters are not unnecessarily disabled.
      // Custom fields can be identified by the first part of the property key, which is a UUID.
      if (customFields?.length > 0 && isCustomFieldFilter(propertyKey)) {
        return !customFields.some(({ key }) => key === propertyKey);
      }

      return !FilterNew.fieldIsApplicableBackendFilter(propertyKey, 'delivery');
    };

    const getSelectableFilters = () => {
      return getSelectableFiltersUtil({
        customFields,
        featureFlags,
        isSelectableFilterDisabled: (propertyKey: string) =>
          selectableFilterDisabled(propertyKey),
      });
    };

    const getFilterGroupObject = (
      id: UUID,
      name: string,
      filterRows: Array<Record<string, any>>,
      isEmptyFilterGroup: boolean,
    ) => {
      return getFilterGroupObjectUtil(
        id,
        name,
        filterGroups,
        filterRows,
        deliveryFilters,
        calculatedFilterModel,
        isEmptyFilterGroup,
      );
    };

    const saveNewFilterGroup = async (
      id: UUID,
      name: string,
      filterRows: Array<Record<string, any>>,
      isEmptyFilterGroup: boolean,
    ) => {
      const newFilterGroup = getFilterGroupObject(
        id,
        name,
        filterRows,
        isEmptyFilterGroup,
      );

      try {
        await saveNewFilterGroupUtil(
          dispatch,
          newFilterGroup,
          filterGroups,
          setDelivery_filterGroupOpen,
          setDelivery_filterGroups,
          setDelivery_selectedFilterGroup,
          handleChangeFilterGroup,
        );
      } catch (error) {
        Log.error('Failed to save new filter group.', error);
      }
    };

    const updateFilterGroup = async (
      filterRows: Array<Record<string, any>>,
    ) => {
      try {
        const updatedFilterGroup = getFilterGroupObject(
          selectedFilterGroup,
          null,
          filterRows,
          false,
        );

        await updateFilterGroupUtil(
          dispatch,
          filterGroups,
          selectedFilterGroup,
          updatedFilterGroup,
          setDelivery_filterGroups,
        );
      } catch (error) {
        Log.error('Failed to update filter group.', error);
      }
    };

    const updateFilterGroupName = async (id: UUID, name: string) => {
      try {
        await updateFilterGroupNameUtil(
          dispatch,
          id,
          name,
          filterGroups,
          setDelivery_filterGroups,
        );
      } catch (error) {
        Log.error('Failed to update filter group name.', error);
      }
    };

    const deleteFilterGroup = async () => {
      try {
        await deleteFilterGroupUtil(
          dispatch,
          filterGroups,
          selectedFilterGroup,
          setDelivery_filterGroups,
          setDelivery_selectedFilterGroup,
          handleChangeFilterGroup,
        );
      } catch (error) {
        Log.error('Failed to delete filter group.', error);
      }
    };

    const handleChangeFilterGroup = (filterGroupRaw?: Record<string, any>) => {
      const filterGroup =
        filterGroupRaw ??
        filterGroups.find(({ id }) => id === selectedFilterGroup);

      dispatch(
        setDelivery_selectedToSiteRecipient(
          filterGroup?.filters?.selectedToSiteRecipient ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedToSiteSupplier(
          filterGroup?.filters?.selectedToSiteSupplier ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedCostCenter(
          filterGroup?.filters?.selectedCostCenter ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedArticleNumber(
          filterGroup?.filters?.selectedArticleNumber ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedArticle(
          filterGroup?.filters?.selectedArticle ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedSupplier(
          filterGroup?.filters?.selectedSupplier ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedRecipient(
          filterGroup?.filters?.selectedRecipient ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedProcessState(
          filterGroup?.filters?.selectedProcessState ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedAcceptState(
          filterGroup?.filters?.selectedAcceptState ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedSettledStatus(
          filterGroup?.filters?.selectedSettledStatus ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedFromSite(
          filterGroup?.filters?.selectedFromSite ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedPermittedToSites(
          filterGroup?.filters?.selectedPermittedToSites ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedPermittedCostCenters(
          filterGroup?.filters?.selectedPermittedCostCenters ?? [],
        ),
      );
      dispatch(
        setDelivery_selectedCustomFields(
          filterGroup?.filters?.selectedCustomFields ?? [],
        ),
      );
    };

    const updateFilterRows = (filterRows: Array<Record<string, any>>) => {
      dispatch(setDelivery_filterRows(filterRows));
    };

    const handleSelectFilterGroup = (filterGroupId: string) => {
      return handleSelectFilterGroupUtil(
        dispatch,
        filterGroupId,
        filterGroups,
        selectedFilterGroup,
        setDelivery_selectedFilterGroup,
        featureFlags,
        handleChangeFilterGroup,
        tabOffset,
      );
    };

    const handleExpandFilterGroup = () => {
      dispatch(setDelivery_filterGroupOpen(!filterGroupOpen));
    };

    // Initial setup effect
    useEffect(() => {
      fixTabsOrder({
        defaultTabs,
        dispatch,
        filterGroups,
        getFilterGroupObject,
        setDelivery_filterGroups,
      });
      initSelectedFilterGroup();
      initSelectedTab();
      initSelectableFilters();
      initFilterGroups();
    }, []);

    useEffect(() => {
      initSelectedFilterGroup();
    }, [filterGroups.length]);

    useEffect(() => {
      if (customFields) {
        initSelectableFilters();
      }
    }, [JSON.stringify(customFields)]);

    useEffect(() => {
      if (
        !selectedFilterGroup ||
        selectedTab === undefined ||
        filterGroups.length === 0
      ) {
        return;
      }

      const filterGroupIndex = filterGroups.findIndex(
        ({ id }) => id === selectedFilterGroup,
      );

      if (filterGroupIndex !== -1 && filterGroupIndex !== selectedTab) {
        dispatch(setDelivery_tab(filterGroupIndex));
        dispatch(
          setDeliveryList_filterModel(
            filterGroups[filterGroupIndex]?.filterModel ??
              DatagridUtils.EMPTY_FILTER_MODEL,
          ),
        );
      }
    }, [selectedFilterGroup, selectedTab, filterGroups]);

    useEffect(() => {
      initSelectableFilters();
    }, [
      deliveryFilters.dateRange,
      deliveryFilters.selectedAcceptState,
      deliveryFilters.selectedArticle,
      deliveryFilters.selectedArticleNumber,
      deliveryFilters.selectedCostCenter,
      deliveryFilters.selectedFromSite,
      deliveryFilters.selectedPermittedCostCenters,
      deliveryFilters.selectedPermittedToSites,
      deliveryFilters.selectedProcessState,
      deliveryFilters.selectedRecipient,
      deliveryFilters.selectedSettledStatus,
      deliveryFilters.selectedSupplier,
      deliveryFilters.selectedToSiteRecipient,
      deliveryFilters.selectedToSiteSupplier,
    ]);

    useEffect(() => {
      if (deliveryFilters.selectedCustomFields?.length) {
        initSelectableFilters();
      }
    }, [deliveryFilters.selectedCustomFields]);

    useEffect(() => {
      initFilterGroups();
    }, [deliveryFilters.dateRange, filterGroups]);

    const hiddenElements = {
      hideAccordion:
        selectedFilterGroup ===
        filterGroups.find(
          ({ name }) => name === TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES.NAME,
        )?.id,
      hideEditNameIcon: selectedTab < tabOffset,
      hideUpdateDeleteButton: selectedTab === TAB.DELIVERY.LIST.INDEX,
    };

    return (
      <FilterGroups
        baseTestId={DeliveriesTestIds.FILTER_GROUPS.BASE}
        currentFilterGroupObject={getFilterGroupObject(selectedFilterGroup)}
        deleteFilterGroup={deleteFilterGroup}
        disableDeleteButton={selectedTab < tabOffset}
        disableUpdateButton={selectedTab < tabOffset}
        filterContext={getFilterContext({ page: 'delivery' })}
        filterGroupMarks={[
          {
            id: filterGroups.find(
              ({ name }) =>
                name === TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES.NAME,
            )?.id,
            mark:
              unassignedDeliveryNotesCount > 99
                ? '+99'
                : (unassignedDeliveryNotesCount ?? '–'),
            tooltipTitle: `Anzahl der nicht zugeordneten Lieferungen: ${unassignedDeliveryNotesCount ?? 0}`,
          },
        ]}
        filterGroupOpen={filterGroupOpen}
        filterGroups={filterGroupsState}
        filterRows={filterRows}
        onChangeValue={onChangeValue}
        onClickExpandFilterGroup={handleExpandFilterGroup}
        onClickFilterGroup={handleSelectFilterGroup}
        originalFilterGroupObject={filterGroups.find(
          ({ id }) => id === selectedFilterGroup,
        )}
        resetSelectedFilterGroup={resetSelectedFilterGroup}
        saveNewFilterGroup={saveNewFilterGroup}
        selectableFilters={selectableFilters}
        selectedFilterGroup={selectedFilterGroup}
        updateFilterGroup={updateFilterGroup}
        updateFilterGroupName={updateFilterGroupName}
        updateFilterRows={updateFilterRows}
        {...hiddenElements}
        withExpandableFilterGroup
      />
    );
  },
  'Filtergruppen konnten nicht geladen werden.',
);

DeliveryFilterGroups.displayName = 'DeliveryFilterGroups';
