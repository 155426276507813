import { useMutation } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { type ReportConfigItem, type FilterConfig } from './types';
import toast from 'react-hot-toast';

export type DeliveryNoteAssetsSearchParams = {
  filterConfig: FilterConfig;
  assetIds: string[];
  exportConfig: ReportConfigItem[];
  exportOrder: string[];
  format: 'csv' | 'excel';
};

type ExportAssetResponse = {
  code: string;
  message: string;
  requestId: string;
};

/**
 * Sends a request to initiate report asset.
 *
 * @param {DeliveryNoteAssetsSearchParams} searchParams - Parameters for filtering and configuring the report
 * @returns {Promise<ExportAssetResponse>} The response containing request_id
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Delivery-Note/operation/request_delivery_note_export_asset_delivery_note_export_request_post
 */
export const requestAssetExport = async (
  searchParams: DeliveryNoteAssetsSearchParams,
): Promise<ExportAssetResponse> => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.DELIVERY_NOTE.REQUEST_ASSET_EXPORT(), {
        json: searchParams,
      })
      .json<ExportAssetResponse>();

    return response;
  } catch (error) {
    console.error('Failed to request export report:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for requesting export of delivery note asset.
 *
 * @returns {UseMutationResult<ExportAssetResponse, Error, DeliveryNoteAssetsSearchParams>}
 */
export const useMutationRequestDeliveryNoteAssetExport = () => {
  return useMutation({
    mutationFn: async (searchParams: DeliveryNoteAssetsSearchParams) =>
      requestAssetExport(searchParams),
    onError(error, { format }) {
      toast.remove();
      toast.error(
        format === 'csv'
          ? 'Fehler beim Laden der CSV-Lieferung!'
          : 'Fehler beim Laden der Excel-Lieferung!',
      );
    },
    onMutate({ format, assetIds }) {
      toast.loading(
        format === 'csv'
          ? `CSV-Lieferung wird geladen... (${assetIds.length} Elemente)`
          : `Excel-Lieferung wird geladen... (${assetIds.length} Elemente)`,
      );
    },
    onSuccess(_, { format }) {
      toast.remove();
      toast.success(
        format === 'csv'
          ? 'CSV-Lieferung wurde erfolgreich abgeschlossen!'
          : 'Excel-Lieferung wurde erfolgreich abgeschlossen!',
      );
    },
  });
};
