import { type UserData } from '~/data/user';

import { withErrorBoundary } from '~/ui/atoms';

import { Tile } from './Tile';

type Props = {
  user: UserData;
} & ComponentStyling;

export const UserTile = withErrorBoundary(
  ({ className, style, user }: Props) => {
    const rows = [
      {
        title: 'Name',
        value: [user.firstName, user.lastName].filter(Boolean).join(' '),
      },
      {
        title: 'Position',
        value: user.position || '—',
      },
      {
        title: 'Firma',
        value: user.company?.name || '—',
      },
    ];

    return (
      <Tile title="Persönliche Daten" className={className} style={style}>
        <table className="border-separate border-spacing-y-2">
          <tbody>
            {rows.map(({ title, value }) => (
              <tr key={title} className="text-left align-top">
                <th className="pr-2 font-normal text-gray-400">{title}</th>
                <td className="w-full font-semibold">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Tile>
    );
  },
  null,
);

UserTile.displayName = 'UserTile';
