import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

import UnitUtils from '~/utils/unitUtils';

const defaultChartData = {
  datasets: [],
  labels: [],
};

type ChartData = {
  datasets: Array<{
    backgroundColor: string[];
    data: number[];
  }>;
  labels: string[];
};

type P = {
  chartData: ChartData;
} & ComponentStyling;

export const DoughnutChart = ({
  chartData = defaultChartData,
  className,
  style,
}: P) => {
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          const label = data?.labels[tooltipItem.index];
          const value = data?.datasets[0]?.data[tooltipItem.index];
          const formattedValue = UnitUtils.roundAndFormatDe_safe(value) ?? '';

          return [label, formattedValue].join(': ');
        },
      },
    },
  };

  return (
    <Doughnut
      data={chartData}
      options={options}
      className={className}
      style={style}
    />
  );
};
