import { FormHelperText } from '@mui/material';

import BasicForm from '~/components/BasicForm';
import { Spinner } from '~/components/Spinner';

import { MultiSelectUsers } from '~/ui/molecules/SelectServerDriven';

import { type UUID } from '~/types/common';

import { DeliveryNoteActionMetaData } from '../DeliveryNoteActionMetaData';

import { useRequestSignatureForm } from './useRequestSignatureForm';
import { useUsersRequestedPrior } from './useUsersRequestedPrior';

type P = {
  closeForm: () => void;
  deliveryNoteIds: UUID[];
  open: boolean;
  permittedUserIds: UUID[];
};

export const RequestSignatureForm = ({
  closeForm,
  deliveryNoteIds,
  open,
  permittedUserIds,
}: P) => {
  const {
    handleCancel,
    handleChange,
    handleSubmit,
    isLoading,
    isLoadingUsersData,
    isSubmitting,
    selectedUserIds,
  } = useRequestSignatureForm({
    closeForm,
    deliveryNoteIds,
    permittedUserIdsFromProps: permittedUserIds,
  });

  const usersRequestedPrior = useUsersRequestedPrior(deliveryNoteIds);

  return (
    <BasicForm
      open={Boolean(open)}
      formSuccess={handleSubmit}
      formAbort={handleCancel}
      title="Signatur anfordern"
      submitButtonTitle="Signaturen anfordern"
      submittingForm={isSubmitting}
      disableSubmitButton={isLoading || isSubmitting || isLoadingUsersData}
    >
      <div className="min-h-40 w-[600px]">
        {isLoading ? (
          <Spinner title={'Lade Nutzerdaten...'} className="min-h-40" />
        ) : (
          <>
            <FormHelperText className="mb-1">
              Wähle die Benutzer aus, die die{' '}
              {deliveryNoteIds.length > 1
                ? `${deliveryNoteIds.length} Lieferung(en)`
                : 'Lieferung'}{' '}
              signieren sollen.
            </FormHelperText>
            <MultiSelectUsers
              value={selectedUserIds}
              onChange={handleChange}
              isDisabled={isLoading || isSubmitting}
            />
            {usersRequestedPrior.length > 0 ? (
              <DeliveryNoteActionMetaData
                className="mt-4"
                title="Signatur bereits angefordert von:"
                users={usersRequestedPrior}
              />
            ) : null}
          </>
        )}
      </div>
    </BasicForm>
  );
};
