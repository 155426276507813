import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQueryCustomFields } from '~/data/customField';
import { useQueryUserData } from '~/data/user';

import {
  setDashboard_filterGroups,
  setDashboard_filterRows,
  setDashboard_selectedFilterGroup,
} from '~/redux/filtersSlice';

import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import DeliveryNote from '~/models/deliveries/DeliveryNote';
import FilterGroupFilter from '~/models/filters/FilterGroupFilter';
import FilterNew from '~/models/filters/FilterNew';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import UserUtils from '~/utils/userUtils';

import { FilterGroups } from '~/components/filterBar/FilterGroups';

import { withErrorBoundary } from 'ui/atoms';

import { selectDashboardFilters } from '../utils';

export const DashboardFilterGroups = withErrorBoundary(
  ({ onChangeValue, resetFilters }) => {
    const dispatch = useDispatch();

    const {
      dateRange,
      filterGroups,
      filterRows,
      oldestFilteredDlnDate,
      selectedAcceptState,
      selectedArticle,
      selectedArticleNumber,
      selectedCostCenter,
      selectedCustomFields,
      selectedFilterGroup,
      selectedFromSite,
      selectedPermittedCostCenters,
      selectedPermittedToSites,
      selectedProcessState,
      selectedRecipient,
      selectedSettledStatus,
      selectedSupplier,
      selectedToSiteRecipient,
      selectedToSiteSupplier,
      selectedToSiteSupplierTradeContact,
    } = useSelector(selectDashboardFilters);

    const { data: customFields, isLoading: isLoadingCustomFields } =
      useQueryCustomFields();

    const { data: currentUser } = useQueryUserData(true);

    const featureFlags =
      currentUser?.companyAccountInfo?.data?.featureFlags ?? {};

    const [state, setState] = useState({
      filterGroups: [],
      selectableFilters: [],
    });

    const getFilterGroups = () => {
      const newFilterGroups = cloneDeep(filterGroups);

      return newFilterGroups.map((filterGroup) => {
        const activeFilters = FilterNew.getActiveFiltersForFilterGroup(
          filterGroup,
          'dashboard',
        );
        const nonApplicableBackendFilters =
          FilterNew.getNonApplicableFilters(activeFilters);

        return {
          ...filterGroup,
          disabled: nonApplicableBackendFilters.length > 0,
        };
      });
    };

    const getSelectableFilters = () => {
      const selectableFilters = [
        {
          id: FilterGroupFilter.FILTER.SELECTED_PROCESS_STATE,
          name: DeliveryNote.PROPERTY.PROCESS_STATE.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_ACCEPT_STATE,
          name: DeliveryNote.PROPERTY.ACCEPT_STATE.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_SETTLED_STATUS,
          name: DeliveryNote.PROPERTY.SETTLED_STATUS.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_TO_SITE_RECIPIENT,
          name: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER,
          name: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_COST_CENTER,
          name: DeliveryNote.PROPERTY.COST_CENTER.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_ARTICLE_NUMBER,
          name: DeliveryNote.PROPERTY.ARTICLE_NUMBER.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_ARTICLE,
          name: DeliveryNote.PROPERTY.ARTICLE.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_SUPPLIER,
          name: DeliveryNote.PROPERTY.SUPPLIER.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_RECIPIENT,
          name: DeliveryNote.PROPERTY.RECIPIENT.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_FROM_SITE,
          name: DeliveryNote.PROPERTY.FROM_SITE.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER_TRADE_CONTACT,
          name: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER_TRADE_CONTACT.STRING,
        },
      ];

      if (
        featureFlags.accessPermittedSites ||
        UserUtils.isPermittedSiteAllowedUser()
      ) {
        selectableFilters.push(
          {
            id: FilterGroupFilter.FILTER.SELECTED_PERMITTED_TO_SITES,
            name: DeliveryNote.PROPERTY.PERMITTED_TO_SITE_NAMES.STRING,
          },
          {
            id: FilterGroupFilter.FILTER.SELECTED_PERMITTED_COST_CENTERS,
            name: DeliveryNote.PROPERTY.PERMITTED_COST_CENTER_NAMES.STRING,
          },
        );
      }

      if (customFields && customFields.length > 0) {
        for (const customField of customFields) {
          selectableFilters.push({
            customField,
            id: customField.key,
            name: customField.displayName,
          });
        }
      }

      return selectableFilters;
    };

    const getFilterGroupObject = (
      id,
      name,
      filterRows = [],
      emptyFilterGroup,
    ) => {
      const filterGroup = filterGroups.find(
        (filterGroup) => filterGroup.id === id,
      );

      let newFilterRows = [...filterRows];
      if (newFilterRows.length === 0 && filterGroup) {
        newFilterRows = [...filterGroup.filterRows];
      }

      return {
        filterRows: newFilterRows,
        filters: {
          selectedAcceptState: emptyFilterGroup ? [] : selectedAcceptState,
          selectedArticle: emptyFilterGroup ? [] : selectedArticle,
          selectedArticleNumber: emptyFilterGroup ? [] : selectedArticleNumber,
          selectedCostCenter: emptyFilterGroup ? [] : selectedCostCenter,
          selectedCustomFields: emptyFilterGroup ? [] : selectedCustomFields,
          selectedFromSite: emptyFilterGroup ? [] : selectedFromSite,
          selectedPermittedCostCenters: emptyFilterGroup
            ? []
            : selectedPermittedCostCenters,
          selectedPermittedToSites: emptyFilterGroup
            ? []
            : selectedPermittedToSites,
          selectedProcessState: emptyFilterGroup ? [] : selectedProcessState,
          selectedRecipient: emptyFilterGroup ? [] : selectedRecipient,
          selectedSettledStatus: emptyFilterGroup ? [] : selectedSettledStatus,
          selectedSupplier: emptyFilterGroup ? [] : selectedSupplier,
          selectedToSiteRecipient: emptyFilterGroup
            ? []
            : selectedToSiteRecipient,
          selectedToSiteSupplier: emptyFilterGroup
            ? []
            : selectedToSiteSupplier,
          selectedToSiteSupplierTradeContact: emptyFilterGroup
            ? []
            : selectedToSiteSupplierTradeContact,
        },
        id,
        name: name ?? filterGroup?.name,
      };
    };

    const initSelectableFilters = () => {
      setState((previousState) => ({
        ...previousState,
        selectableFilters: getSelectableFilters(),
      }));
    };

    const initFilterGroups = () => {
      const newFilterGroups = getFilterGroups();

      setState((previousState) => ({
        ...previousState,
        filterGroups: newFilterGroups,
      }));

      if (
        newFilterGroups.find(({ id }) => id === selectedFilterGroup)?.disabled
      ) {
        dispatch(setDashboard_selectedFilterGroup(null));
      }
    };

    const onClickFilterGroup = (id) => {
      if (id !== selectedFilterGroup) {
        dispatch(setDashboard_selectedFilterGroup(id));
        return;
      }

      dispatch(setDashboard_selectedFilterGroup(null));
      resetFilters();
    };

    const saveNewFilterGroup = async (id, name, filterRows, emptyFilters) => {
      const newFilterGroups = [
        ...filterGroups,
        getFilterGroupObject(id, name, filterRows, emptyFilters),
      ];

      dispatch(setDashboard_filterGroups(newFilterGroups));
      dispatch(setDashboard_selectedFilterGroup(id));

      const [, error] = await promiseHandler(
        UserService.updateDashboardFilterGroups(newFilterGroups),
      );

      if (error) {
        Log.error('Failed to save new filter group.', error);
        ToastService.error(['Filter konnte nicht hinzugefügt werden.']);
      }
    };

    const updateFilterGroupName = async (id, name) => {
      const newFilterGroups = cloneDeep(filterGroups);

      for (const filterGroup of newFilterGroups) {
        if (filterGroup.id === id) {
          filterGroup.name = name;
        }
      }

      dispatch(setDashboard_filterGroups(newFilterGroups));

      const [, error] = await promiseHandler(
        UserService.updateDashboardFilterGroups(newFilterGroups),
      );

      if (error) {
        Log.error('Failed to update filter group name.', error);
        ToastService.error(['Filter konnte nicht umbenannt werden.']);
      }
    };

    const updateFilterGroup = async (filterRows) => {
      let newFilterGroups = cloneDeep(filterGroups);

      newFilterGroups = ArrayUtils.updateByKey(
        filterGroups,
        'id',
        selectedFilterGroup,
        getFilterGroupObject(selectedFilterGroup, null, filterRows),
      );

      dispatch(setDashboard_filterGroups(newFilterGroups));

      const [, error] = await promiseHandler(
        UserService.updateDashboardFilterGroups(newFilterGroups),
      );

      if (error) {
        Log.error('Failed to update filter group.', error);
        ToastService.error(['Filter konnte nicht geändert werden.']);
      }
    };

    const updateFilterRows = (filterRows) => {
      dispatch(setDashboard_filterRows(filterRows));
    };

    const deleteFilterGroup = async () => {
      let newFilterGroups = cloneDeep(filterGroups);

      newFilterGroups = ArrayUtils.removeByKey(
        filterGroups,
        'id',
        selectedFilterGroup,
      );

      dispatch(setDashboard_filterGroups(newFilterGroups));
      dispatch(setDashboard_selectedFilterGroup(null));
      resetFilters();

      const [, error] = await promiseHandler(
        UserService.updateDashboardFilterGroups(newFilterGroups),
      );

      if (error) {
        Log.error('Failed to delete filter group.', error);
        ToastService.error(['Filter konnte nicht gelöscht werden.']);
      }
    };

    useEffect(() => {
      initSelectableFilters();
      initFilterGroups();
    }, []);

    useEffect(() => {
      initSelectableFilters();
    }, [
      dateRange,
      oldestFilteredDlnDate,
      selectedAcceptState,
      selectedArticle,
      selectedArticleNumber,
      selectedCostCenter,
      selectedCustomFields,
      selectedFilterGroup,
      selectedFromSite,
      selectedPermittedCostCenters,
      selectedPermittedToSites,
      selectedProcessState,
      selectedRecipient,
      selectedSettledStatus,
      selectedSupplier,
      selectedToSiteRecipient,
      selectedToSiteSupplier,
      selectedToSiteSupplierTradeContact,
    ]);

    useEffect(() => {
      if (customFields) {
        initSelectableFilters();
      }
    }, [JSON.stringify(customFields)]);

    useEffect(() => {
      initFilterGroups();
    }, [filterGroups, dateRange, oldestFilteredDlnDate]);

    const originalFilterGroupObject = filterGroups.find(
      ({ id }) => id === selectedFilterGroup,
    );

    return (
      <FilterGroups
        filterGroups={state.filterGroups}
        selectedFilterGroup={selectedFilterGroup}
        filterRows={filterRows}
        onClickFilterGroup={onClickFilterGroup}
        saveNewFilterGroup={saveNewFilterGroup}
        updateFilterGroupName={updateFilterGroupName}
        deleteFilterGroup={deleteFilterGroup}
        updateFilterGroup={updateFilterGroup}
        updateFilterRows={updateFilterRows}
        currentFilterGroupObject={getFilterGroupObject(selectedFilterGroup)}
        originalFilterGroupObject={originalFilterGroupObject}
        resetSelectedFilterGroup={() =>
          dispatch(setDashboard_selectedFilterGroup(null))
        }
        selectableFilters={state.selectableFilters}
        onChangeValue={onChangeValue}
        filterGroupOpen
      />
    );
  },
  'Filtergruppen konnten nicht geladen werden.',
);

DashboardFilterGroups.displayName = 'DashboardFilterGroups';
