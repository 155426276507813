import { SettingsTestIds } from '~/constants/test-ids';

import { withErrorBoundary } from '~/ui/atoms';

export const Impressum = withErrorBoundary(() => {
  return (
    <div className="mx-4">
      <div className="main-header" data-testid={SettingsTestIds.IMPRINT.TITLE}>
        Impressum
      </div>
      <div className="rounded-md bg-white p-4 shadow-lg">
        <h3 className="main-text">Angaben gemäß § 5 TMG:</h3>
        <div className="bold">VESTIGAS GmbH</div>
        <div>Dorf Zellhub 48</div>
        <div>84307 Eggenfelden</div>
        <div className="mt-4">
          <span className="font-bold">Registergericht: </span>Landshut
        </div>
        <div>
          <span className="font-bold">HRB: </span>12875
        </div>
        <div className="mt-4 font-bold">Kontakt:</div>
        <div>Telefon: +49 (0)8721 507533</div>
        <div>E-Mail: info@vestigas.com</div>
        <div className="font- mt-4">Vertreten durch die Geschäftsführer:</div>
        <div>Julian Blum, Paul Kaiser</div>
        <div className="font- mt-4">Umsatzsteuer:</div>
        <div>
          Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetzt:
        </div>
        <div>DE346828280</div>
        <div className="font- mt-4">Büro:</div>
        <div>Lindwurmstraße 44</div>
        <div>80337 München</div>
        <div>Telefon: +49 (0)89 9589 3094</div>
        <h3 className="main-text mt-4 border-t pt-4">
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
        </h3>
        <div className="font-bold">VESTIGAS GmbH</div>
        <div>Dorf Zellhub 48</div>
        <div>84307 Eggenfelden</div>
        <div className="mt-4 font-bold">Kontakt:</div>
        <div>Telefon: +49 (0)8721 507533</div>
        <div>E-Mail: info@vestigas.com</div>
      </div>
    </div>
  );
}, 'Impressum konnte nicht geladen werden.');
