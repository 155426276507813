import { withErrorBoundary } from '~/ui/atoms';

import { DeliveryNoteDataQualityPath } from './DeliveryNoteDataQualityPath';

export const DeliveryNoteDataQualityCategory = withErrorBoundary(
  ({ category, dataQualityPairs }) => (
    <div className="flex flex-col gap-2">
      <h3>{category}</h3>
      {dataQualityPairs.map((item) => (
        <DeliveryNoteDataQualityPath key={item.name} {...item} />
      ))}
    </div>
  ),
  'Daten konnten nicht geladen werden.',
);
