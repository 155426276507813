import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4, validate as uuidvalidate } from 'uuid';
import {
  Checkbox,
  Chip as MuiChip,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import { LOADING_STATE } from '~/constants/LoadingState';

import ToastService from '~/services/toast.service';
import CustomFieldService from '~/services/customField.service';

import Article from '~/models/articles/Article';
import Company from '~/models/masterdata/Company';
import CustomFieldOption from '~/models/customData/CustomFieldOption';
import CustomField from '~/models/customData/CustomField';

import ArrayUtils from '~/utils/arrayUtils';
import FunctionUtils from '~/utils/functionUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import { Select } from '~/components/baseComponents/inputs/select/Select';
import BasicForm from '~/components/BasicForm';
import { Spinner } from '~/components/Spinner';
import ChipList from '~/components/baseComponents/chips/ChipList';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';

import { MultiSelectCompanies } from '~/ui/molecules/SelectServerDriven';

class CustomFieldFormClassComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customField: new CustomField(),
      customFieldOptionFormOpen: false,
      customFieldOptionsLoading: LOADING_STATE.NOT_LOADED,
      deletingForm: false,
      newCustomFieldOption: new CustomFieldOption(),
      newCustomFieldOptions: [],
      origCustomFieldOptions: [],
      removedCustomFieldOptions: [],
      submittingForm: false,
    };
  }

  componentDidMount() {
    this.resetForm();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.resetForm();
    }
  }

  async resetForm() {
    this.setState({
      customField: this.props.customField ?? new CustomField(),
      newCustomFieldOption: new CustomFieldOption(),
      newCustomFieldOptions: [],
      origCustomFieldOptions: [],
      removedCustomFieldOptions: [],
    });

    await this.loadCustomFieldOptions();
  }

  formSuccess = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      submittingForm: true,
    });

    const body = {
      allowed_units: this.state.customField.allowedUnits,
      default_unit: this.state.customField.defaultUnit ?? '',
      display_name: this.state.customField.displayName,
      has_options: this.state.customField.hasOptions,
      level: this.state.customField.level,
      name: this.state.customField.name,
      public: this.state.customField.public,
      type: this.state.customField.type,
      used_by: this.state.customField.usedBy,
      visibility: this.state.customField.visibility,
    };

    Log.info('Submit custom field form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.CUSTOM_FIELD);

    let customFieldId = this.props.customField?.id;

    if (this.renderForCreate()) {
      const [newCustomFieldId, error] = await promiseHandler(
        CustomFieldService.createCustomField(body),
      );

      if (error) {
        Log.error('Failed to create custom field.', error);
        ToastService.httpError(
          ['Flexibles Feld konnte nicht angelegt werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to create',
          Log.FEATURE.CUSTOM_FIELD,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }

      customFieldId = newCustomFieldId;
    } else {
      const [response, error] = await promiseHandler(
        CustomFieldService.updateCustomField(this.props.customField.id, body),
      );

      if (error) {
        Log.error(
          'Failed to update custom field. id: ' + this.props.customField.id,
          error,
        );
        ToastService.httpError(
          ['Flexibles Feld konnte nicht aktualisiert werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.CUSTOM_FIELD,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }
    }

    // Update the options of the custom field
    if (this.state.customField.hasOptions) {
      for (
        let index = 0;
        index < this.state.newCustomFieldOptions.length;
        index++
      ) {
        const body = {
          description: this.state.newCustomFieldOptions[index].description,
          display_name: this.state.newCustomFieldOptions[index].displayName,
          name: this.state.newCustomFieldOptions[index].displayName,
        };

        const [response, error] = await promiseHandler(
          CustomFieldService.createCustomFieldOption(customFieldId, body),
        );

        if (error) {
          Log.error('Failed to create custom field option.', error);
          ToastService.httpError(
            ['Dropdown Option konnte nicht angelegt werden.'],
            error.response,
          );
          Log.productAnalyticsEvent(
            'Failed to create option',
            Log.FEATURE.CUSTOM_FIELD,
            Log.TYPE.ERROR,
          );
        }
      }

      for (
        let index = 0;
        index < this.state.removedCustomFieldOptions.length;
        index++
      ) {
        const [response, error] = await promiseHandler(
          CustomFieldService.deleteCustomFieldOption(
            customFieldId,
            this.state.removedCustomFieldOptions[index].id,
          ),
        );

        if (error) {
          Log.error('Failed to delete custom field option.', error);
          ToastService.httpError(
            ['Dropdown Option konnte nicht entfernt werden.'],
            error.response,
          );
          Log.productAnalyticsEvent(
            'Failed to delete option',
            Log.FEATURE.CUSTOM_FIELD,
            Log.TYPE.ERROR,
          );
        }
      }
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    CustomFieldService.refreshCustomFields();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.CUSTOM_FIELD);
    this.props.closeForm();
    this.resetForm();
  };
  formDelete = async (event) => {
    event.preventDefault();

    Log.info(
      'Delete custom field',
      { id: this.props.customField.id },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent('Delete', Log.FEATURE.CUSTOM_FIELD);

    this.setState({
      deletingForm: true,
    });

    const [response, error] = await promiseHandler(
      CustomFieldService.deleteCustomField(this.props.customField.id),
    );

    if (error) {
      ToastService.httpError(
        ['Flexibles Feld konnte nicht gelöscht werden.'],
        error.response,
      );
      Log.error('Failed to delete custom field.', error);
      Log.productAnalyticsEvent(
        'Failed to delete',
        Log.FEATURE.CUSTOM_FIELD,
        Log.TYPE.ERROR,
      );
      this.setState({
        deletingForm: false,
      });
      return;
    }

    this.setState({
      deletingForm: false,
    });

    this.props.closeForm();
    this.resetForm();
    await CustomFieldService.refreshCustomFields();
  };
  renderForCreate = () => {
    return this.props.type === 'create';
  };

  async loadCustomFieldOptions() {
    if (!this.props.customField?.hasOptions) {
      return;
    }

    this.setState({
      customFieldOptionsLoading: LOADING_STATE.LOADING,
    });

    const [customFieldOptions, error] = await promiseHandler(
      CustomFieldService.getCustomFieldOptions(
        this.props.customField?.id,
        true,
      ),
    );

    if (error) {
      Log.error(
        'Failed to load custom field options. custom field id: ' +
          this.props.customField?.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load custom field options',
        Log.FEATURE.CUSTOM_FIELD,
        Log.TYPE.ERROR,
      );
      this.setState({
        customFieldOptionsLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    const newCustomField = cloneDeep(this.state.customField);

    newCustomField.options = customFieldOptions;

    this.setState({
      customField: newCustomField,
      customFieldOptionsLoading: LOADING_STATE.SUCCEEDED,
      origCustomFieldOptions: customFieldOptions,
    });
  }

  handleInputChange = (event) => {
    const newCustomField = cloneDeep(this.state.customField);

    switch (event.target.name) {
      case 'name': {
        newCustomField.name = event.target.value;
        Log.info(
          'Change form value of name',
          { from: this.state.customField.name, to: newCustomField.name },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'custom_field_change_name',
          Log.productAnalyticsEvent,
          ['Change name', Log.FEATURE.CUSTOM_FIELD],
        );
        break;
      }

      case 'displayName': {
        newCustomField.displayName = event.target.value;
        Log.info(
          'Change form value of display name',
          {
            from: this.state.customField.displayName,
            to: newCustomField.displayName,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'custom_field_change_display_name',
          Log.productAnalyticsEvent,
          ['Change display name', Log.FEATURE.CUSTOM_FIELD],
        );
        break;
      }
    }

    this.setState({
      customField: newCustomField,
    });
  };
  handleChangeLevel = (event) => {
    const newCustomField = cloneDeep(this.state.customField);

    newCustomField.level = event.target.value;

    Log.info(
      'Change form value of level',
      { from: this.state.customField.level, to: newCustomField.level },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change level', Log.FEATURE.CUSTOM_FIELD);

    this.setState({
      customField: newCustomField,
    });
  };
  handleChangeVisibility = (event) => {
    const newCustomField = cloneDeep(this.state.customField);

    newCustomField.visibility = event.target.value;

    Log.info(
      'Change form value of visibility',
      {
        from: this.state.customField.visibility,
        to: newCustomField.visibility,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change visibility', Log.FEATURE.CUSTOM_FIELD);

    this.setState({
      customField: newCustomField,
    });
  };
  handleChangePublic = (event) => {
    const newCustomField = cloneDeep(this.state.customField);

    newCustomField.public = event.target.value === 'public_yes';

    Log.info(
      'Change form value of public',
      { from: this.state.customField.public, to: newCustomField.public },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change public', Log.FEATURE.CUSTOM_FIELD);

    this.setState({
      customField: newCustomField,
    });
  };
  handleChangeCustomFieldType = (event) => {
    const newCustomField = cloneDeep(this.state.customField);

    newCustomField.type = event.target.value;

    // Dropdown options are only possible if the custom field is of type enumeration
    if (newCustomField.type !== CustomField.TYPE.ENUMERATION.KEY) {
      newCustomField.hasOptions = false;
    }

    Log.info(
      'Change form value of type',
      { from: this.state.customField.type, to: newCustomField.type },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change type', Log.FEATURE.CUSTOM_FIELD);

    this.setState({
      customField: newCustomField,
    });
  };
  handleCheckboxChange = (event) => {
    const newCustomField = cloneDeep(this.state.customField);

    newCustomField.hasOptions = event.target.checked;

    Log.info(
      'Change form value of has options',
      {
        from: this.state.customField.hasOptions,
        to: newCustomField.hasOptions,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change has options checkbox',
      Log.FEATURE.CUSTOM_FIELD,
    );

    this.setState({
      customField: newCustomField,
    });
  };
  handleChangeAllowedUnits = (allowedUnits) => {
    const newCustomField = cloneDeep(this.state.customField);

    newCustomField.allowedUnits = allowedUnits.map((unit) => unit.id);
    if (!newCustomField.allowedUnits.includes(newCustomField.defaultUnit)) {
      newCustomField.defaultUnit = null;
    } // Reset default unit if it is removed from allowed units.

    Log.info(
      'Change form value of allowed units',
      {
        from: this.state.customField.allowedUnits,
        to: newCustomField.allowedUnits,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change allowed units', Log.FEATURE.CUSTOM_FIELD);

    this.setState({
      customField: newCustomField,
    });
  };
  handleChangeDefaultUnit = (event) => {
    const newCustomField = cloneDeep(this.state.customField);

    newCustomField.defaultUnit = event.target.value;

    Log.info(
      'Change form value of default unit',
      {
        from: this.state.customField.defaultUnit,
        to: newCustomField.defaultUnit,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change default unit', Log.FEATURE.CUSTOM_FIELD);

    this.setState({
      customField: newCustomField,
    });
  };
  handleChangeUsedBy = (usedBy) => {
    const newCustomField = cloneDeep(this.state.customField);

    newCustomField.usedBy = usedBy.map((company) => company.id);

    Log.info(
      'Change form value of used by',
      {
        from: this.state.customField.usedBy,
        to: newCustomField.usedBy,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change used by', Log.FEATURE.CUSTOM_FIELD);

    this.setState({
      customField: newCustomField,
    });
  };
  handleNewOptionCreate = (option) => {
    if (!uuidvalidate(option.inputValue)) {
      ToastService.error(['Ungültige ID: Bitte gib eine gültige UUID ein.']);
      Log.productAnalyticsEvent(
        'Invalid UUID',
        Log.FEATURE.CUSTOM_FIELD,
        Log.TYPE.ERROR,
      );
      return null;
    }

    return new Company({ id: option.inputValue, name: option.inputValue });
  };

  getUnsavedChanges() {
    // Unsaved changes logic is a bit tricky with create forms because this.props.customField is null and some form values are set per default.
    if (this.renderForCreate()) {
      return [];
    }

    // We need to overwrite the custom field options
    // because otherwise the unsaved changes would be determined incorrectly.
    // If the original ones haven't been set yet, no changes should be displayed. Thus, the current state is compared with itself.
    const newPropsCustomField = cloneDeep(this.props.customField);
    newPropsCustomField.options = this.state.origCustomFieldOptions;

    const newStateCustomField = cloneDeep(this.state.customField);
    newStateCustomField.options = this.getCustomFieldOptions();

    return CustomField.getDifferentValues(
      newPropsCustomField,
      newStateCustomField,
    );
  }

  onAddNewCustomFieldOption = (event) => {
    event.currentTarget.blur();

    this.setState({
      customFieldOptionFormOpen: true,
    });
  };
  onDeleteCustomFieldOption = (optionId) => {
    // Previously added -> remove from the added ones
    if (
      this.state.newCustomFieldOptions.find(
        (customFieldOption) => customFieldOption.id === optionId,
      )
    ) {
      this.setState({
        newCustomFieldOptions: ArrayUtils.removeByKey(
          this.state.newCustomFieldOptions,
          'id',
          optionId,
        ),
      });
      return;
    }

    // Normal case: Extend the list of removed items
    this.setState({
      removedCustomFieldOptions: [
        ...this.state.removedCustomFieldOptions,
        this.state.origCustomFieldOptions.find(
          (origCustomFieldOption) => origCustomFieldOption.id === optionId,
        ),
      ],
    });
  };
  customFieldOptionFormSuccess = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // Already added -> do nothing
    if (
      this.state.newCustomFieldOptions.find(
        (customFieldOption) =>
          customFieldOption.displayName ===
          this.state.newCustomFieldOption.displayName,
      )
    ) {
      this.setState({
        customFieldOptionFormOpen: false,
        newCustomFieldOption: new CustomFieldOption(),
      });
      return;
    }

    // Has been removed -> add it again (remove it from the removed ones)
    if (
      this.state.removedCustomFieldOptions.find(
        (customFieldOption) =>
          customFieldOption.displayName ===
          this.state.newCustomFieldOption.displayName,
      )
    ) {
      this.setState({
        customFieldOptionFormOpen: false,
        newCustomFieldOption: new CustomFieldOption(),
        removedCustomFieldOptions: ArrayUtils.removeByKey(
          this.state.removedCustomFieldOptions,
          'displayName',
          this.state.newCustomFieldOption.displayName,
        ),
      });
      return;
    }

    // Has not been removed and is in the orig ones, so it means that it is displayed right now -> do nothing
    if (
      this.state.origCustomFieldOptions.find(
        (customFieldOption) =>
          customFieldOption.displayName ===
          this.state.newCustomFieldOption.displayName,
      )
    ) {
      this.setState({
        customFieldOptionFormOpen: false,
        newCustomFieldOption: new CustomFieldOption(),
      });
      return;
    }

    // Last case: add it to the list of new ones
    this.setState({
      customFieldOptionFormOpen: false,
      newCustomFieldOption: new CustomFieldOption(),
      newCustomFieldOptions: [
        ...this.state.newCustomFieldOptions,
        {
          description: this.state.newCustomFieldOption.description,
          displayName: this.state.newCustomFieldOption.displayName,
          id: uuidv4(),
        },
      ],
    });
  };
  customFieldOptionFormAbort = () => {
    this.setState({
      customFieldOptionFormOpen: false,
    });
  };

  getCustomFieldOptions() {
    const customFieldOptions = [];

    for (const origCustomFieldOption of this.state.origCustomFieldOptions) {
      // Don't display if it has been removed.
      if (
        this.state.removedCustomFieldOptions.find(
          (removedCustomFieldOption) =>
            removedCustomFieldOption.id === origCustomFieldOption.id,
        )
      ) {
        continue;
      }

      customFieldOptions.push(origCustomFieldOption);
    }

    for (const newCustomFieldOption of this.state.newCustomFieldOptions) {
      // Add the new tag for the added ones.
      customFieldOptions.push({ ...newCustomFieldOption, new: true });
    }

    return customFieldOptions;
  }

  onChangeCustomFieldOptionDisplayName = (event) => {
    const newCustomFieldOption = cloneDeep(this.state.newCustomFieldOption);

    newCustomFieldOption.displayName = event.target.value;

    this.setState({
      newCustomFieldOption,
    });
  };
  onChangeCustomFieldOptionDescription = (event) => {
    const newCustomFieldOption = cloneDeep(this.state.newCustomFieldOption);

    newCustomFieldOption.description = event.target.value;

    this.setState({
      newCustomFieldOption,
    });
  };

  getCustomFieldOptionsChipList() {
    if (!this.state.customField?.hasOptions) {
      return null;
    }

    if (this.state.customFieldOptionsLoading === LOADING_STATE.LOADING) {
      return (
        <div className="w-480px h-42px">
          <Spinner />
        </div>
      );
    }

    if (this.state.customFieldOptionsLoading === LOADING_STATE.FAILED) {
      return (
        <div className="w-480px h-42px">
          Daten konnten nicht geladen werden.
        </div>
      );
    }

    return (
      <div className="flex-s-c gap-8px w-full">
        <MuiChip
          onClick={this.onAddNewCustomFieldOption}
          key="new-option"
          label="+ Dropdown Option"
          sx={{
            // primary500
            '&:hover': {
              backgroundColor: '#E4E6ED',
            },

            backgroundColor: 'white',

            border: '#173C88 solid 1px',

            color: '#173C88',
            cursor: 'pointer',
          }}
        />
        <BasicForm
          open={this.state.customFieldOptionFormOpen}
          formSuccess={this.customFieldOptionFormSuccess}
          formAbort={this.customFieldOptionFormAbort}
          title="Dropdown Option Hinzufügen"
        >
          <div className="w-350px">
            <TextField
              title="Name"
              value={this.state.newCustomFieldOption.displayName}
              onChange={this.onChangeCustomFieldOptionDisplayName}
              placeholder="Name"
              autoComplete="off"
              required
              autoFocus
              fullWidth
            />
            <TextField
              title="Beschreibung"
              value={this.state.newCustomFieldOption.description}
              onChange={this.onChangeCustomFieldOptionDescription}
              placeholder="Beschreibung"
              autoComplete="off"
              autoFocus
              fullWidth
              className="mt-20px"
            />
          </div>
        </BasicForm>
        <ChipList
          items={this.getCustomFieldOptions().map((customFieldOption) => {
            return {
              ...customFieldOption,
              name: customFieldOption.description
                ? customFieldOption.displayName +
                  ' (' +
                  customFieldOption.description +
                  ')'
                : customFieldOption.displayName,
            };
          })}
          newItems={this.state.newCustomFieldOptions}
          onDelete={this.onDeleteCustomFieldOption}
          deletable
        />
      </div>
    );
  }

  getAllowedUnitOptions() {
    return Object.values(Article.UNIT).map((value) => {
      return {
        id: value.STANDARDISED,
        name: value.ABBREVIATED_STRING ?? value.ABBREVIATED,
      };
    });
  }

  getDefaultUnitOptions() {
    return this.getAllowedUnitOptions().filter((unit) =>
      this.state.customField?.allowedUnits?.includes(unit.id),
    );
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        formDelete={this.renderForCreate() ? null : this.formDelete}
        title={
          'Flexibles Feld ' +
          (this.renderForCreate() ? 'erstellen' : this.props.customField.name)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        deletingForm={this.state.deletingForm}
        unsavedChanges={this.getUnsavedChanges()}
        id={this.props.customField?.id}
      >
        <Grid container direction="row" spacing={3} space={4}>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text mt-0">Flexibles Feld</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="name-input"
                  name="name"
                  label="Name"
                  type="text"
                  disabled={!this.renderForCreate()}
                  required
                  fullWidth
                  value={this.state.customField?.name}
                  onChange={this.handleInputChange}
                  autoFocus={this.renderForCreate()}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="display-name-input"
                  name="displayName"
                  label="Anzeige-Name"
                  type="text"
                  required
                  fullWidth
                  value={this.state.customField?.displayName}
                  onChange={this.handleInputChange}
                  autoFocus={!this.renderForCreate()}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Level</InputLabel>
                <Select
                  value={this.state.customField?.level}
                  fullWidth
                  onChange={this.handleChangeLevel}
                  size="small"
                  options={CustomField.getCustomFieldLevels()}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Sichtbarkeit</InputLabel>
                <Select
                  value={this.state.customField?.visibility}
                  fullWidth
                  onChange={this.handleChangeVisibility}
                  size="small"
                  options={CustomField.getCustomFieldVisibilities()}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">
              Sichtbarkeit Firmen-Account-intern (Privat) oder
              Firmen-Account-übergreifend (Öffentlich)
            </h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <RadioGroup
                  onChange={this.handleChangePublic}
                  value={
                    this.state.customField?.public ? 'public_yes' : 'public_no'
                  }
                  row
                >
                  <FormControlLabel
                    value="public_no"
                    control={<Radio />}
                    label="Privat"
                  />
                  <FormControlLabel
                    value="public_yes"
                    control={<Radio />}
                    label="Öffentlich"
                    className="mr-50px"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">Typ</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <InputLabel className="text-13px">Typ</InputLabel>
                <Select
                  value={this.state.customField?.type}
                  fullWidth
                  onChange={this.handleChangeCustomFieldType}
                  size="small"
                  options={CustomField.getCustomFieldTypes()}
                  disabled={!this.renderForCreate()}
                />
              </Grid>
              {this.state.customField?.type ===
              CustomField.TYPE.ENUMERATION.KEY ? (
                <>
                  <Grid item xs={6} lg={4}>
                    <FormControlLabel
                      className="mt-17px"
                      label="Dropdown"
                      control={
                        <Checkbox
                          checked={this.state.customField?.hasOptions}
                          onChange={this.handleCheckboxChange}
                          name="has_options"
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    {this.getCustomFieldOptionsChipList()}
                  </Grid>
                </>
              ) : null}
              {this.state.customField?.type ===
              CustomField.TYPE.VALUE_MEASURE.KEY ? (
                <Grid item xs={12} lg={12} className="mt-10px">
                  <Grid container spacing={2}>
                    <Grid item xs={6} lg={4}>
                      <GenericMultiPicker
                        textfieldLabel="Mögliche Einheiten"
                        pickedItemIds={
                          this.state.customField?.allowedUnits ?? []
                        }
                        allItems={this.getAllowedUnitOptions()}
                        onSaveSelection={this.handleChangeAllowedUnits}
                      />
                    </Grid>
                    {this.state.customField?.allowedUnits?.length > 0 ? (
                      <Grid item xs={6} lg={4}>
                        <InputLabel className="text-13px">
                          Default Einheit
                        </InputLabel>
                        <Select
                          value={this.state.customField?.defaultUnit}
                          fullWidth
                          onChange={this.handleChangeDefaultUnit}
                          size="small"
                          options={this.getDefaultUnitOptions()}
                          withEmptyOption
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8}>
            <h3 className="mt-20px main-text">
              In PDF-Lieferschein verwendet von den Firmen...
            </h3>
            <MultiSelectCompanies
              placeholder={'Firmen'}
              value={this.state.customField?.usedBy ?? []}
              onChange={this.handleChangeUsedBy}
              // FIXME: allow creating new companies
              // onNewOptionCreate={this.handleNewOptionCreate}
            />
          </Grid>
        </Grid>
      </BasicForm>
    );
  }
}

export const CustomFieldForm = CustomFieldFormClassComponent;
