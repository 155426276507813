import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DataGridPro } from '@mui/x-data-grid-pro';

import {
  DeclinedIconLight,
  DoneIconLight,
  EditedIconLight,
  NotSettledIcon,
  PartlySettledIcon,
  SettledIcon,
  UnsignedIconLight,
} from '~/assets/icons';

import ToastService from '~/services/toast.service';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import Article from '~/models/articles/Article';
import BilledItem from '~/models/billingState/BilledItem';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import ValueGroup from '~/models/deliveries/ValueGroup';

import UnitUtils from '~/utils/unitUtils';
import DatagridUtils from '~/utils/datagridUtils';
import Log from '~/utils/Log';
import { LightTooltip, LightTooltipWide } from '~/utils/componentUtils';

import { ArticleModal } from '~/components/articles/ArticleModal';

import { withErrorBoundary } from '~/ui/atoms';

import { DeliveryNoteArticleSummary } from './DeliveryNoteArticleSummary';
import { EditingHistorySafe as EditingHistory } from './EditingHistorySafe';
import {
  type CompanyAccount,
  type DeliveryDirection,
  type DeliveryNoteType,
} from './types';

const mapStateToProps = (state) => ({
  companyAccount: state.companyAccount,
});

type DeliveryNoteArticleListProps = {
  companyAccount: {
    companyAccount: CompanyAccount;
  };
  deliveryDirection: DeliveryDirection;
  deliveryNote: DeliveryNoteType;
};

const DeliveryNoteArticleList = ({
  deliveryDirection,
  deliveryNote,
}: DeliveryNoteArticleListProps) => {
  const [article, setArticle] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (article) {
      const updatedArticle = deliveryNote?.articles?.find(
        ({ logisticsPackage, position }) =>
          ValueGroup.getCurrentValue(logisticsPackage) ===
            ValueGroup.getCurrentValue(article?.logisticsPackage) &&
          ValueGroup.getCurrentValue(position) ===
            ValueGroup.getCurrentValue(article?.position),
      );
      setArticle(updatedArticle);
    }
  }, [deliveryNote?.articles]);

  const handleClose = () => {
    Log.productAnalyticsEvent('Close article', Log.FEATURE.DELIVERY_NOTE);
    setOpen(false);
  };

  const onRowClick = (row: Record<string, any>) => {
    const rowId = row.id?.split(';');

    const selectedArticle = deliveryNote?.articles?.find((article) => {
      return (
        ValueGroup.getCurrentValue(article.logisticsPackage) === rowId[0] &&
        ValueGroup.getCurrentValue(article.position) === rowId[1]
      );
    });

    if (!selectedArticle) {
      ToastService.error([ToastService.MESSAGE.ARTICLE_OPEN_FAILED]);
      Log.error(
        'Failed to find article: package=' +
          rowId[0] +
          ',  position=' +
          rowId[1],
      );
      Log.productAnalyticsEvent(
        'Failed to open article',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      return;
    }

    Log.info(
      'Open article modal',
      {
        from: 'Delivery Note',
        to:
          'Article | Package: ' +
          ValueGroup.getCurrentValue(selectedArticle.logisticsPackage) +
          ' | Position: ' +
          ValueGroup.getCurrentValue(selectedArticle.position),
      },
      Log.BREADCRUMB.MODAL_OPEN.KEY,
    );
    Log.productAnalyticsEvent('Open article', Log.FEATURE.DELIVERY_NOTE);

    setArticle(selectedArticle);
    setOpen(true);
  };

  const getColumns = () => {
    const columns = [];

    const displayAmountColumn = deliveryNote?.articles?.some((article) =>
      article.displayAmount(),
    );
    const displayWeightColumn = deliveryNote?.articles?.some((article) =>
      article.displayWeight(),
    );

    if (
      deliveryNote?.settledStatus !== BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY
    ) {
      columns.push({
        field: 'settledStatus',
        headerName: '',
        renderCell(params) {
          switch (params.value) {
            case BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY: {
              return (
                <LightTooltip
                  title={BilledItem.SETTLED_STATUS.FULLY_SETTLED.DESCRIPTION}
                >
                  <div className="flex h-full items-center gap-1 py-0.5">
                    <div className="border-successBase flex size-10 items-center justify-center rounded-md">
                      <SettledIcon className="text-successBase" />
                    </div>
                  </div>
                </LightTooltip>
              );
            }

            case BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY: {
              return (
                <LightTooltip
                  title={BilledItem.SETTLED_STATUS.PARTLY_SETTLED.DESCRIPTION}
                >
                  <div className="flex h-full items-center gap-1 py-0.5">
                    <div className="border-warningBase flex size-10 items-center justify-center rounded-md">
                      <PartlySettledIcon className="text-warningBase" />
                    </div>
                  </div>
                </LightTooltip>
              );
            }

            case BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY: {
              return (
                <LightTooltip
                  title={BilledItem.SETTLED_STATUS.NOT_SETTLED.DESCRIPTION}
                >
                  <div className="flex h-full items-center gap-1 py-0.5">
                    <div className="border-grey600 flex size-10 items-center justify-center rounded-md">
                      <NotSettledIcon className="text-grey600" />
                    </div>
                  </div>
                </LightTooltip>
              );
            }

            default: {
              return null;
            }
          }
        },
        sortable: true,
        width: 70,
      });
    }

    if (
      deliveryNote?.acceptState === AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      columns.push({
        field: 'acceptState',
        headerName: '',
        renderCell(params) {
          switch (params.value.acceptState) {
            case AcceptStateCalculator.ACCEPT_STATE.DECLINED: {
              return (
                <LightTooltip
                  title={'Artikel wurde abgelehnt (' + params.value.by + ').'}
                >
                  <div className="flex h-full items-center gap-1 py-0.5">
                    <DeclinedIconLight className="ml-2 h-4" />
                  </div>
                </LightTooltip>
              );
            }

            case AcceptStateCalculator.ACCEPT_STATE.APPROVED: {
              return (
                <LightTooltip title={'Artikel wurde bestätigt.'}>
                  <div className="flex h-full items-center gap-1 py-0.5">
                    <DoneIconLight className="ml-2 h-4" />
                  </div>
                </LightTooltip>
              );
            }

            case AcceptStateCalculator.ACCEPT_STATE.OPEN: {
              return (
                <LightTooltip
                  title={
                    'Artikel wurde nicht signiert (' + params.value.by + ').'
                  }
                >
                  <div className="flex h-full items-center gap-1 py-0.5">
                    <UnsignedIconLight className="ml-2 h-4" />
                  </div>
                </LightTooltip>
              );
            }

            default: {
              return null;
            }
          }
        },
        sortable: true,
        width: 70,
      });
    }

    columns.push(
      {
        align: 'right',
        field: 'position',
        flex: 1,
        headerAlign: 'right',
        headerName: 'Position',
        sortable: true,
      },
      {
        field: 'number',
        flex: 1,
        headerName: 'Artikel-Nr.',
        renderCell: (params) =>
          DatagridUtils.displayCellTooltipControlled(
            <EditingHistory value={params.value} fallback="" />,
            ValueGroup.getCurrentValue(params.value),
          ),
        sortable: true,
      },
    );

    if (
      deliveryNote?.articles?.some(
        (article) =>
          article.isConcrete() &&
          article.concrete?.concreteId &&
          ValueGroup.getCurrentValue(article.concrete?.concreteId) !==
            ValueGroup.getCurrentValue(article.number),
      )
    ) {
      columns.push({
        field: 'concreteId',
        flex: 1,
        headerName: 'Sorten-Nr.',
        renderCell: (params) =>
          DatagridUtils.displayCellTooltipControlled(
            <EditingHistory value={params.value} fallback="" />,
            ValueGroup.getCurrentValue(params.value),
          ),
        sortable: true,
      });
    }

    columns.push({
      field: 'type',
      flex: 3,
      headerName: 'Artikel',
      renderCell(params) {
        return DatagridUtils.displayCellTooltipControlled(
          params.value.edited ? (
            <div className="flex-s-c">
              {ValueGroup.getCurrentValue(params.value.type)}
              <LightTooltipWide title="Artikel wurde bearbeitet (mehr Infos in der Detailansicht).">
                <EditedIconLight className="ml-2 size-4" />
              </LightTooltipWide>
            </div>
          ) : (
            <EditingHistory value={params.value.type} fallback="" />
          ),
          ValueGroup.getCurrentValue(params.value.type),
        );
      },
      sortable: true,
    });

    if (
      deliveryNote?.acceptState === AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      columns.push({
        field: 'partialAcceptStatus',
        flex: 2,
        headerName: 'Abgelehnt',
        renderCell: DatagridUtils.displayCellTooltip,
        sortable: true,
      });
    }

    if (displayAmountColumn) {
      columns.push(
        {
          field: 'amountValue',
          flex: 1,
          headerName: 'Menge',
          sortable: true,
          type: 'number',
          valueFormatter: (value) =>
            UnitUtils.formatDe_safe(ValueGroup.getCurrentValue(value)),
        },
        {
          field: 'amountUnit',
          headerName: 'Einheit',
          renderCell(params) {
            return (
              <EditingHistory
                value={params.value}
                callback={UnitUtils.getAbbreviatedUnit}
                fallback=""
              />
            );
          },
          sortable: true,
          width: 70,
        },
      );
    }

    if (displayWeightColumn) {
      columns.push(
        {
          field: 'weightValue',
          flex: 1,
          headerName: 'Gewicht',
          sortable: true,
          type: 'number',
          valueFormatter: (value) => UnitUtils.formatDe_safe(value),
          valueGetter: (value) => ValueGroup.getCurrentValue(value),
        },
        {
          field: 'weightUnit',
          headerName: 'Einheit',
          renderCell(params) {
            return (
              <EditingHistory
                value={params.value}
                callback={UnitUtils.getAbbreviatedUnit}
                fallback=""
              />
            );
          },
          sortable: true,
          width: 70,
        },
      );
    }

    return columns;
  };

  const getRowBackgroundClass = (row: Record<string, any>) => {
    if (row.id === hoveredRow) {
      return 'bg-light-grey';
    }

    if (
      row.acceptState.acceptState ===
      AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      return 'bg-declined';
    }

    return '';
  };

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setHoveredRow(event.currentTarget.dataset.id);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const shouldDisplaySummary = () => {
    if (
      deliveryDirection === Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY
    ) {
      return true;
    }

    return (
      deliveryNote?.deliveredArticles?.length > 1 ||
      deliveryNote?.deliveredArticles?.length === 0 ||
      deliveryNote?.returnedArticles?.length > 1
    );
  };

  const articles =
    deliveryDirection === Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY
      ? deliveryNote?.deliveredArticles
      : deliveryNote?.returnedArticles;

  const rows = articles?.map((article) => {
    const position = deliveryNote?.hasMultipleLogisticsPackages()
      ? '(' +
        ValueGroup.getCurrentValue(article.logisticsPackage) +
        ') ' +
        ValueGroup.getCurrentValue(article.position)
      : ValueGroup.getCurrentValue(article.position);

    return {
      acceptState: {
        acceptState: article.acceptState,
        by: [
          ...(article.acceptArticleSupplier.acceptState === article.acceptState
            ? [DeliveryNote.PROCESS_ROLE.SUPPLIER.STRING]
            : []),
          ...(article.acceptArticleCarrier.acceptState === article.acceptState
            ? [DeliveryNote.PROCESS_ROLE.CARRIER.STRING]
            : []),
          ...(article.acceptArticleRecipient.acceptState === article.acceptState
            ? [DeliveryNote.PROCESS_ROLE.RECIPIENT.STRING]
            : []),
          ...(article.acceptArticleOnBehalfSupplier.acceptState ===
          article.acceptState
            ? [DeliveryNote.PROCESS_ROLE.SUPPLIER.STRING]
            : []),
          ...(article.acceptArticleOnBehalfCarrier.acceptState ===
          article.acceptState
            ? [DeliveryNote.PROCESS_ROLE.CARRIER.STRING]
            : []),
          ...(article.acceptArticleOnBehalfRecipient.acceptState ===
          article.acceptState
            ? [DeliveryNote.PROCESS_ROLE.RECIPIENT.STRING]
            : []),
        ].join(', '),
      },
      amountUnit: article.amount?.unit,
      amountValue: article.amount?.value,
      concreteId: article.concrete?.concreteId,
      id:
        ValueGroup.getCurrentValue(article.logisticsPackage) +
        ';' +
        ValueGroup.getCurrentValue(article.position),
      number: article.number,
      partialAcceptStatus: article.getPartialAcceptStatusString(),
      position,
      settledStatus: article.billedItem?.settledStatus,
      type: { edited: article.hasBeenEdited(), type: article.type },
      weightUnit: article.weight?.unit,
      weightValue: article.weight?.value,
    };
  });

  return (
    <>
      {shouldDisplaySummary() && (
        <DeliveryNoteArticleSummary
          deliveryNote={deliveryNote}
          deliveryDirection={deliveryDirection}
        />
      )}
      <ArticleModal
        open={open}
        handleClose={handleClose}
        article={article}
        deliveryNote={deliveryNote}
        deliveryDirection={deliveryDirection}
      />
      <DataGridPro
        className="border-none"
        rows={rows}
        columns={getColumns()}
        pageSizeOptions={[100]}
        onRowClick={(rowData) => {
          onRowClick(rowData.row);
        }}
        getRowClassName={(params) => `${getRowBackgroundClass(params.row)}`}
        disableRowSelectionOnClick
        slotProps={{
          row: {
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
            style: { cursor: 'pointer' },
          },
        }}
      />
    </>
  );
};

export default withErrorBoundary(
  connect(mapStateToProps)(DeliveryNoteArticleList),
  'Artikel konnten nicht geladen werden.',
);
