import React from 'react';
import { Grid } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';

import { ProfileBanner } from './ProfileBanner';
import ProfileDetails from './ProfileDetails';
import ProfileUserSettings from './ProfileUserSettings';

export const ProfileSettings = withErrorBoundary(
  () => (
    <>
      <ProfileBanner />
      <div className="mt-4">
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} sm={6}>
            <div className="rounded-md bg-white p-4 shadow-lg">
              <ProfileDetails />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="rounded-md bg-white p-4 shadow-lg">
              <ProfileUserSettings />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  ),
  'Profil konnte nicht geladen werden.',
);

ProfileSettings.displayName = 'ProfileSettings';
