import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { DeclinedIconLight } from '~/assets/icons';

import ValueGroup from '~/models/deliveries/ValueGroup';
import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import SignatureRoles from '~/models/masterdata/SignatureRoles';
import BilledItem from '~/models/billingState/BilledItem';
import CustomField from '~/models/customData/CustomField';

import { withErrorBoundary } from '~/ui/atoms';

import BasicModal from '~/components/BasicModal';
import { DeliveryNoteMetaDataItem } from '~/components/deliveries/deliveryNote/deliveryNoteMetaData/DeliveryNoteMetaDataItem';
import { EditingHistorySafe as EditingHistory } from '~/components/deliveries/deliveryNote/EditingHistorySafe';

import { ArticleBilledItem } from './ArticleBilledItem';
import { ArticleConcrete } from './ArticleConcrete';
import { ArticleManufacturer } from './ArticleManufacturer';
import { ArticleMaterial } from './ArticleMaterial';
import { ArticleMetaData } from './ArticleMetaData';
import { ArticleStatus } from './ArticleStatus';
import { ArticleWeighing } from './ArticleWeighing';

export const ArticleModal = withErrorBoundary(
  ({ article, deliveryDirection, deliveryNote, handleClose, open }) => {
    const { customFieldsBulk } = useSelector((state) => state.customFields);

    const grossWeightSet = () => {
      return (
        ValueGroup.getCurrentValue(
          article?.weighingInformation?.gross?.weight?.value,
        ) &&
        ValueGroup.getCurrentValue(
          article?.weighingInformation?.gross?.weight?.unit,
        )
      );
    };

    const tareWeightSet = () => {
      return (
        ValueGroup.getCurrentValue(
          article?.weighingInformation?.tare?.weight?.value,
        ) &&
        ValueGroup.getCurrentValue(
          article?.weighingInformation?.tare?.weight?.unit,
        )
      );
    };

    const acceptArticles = [
      {
        acceptArticle: article?.acceptArticleSupplier,
        party: SignatureRoles.SIGNATURE_ROLE.SUPPLIER.KEY,
      },
      {
        acceptArticle: article?.acceptArticleCarrier,
        party: SignatureRoles.SIGNATURE_ROLE.CARRIER.KEY,
      },
      {
        acceptArticle: article?.acceptArticleRecipient,
        party: SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY,
      },
      {
        acceptArticle: article?.acceptArticleOnBehalfSupplier,
        party: SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_SUPPLIER.KEY,
      },
      {
        acceptArticle: article?.acceptArticleOnBehalfCarrier,
        party: SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_CARRIER.KEY,
      },
      {
        acceptArticle: article?.acceptArticleOnBehalfRecipient,
        party: SignatureRoles.SIGNATURE_ROLE.ON_BEHALF_RECIPIENT.KEY,
      },
    ];

    return (
      <BasicModal
        title={ValueGroup.getCurrentValue(article?.type)}
        open={open}
        closeModal={handleClose}
        fullWidth
      >
        <Grid
          container
          spacing={4}
          justifyContent="space-between"
          className="main-text"
        >
          <Grid item xs={12} lg={3}>
            <div className="main-background h-full rounded-md border-gray-400 p-4">
              <ArticleMetaData
                article={article}
                deliveryNote={deliveryNote}
                deliveryDirection={deliveryDirection}
              />
            </div>
          </Grid>
          <Grid item xs={12} lg={9}>
            <div className="flex w-full flex-col gap-4">
              {article?.billedItem?.settledStatus ===
              BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY ? (
                <div className="border-successBase rounded-md p-4">
                  <ArticleBilledItem
                    article={article}
                    billedItem={article?.billedItem}
                  />
                </div>
              ) : null}
              {article?.billedItem?.settledStatus ===
              BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY ? (
                <div className="border-warningBase rounded-md p-4">
                  <ArticleBilledItem
                    article={article}
                    billedItem={article?.billedItem}
                  />
                </div>
              ) : null}
              {acceptArticles.map((acceptArticle) => {
                // Display the declined articles.
                return acceptArticle.acceptArticle?.acceptState ===
                  AcceptStateCalculator.ACCEPT_STATE.DECLINED ? (
                  <div
                    className="bg-declined relative rounded-md border-gray-400 p-4"
                    key={acceptArticle.party}
                  >
                    <DeclinedIconLight className="box-shadow absolute -left-2 -top-2 size-4 rounded-full" />
                    <ArticleStatus
                      party={acceptArticle.party}
                      acceptArticle={acceptArticle.acceptArticle}
                    />
                  </div>
                ) : null;
              })}
              {acceptArticles.map((acceptArticle) => {
                // Display the articles that are not declined but have attachments or a comment though.
                // In this case don't display a red background and no declined icon.
                return acceptArticle.acceptArticle?.acceptState !==
                  AcceptStateCalculator.ACCEPT_STATE.DECLINED &&
                  (acceptArticle.acceptArticle?.attachments?.length > 0 ||
                    acceptArticle.acceptArticle?.reason) ? (
                  <div
                    className="relative rounded-md border-gray-400 p-4"
                    key={acceptArticle.party}
                  >
                    <ArticleStatus
                      party={acceptArticle.party}
                      acceptArticle={acceptArticle.acceptArticle}
                    />
                  </div>
                ) : null;
              })}
              {ValueGroup.getCurrentValue(article?.description) ? (
                <div className="rounded-md border-gray-400 p-4">
                  <DeliveryNoteMetaDataItem
                    category="Beschreibung"
                    value={<EditingHistory value={article?.description} />}
                  />
                </div>
              ) : null}
              {ValueGroup.getCurrentValue(article?.comment) ? (
                <div className="rounded-md border-gray-400 p-4">
                  <DeliveryNoteMetaDataItem
                    category="Kommentar"
                    value={<EditingHistory value={article?.comment} />}
                  />
                </div>
              ) : null}
              {article?.customData?.getLength() > 0 ? (
                <div className="rounded-md border-gray-400 p-4">
                  <DeliveryNoteMetaDataItem
                    category="Weitere Informationen"
                    value={
                      <>
                        {article?.customData?.getEntries()?.map((entry) => {
                          const customFieldId = CustomField.getIdFromKey(
                            entry[0],
                          );
                          const customField = customFieldsBulk.find(
                            (customField) => customField.id === customFieldId,
                          );

                          const value =
                            typeof entry[1] === 'object' &&
                            !ValueGroup.isValueGroup(entry[1])
                              ? JSON.stringify(entry[1])
                              : entry[1];
                          return (
                            <div className="flex flex-wrap">
                              <span className="break-words">
                                {customField?.displayName ?? entry[0]}:{' '}
                              </span>
                              <EditingHistory value={value} />
                            </div>
                          );
                        })}
                      </>
                    }
                  />
                </div>
              ) : null}
              {ValueGroup.getCurrentValue(article?.manufacturer?.name) ? (
                <div className="rounded-md border-gray-400 p-4">
                  <ArticleManufacturer manufacturer={article?.manufacturer} />
                </div>
              ) : null}
              {grossWeightSet() || tareWeightSet() ? (
                <div className="rounded-md border-gray-400 p-4">
                  <ArticleWeighing weighing={article?.weighingInformation} />
                </div>
              ) : null}
              {article?.isConcrete() ? (
                <div className="border-left-accent main-background p-4">
                  <ArticleConcrete
                    concrete={article?.concrete}
                    delivery={article?.delivery}
                  />
                </div>
              ) : null}
              {article?.concrete?.components
                ? article?.concrete?.components.map((material) => {
                    return (
                      <div
                        className="rounded-md border-gray-400 p-4"
                        key={ValueGroup.getCurrentValue(material.name)}
                      >
                        <ArticleMaterial material={material} initialComponent />
                      </div>
                    );
                  })
                : null}
              {article?.concrete?.posteriorAdded
                ? article?.concrete?.posteriorAdded.map((material) => {
                    return (
                      <div
                        className="rounded-md border-gray-400 p-4"
                        key={ValueGroup.getCurrentValue(material.name)}
                      >
                        <ArticleMaterial material={material} />
                      </div>
                    );
                  })
                : null}
            </div>
          </Grid>
        </Grid>
      </BasicModal>
    );
  },
  'Artikel-Details konnten nicht geladen werden.',
);

ArticleModal.displayName = 'ArticleModal';
