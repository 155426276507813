import clsx from 'clsx';
import { memo, useCallback, type ReactElement } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';

import { ComponentTestIds } from '~/constants/test-ids';

import { Spinner } from '~/components/Spinner';

import { VirtualizedListServerDriven } from '~/ui/molecules/VirtualizedList';

import {
  Column,
  ColumnHeader,
  CountIndicator,
  ListActions,
  ManageableListItem,
  MultiItemsManagerHeader,
  NoResults,
  SelectedItemsDisplayMode,
  Triangle,
} from './components';
import { useMultiItemsManager } from './useMultiItemsManager';
import { type Item, type MultiItemsManagerProps } from './types';
import { defaultSelectFunction } from './utils';

/**
 * Component for managing a list of selected items.
 * Optimized for managing large lists of items with features including:
 * - Search functionality
 * - Visual indicators for added/removed items
 * - Server-side pagination support and virtualized list rendering with infinite scroll
 * - Edit and display modes
 */
export const MultiItemsManager = memo(
  ({
    fieldName = 'name',
    getterFunctionSelectableItems,
    getterFunctionSelectedItems,
    label,
    onChange,
    onSaveSelection,
    onSelectedItemClick,
    openSelectTestId,
    selectFunctionSelectableItems = defaultSelectFunction,
    selectFunctionSelectedItems = defaultSelectFunction,
  }: MultiItemsManagerProps) => {
    const {
      addedItems,
      addedItemsCount,
      fetchNextPageSelectableItems,
      fetchNextPageSelectedItems,
      handleAdd,
      handleCancel,
      handleDelete,
      handleEdit,
      handleOpenBody,
      handleSave,
      hasNextPageSelectableItems,
      hasNextPageSelectedItems,
      isEditing,
      isFetchingNextPageSelectableItems,
      isFetchingNextPageSelectedItems,
      isLoadingSelectableItems,
      isLoadingSelectedItems,
      isOpen,
      removedItems,
      removedItemsCount,
      searchInputRef,
      searchString,
      selectableItems,
      selectableItemsFiltered,
      selectableItemsTotalCount,
      selectedItems,
      selectedItemsFiltered,
      selectedItemsTotalCount,
      setSearchString,
    } = useMultiItemsManager({
      fieldName,
      getterFunctionSelectableItems,
      getterFunctionSelectedItems,
      onChange,
      onSaveSelection,
      selectFunctionSelectableItems,
      selectFunctionSelectedItems,
    });

    const [bodyAnimationRef] = useAutoAnimate({
      duration: 200,
      easing: 'ease-out',
    });
    const [columnsAnimationRef] = useAutoAnimate({
      duration: 200,
      easing: 'ease-out',
    });

    const hasNoMatchingSelectedItems =
      searchString && selectedItemsFiltered.length === 0;

    const hasNoMatchingSelectableItems =
      searchString && selectableItems?.length === 0;

    const getChangesString = ({ addedItemsCount, removedItemsCount }) => {
      if (addedItemsCount + removedItemsCount === 0) {
        return '';
      }

      return `(${[
        addedItemsCount > 0 && `${addedItemsCount} hinzugefügt`,
        removedItemsCount > 0 && `${removedItemsCount} entfernt`,
      ]
        .filter(Boolean)
        .join(', ')})`;
    };

    const getItemType = useCallback(
      (item: Item): Item['type'] => {
        if (addedItems.includes(item)) {
          return 'added';
        }

        if (removedItems.includes(item)) {
          return 'removed';
        }

        return 'current';
      },
      [addedItems, removedItems],
    );

    const renderSelectedItem = (item: Item): ReactElement => (
      <ManageableListItem
        item={item}
        fieldName={fieldName}
        type={getItemType(item)}
        onClick={handleDelete}
        variant="selected"
      />
    );

    const renderSelectableItem = (item: Item): ReactElement => (
      <ManageableListItem
        item={item}
        fieldName={fieldName}
        type={getItemType(item)}
        onClick={handleAdd}
        variant="selectable"
      />
    );

    return (
      <div className="rounded-md border border-gray-300 bg-white">
        <div>
          <MultiItemsManagerHeader
            testId={openSelectTestId}
            isOpen={isOpen}
            onOpen={handleOpenBody}
          >
            <CountIndicator>
              {isLoadingSelectedItems ? <Spinner /> : selectedItemsTotalCount}
            </CountIndicator>
            <div>
              {label} {getChangesString({ addedItemsCount, removedItemsCount })}
            </div>
          </MultiItemsManagerHeader>
          <div ref={bodyAnimationRef}>
            {isOpen && (
              <>
                <ListActions
                  ref={searchInputRef}
                  hasChanges={addedItemsCount + removedItemsCount > 0}
                  isEditing={isEditing}
                  onCancel={handleCancel}
                  onEdit={handleEdit}
                  onFocus={() => handleOpenBody(true)}
                  onSave={handleSave}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  editButtonTestId={
                    ComponentTestIds.MULTI_SELECT.OPEN_EDIT_MULTI_SELECT_BUTTON
                  }
                  saveButtonTestId={
                    ComponentTestIds.MULTI_SELECT.SAVE_MULTI_SELECT_BUTTON
                  }
                />
                <div
                  ref={columnsAnimationRef}
                  className="flex max-h-[50vh] flex-1"
                >
                  <Column
                    className={clsx('flex max-h-96 min-h-40 flex-1', {
                      'p-2': !isEditing,
                      'w-1/2': isEditing,
                    })}
                  >
                    {isEditing && (
                      <ColumnHeader>Zugeordnete {label}</ColumnHeader>
                    )}
                    <>
                      {isEditing ? (
                        <VirtualizedListServerDriven
                          fetchNextPage={fetchNextPageSelectedItems}
                          hasNextPage={hasNextPageSelectedItems}
                          isFetchingNextPage={isFetchingNextPageSelectedItems}
                          totalCount={selectedItemsFiltered.length}
                          className={
                            hasNoMatchingSelectedItems ? 'h-0' : 'h-96'
                          }
                          itemHeight={48}
                          items={selectedItemsFiltered}
                          renderItem={(item) => renderSelectedItem(item)}
                        />
                      ) : (
                        <SelectedItemsDisplayMode
                          currentItems={selectedItems ?? []}
                          currentItemsFiltered={selectedItemsFiltered}
                          label={label}
                          onSelectedItemClick={onSelectedItemClick}
                          className={
                            hasNoMatchingSelectedItems ? 'flex h-0' : 'h-full'
                          }
                          hasNextPage={hasNextPageSelectedItems}
                          isFetchingNextPage={isFetchingNextPageSelectedItems}
                          fetchNextPage={fetchNextPageSelectedItems}
                        />
                      )}
                      {hasNoMatchingSelectedItems && (
                        <NoResults searchString={searchString} />
                      )}
                    </>
                  </Column>
                  {isEditing && (
                    <>
                      <Column className="min-w-fit items-center justify-center border-l border-r p-4">
                        <Triangle direction="left" />
                        <Triangle direction="right" />
                      </Column>
                      <Column className="min-h-40 w-1/2 flex-1">
                        <ColumnHeader>Zuordenbare {label}</ColumnHeader>
                        <VirtualizedListServerDriven
                          fetchNextPage={fetchNextPageSelectableItems}
                          hasNextPage={hasNextPageSelectableItems}
                          isFetchingNextPage={isFetchingNextPageSelectableItems}
                          totalCount={selectableItemsFiltered.length}
                          className={
                            hasNoMatchingSelectableItems ? 'h-0' : 'h-96'
                          }
                          itemHeight={48}
                          items={selectableItemsFiltered}
                          renderItem={(item) => renderSelectableItem(item)}
                        />
                        {hasNoMatchingSelectableItems && (
                          <NoResults searchString={searchString} />
                        )}
                      </Column>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
);
