import ms from 'ms';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/Log';

import { queryKeysUser } from './queryKeys';

import { type Address } from '~/types/address';
import { type UUID } from '~/types/common';
import { type EntityType } from '~/types/entity';
import { type Permission } from '~/types/permission';

export type UserCompany = {
  id: UUID;
  companyAccountId: UUID;
  name: string;
  address: Address;
  orgUnits: UUID[];
  orgUnitPaths: UUID[][];
  permissionsFrom: Array<{
    id: number;
    subjectId: UUID;
    subjectName: string;
    subjectType: EntityType;
    permissions: Permission[];
  }>;
  syncKey: string;
};

/**
 * Fetches company data for a specific user from the API.
 * @param {UUID} userId - The ID of the user to fetch company data for
 * @returns {Promise<UserCompany['company']|undefined>} The company data if successful, undefined otherwise
 * @see https://app.dev.vestigas.com/redoc#tag/User/operation/get_company_for_user_user__user_id__company_get
 */
export const fetchUserCompany = async (
  userId: UUID,
): Promise<UserCompany['company'] | undefined> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.USER.GET_COMPANY(userId))
      .json<UserCompany['company']>();

    return response;
  } catch (error) {
    Log.error('Error fetching user company data', error);
    throw error;
  }
};

/**
 * Generates the query options for the user company query.
 *
 * @param {Object} params - Parameters for the query
 * @param {UUID} params.userId - The ID of the user to fetch company data for
 * @param {Object} params.options - Additional options for the query
 * @returns {Object} The query options including queryKey, queryFn, and other settings.
 */
export const getUserCompanyQueryOptions = ({
  userId,
  options,
}: {
  userId: UUID;
  options?: Parameters<typeof useQuery>[0];
}) => {
  return {
    queryFn: async () => fetchUserCompany(userId),
    queryKey: queryKeysUser.getCompany(userId),
    staleTime: ms('10m'),
    ...options,
  };
};

/**
 * React Query based custom hook for getting the company data for a specific user.
 * @param {UUID} userId - The ID of the user to fetch company data for
 * @param {Object} options - Additional options for the useQuery hook
 * @returns {UseQueryResult<UserCompany['company'] | undefined>} The result of the useQuery hook
 */
export const useQueryUserCompany = (
  userId: UUID,
  options?: Parameters<typeof useQuery>[0],
): UseQueryResult<UserCompany['company'] | undefined> =>
  useQuery(
    getUserCompanyQueryOptions({
      options,
      userId,
    }),
  );
