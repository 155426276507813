import cloneDeep from 'lodash/cloneDeep';
import { useEffect } from 'react';
import { Grid, InputLabel, TextField } from '@mui/material';

import { useQueryUserData } from '~/data/user';

import Site from '~/models/masterdata/Site';

import FunctionUtils from '~/utils/functionUtils';
import Log from '~/utils/Log';

import { Select } from '~/components/baseComponents/inputs/select/Select';

import { withErrorBoundary } from '~/ui/atoms';
import { SelectCompany } from '~/ui/molecules/SelectServerDriven';

export const SiteInformation = withErrorBoundary(({ setSite, site }) => {
  const {
    data: currentUser,
    isError: isErrorUserData,
    isLoading: isLoadingUserData,
    isSuccess: isSuccessUserData,
  } = useQueryUserData(true);

  const companyInfo = currentUser?.companyInfo ?? {};

  const resetDefaultValues = () => {
    const newSite = cloneDeep(site);
    newSite.companyId ||= companyInfo.id;

    setSite(newSite);
  };

  useEffect(() => {
    resetDefaultValues();
  }, [isSuccessUserData]);

  const handleInputChange = (event) => {
    const newSite = cloneDeep(site);

    switch (event.target.name) {
      case 'name': {
        newSite.name = event.target.value;

        Log.info(
          'Change form value of name',
          {
            from: site.name,
            to: newSite.name,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_name',
          Log.productAnalyticsEvent,
          ['Change name in create site wizard', Log.FEATURE.WIZARD],
        );

        break;
      }

      case 'street_name': {
        newSite.address.streetName = event.target.value;

        Log.info(
          'Change form value of street name',
          {
            from: site.address.streetName,
            to: newSite.address.streetName,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_street_name',
          Log.productAnalyticsEvent,
          ['Change street name in create site wizard', Log.FEATURE.WIZARD],
        );

        break;
      }

      case 'building_number': {
        newSite.address.buildingNumber = event.target.value;

        Log.info(
          'Change form value of building number',
          {
            from: site.address.buildingNumber,
            to: newSite.address.buildingNumber,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_building_number',
          Log.productAnalyticsEvent,
          ['Change building number in create site wizard', Log.FEATURE.WIZARD],
        );

        break;
      }

      case 'city': {
        newSite.address.city = event.target.value;

        Log.info(
          'Change form value of city',
          { from: site.address.city, to: newSite.address.city },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_city',
          Log.productAnalyticsEvent,
          ['Change city in create site wizard', Log.FEATURE.WIZARD],
        );

        break;
      }

      case 'post_code': {
        newSite.address.postCode = event.target.value;

        Log.info(
          'Change form value of post code',
          {
            from: site.address.postCode,
            to: newSite.address.postCode,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_post_code',
          Log.productAnalyticsEvent,
          ['Change post code in create site wizard', Log.FEATURE.WIZARD],
        );

        break;
      }

      case 'latitude': {
        newSite.coords.latitude = event.target.value;

        Log.info(
          'Change form value of latitude',
          {
            from: site.coords.latitude,
            to: newSite.coords.latitude,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_latitude',
          Log.productAnalyticsEvent,
          ['Change latitude in create site wizard', Log.FEATURE.WIZARD],
        );

        break;
      }

      case 'longitude': {
        newSite.coords.longitude = event.target.value;

        Log.info(
          'Change form value of longitude',
          {
            from: site.coords.longitude,
            to: newSite.coords.longitude,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'site_create_wizard_change_longitude',
          Log.productAnalyticsEvent,
          ['Change longitude in create site wizard', Log.FEATURE.WIZARD],
        );

        break;
      }

      default: {
        break;
      }
    }

    setSite(newSite);
  };

  const handleChangeCompany = (value) => {
    const newSite = cloneDeep(site);
    newSite.companyId = value;

    Log.info(
      'Change form value of company',
      {
        from: site.company,
        to: newSite.company,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change company in create site wizard',
      Log.FEATURE.WIZARD,
    );

    setSite(newSite);
  };

  const handleChangeSiteType = (event) => {
    const newSite = cloneDeep(site);
    newSite.type = event.target.value;

    Log.info(
      'Change form value of type',
      {
        from: site.type,
        to: newSite.type,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change type in create site wizard',
      Log.FEATURE.WIZARD,
    );

    setSite(newSite);
  };

  return (
    <Grid container direction="row" spacing={3} space={4}>
      <Grid item lg={12} xs={12}>
        <h3 className="main-text mt-0">Standort Informationen</h3>
        <Grid container spacing={2}>
          <Grid item lg={8} xs={12}>
            <TextField
              autoComplete="off"
              id="name-input"
              label="Name"
              name="name"
              onChange={handleInputChange}
              type="text"
              value={site.name}
              fullWidth
              autoFocus
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={6}>
            <TextField
              autoComplete="off"
              id="street_name-input"
              label="Straße"
              name="street_name"
              onChange={handleInputChange}
              type="text"
              value={site.address.streetName}
              fullWidth
            />
          </Grid>
          <Grid item lg={4} xs={6}>
            <TextField
              autoComplete="off"
              fullWidth
              id="building_number-input"
              label="Hausnummer"
              name="building_number"
              onChange={handleInputChange}
              type="text"
              value={site.address.buildingNumber}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={6}>
            <TextField
              id="post_code-input"
              label="PLZ"
              name="post_code"
              onChange={handleInputChange}
              type="text"
              value={site.address.postCode}
              fullWidth
            />
          </Grid>
          <Grid item lg={4} xs={6}>
            <TextField
              id="city-input"
              label="Ort"
              name="city"
              onChange={handleInputChange}
              type="text"
              value={site.address.city}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={6}>
            <TextField
              autoComplete="off"
              id="latitude-input"
              label="Breitengrad"
              name="latitude"
              onChange={handleInputChange}
              step="any"
              type="text"
              value={site.coords.latitude}
              fullWidth
            />
          </Grid>
          <Grid item lg={4} xs={6}>
            <TextField
              autoComplete="off"
              id="longitude-input"
              label="Längengrad"
              name="longitude"
              onChange={handleInputChange}
              step="any"
              type="text"
              value={site.coords.longitude}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <h3 className="main-text mt-4">
          Welcher Firma soll der Standort zugeordnet sein?
        </h3>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={6}>
            <SelectCompany
              onChange={handleChangeCompany}
              value={site.companyId}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <h3 className="main-text mt-4">
          Welcher Art entspricht dieser Standort?
        </h3>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={6}>
            <InputLabel className="text-13px">Art</InputLabel>
            <Select
              onChange={handleChangeSiteType}
              options={Site.getSiteTypes()}
              size="small"
              value={site.type}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}, 'Daten konnten nicht geladen werden.');
