import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import { memo, useCallback, useEffect, useState } from 'react';
import { gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';

import { LOADING_STATE } from '~/constants/LoadingState';
import { ComponentTestIds } from '~/constants/test-ids';

import LocalStorageService from '~/services/localStorage.service';
import UserService from '~/services/user.service';

import ArrayUtils from '~/utils/arrayUtils';
import DatagridUtils from '~/utils/datagridUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import { LoadingState, Pagination } from '~/ui/molecules/Datagrid/components';

import { GridToolbar } from './interaction/GridToolbar';

export const BasicTable = memo(
  ({
    className,
    columns,
    csvOptions,
    defaultHiddenColumns,
    defaultPinnedColumns,
    disableColumnFilter,
    disableColumnReorder,
    excelColumns,
    excelData,
    filterModel,
    hideFooter,
    hideHeader,
    hideToolbar,
    history,
    isFetching,
    isLoading,
    loading,
    localStorageKey,
    logicOperators,
    multiplePdfDownload,
    noExportButton,
    onCSVExportFromBackend,
    onExcelDlnExport,
    onExcelExportFromBackend,
    onExcelInvoiceExport,
    onFilterModelChange,
    onlySingleSelection,
    onMapDirectDeliveryNote,
    onMultiPermissionGrantEdit,
    onPageChange: propsOnPageChange,
    onPdfExport,
    onRequestDeliveryNoteSignature,
    onRowClick,
    onRowRightClick,
    onRowSelectionModelChange: propsOnRowSelectionModelChange,
    onShareDeliveryNote,
    onStateChange: propsOnStateChange,
    page: propsPage,
    pageSize: propsPageSize,
    paginationText,
    pinnedColumnsCookieId,
    productAnalyticsFeature,
    rowSelectionModel,
    sortingMode,
    testId,
    ...otherProps
  }) => {
    // State management
    const [columnOrderModel, setColumnOrderModel] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [columnWidthModel, setColumnWidthModel] = useState({});
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState(
      [],
    );
    const [filteredSortedRows, setFilteredSortedRows] = useState([]);
    const [page, setPage] = useState(propsPage ?? 0);
    const [pageSize] = useState(propsPageSize ?? 100);
    const [pinnedColumns, setPinnedColumns] = useState(
      LocalStorageService.getObjectFromLocalStorage(pinnedColumnsCookieId) ??
        defaultPinnedColumns ?? { left: [], right: [] },
    );

    // Refs
    const apiRef = useGridApiRef();

    // Initialize debounced function
    const debouncedUpdateUserSettings = useCallback(
      debounce(async () => {
        await promiseHandler(
          UserService.updateOwnUserSettings({
            [localStorageKey]: {
              columnOrderModel,
              columnVisibilityModel,
              columnWidthModel,
            },
          }),
        );
      }, 400),
      [
        columnOrderModel,
        columnVisibilityModel,
        columnWidthModel,
        localStorageKey,
      ],
    );

    useEffect(() => {
      // Load initial state from localStorage
      const data =
        LocalStorageService.getObjectFromLocalStorage(localStorageKey);

      const newState = {
        columnOrderModel: data?.columnOrderModel ?? [],
        columnVisibilityModel: data?.columnVisibilityModel ?? {},
        columnWidthModel: data?.columnWidthModel ?? {},
      };

      newState.columnVisibilityModel = hideDefaultColumns(
        newState.columnVisibilityModel,
      );

      setColumnOrderModel(newState.columnOrderModel);
      setColumnVisibilityModel(newState.columnVisibilityModel);
      setColumnWidthModel(newState.columnWidthModel);
    }, [localStorageKey]);

    useEffect(() => {
      setColumnVisibilityModel((previousModel) =>
        hideDefaultColumns(previousModel),
      );
    }, [defaultHiddenColumns]);

    const hideDefaultColumns = useCallback(
      (visibilityModel) => {
        const newColumnVisibilityModel = cloneDeep(visibilityModel);

        if (defaultHiddenColumns) {
          for (const column of defaultHiddenColumns) {
            if (newColumnVisibilityModel[column] === undefined) {
              newColumnVisibilityModel[column] = false;
            }
          }
        }

        return newColumnVisibilityModel;
      },
      [defaultHiddenColumns],
    );

    const storeLocally = useCallback(
      (visibilityModel, widthModel, orderModel) => {
        LocalStorageService.setObjectAsLocalStorage(localStorageKey, {
          columnOrderModel: orderModel,
          columnVisibilityModel: visibilityModel,
          columnWidthModel: widthModel,
        });
      },
      [localStorageKey],
    );

    const getOrderedColumns = useCallback(() => {
      if (disableColumnReorder) {
        return columns;
      }

      const data =
        LocalStorageService.getObjectFromLocalStorage(localStorageKey);

      if (!data?.columnOrderModel) {
        return columns;
      }

      const indexedColumns = columns.map((column, index_) => {
        // Get column index from data
        const index = data.columnOrderModel.indexOf(column.field);

        return {
          index: index === -1 ? index_ : index, // Add new columns at their specified position
          ...column,
        };
      });

      return ArrayUtils.sortByKey(indexedColumns, 'index');
    }, [columns, disableColumnReorder, localStorageKey]);

    // according to cookie, columns are ordered, customized widths are set and the selected row height is applied
    const getColumns = useCallback(() => {
      const newColumns = cloneDeep(getOrderedColumns());

      for (const [index, column] of newColumns.entries()) {
        const customWidth = columnWidthModel[column.field];
        if (customWidth) {
          newColumns[index].width = customWidth;
        }
      }

      return newColumns;
    }, [getOrderedColumns, columnWidthModel]);

    const hiddenHeaderProps = hideHeader
      ? {
          columnHeaderHeight: 0,
          sx: {
            '& .MuiDataGrid-columnHeaders': {
              display: 'none !important',
            },
            '& .MuiDataGrid-toolbarContainer': {
              display: 'none !important',
              height: '0 !important',
              visibility: 'hidden !important',
            },
          },
        }
      : null;

    const getPinnedColumns = useCallback(() => {
      if (pinnedColumns === null) {
        return (
          defaultPinnedColumns ?? {
            left: [],
            right: [],
          }
        );
      }

      return pinnedColumns;
    }, [pinnedColumns, defaultPinnedColumns]);

    const getToolbar = useCallback(() => {
      if (hideToolbar) {
        return null;
      }

      return (
        <GridToolbar
          csvOptions={
            csvOptions ?? {
              delimiter: ',',
              includeHeaders: true,
            }
          }
          excelColumns={excelColumns}
          excelData={getFilteredSortedExcelData()}
          multiplePdfDownload={multiplePdfDownload}
          noExportButton={noExportButton}
          onExcelDlnExport={onExcelDlnExport}
          columnVisibilityModel={columnVisibilityModel}
          onExcelExportFromBackend={onExcelExportFromBackend}
          onCSVExportFromBackend={onCSVExportFromBackend}
          onExcelInvoiceExport={onExcelInvoiceExport}
          onMapDirectDeliveryNote={onMapDirectDeliveryNote}
          onMultiPermissionGrantEdit={onMultiPermissionGrantEdit}
          onPdfExport={onPdfExport}
          onRequestDeliveryNoteSignature={onRequestDeliveryNoteSignature}
          onShareDeliveryNote={onShareDeliveryNote}
          productAnalyticsFeature={productAnalyticsFeature}
        />
      );
    }, [
      csvOptions,
      excelColumns,
      hideToolbar,
      multiplePdfDownload,
      noExportButton,
      onCSVExportFromBackend,
      onExcelDlnExport,
      onExcelExportFromBackend,
      onExcelInvoiceExport,
      onMapDirectDeliveryNote,
      onMultiPermissionGrantEdit,
      onPdfExport,
      onRequestDeliveryNoteSignature,
      onShareDeliveryNote,
      productAnalyticsFeature,
    ]);

    const getFilteredSortedExcelData = useCallback(() => {
      if (!excelData?.length) {
        return [];
      }

      if (!filteredSortedRows?.length) {
        return excelData;
      }

      return ArrayUtils.sortByKeyValues(excelData, filteredSortedRows, 'id');
    }, [excelData, filteredSortedRows]);

    const onColumnVisibilityModelChange = useCallback(
      (event) => {
        Log.info(
          'Change column visibility',
          {
            from: columnVisibilityModel,
            to: event,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change column visibility',
          Log.FEATURE.BASIC_TABLE,
        );

        setColumnVisibilityModel(event);
        storeLocally(event, columnWidthModel, columnOrderModel);
      },
      [columnVisibilityModel, columnWidthModel, columnOrderModel, storeLocally],
    );

    const onColumnWidthChange = useCallback(
      (event) => {
        const newColumnWidthModel = cloneDeep(columnWidthModel);
        newColumnWidthModel[event.colDef.field] = event.colDef.width;

        Log.info(
          'Change column width',
          {
            from: columnWidthModel,
            to: newColumnWidthModel,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change column width',
          Log.FEATURE.BASIC_TABLE,
        );

        setColumnWidthModel(newColumnWidthModel);
        storeLocally(
          columnVisibilityModel,
          newColumnWidthModel,
          columnOrderModel,
        );
      },
      [columnVisibilityModel, columnWidthModel, columnOrderModel, storeLocally],
    );

    const onColumnOrderChange = useCallback(
      async (newColumnOrderModel) => {
        const storedColumnOrderModel =
          LocalStorageService.getObjectFromLocalStorage(
            localStorageKey,
          )?.columnOrderModel;

        if (
          newColumnOrderModel &&
          JSON.stringify(newColumnOrderModel) ===
            JSON.stringify(storedColumnOrderModel)
        ) {
          return;
        }

        if (columnOrderModel?.length === 0) {
          storeLocally(
            LocalStorageService.getObjectFromLocalStorage(localStorageKey)
              ?.columnVisibilityModel,
            LocalStorageService.getObjectFromLocalStorage(localStorageKey)
              ?.columnWidthModel,
            newColumnOrderModel,
          );

          setColumnOrderModel(newColumnOrderModel);
          return;
        }

        Log.info(
          'Change column order',
          {
            from: storedColumnOrderModel,
            to: newColumnOrderModel,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change column order',
          Log.FEATURE.BASIC_TABLE,
        );

        storeLocally(
          columnVisibilityModel,
          columnWidthModel,
          newColumnOrderModel,
        );
        setColumnOrderModel(newColumnOrderModel);
        debouncedUpdateUserSettings();
      },
      [
        columnOrderModel,
        columnVisibilityModel,
        columnWidthModel,
        debouncedUpdateUserSettings,
        localStorageKey,
        storeLocally,
      ],
    );

    const onStateChange = useCallback(
      (state) => {
        onColumnOrderChange(state.columns.orderedFields);

        if (propsOnStateChange) {
          propsOnStateChange(state);
        }

        if (!excelData) {
          return;
        }

        const newFilteredSortedRows = Array.from(
          new Set(
            gridFilteredSortedRowIdsSelector(state, apiRef.current.state),
          ),
        );

        if (
          JSON.stringify(filteredSortedRows) ===
          JSON.stringify(newFilteredSortedRows)
        ) {
          return;
        }

        setFilteredSortedRows(newFilteredSortedRows);
      },
      [onColumnOrderChange, propsOnStateChange, excelData, filteredSortedRows],
    );

    const onRowSelectionModelChange = useCallback(
      (event) => {
        if (onlySingleSelection) {
          propsOnRowSelectionModelChange(
            ArrayUtils.subtract(event, rowSelectionModel),
          );
          return;
        }

        return propsOnRowSelectionModelChange(event);
      },
      [onlySingleSelection, propsOnRowSelectionModelChange, rowSelectionModel],
    );

    const onPageChange = useCallback(
      (newPage) => {
        setPage(newPage);

        if (propsOnPageChange) {
          propsOnPageChange(newPage);
        }
      },
      [propsOnPageChange],
    );

    const onPinnedColumnsChange = useCallback(
      (event) => {
        Log.info(
          'Change pinned columns',
          {
            from: pinnedColumns,
            to: event,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change pinned columns',
          Log.FEATURE.BASIC_TABLE,
        );

        setPinnedColumns(event);

        LocalStorageService.setObjectAsLocalStorage(
          pinnedColumnsCookieId,
          event,
        );
      },
      [pinnedColumns, pinnedColumnsCookieId],
    );

    let slots = {
      loadingOverlay() {
        return <LoadingState />;
      },
      noRowsOverlay: () => (
        <div
          className="flex h-full w-full items-center justify-center"
          data-testid={ComponentTestIds.TABLE.EMPTY_TABLE}
        >
          {loading === LOADING_STATE.FAILED
            ? 'Daten konnten nicht geladen werden.'
            : 'Keine Einträge'}
        </div>
      ),
      pagination: Pagination,
      toolbar: () => getToolbar(),
    };

    if (hideFooter) {
      slots = {
        ...slots,
        footer: () => null,
      };
    }

    // Ensure detailPanelExpandedRowIds is an array of non-null values (null breaks the DataGridPro component)
    const filteredDetailPanelExpandedRowIds = detailPanelExpandedRowIds.filter(
      (id) => id !== null,
    );

    const slotProps = {
      ...(logicOperators && {
        filterPanel: {
          logicOperators,
        },
      }),
      pagination: {
        'data-testid': ComponentTestIds.TABLE.PAGINATION,
        isFetching,
        paginationText,
      },
      row: {
        onContextMenu: onRowRightClick,
        style: {
          cursor: onRowRightClick
            ? 'context-menu'
            : onRowClick
              ? 'pointer'
              : 'auto',
        },
      },
    };

    const shittyIsLoading = Boolean(
      isLoading === true ||
        loading === true ||
        loading === LOADING_STATE.LOADING,
    );

    useEffect(() => {
      return () => {
        // Cleanup function
        if (apiRef.current) {
          apiRef.current.subscribeEvent('unmount', () => {
            // Cleanup any subscriptions
            apiRef.current.state.subscription.unsubscribe();
          });
        }
      };
    }, []);

    return (
      <DataGridPro
        apiRef={apiRef}
        className={className}
        columns={getColumns()}
        columnVisibilityModel={columnVisibilityModel}
        data-testid={testId}
        detailPanelExpandedRowIds={filteredDetailPanelExpandedRowIds}
        disableColumnFilter={disableColumnFilter}
        disableColumnSorting={isFetching}
        disableRowSelectionOnClick
        filterModel={filterModel}
        initialState={{ pinnedColumns: getPinnedColumns() }}
        loading={shittyIsLoading}
        onFilterModelChange={onFilterModelChange}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        onColumnWidthChange={onColumnWidthChange}
        onDetailPanelExpandedRowIdsChange={setDetailPanelExpandedRowIds}
        onPageChange={onPageChange}
        onPinnedColumnsChange={onPinnedColumnsChange}
        onRowClick={onRowClick}
        onRowSelectionModelChange={onRowSelectionModelChange}
        onStateChange={onStateChange}
        page={page}
        pageSize={pageSize}
        pagination={true}
        rowHeight={DatagridUtils.ROW_HEIGHT.THIN}
        slotProps={slotProps}
        slots={slots}
        sortingMode={sortingMode}
        {...hiddenHeaderProps}
        {...otherProps}
      />
    );
  },
);

BasicTable.displayName = 'BasicTable';
