import cloneDeep from 'lodash/cloneDeep';
import { memo, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import {
  setInvoice_filterGroupOpen,
  setInvoice_filterGroups,
  setInvoice_filterModel,
  setInvoice_filterRows,
  setInvoice_selectedBuyer,
  setInvoice_selectedFilterGroup,
  setInvoice_selectedNumber,
  setInvoice_selectedSeller,
  setInvoice_selectedStatus,
  setInvoice_selectedToSite,
} from '~/redux/filtersSlice';

import InvoicesService from '~/services/invoices.service';
import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import FilterGroupFilter from '~/models/filters/FilterGroupFilter';
import Invoice from '~/models/invoices/Invoice';

import ArrayUtils from '~/utils/arrayUtils';
import DatagridUtils from '~/utils/datagridUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';

import { FilterGroups } from '~/components/filterBar/FilterGroups';

import { withErrorBoundary } from '~/ui/atoms';

import { InvoiceFilterRow } from './InvoiceFilterRow';

const selectInvoiceFilters = (state) => state.filters;

const selectInvoiceFilterState = createSelector(
  [selectInvoiceFilters],
  (filters) => ({
    filterGroupOpen: filters.invoice_filterGroupOpen,
    filterGroups: filters.invoice_filterGroups,
    filterModel: filters.invoice_filterModel,
    filterRows: filters.invoice_filterRows,
    selectedBuyer: filters.invoice_selectedBuyer,
    selectedFilterGroup: filters.invoice_selectedFilterGroup,
    selectedNumber: filters.invoice_selectedNumber,
    selectedSeller: filters.invoice_selectedSeller,
    selectedStatus: filters.invoice_selectedStatus,
    selectedToSite: filters.invoice_selectedToSite,
  }),
);

export const InvoiceFilterGroups = memo(
  withErrorBoundary(({ data }) => {
    const {
      filterGroupOpen,
      filterGroups,
      filterModel,
      filterRows,
      selectedBuyer,
      selectedFilterGroup,
      selectedNumber,
      selectedSeller,
      selectedStatus,
      selectedToSite,
    } = useSelector(selectInvoiceFilterState);

    const dispatch = useDispatch();

    const initFilterModel = useCallback(() => {
      const index = filterGroups.findIndex(
        ({ id }) => id === selectedFilterGroup,
      );

      if (index === -1) {
        return;
      }

      dispatch(
        setInvoice_filterModel(
          filterGroups[index]?.filterModel ?? DatagridUtils.EMPTY_FILTER_MODEL,
        ),
      );
    }, [filterGroups, selectedFilterGroup, dispatch]);

    useEffect(() => {
      initFilterModel();
    }, [filterGroups, selectedFilterGroup, dispatch]);

    const onClickFilterGroup = (id) => {
      if (id === selectedFilterGroup) {
        return;
      }

      dispatch(setInvoice_selectedFilterGroup(id));

      Log.productAnalyticsEvent(
        'Open custom invoice filter group',
        Log.FEATURE.FILTER_GROUPS,
      );
    };

    const onClickExpandFilterGroup = () => {
      dispatch(setInvoice_filterGroupOpen(!filterGroupOpen));
    };

    const getInvoiceStatusOptions = useCallback(() => {
      return [
        Invoice.FILTER_CATEGORY.CORRECT,
        Invoice.FILTER_CATEGORY.DELAYED_SIGNED,
        Invoice.FILTER_CATEGORY.NO_CHECKING_POSSIBLE,
        Invoice.FILTER_CATEGORY.FORMAL_CHECK_ERROR,
        Invoice.FILTER_CATEGORY.DLN_CHECK_ERROR,
        Invoice.FILTER_CATEGORY.SIGNATURE_CHECK_ERROR,
        Invoice.FILTER_CATEGORY.ARTICLE_EXISTS_CHECK_ERROR,
        Invoice.FILTER_CATEGORY.AMOUNT_CHECK_CHECK_ERROR,
        Invoice.FILTER_CATEGORY.AMOUNT_APPROVED_CHECK_ERROR,
      ];
    }, []);

    const getFilterGroupObject = useCallback(
      (id, name, filterRows = [], emptyFilterGroupObject) => {
        const filterGroup = filterGroups.find(
          (filterGroup) => filterGroup.id === id,
        );

        let newFilterRows = [...filterRows];
        if (newFilterRows.length === 0 && filterGroup) {
          newFilterRows = [...filterGroup.filterRows];
        }

        if (newFilterRows.length === 0 || emptyFilterGroupObject) {
          newFilterRows = [FilterGroupFilter.FILTER.SELECTED_STATUS];
        }

        return {
          filterModel: emptyFilterGroupObject
            ? DatagridUtils.EMPTY_FILTER_MODEL
            : filterModel,
          filterRows: newFilterRows,
          filters: {
            selectedBuyer: emptyFilterGroupObject ? [] : selectedBuyer,
            selectedNumber: emptyFilterGroupObject ? [] : selectedNumber,
            selectedSeller: emptyFilterGroupObject ? [] : selectedSeller,
            selectedStatus: emptyFilterGroupObject ? [] : selectedStatus,
            selectedToSite: emptyFilterGroupObject ? [] : selectedToSite,
          },
          id,
          name: name ?? filterGroup?.name,
        };
      },
      [
        filterGroups,
        filterModel,
        selectedBuyer,
        selectedNumber,
        selectedSeller,
        selectedStatus,
        selectedToSite,
      ],
    );

    const saveNewFilterGroup = async (
      id,
      name,
      filterRows,
      emptyFilterGroupObject,
    ) => {
      const newFilterGroups = [
        ...filterGroups,
        getFilterGroupObject(id, name, filterRows, emptyFilterGroupObject),
      ];

      dispatch(setInvoice_filterGroups(newFilterGroups));
      dispatch(setInvoice_selectedFilterGroup(id));
      dispatch(setInvoice_filterGroupOpen(true));

      const [, error] = await promiseHandler(
        UserService.updateInvoiceFilterGroups(newFilterGroups),
      );

      if (error) {
        Log.error('Failed to save new filter group.', error);
        ToastService.error(['Filter konnte nicht hinzugefügt werden.']);
      }
    };

    const updateFilterGroupName = async (id, name) => {
      const newFilterGroups = cloneDeep(filterGroups);

      for (const filterGroup of newFilterGroups) {
        if (filterGroup.id === id) {
          filterGroup.name = name;
        }
      }

      dispatch(setInvoice_filterGroups(newFilterGroups));

      const [, error] = await promiseHandler(
        UserService.updateInvoiceFilterGroups(newFilterGroups),
      );

      if (error) {
        Log.error('Failed to update filter group name.', error);
        ToastService.error(['Filter konnte nicht umbenannt werden.']);
      }
    };

    const updateFilterGroup = async (filterRows) => {
      let newFilterGroups = cloneDeep(filterGroups);

      newFilterGroups = ArrayUtils.updateByKey(
        filterGroups,
        'id',
        selectedFilterGroup,
        getFilterGroupObject(selectedFilterGroup, null, filterRows),
      );

      dispatch(setInvoice_filterGroups(newFilterGroups));

      const [, error] = await promiseHandler(
        UserService.updateInvoiceFilterGroups(newFilterGroups),
      );

      if (error) {
        Log.error('Failed to update filter group.', error);
        ToastService.error(['Filter konnte nicht geändert werden.']);
      }
    };

    const updateFilterRows = (filterRows) => {
      dispatch(setInvoice_filterRows(filterRows));
    };

    const deleteFilterGroup = async () => {
      let newFilterGroups = cloneDeep(filterGroups);

      newFilterGroups = ArrayUtils.removeByKey(
        filterGroups,
        'id',
        selectedFilterGroup,
      );

      dispatch(setInvoice_filterGroups(newFilterGroups));
      dispatch(setInvoice_selectedFilterGroup(filterGroups[0].id));

      const [, error] = await promiseHandler(
        UserService.updateInvoiceFilterGroups(newFilterGroups),
      );

      if (error) {
        Log.error('Failed to delete filter group.', error);
        ToastService.error(['Filter konnte nicht gelöscht werden.']);
      }
    };

    const handleResetSelectedFilterGroup = () => {
      dispatch(setInvoice_selectedFilterGroup(null));
    };

    const handleChangeValue = (type, _, filterValue) => {
      switch (type) {
        case FilterGroupFilter.FILTER.SELECTED_STATUS: {
          Log.info(
            'Change filter value of ' + type,
            {
              from: selectedStatus,
              to: filterValue,
            },
            Log.BREADCRUMB.FILTER_CHANGE.KEY,
          );
          Log.productAnalyticsEvent(
            'Filter status',
            Log.FEATURE.DELIVERY_OVERVIEW,
          );

          dispatch(setInvoice_selectedStatus(filterValue));

          break;
        }

        case FilterGroupFilter.FILTER.SELECTED_SELLER: {
          Log.info(
            'Change filter value of ' + type,
            {
              from: selectedSeller,
              to: filterValue,
            },
            Log.BREADCRUMB.FILTER_CHANGE.KEY,
          );
          Log.productAnalyticsEvent(
            'Filter seller',
            Log.FEATURE.DELIVERY_OVERVIEW,
          );

          dispatch(setInvoice_selectedSeller(filterValue));

          break;
        }

        case FilterGroupFilter.FILTER.SELECTED_BUYER: {
          Log.info(
            'Change filter value of ' + type,
            {
              from: selectedBuyer,
              to: filterValue,
            },
            Log.BREADCRUMB.FILTER_CHANGE.KEY,
          );
          Log.productAnalyticsEvent(
            'Filter buyer',
            Log.FEATURE.DELIVERY_OVERVIEW,
          );

          dispatch(setInvoice_selectedBuyer(filterValue));

          break;
        }

        case FilterGroupFilter.FILTER.SELECTED_NUMBER: {
          Log.info(
            'Change filter value of ' + type,
            {
              from: selectedNumber,
              to: filterValue,
            },
            Log.BREADCRUMB.FILTER_CHANGE.KEY,
          );
          Log.productAnalyticsEvent(
            'Filter number',
            Log.FEATURE.DELIVERY_OVERVIEW,
          );

          dispatch(setInvoice_selectedNumber(filterValue));

          break;
        }

        case FilterGroupFilter.FILTER.SELECTED_TO_SITE: {
          Log.info(
            'Change filter value of ' + type,
            {
              from: selectedToSite,
              to: filterValue,
            },
            Log.BREADCRUMB.FILTER_CHANGE.KEY,
          );
          Log.productAnalyticsEvent(
            'Filter to site',
            Log.FEATURE.DELIVERY_OVERVIEW,
          );

          dispatch(setInvoice_selectedToSite(filterValue));

          break;
        }

        default: {
          Log.error('Invalid filter type.', type);
        }
      }
    };

    const getFilteredOptions = useCallback(
      (name) => {
        if (!data?.length) {
          return [];
        }

        const optionData = {
          rows: data,
          selectedBuyer:
            name === Invoice.PROPERTY.BUYER.STRING ? [] : selectedBuyer,
          selectedNumber:
            name === Invoice.PROPERTY.NUMBER.STRING ? [] : selectedNumber,
          selectedSeller:
            name === Invoice.PROPERTY.SELLER.STRING ? [] : selectedSeller,
          selectedStatus:
            name === Invoice.PROPERTY.STATUS.STRING ? [] : selectedStatus,
          selectedToSite:
            name === Invoice.PROPERTY.TO_SITE.STRING ? [] : selectedToSite,
        };

        const filteredData = InvoicesService.filterRows(optionData);

        switch (name) {
          case Invoice.PROPERTY.STATUS.STRING: {
            return getInvoiceStatusOptions();
          }

          case Invoice.PROPERTY.SELLER.STRING: {
            return ArrayUtils.getDistinctValuesByKey(
              filteredData,
              Invoice.PROPERTY.SELLER.KEY,
            );
          }

          case Invoice.PROPERTY.BUYER.STRING: {
            return ArrayUtils.getDistinctValuesByKey(
              filteredData,
              Invoice.PROPERTY.BUYER.KEY,
            );
          }

          case Invoice.PROPERTY.NUMBER.STRING: {
            return ArrayUtils.getDistinctValuesByKey(
              filteredData,
              Invoice.PROPERTY.NUMBER.KEY,
            );
          }

          case Invoice.PROPERTY.TO_SITE.STRING: {
            return ArrayUtils.getDistinctValuesByKey(
              filteredData,
              Invoice.PROPERTY.TO_SITE.KEY,
            );
          }

          default: {
            return [];
          }
        }
      },
      [
        data,
        selectedBuyer,
        selectedNumber,
        selectedSeller,
        selectedStatus,
        selectedToSite,
      ],
    );

    const getAllOptions = useCallback(
      (name) => {
        if (!data?.length) {
          return [];
        }

        switch (name) {
          case Invoice.PROPERTY.STATUS.STRING: {
            return getInvoiceStatusOptions();
          }

          case Invoice.PROPERTY.SELLER.STRING: {
            return ArrayUtils.getDistinctValuesByKey(
              data,
              Invoice.PROPERTY.SELLER.KEY,
            );
          }

          case Invoice.PROPERTY.BUYER.STRING: {
            return ArrayUtils.getDistinctValuesByKey(
              data,
              Invoice.PROPERTY.BUYER.KEY,
            );
          }

          case Invoice.PROPERTY.NUMBER.STRING: {
            return ArrayUtils.getDistinctValuesByKey(
              data,
              Invoice.PROPERTY.NUMBER.KEY,
            );
          }

          case Invoice.PROPERTY.TO_SITE.STRING: {
            return ArrayUtils.getDistinctValuesByKey(
              data,
              Invoice.PROPERTY.TO_SITE.KEY,
            );
          }

          default: {
            return [];
          }
        }
      },
      [data],
    );

    const getSelectableFilters = useCallback(() => {
      const selectableFilters = [
        {
          id: FilterGroupFilter.FILTER.SELECTED_STATUS,
          name: Invoice.PROPERTY.STATUS.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_SELLER,
          name: Invoice.PROPERTY.SELLER.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_BUYER,
          name: Invoice.PROPERTY.BUYER.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_NUMBER,
          name: Invoice.PROPERTY.NUMBER.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_TO_SITE,
          name: Invoice.PROPERTY.TO_SITE.STRING,
        },
      ];

      return selectableFilters.map((item) => ({
        ...item,
        allOptions: getAllOptions(item.name),
        filteredOptions: getFilteredOptions(item.name),
      }));
    }, [getAllOptions, getFilteredOptions]);

    const originalFilterGroupObject = useMemo(
      () => filterGroups.find(({ id }) => id === selectedFilterGroup),
      [filterGroups, selectedFilterGroup],
    );

    const selectableFiltersValue = useMemo(
      () => getSelectableFilters(),
      [getSelectableFilters],
    );

    const currentFilterGroupObject = useMemo(
      () => getFilterGroupObject(selectedFilterGroup),
      [getFilterGroupObject, selectedFilterGroup],
    );

    return (
      <FilterGroups
        currentFilterGroupObject={currentFilterGroupObject}
        deleteFilterGroup={deleteFilterGroup}
        filterGroupOpen={filterGroupOpen}
        filterGroups={filterGroups}
        filterRows={filterRows}
        onChangeValue={handleChangeValue}
        onClickExpandFilterGroup={onClickExpandFilterGroup}
        onClickFilterGroup={onClickFilterGroup}
        originalFilterGroupObject={originalFilterGroupObject}
        renderFilterRow={InvoiceFilterRow}
        resetSelectedFilterGroup={handleResetSelectedFilterGroup}
        saveNewFilterGroup={saveNewFilterGroup}
        selectableFilters={selectableFiltersValue}
        selectedFilterGroup={selectedFilterGroup}
        updateFilterGroup={updateFilterGroup}
        updateFilterGroupName={updateFilterGroupName}
        updateFilterRows={updateFilterRows}
        withExpandableFilterGroup
      />
    );
  }, 'Filter konnten nicht geladen werden.'),
);

InvoiceFilterGroups.displayName = 'InvoiceFilterGroups';
