import { memo } from 'react';

import { DeliveryStatus } from '~/components/deliveries/DeliveryStatus';

type P = {
  value: string;
};

export const DeliveryStatusCell = memo(({ value }: P) => {
  const valueArray = value.split(';');

  const processState = valueArray?.[0];
  const combinedState = valueArray?.[2];
  const settledStatus = valueArray?.[3];

  return (
    <div className="flex h-full items-center gap-1 py-0.5">
      <DeliveryStatus
        processState={processState}
        combinedState={combinedState}
        settledStatus={settledStatus}
        whiteBackground
      />
    </div>
  );
});
