import { CircularProgress, IconButton as MuiIconButton } from '@mui/material';
import { LightTooltipWide } from '~/utils/componentUtils';

export default function IconButton(props) {
  const iconButton = (
    <div className="circle h-40px w-40px bg-white shadow-lg">
      <MuiIconButton onClick={props.onClick} disabled={props.isLoading}>
        {props.isLoading ? <CircularProgress size={24} /> : props.children}
      </MuiIconButton>
    </div>
  );

  if (props.tooltipTitle) {
    return (
      <LightTooltipWide title={props.tooltipTitle}>
        {iconButton}
      </LightTooltipWide>
    );
  }

  return iconButton;
}
