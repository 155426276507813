import cloneDeep from 'lodash/cloneDeep';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TAB } from '~/constants/Tab';

import { getSearchBody } from '~/data/deliveryNote';
import { useQueryUserData } from '~/data/user';

import {
  setDashboard_tab,
  setDashboard_selectedDateRange,
  setDashboard_selectedPredefinedDateRange,
  setDashboard_individualDateRange,
  setDashboard_selectedUnit,
  setDashboard_selectedToSiteRecipient,
  setDashboard_selectedToSiteSupplier,
  setDashboard_selectedCostCenter,
  setDashboard_selectedArticleNumber,
  setDashboard_selectedArticle,
  setDashboard_selectedSupplier,
  setDashboard_selectedRecipient,
  setDashboard_selectedProcessState,
  setDashboard_selectedFromSite,
  setDashboard_selectedArticleInDetailOverview,
  setDashboard_selectedCustomFields,
  setDashboard_selectedToSiteSupplierTradeContact,
  setDashboard_selectedPermittedToSites,
  setDashboard_selectedPermittedCostCenters,
  setDashboard_selectedAcceptState,
  setDashboard_selectedSettledStatus,
} from '~/redux/filtersSlice';
import { setPageTitle } from '~/redux/menuSlice';

import DashboardService from '~/services/dashboard.service';

import FilterGroupFilter from '~/models/filters/FilterGroupFilter';

import { dateUtils } from '~/utils/dateUtils';
import Log from '~/utils/Log';

import { selectDashboardFilters } from './utils';

const selectCostCenters = ({ filters }) => filters.selectedCostCenters;
const selectSites = ({ filters }) => filters.selectedSites;

export const useDashboard = () => {
  const dispatch = useDispatch();

  const {
    filterGroups,
    individualDateRange,
    selectedAcceptState,
    selectedArticle,
    selectedArticleNumber,
    selectedCustomFields,
    selectedDateRange,
    selectedFromSite,
    selectedPermittedCostCenters,
    selectedPermittedToSites,
    selectedProcessState,
    selectedRecipient,
    selectedSettledStatus,
    selectedSupplier,
    selectedToSiteRecipient,
    selectedToSiteSupplier,
    selectedUnit,
    selectedCostCenter,
    selectedFilterGroup,
    selectedPredefinedDateRange,
    selectedTab,
    selectedToSiteSupplierTradeContact,
  } = useSelector(selectDashboardFilters);

  const selectedCostCenters = useSelector(selectCostCenters);
  const selectedSites = useSelector(selectSites);

  const timeAggregate =
    DashboardService.getAggregationFromDateRange(selectedDateRange);

  const searchBody = getSearchBody({
    filterGroups: {
      acceptStates: selectedAcceptState,
      article: selectedArticle,
      articleNumber: selectedArticleNumber,
      dateRange: selectedDateRange,
      fromSite: [...selectedFromSite, ...selectedSites],
      permittedCostCenters: selectedPermittedCostCenters,
      permittedToSites: selectedPermittedToSites,
      processStates: selectedProcessState,
      recipients: selectedRecipient,
      selectedCostCenters: [...selectedCostCenter, ...selectedCostCenters],
      selectedCustomFields,
      selectedSites,
      settledStatus: selectedSettledStatus,
      suppliers: selectedSupplier,
      toSiteRecipient: selectedToSiteRecipient,
      toSiteSupplier: selectedToSiteSupplier,
    },
  });

  const { data: currentUser } = useQueryUserData(true);

  const featureFlags =
    currentUser?.companyAccountInfo?.data?.featureFlags ?? {};

  const handleChangeFilterGroup = () => {
    const filterGroup = filterGroups.find(
      ({ id }) => id === selectedFilterGroup,
    );

    dispatch(
      setDashboard_selectedToSiteRecipient(
        filterGroup.filters.selectedToSiteRecipient ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedToSiteSupplier(
        filterGroup.filters.selectedToSiteSupplier ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedCostCenter(
        filterGroup.filters.selectedCostCenter ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedArticleNumber(
        filterGroup.filters.selectedArticleNumber ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedArticle(filterGroup.filters.selectedArticle ?? []),
    );
    dispatch(
      setDashboard_selectedSupplier(filterGroup.filters.selectedSupplier ?? []),
    );
    dispatch(
      setDashboard_selectedRecipient(
        filterGroup.filters.selectedRecipient ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedProcessState(
        filterGroup.filters.selectedProcessState ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedAcceptState(
        filterGroup.filters.selectedAcceptState ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedSettledStatus(
        filterGroup.filters.selectedSettledStatus ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedFromSite(filterGroup.filters.selectedFromSite ?? []),
    );
    dispatch(
      setDashboard_selectedPermittedToSites(
        filterGroup.filters.selectedPermittedToSites ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedPermittedCostCenters(
        filterGroup.filters.selectedPermittedCostCenters ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedCustomFields(
        filterGroup.filters.selectedCustomFields ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedToSiteSupplierTradeContact(
        filterGroup.filters.selectedToSiteSupplierTradeContact ?? [],
      ),
    );
  };

  // Callback from FilterGroups so that Dashboard state selectedToSiteSupplier, etc. are up-to-date, data is filtered and chart is updated accordingly
  const handleFilterChange = (type, customField, filterValue) => {
    switch (type) {
      case FilterGroupFilter.FILTER.SELECTED_TO_SITE_RECIPIENT: {
        Log.info(
          `Change filter value of ${type}`,
          {
            from: selectedToSiteRecipient,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter toSiteRecipient',
          Log.FEATURE.DASHBOARD,
        );

        dispatch(setDashboard_selectedToSiteRecipient(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER: {
        Log.info(
          `Change filter value of ${type}`,
          {
            from: selectedToSiteSupplier,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter toSiteSupplier',
          Log.FEATURE.DASHBOARD,
        );

        dispatch(setDashboard_selectedToSiteSupplier(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_COST_CENTER: {
        Log.info(
          `Change filter value of ${type}`,
          {
            from: selectedCostCenter,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Filter cost center', Log.FEATURE.DASHBOARD);

        dispatch(setDashboard_selectedCostCenter(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_ARTICLE_NUMBER: {
        Log.info(
          `Change filter value of ${type}`,
          {
            from: selectedArticleNumber,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter article number',
          Log.FEATURE.DASHBOARD,
        );

        dispatch(setDashboard_selectedArticleNumber(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_ARTICLE: {
        Log.info(
          'Change filter value of ' + type,
          {
            from: selectedArticle,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Filter article', Log.FEATURE.DASHBOARD);

        dispatch(setDashboard_selectedArticle(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_SUPPLIER: {
        Log.info(
          'Change filter value of ' + type,
          {
            from: selectedSupplier,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Filter supplier', Log.FEATURE.DASHBOARD);

        dispatch(setDashboard_selectedSupplier(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_RECIPIENT: {
        Log.info(
          'Change filter value of ' + type,
          {
            from: selectedRecipient,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Filter recipient', Log.FEATURE.DASHBOARD);

        dispatch(setDashboard_selectedRecipient(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER_TRADE_CONTACT: {
        Log.info(
          'Change filter value of ' + type,
          {
            from: selectedToSiteSupplierTradeContact,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter toSiteSupplierTradeContact',
          Log.FEATURE.DASHBOARD,
        );

        dispatch(setDashboard_selectedToSiteSupplierTradeContact(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_PROCESS_STATE: {
        Log.info(
          'Change filter value of ' + type,
          {
            from: selectedProcessState,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter process state',
          Log.FEATURE.DASHBOARD,
        );

        dispatch(setDashboard_selectedProcessState(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_ACCEPT_STATE: {
        Log.info(
          'Change filter value of ' + type,
          {
            from: selectedAcceptState,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Filter accept state', Log.FEATURE.DASHBOARD);

        dispatch(setDashboard_selectedAcceptState(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_SETTLED_STATUS: {
        Log.info(
          'Change filter value of ' + type,
          {
            from: selectedSettledStatus,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter settled status',
          Log.FEATURE.DASHBOARD,
        );

        dispatch(setDashboard_selectedSettledStatus(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_FROM_SITE: {
        Log.info(
          'Change filter value of ' + type,
          {
            from: selectedFromSite,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Filter fromSite', Log.FEATURE.DASHBOARD);

        dispatch(setDashboard_selectedFromSite(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_PERMITTED_TO_SITES: {
        Log.info(
          'Change filter value of ' + type,
          {
            from: selectedPermittedToSites,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter permittedToSites',
          Log.FEATURE.DASHBOARD,
        );

        dispatch(setDashboard_selectedPermittedToSites(filterValue));

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_PERMITTED_COST_CENTERS: {
        Log.info(
          'Change filter value of ' + type,
          {
            from: selectedPermittedCostCenters,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter permittedCostCenters',
          Log.FEATURE.DASHBOARD,
        );

        dispatch(setDashboard_selectedPermittedCostCenters(filterValue));

        break;
      }

      default: {
        break;
      }
    }

    const newSelectedCustomFields = cloneDeep(selectedCustomFields);

    if (customField) {
      const selectedCustomFieldIndex = selectedCustomFields.findIndex(
        ({ key }) => key === customField.key,
      );

      Log.info(
        `Change filter value of ${customField.key}`,
        {
          from: selectedCustomFields[selectedCustomFieldIndex]?.filterValue,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Filter custom field', Log.FEATURE.DASHBOARD);

      if (selectedCustomFieldIndex === -1) {
        // If the custom field has not filter applied yet, push it to the selectedCustomFields filter.
        newSelectedCustomFields.push({
          filterValue,
          key: customField.key,
        });
      } else if (filterValue.length > 0) {
        // If a filter value has been added, overwrite the custom field filter
        newSelectedCustomFields[selectedCustomFieldIndex].filterValue =
          filterValue;
      } else {
        // If all filter values have been removed, remove the whole custom field entry from the selectedCustomFields variable
        // because we don't want empty custom field entries in selectedCustomFields.
        newSelectedCustomFields.splice(selectedCustomFieldIndex, 1);
      }

      dispatch(setDashboard_selectedCustomFields(newSelectedCustomFields));
    }
  };

  // Callback from DetailOverview so that Dashboard selectedArticleInDetailOverview is up-to-date
  const handleSelectedArticleInDetailOverviewChange = (
    selectedArticleInDetailOverview,
  ) => {
    dispatch(
      setDashboard_selectedArticleInDetailOverview(
        selectedArticleInDetailOverview,
      ),
    );
  };

  const handleDateRangeChange = (value) => {
    const numberOfDays = dateUtils.getNumberOfDays(value[0], value[1]);
    if (numberOfDays >= 0 && numberOfDays <= 3650) {
      Log.info(
        'Change filter value of selected date range',
        {
          from: selectedDateRange,
          to: value,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Filter date range', Log.FEATURE.DASHBOARD);

      dispatch(setDashboard_selectedDateRange(value));
      dispatch(setDashboard_individualDateRange(true));

      // Per default, the first selectable unit is selected.
      // Anyway, you should find a better solution for this in the future.
      dispatch(
        setDashboard_selectedUnit(
          DashboardService.getMajorSelectableUnitsForArchiveMode()[0].id,
        ),
      );
    }
  };

  const handlePredefinedDateRangeChange = (value) => {
    Log.info(
      'Change filter value of selected predefined date range',
      {
        from: selectedPredefinedDateRange,
        to: value,
      },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      `Filter predefined date range: ${value}`,
      Log.FEATURE.DASHBOARD,
    );

    const timeframe = dateUtils.getTimeframeFromDateRange(value);

    dispatch(setDashboard_selectedPredefinedDateRange(value));
    dispatch(setDashboard_selectedDateRange(timeframe));
    dispatch(setDashboard_individualDateRange(false));
  };

  const handleUnitChange = (unit) => {
    Log.info(
      'Change filter value of unit',
      {
        from: selectedUnit,
        to: unit,
      },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter unit', Log.FEATURE.DASHBOARD);

    dispatch(setDashboard_selectedUnit(unit));
  };

  const handleChangeTab = (_event, value) => {
    if (value === TAB.DASHBOARD.CHARTS.INDEX) {
      Log.productAnalyticsEvent('Open charts tab', Log.FEATURE.DASHBOARD);
    }

    if (value === TAB.DASHBOARD.REPORT.INDEX) {
      Log.productAnalyticsEvent('Open reports tab', Log.FEATURE.DASHBOARD);
    }

    dispatch(setDashboard_tab(value));
  };

  const resetFilters = () => {
    dispatch(setDashboard_selectedToSiteRecipient([]));
    dispatch(setDashboard_selectedToSiteSupplier([]));
    dispatch(setDashboard_selectedCostCenter([]));
    dispatch(setDashboard_selectedArticleNumber([]));
    dispatch(setDashboard_selectedArticle([]));
    dispatch(setDashboard_selectedSupplier([]));
    dispatch(setDashboard_selectedRecipient([]));
    dispatch(setDashboard_selectedProcessState([]));
    dispatch(setDashboard_selectedAcceptState([]));
    dispatch(setDashboard_selectedSettledStatus([]));
    dispatch(setDashboard_selectedFromSite([]));
    dispatch(setDashboard_selectedPermittedToSites([]));
    dispatch(setDashboard_selectedPermittedCostCenters([]));
    dispatch(setDashboard_selectedCustomFields([]));
    dispatch(setDashboard_selectedToSiteSupplierTradeContact([]));
  };

  useEffect(() => {
    dispatch(setPageTitle('Statistiken'));
    document.title = 'VESTIGAS - Statistiken';
  }, []);

  useEffect(() => {
    if (selectedFilterGroup) {
      handleChangeFilterGroup();
    }
  }, [selectedFilterGroup]);

  return {
    featureFlags,
    handleChangeTab,
    handleDateRangeChange,
    handleFilterChange,
    handlePredefinedDateRangeChange,
    handleSelectedArticleInDetailOverviewChange,
    handleUnitChange,
    individualDateRange,
    resetFilters,
    searchBody,
    selectedDateRange,
    selectedPredefinedDateRange,
    selectedTab,
    selectedUnit,
    timeAggregate,
  };
};
