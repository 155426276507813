import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, InputLabel, MenuItem, Select } from '@mui/material';

import PermissionGrantService from '~/services/permissionGrant.service';

import PermissionGrant, {
  SUBJECT_TYPES,
} from '~/models/masterdata/PermissionGrant';
import Permissions from '~/models/masterdata/Permissions';

import { LightTooltip } from '~/utils/componentUtils';
import Log from '~/utils/Log';
import PromiseUtils from '~/utils/promiseUtils';

import { withErrorBoundary } from '~/ui/atoms';

import { PermissionForm } from '~/components/settings/masterData/permissionGrant/PermissionForm';
import { PermissionGrantMultiPicker } from '~/components/settings/masterData/permissionGrant/PermissionGrantMultiPicker';

export const PermissionGrantPicker = withErrorBoundary(
  forwardRef(({ grantPermissionsOnCostCenters }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [permissions, setPermissions] = useState(new Permissions());
    const [pickedSubjects, setPickedSubjects] = useState([]);
    const [pickedSubjectType, setPickedSubjectType] = useState(
      PermissionGrant.SUBJECT_TYPE.USER.KEY,
    );

    useImperativeHandle(ref, () => ({
      async submit(siteId) {
        const promises = [];

        if (!permissions.permissionGranted()) {
          return;
        }

        for (const subject of pickedSubjects) {
          const body = {
            permissions: permissions.getBackendPermissions(),
          };

          Log.info(
            'Submit permission grant form',
            body,
            Log.BREADCRUMB.FORM_SUBMIT.KEY,
          );

          const promise = PermissionGrantService.createNewPermissionGrant(
            pickedSubjectType,
            subject,
            PermissionGrant.ENTITY_TYPE.SITE.KEY,
            siteId,
            body,
          );

          promises.push(promise);
        }

        return PromiseUtils.allResolved(promises);
      },

      async submitCostCenters(costCenterIds) {
        const promises = [];

        if (!permissions.permissionGranted()) {
          return;
        }

        for (const costCenterId of costCenterIds) {
          for (const subject of pickedSubjects) {
            const body = {
              permissions: permissions.getBackendPermissions(),
            };

            Log.info(
              'Submit permission grant form',
              body,
              Log.BREADCRUMB.FORM_SUBMIT.KEY,
            );

            const promise = PermissionGrantService.createNewPermissionGrant(
              pickedSubjectType,
              subject,
              PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
              costCenterId,
              body,
            );

            promises.push(promise);
          }
        }

        return PromiseUtils.allResolved(promises);
      },
    }));

    const handleChangeSubjectType = (event) => {
      Log.info(
        'Change form value of subject type',
        {
          from: pickedSubjectType,
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change subject type in create site wizard',
        Log.FEATURE.WIZARD,
      );

      setPickedSubjectType(event.target.value);
      setPickedSubjects([]);
    };

    const handleChangeSubjects = (event) => {
      const newPickedSubjects = event.map(({ id }) => id);

      Log.info(
        'Change form value of subjects',
        {
          from: pickedSubjects,
          to: newPickedSubjects,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change subjects in create site wizard',
        Log.FEATURE.WIZARD,
      );

      setPickedSubjects(newPickedSubjects);
    };

    const handleChangeRole = (event) => {
      Log.info(
        'Change form value of role',
        {
          from: permissions.getDefaultRoleName(),
          to: event.target.value,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        `Change role (${event.target.value}) in create site wizard`,
        Log.FEATURE.WIZARD,
      );

      const newPermissions = new Permissions();

      if (event.target.value === Permissions.INDIVIDUAL_ROLE) {
        setIsOpen(true);
        return;
      }

      newPermissions.initWithDefaultRole(event.target.value);
      setPermissions(newPermissions);
    };

    const openPermissionForm = () => {
      Log.productAnalyticsEvent(
        'Open permission form in create site wizard',
        Log.FEATURE.WIZARD,
      );
      setIsOpen(true);
    };

    const permissionFormSuccess = (permissions) => {
      setIsOpen(false);
      setPermissions(permissions);
    };

    const permissionFormAbort = () => {
      setIsOpen(false);
    };

    return (
      <div className="rounded-md border border-gray-300 p-4">
        <div className="flex items-center gap-4">
          <div>
            <InputLabel className="text-13px pb-0.5">
              Berechtigung für
            </InputLabel>
            <Select
              className="w-80"
              onChange={handleChangeSubjectType}
              size="small"
              value={pickedSubjectType}
            >
              {Object.entries(SUBJECT_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_for"
            displayPermissionGrantOfCostCentersWithSites={
              grantPermissionsOnCostCenters
            }
            onChange={handleChangeSubjects}
            pickedIds={pickedSubjects}
            subjectType={pickedSubjectType}
            fullWidth
          />
        </div>

        <div className="mt-4 flex items-end gap-4">
          <div>
            <InputLabel className="text-13px pb-0.5">
              Berechtigung als
            </InputLabel>
            <Select
              key={0}
              className="w-80"
              onChange={handleChangeRole}
              renderValue={(id) => {
                if (!permissions.permissionGranted()) {
                  return (
                    <span className="text-mui-not-selected-grey">
                      Rolle wählen
                    </span>
                  );
                }

                return Permissions.getPickableRoles().find(
                  (option) => option.id === id,
                ).name;
              }}
              size="small"
              value={permissions.getDefaultRoleName() ?? 'None'}
            >
              {Permissions.getPickableRoles().map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="mb-0.5">
            <LightTooltip title="Detaillierte Berechtigungen einsehen">
              <Button
                color="primary"
                onClick={openPermissionForm}
                variant="outlined"
              >
                Details verwalten
              </Button>
            </LightTooltip>
          </div>
        </div>
        <PermissionForm
          formAbort={permissionFormAbort}
          formSuccess={permissionFormSuccess}
          open={isOpen}
          permissions={permissions}
        />
      </div>
    );
  }),
  'Daten konnten nicht geladen werden.',
);
