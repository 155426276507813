import ArrayUtils from '~/utils/arrayUtils';

import { FILTER_OPERATOR_BACKEND } from './constants';
import FilterNew from './FilterNew';
import FilterProps from './FilterProps';

export default class BackendFilter {
  constructor(backendFilter) {
    // Use this in the future for the backend filters
    this.name = backendFilter.name;
    this.comp = backendFilter.comp;
    this.value = backendFilter.value;
    this.caseSensitive = backendFilter.caseSensitive;
  }

  /**
   * Gets all filters that can be used in API requests to the backend based on the supported fields and operators.
   *
   * @return {Array} An array of filter configurations for each field that can be filtered.
   */
  static getApplicableBackendFilters() {
    // This code is not clean and must be refactored in the future:
    // dlnProperties is actually "field". Thus, we need to filter FilterProps.FILTER_MAPPING by "page".
    // Currently, this is not needed because we only have filters for delivery notes. This will change as soon
    // as we also start to load paginated invoices.

    // Get all fields that can be filtered
    const filterableFields = ArrayUtils.removeDuplicates(
      FilterProps.FILTER_MAPPING.filter(({ BACKEND_FILTER }) =>
        Boolean(BACKEND_FILTER),
      ).map(({ FIELD }) => FIELD),
    );

    const filters = [];

    // Generate the filter configurations for each field that can be filtered
    for (const field of filterableFields) {
      for (const operator of BackendFilter.getApplicableBackendOperators()) {
        filters.push(new FilterNew({ field, operator }));
      }
    }

    return filters;
  }

  /**
   * Get all filter operators that can be used in API requests to the backend.
   *
   * @return {Array} An array of applicable backend operators.
   */
  static getApplicableBackendOperators() {
    return FilterProps.OPERATOR_STRING_MAPPING.map(
      ({ OPERATOR }) => OPERATOR,
    ).filter(Boolean);
  }

  /**
   * Filter operators supported in API requests to the backend.
   */
  static OPERATOR = FILTER_OPERATOR_BACKEND;
  /**
   * Field names that can be filtered in the backend.
   */
  static FILTER = {
    ARTICLE_NAME: 'article_name',
    ARTICLE_NUMBER: 'article_number',
    AUTHORIZED_ACCOUNTING_REFERENCE_ID: 'authorized_accounting_reference_id',
    CONFIRMED_ACCOUNTING_REFERENCE_ID: 'confirmed_accounting_reference_id',
    CONFIRMED_SITE_ID: 'confirmed_site_id',
    CONFIRMED_SITE_NAME: 'confirmed_site_name',
    COST_CENTER: 'confirmed_accounting_reference_name',
    DLN_ACCEPT_STATE: 'dln_accept_state',
    DLN_NR: 'dln_nr',
    DLN_SETTLED_STATUS: 'billed_state',
    LICENSE_PLATE_NUMBER: 'license_plate_number',
    LOADING_LOCATION: 'loading_location',
    PROCESS_STATE: 'process_state',
    QUERY: 'search',
    RECIPIENT_NAME: 'recipient_name',
    SUPPLIER_ASSIGNED_SITE_NAME: 'supplier_assigned_site_name',
    SUPPLIER_NAME: 'supplier_name',
  };
}
